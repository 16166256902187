import React from "react";
import {IOutageComparison, Status} from "../../types";
import {locale} from "../../locale";
import { convertAndDisplayVolumeUnit } from "../../utilities/UnitConversions";
import useTypedSelector from "../../hooks/useTypedSelector";

interface LocalAreaImpactTooltipInfoProps {
    outage: IOutageComparison;
}

const LocalAreaImpactTooltipInfo: React.FC<LocalAreaImpactTooltipInfoProps> = (props) => {
    const unit = useTypedSelector(state => state.app.volumeUnit);

    let localAreaBaseCapability;
    if(props.outage.changeStatus === Status.REMOVED){
        localAreaBaseCapability = props.outage.previous?.localAreaBaseCapability
    } else {
        localAreaBaseCapability = props.outage.current?.localAreaBaseCapability
    }

    return (
        <span>
                {locale("outageCard.tooltip.localArea")}
            {" "}<b>{convertAndDisplayVolumeUnit(Number(localAreaBaseCapability?.replace(",", "") ?? '0'), unit)}</b>
            </span>
    )
};

export default LocalAreaImpactTooltipInfo