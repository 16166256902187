import * as S from "../styles";
import {locale} from "../locale";
import leftChevrons from "../icons/chevrons-left-charcoal.svg";
import leftChevron from "../icons/chevron-left-charcoal.svg";
import rightChevron from "../icons/chevron-right-charcoal.svg";
import rightChevrons from "../icons/chevrons-right-charcoal.svg";
import React from "react";

export class PagingInfo<T> {
    private readonly startPagesVisibleCount: number;
    private readonly endPagesVisibleCount: number;
    public allItems: T[];
    public items: T[];
    public totalPages: number;
    public readonly currentPage: number;
    public readonly itemsPerPage: number;

    constructor(allItems: T[], itemsPerPage = 15, currentPage = 1, startPagesVisibleCount = 3, endPagesVisibleCount = 3) {
        this.allItems = allItems;
        this.currentPage = currentPage;
        this.itemsPerPage = itemsPerPage;
        this.startPagesVisibleCount = startPagesVisibleCount;
        this.endPagesVisibleCount = endPagesVisibleCount;
        this.totalPages = Math.ceil(allItems.length / this.itemsPerPage);
        this.items = allItems.slice((itemsPerPage*(currentPage-1)), (itemsPerPage*currentPage));
    }

    isCurrentPage(x: number): boolean {
        return x === this.currentPage;
    }

    get previousPage(): number {
        return this.currentPage - 1;
    }

    get nextPage(): number {
        return this.currentPage + 1;
    }

    get lastPage(): number {
        return this.totalPages;
    }

    canGoToPage(pageNumber: number): boolean {
        return pageNumber <= this.totalPages && pageNumber > 0 && this.currentPage !== pageNumber;
    }

    goToPage(pageNumber: number): PagingInfo<T> {
        if (this.canGoToPage(pageNumber)) {
            return new PagingInfo<T>(this.allItems, this.itemsPerPage, pageNumber);
        }
        return this;
    }

    get visiblePages(): (number|null)[] {
        // pages around current page are visible
        let items = [this.currentPage-1, this.currentPage, this.currentPage+1];

        // add start pages visible
        for (let i=1; i <= this.startPagesVisibleCount; i++) {
            items.push(i);
        }

        // add end pages visible
        for (let i=0; i < this.endPagesVisibleCount; i++) {
            items.push(this.totalPages-i);
        }

        // remove duplicates & page number out of range
        const toReturn: (number|null)[] = [];
        items =  items.filter((x, index) => items.indexOf(x) === index).filter(x => x > 0 && x <= this.totalPages).sort((a,b) => a-b);
        let previous = 0;

        // add gaps where
        items.forEach(i => {
            if (i !== previous+1) {
                toReturn.push(null);
            }
            toReturn.push(i);
            previous = i;
        });
        return toReturn;
    }
}

interface PageSelectorProps {
    pagingInfo: PagingInfo<any>;
    saveState: (a: any) => void;
    itemsPerPage?: number;
}

const PageSelector: React.FC<PageSelectorProps> = (props) => {
    function goto(pageNumber: number) {
        props.saveState(props.pagingInfo.goToPage(pageNumber));
    }

    if (props.pagingInfo === undefined)
        return null;

    return <S.FlexContainerRowNoWrap style={{alignContent: "space-around"}}>
            <S.PagingComponent>
                <S.FlexContainerRowNoWrap style={{width: "275px"}}>
                    <S.FlexContainerRowNoWrap style={{marginRight: "auto"}}>
                        <S.PagingComponentLink isActive={false}
                                               isEnabled={props.pagingInfo.canGoToPage(1)}
                                               isHidden={!props.pagingInfo.canGoToPage(1)}
                                               onClick={() => goto(1)} title={locale("pager.altText.first")}>
                            <img alt={locale("pager.altText.first")} src={leftChevrons}/></S.PagingComponentLink>
                        <S.PagingComponentLink isActive={false}
                                               isEnabled={props.pagingInfo.canGoToPage(props.pagingInfo.previousPage)}
                                               isHidden={!props.pagingInfo.canGoToPage(1)}
                                               onClick={() => goto(props.pagingInfo.previousPage)} title={locale("pager.altText.previous")}>
                            <img alt={locale("pager.altText.previous")} src={leftChevron}/></S.PagingComponentLink>
                    </S.FlexContainerRowNoWrap>
                    <S.FlexContainerRowNoWrap>
                        {props.pagingInfo.visiblePages.map(x => <S.PagingComponentLink key={`page-selector-${x}`}
                                                                                  isActive={x !== null && props.pagingInfo.isCurrentPage(x)}
                                                                                  isEnabled={props.pagingInfo.canGoToPage(x ?? 1)}
                                                                                  onClick={() => goto(x ?? 0)}>{x ?? "..."}</S.PagingComponentLink>)}
                    </S.FlexContainerRowNoWrap>
                    <S.FlexContainerRowNoWrap style={{marginLeft: "auto"}}>
                        <S.PagingComponentLink isActive={false}
                                               isEnabled={props.pagingInfo.canGoToPage(props.pagingInfo.nextPage)}
                                               isHidden={!props.pagingInfo.canGoToPage(props.pagingInfo.nextPage)}
                                               onClick={() => goto(props.pagingInfo.nextPage)} title={locale("pager.altText.next")}>
                            <img alt={locale("pager.altText.next")} src={rightChevron}/></S.PagingComponentLink>
                        <S.PagingComponentLink isActive={false}
                                               isEnabled={props.pagingInfo.canGoToPage(props.pagingInfo.lastPage)}
                                               isHidden={!props.pagingInfo.canGoToPage(props.pagingInfo.nextPage)}
                                               onClick={() => goto(props.pagingInfo.lastPage)} title={locale("pager.altText.last")}>
                            <img alt={locale("pager.altText.last")} src={rightChevrons}/></S.PagingComponentLink>
                    </S.FlexContainerRowNoWrap>
                </S.FlexContainerRowNoWrap>
            </S.PagingComponent>
        </S.FlexContainerRowNoWrap>
};

export default PageSelector;