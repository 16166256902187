import React, {useState, useEffect} from 'react';
import * as S from '../styles';
import {locale} from '../locale';
import {Chips} from '../types';

interface Props {
    data: string;
}

interface IChip {
    tsp_eligible: boolean;
    partial_impact: boolean;
    potential_impact: boolean;
}

const OutageCardChip: React.FC<Props> = (props) => {
    const [chips, setChips] = useState<IChip>({
        tsp_eligible: false,
        partial_impact: false,
        potential_impact: false,
    });

    useEffect(() => {
        const info = props.data.toLowerCase();
        setChips({
            tsp_eligible: findInString(info, 'tsp eligible'),
            partial_impact: findInString(info, 'partial impact'),
            potential_impact: findInString(info, 'potential impact'),
        })
    }, [props.data]);

    const findInString = (str: string, chip: string) => {
        const result = str.search(chip);
        return result >= 0;

    };

    return (
        <S.ChipWrapper>
            {chips.tsp_eligible && <S.Chip type={Chips.TSP_ELIGIBLE}>{locale('chip.tspEligible')}</S.Chip>}
            {chips.partial_impact && <S.Chip type={Chips.PARTIAL_IMPACT}>{locale('chip.partialImpact')}</S.Chip>}
            {chips.potential_impact && <S.Chip type={Chips.POTENTIAL_IMPACT}>{locale('chip.potentialImpact')}</S.Chip>}
        </S.ChipWrapper>
    )
};

export default OutageCardChip;