import styled from "styled-components";


export const CalenderInfoContainer = styled.div`
    position: absolute;
    background: #f0f0f0;
    margin-top: -6px;
    margin-left: -1px;
    width: 230px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #aeaeae;
    padding: 5px;
    text-align: center;
    a {
        margin-top: unset;
        margin-bottom: unset;
        overflow-wrap: anywhere;
        font-size: 13px;
    }
`;