const colors = {
    primary: '#1A72C8',
    hover_primary: "#4295E6",
    secondary: '#959595',
    selected: '#555759',
    background: '#FCFCFC',
    charcoal: '#8C878A',
    charcoal_text:  '#555759',
    black_text: '#000000',
    changed: '#53C0D5',
    error_border: 'red',
    error_background: '#F9CCCA',
    new: '#468B45',
    removed: '#FFF579',
    changedForeground: '#FFFFFF',
    newForeground: '#FFFFFF',
    removedForeground: '#000000',
    diff_red: '#B2282E',
    tc_green: '#7F982D',
    tc_blue: '#82A6DD',
    tc_blue_100: '#1A72C8',
    light_blue: '#E6EEF7',
    orange: '#FF8A00',

    // chart colors
    historical_flow: '#6E6E6F',
    contract: '#000000',
    current_flow: '#000000',
};

export default colors;