import React from 'react';
import arrow from "../icons/chevron_left.svg";
import * as S from "../styles"
import {useDispatch} from "react-redux";
import useTypedSelector from "../hooks/useTypedSelector";
import {clearIsDirty, showDiscardConfirm} from "../actions/appActions";

// TODO Change color of text when it is the active page

interface Props {
    leftContent?: string;
    centerContent?: string;
    rightContent?: string;
    handleBackNavigation?: any;
    isOverlay?: boolean;
}

const BackNavigationHeader: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const isDirty = useTypedSelector(state => state.app.isDirty);

    // TODO consolidate this navigate in these components BackNavigationHeader, Menu, NavIcon
    function onNavigate() {
        if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                props.handleBackNavigation();
            }));

            return;
        }

        props.handleBackNavigation();
    }
    return (
        <S.BackNavigationHeader style={{zIndex: 2}} onClick={onNavigate} leftAligned={props.leftContent != null}>
            <div>
                <S.BackIcon src={arrow} alt={props.leftContent}/>
                {props.leftContent != null ? <S.HeaderAreaName>{props.leftContent}</S.HeaderAreaName> : null}
                {props.centerContent != null ?
                    <S.BackNavigationCenterContent>{props.centerContent}</S.BackNavigationCenterContent> : null}
                {props.rightContent != null ?
                    <div style={{padding: "5px", marginLeft: "auto"}}>{props.rightContent}</div> : null}
            </div>
        </S.BackNavigationHeader>
    );
};

export default BackNavigationHeader;