import {SET_CURRENT_SYSTEM_REPORT_DATAGRID,
    SET_CSR_DURATION,
    CurrentSystemReportActionTypes} from '../types/CurrentSystemReportTypes';
import { ICurrentSystemReportData} from "../types";

export interface ICurrentReportSystemDatagridState {
    duration: number;
    receiptGrid: ICurrentSystemReportData[];
    deliveryGrid: ICurrentSystemReportData[];
    linepackGrid: ICurrentSystemReportData[];
}

export const initialDataGridState: ICurrentReportSystemDatagridState={
    duration: 1,
    receiptGrid: [],
    deliveryGrid: [],
    linepackGrid: []
}

export function currentSystemReportReducer(state=initialDataGridState, action: CurrentSystemReportActionTypes): ICurrentReportSystemDatagridState{
    if (state===undefined){
        return state;
    }
    switch (action.type){
        case SET_CURRENT_SYSTEM_REPORT_DATAGRID:
            return {
                ...state,
                duration: action.duration,
                receiptGrid: action.receiptGrid,
                deliveryGrid: action.deliveryGrid,
                linepackGrid: action.linepackGrid
            };
        case SET_CSR_DURATION:
            return{
                ...state,
                duration: action.duration
            };
        default:
            return state
    }
}