import React from "react";
import MainlineMaintenanceWidget from "./widgets/MainlineMaintenanceWidget";
import * as S from "../../styles";

const MainlineDashboard: React.FC = () => {
    return <S.DashboardGridContainer>
        <S.DashboardGridItem size={2}>
            <MainlineMaintenanceWidget/>
        </S.DashboardGridItem>
    </S.DashboardGridContainer>
};

export default MainlineDashboard;
