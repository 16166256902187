import React from 'react';
import * as S from "../../styles";
import {IArea} from "../../types";
import {locale} from "../../locale";

interface Props {
    area: IArea;
    columnWidths: any;
    hasRemoveColumn?: boolean;
}

const HeaderRowOutage: React.FC<Props> = (props) => {
    return <S.TableHeaderRow key={props.area.id + "-OutageHeaderRow"}>
        {!props.area.isPlantTurnAround &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.outageId}>
                <S.TableCellHeaderText>{locale('outageId')}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
        {!props.area.isPlantTurnAround &&
        <S.TableCellWrapper>
            <S.TableColumn width={props.columnWidths.description}>
                <S.TableCellHeaderText>{locale('outageDescription')}</S.TableCellHeaderText>
            </S.TableColumn>
        </S.TableCellWrapper>}
        {!props.area.isPlantTurnAround &&
        <S.TableCellWrapper>
            <S.TableColumn width={props.columnWidths.impact}>
                <S.TableCellHeaderText>{locale('serviceAllowableArea')}</S.TableCellHeaderText>
            </S.TableColumn>
        </S.TableCellWrapper>}
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.start}>
                <S.TableCellHeaderText>{locale('start')}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.end}>
                <S.TableCellHeaderText>{locale('end')}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>
        {!props.area.isPlantTurnAround &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.capability}>
                <S.TableCellHeaderText>{locale("capability")}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
        {props.area.hasLocalOutages &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.capability}>
                <S.TableCellHeaderText>{locale("localBaseCapability")}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
        {props.area.hasLocalOutages &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.capability}>
                <S.TableCellHeaderText>{locale("localOutageCapability")}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
        {props.area.hasTypicalFlow &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.typicalFlow}>
                <S.TableCellHeaderText>{locale('typicalFlow')}</S.TableCellHeaderText>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
        {props.hasRemoveColumn &&
        <S.TableCellWrapperNoGrow>
            <S.TableColumnNoGrow width={props.columnWidths.removeRow}>
            </S.TableColumnNoGrow>
        </S.TableCellWrapperNoGrow>}
    </S.TableHeaderRow>
};

export default HeaderRowOutage;