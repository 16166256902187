import React from 'react';

import {locale} from "../locale";
import * as S from "../styles";
import linksStore from "../utilities/linksStore";
import alertIcon from "../icons/alert-circle.svg";
import useTypedSelector from "../hooks/useTypedSelector";

const BetaBanner: React.FC = () => {
    const showBanner = useTypedSelector(state => state.toggles.showBanner);

    function getBannerContents() {
       // if(process.env.REACT_APP_ENV === "qual"){
       //     return (
       //        <p>
       //             {locale('banner.beta.qual')}
       //             <a href="http://my.tccustomerexpress.com/"
        //               target="_blank"
        //               rel="noopener noreferrer">
        //                my.tccustomerexpress.com
        //            </a>
        //        </p>
        //    )
        //}
        return (
            <div>
                <p style={{color:'white',}}>
                    {locale('banner.beta.part1')}
                    <strong>{locale('banner.beta.new')}</strong>
                </p>
                <p style={{color:'white',}}>
                    {locale('banner.beta.part2')}
                </p>
                <p>
                    <a href={linksStore.contactUs}
                    target="_blank"
                    rel="noopener noreferrer">
                        {locale("banner.beta.link1")}
                    </a>
                </p>
                <p>
                    {locale('banner.beta.part3')}
                </p>
            </div>
        )
    }

    return (showBanner ?
            <S.GlobalBanner>
                <S.GlobalBannerChildWrapper>
                    <S.Icon src={alertIcon}/>
                    {getBannerContents()}
                </S.GlobalBannerChildWrapper>
            </S.GlobalBanner>
            :
            null
    );
};

export default BetaBanner;