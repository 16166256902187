import {IToggle} from "./index";

export const SET_TOGGLES = 'SET_TOGGLES';
export const SET_TOGGLES_LOADED = 'SET_TOGGLES_LOADED';
export const CLEAR_TOGGLES_LOADED = 'CLEAR_TOGGLES_LOADED';

interface ISetTogglesAction {
    type: typeof SET_TOGGLES;
    toggles: IToggle[];
}

interface ISetTogglesLoaded {
    type: typeof SET_TOGGLES_LOADED;
    loaded: boolean;
}

interface IClearTogglesLoaded {
    type: typeof CLEAR_TOGGLES_LOADED;
}

export type ToggleActionTypes = ISetTogglesAction | ISetTogglesLoaded | IClearTogglesLoaded;