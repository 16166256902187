import React, {useEffect, useState} from 'react';
import * as S from "../styles";
import loader from "../icons/loader.svg";
import {locale} from "../locale";
import useTypedSelector from "../hooks/useTypedSelector";

const BusyModal: React.FC = () => {
    const progress = useTypedSelector(x => x.app.progress);
    const [text, setText] = useState<string>(locale("working"));

    useEffect(() => {
        if (progress.total === 0) {
            setText(locale("working"));
        } else {
            setText(`${parseInt((progress.completed*100/progress.total).toString())}${locale("percentComplete")}`);
        }
    }, [progress]);

    return (
        <div>
            <S.BusyModalOverlay>
            </S.BusyModalOverlay>
            <S.BusyModal>
                <S.CenteredH2><S.LoadingIcon src={loader}/></S.CenteredH2>
                <S.CenteredH2>{text}</S.CenteredH2>
            </S.BusyModal>
        </div>
    );
};

export default BusyModal;