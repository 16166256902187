import React from 'react';
import * as S from "../styles";

interface Props {
    name: string;
    number: string;
}

const PhoneNumber: React.FC<Props> = (props) => {
    return (
        <S.PhoneNumberContainer>
            <S.SmallHeading>{props.name}</S.SmallHeading>
            <a href={`tel:${props.number}`}>{props.number}</a>
        </S.PhoneNumberContainer>
    );
};

export default PhoneNumber;