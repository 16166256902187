import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DateUtilities from "../utilities/DateUtilities";

interface Props {
    handleChange: (date: [Date,Date] | null) => void;
    minDate?: Date;
    start?: Date;
    end?: Date;
    date?: any;
    placeholder: string;
}

const StyledInput = styled.input`
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    height: 100%;
    min-width: 220px;
    min-height: 35px;
    border: 1px solid #CFCDCE;
    border-radius: 4px;
    box-sizing: border-box;
    color: #8C878A;
    padding: 7px;
    padding-right: 30px;
`;

const DateRangeInput: React.FC<Props> = (props) => {
    // React datepicker currently has a bug where the datepicker closes automatically on the thing
    // so we manually override this behaviour
    
    const [open, setOpen] = useState<boolean>(false);

    let displayText = "";
    if (props.start) {
        displayText = DateUtilities.WithFormat(DateUtilities.uiDateFormat).Reformat(props.start?.toString());
    }
    if (props.end) {
        displayText += " - " + DateUtilities.WithFormat(DateUtilities.uiDateFormat).Reformat(props.end?.toString());
    }

    const Input = () => { 
        return(
            <StyledInput className="dateInput" onClick={() => setOpen(true) } onBlur={() => setOpen(false)} placeholder={props.placeholder} readOnly value={displayText} />
      )
    };

    const CustomDateInput = forwardRef(Input);
    return (
        <>
            <DatePicker
                selected={props.start}
                startDate={props.start}
                endDate={props.end}
                placeholderText={props.placeholder}
                onChange={(dates: [Date, Date]) => {
                    
                    if (dates) {
                        if (!dates[1] && props.start && !props.end) {
                            props.handleChange([dates[0], props.start]);
                            setOpen(false);
                        } else {
                            props.handleChange(dates);
                        }
                        if (dates.length > 1 && dates[1] !== null) {
                            setOpen(false);
                        }
                    } else {
                        props.handleChange(dates)
                    }
                }}
                shouldCloseOnSelect={false}
                minDate={props.minDate ? new Date() : new Date(1997,  6, 1)}
                isClearable={true}
                open={open}
                selectsRange
                customInput={<CustomDateInput />}
                onClickOutside={() => setOpen(false)}
                closeOnScroll={() => {setOpen(false); return true}}
                showYearDropdown
                showMonthDropdown
                popperModifiers={{
                    flip: {
                        behavior: ["bottom"]
                    },
                    preventOverflow: {
                        enabled: false
                    },
                    hide: {
                        enabled: false
                    }
                }}
            />
        </>
    );
};

export default DateRangeInput;