import DateUtilities from "./DateUtilities";

class Log {
    static debugLoggingEnabled: boolean = process.env.REACT_APP_LOGGING_LEVELS?.toUpperCase().indexOf("DEBUG") !== -1 ?? false;
    static warnLoggingEnabled: boolean = process.env.REACT_APP_LOGGING_LEVELS?.toUpperCase().indexOf("WARN") !== -1 ?? false;
    static errorLoggingEnabled: boolean = process.env.REACT_APP_LOGGING_LEVELS?.toUpperCase().indexOf("ERROR") !== -1 ?? false;

    static debug(message: string) {
        if (this.debugLoggingEnabled) {
            this.log(message);
        }
    }

    static warn(message: string) {
        if (this.warnLoggingEnabled) {
            this.log(message);
        }
    }

    static error(message: string) {
        if (this.errorLoggingEnabled) {
            this.log(message);
        }
    }

    static log(message: string) {
        console.log(`${DateUtilities.Now().toFormat(DateUtilities.serviceDateFormat)} - ${message}`);
    }
}

export default Log;