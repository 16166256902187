import {IToggle} from "../types";
import {
    ToggleActionTypes,
    CLEAR_TOGGLES_LOADED,
    SET_TOGGLES,
    SET_TOGGLES_LOADED,
} from "../types/ToggleActionTypes";

export function setToggles(toggles: IToggle[]): ToggleActionTypes {
    return {
        type: SET_TOGGLES,
        toggles: toggles
    }
}

export function setTogglesLoaded(loaded: boolean): ToggleActionTypes {
    return {
        type: SET_TOGGLES_LOADED,
        loaded: loaded
    }
}

export function clearTogglesLoaded(): ToggleActionTypes {
    return {
        type: CLEAR_TOGGLES_LOADED,
    }
}