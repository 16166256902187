import React, {ChangeEvent, FormEvent, useState, useRef, useEffect} from 'react';
import {locale} from '../locale';
import * as S from "../styles";
import image from '../assets/contact_background.jpg';
import PhoneNumber from "./PhoneNumber";
import {Fetch} from "../utilities/Fetch";
import {useDispatch} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./Footer";
import GoogleAnalyticsUtilities from "../utilities/GoogleAnalyticsUtilities";
import {isMobile} from 'react-device-detect';
import ResultModal from "./ResultModal";
import linksStore from "../utilities/linksStore";
import BetaBanner from "./BetaBanner";
import SubscribeBanner from "./subscriptions/SubscribeBanner";
import useTypedSelector from "../hooks/useTypedSelector";

const ContactUs: React.FC = () => {
    useEffect(() => {
        GoogleAnalyticsUtilities.PageView("ContactUs");
    }, []);
    const initialState = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        comment: undefined,
        contactEmail: true,
        contactPhone: false,
        publishedDateTimeUtc: new Date(),
    };
    const dispatch = useDispatch();
    const [, updateState] = useState();
    const [inputs, setInputs] = useState<any>(initialState);
    const [formRef, setFormRef] = useState();
    const [statusText, setStatusText] = useState(' ');
    const [showResult, setShowResult] = useState<boolean>(false);
    const [sentSuccess, setSentSuccess] = useState<boolean>(false);
    const [userValidated, setUserValidated] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string | null>("");
    const [recaptchaRef, setRecaptchaRef] = useState();
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const commentRef = useRef<HTMLTextAreaElement>(null);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);

    useEffect(() => {
        if (firstNameRef && firstNameRef.current && !isMobile)
            firstNameRef.current.focus();
    }, []);

    const updateInputs = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStatusText(' ');
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (event: FormEvent) => {
        for (const key in inputs) {
            if (inputs[key] === undefined)
                inputs[key] = '';
        }
        setStatusText('');
        if (inputIsValid()) {
            event.preventDefault();
            const payload = Object.assign(inputs, {token: captchaToken});
            Fetch.Build("/feedback", dispatch)
                .withPayload(payload)
                .error(error => {
                    setSentSuccess(false);
                    error ? setStatusText(locale('error.generic')) : setStatusText(`An error occurred: ${error} Please try again later.`)
                    setShowResult(true);
                })
                .success(() => {
                    setInputs({...initialState});
                    formRef.reset();
                    setSentSuccess(true);
                    setStatusText(locale('contactPage.resultMessage.messageSentSuccess'));
                    recaptchaRef.reset();
                    setUserValidated(false);
                    setShowResult(true);
                })
                .post();
        } else {
            updateState({}); // forces re-reneder to show error messages
            event.preventDefault();
        }

    };

    const validateUser = (token: string | null) => {
        // Enables the button to be used, further validation done by google on backend
        setCaptchaToken(token);
        setUserValidated(true);
    };

    const setFocus = (el: HTMLInputElement | HTMLTextAreaElement) => {
        el.focus();
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    };

    const inputIsValid = () => {
        const emailValidate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
        const phoneValidate = /^([0-9]{1,2}?[-. ])?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const nameValidate = /^[A-Za-zÀ-ÖØ-öø-ÿ ]{2,30}$/;

        let isValid = true;
        let focusSet = false;

        if (inputs['firstName'] === '' || !nameValidate.test(String(inputs['firstName']).toLowerCase())) {
            inputs['firstName'] = '';
            isValid = false;
            if (firstNameRef && firstNameRef.current) {
                setFocus(firstNameRef.current);
                focusSet = true;
            }
        }
        if (inputs['lastName'] === '' || !nameValidate.test(String(inputs['lastName']).toLowerCase())) {
            inputs['lastName'] = '';
            isValid = false;
            if (!focusSet && lastNameRef && lastNameRef.current) {
                setFocus(lastNameRef.current);
                focusSet = true;
            }
        }
        if (inputs['email'] === '' || !emailValidate.test(String(inputs['email']).toLowerCase())) {
            inputs['email'] = '';
            isValid = false;
            if (!focusSet && emailRef && emailRef.current) {
                setFocus(emailRef.current);
                focusSet = true;
            }
        }
        if (inputs['contactPhone'] && (inputs['phone'] === '' || !phoneValidate.test(String(inputs['phone'])))) {
            inputs['phone'] = '';
            isValid = false;
            if (!focusSet && phoneRef && phoneRef.current) {
                setFocus(phoneRef.current);
                focusSet = true;
            }
        }
        if (inputs['comment'] === '') {
            isValid = false;
            if (!focusSet && commentRef && commentRef.current) {
                setFocus(commentRef.current);
            }
        }
        return isValid;
    };

    return (
        <div>
            {showResult && <ResultModal text={statusText} success={sentSuccess} onExit={() => {
                setShowResult(false);
                setSentSuccess(false);
            }}/>}
            <BetaBanner/>
            {/* Hardcoded, as the styles don't really need the banner space for the contact us page*/}
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <S.BackNavigationHeader style={{cursor: "default"}}>
                    <S.CenteredPageTitle>{locale("page.header.contact")}</S.CenteredPageTitle>
                </S.BackNavigationHeader>
                <S.BackgroundImage src={image}/>
                <S.ContactWrapper>
                    <S.AllNumbersContainer>
                        <S.ContactPageHeader
                            hiddenOnMobile={false}>{locale('contactPage.headings.contacts')}</S.ContactPageHeader>
                        <PhoneNumber name={locale('contactPage.headings.pipeLineLocal')} number={'403-920-7473'}/>
                        <PhoneNumber name={locale('contactPage.headings.pipeLineFree')} number={'1-877-920-7473'}/>
                        <S.PhoneNumberContainer webLink={true}>
                            <S.SmallHeading>{locale('contactPage.headings.otherContacts')}</S.SmallHeading>
                            <a href={linksStore.customerExpressContact}>tccustomerexpress.com/contacts</a>
                        </S.PhoneNumberContainer>

                        <S.ContactPageHeader
                            hiddenOnMobile={false}>{locale('contactPage.headings.faq')}</S.ContactPageHeader>
                        <S.PhoneNumberContainer webLink={true}>
                            <a href={`${process.env.PUBLIC_URL}/DOP_FAQ_R3_01.pdf`} target={'_blank'} rel="noopener noreferrer">
                                {locale('contactPage.faq.outage')}
                            </a>
                        </S.PhoneNumberContainer>
                        <S.PhoneNumberContainer webLink={true}>
                            <a href={`${process.env.PUBLIC_URL}/Bulletin_FAQ_R3_01.pdf`} target={'_blank'} rel="noopener noreferrer">
                                {locale('contactPage.faq.bulletin')}
                            </a>
                        </S.PhoneNumberContainer>
                        <S.PhoneNumberContainer webLink={true}>
                            <a href={`${process.env.PUBLIC_URL}/FAQ_Current_System_Report.pdf`}>
                                {locale('contactPage.faq.currentSystemReport')}
                            </a>
                        </S.PhoneNumberContainer>
                        <S.PhoneNumberContainer webLink={true}>
                            <a href={`${process.env.PUBLIC_URL}/FAQ_Gas_Day_Summary_report.pdf`}>
                                {locale('contactPage.faq.gasDaySummaryReport')}
                            </a>
                        </S.PhoneNumberContainer>
                    </S.AllNumbersContainer>

                    <S.FormContainer>
                        <S.ContactPageHeader>{locale('contactPage.headings.feedback')}</S.ContactPageHeader>
                        <form ref={ref => setFormRef(ref)} autoComplete="off">
                            <S.FormColumn>
                                <S.TextInput type="text" name="firstName"
                                             placeholder={locale('contactPage.inputField.placeholder.firstName')}
                                             ref={firstNameRef}
                                             onChange={updateInputs}/>
                                {inputs['firstName'] === '' ?
                                    <small>{locale('contactPage.inputField.errorMessage.firstNameError')}</small> :
                                    <small/>}
                                <S.TextInput type="text" name="lastName"
                                             placeholder={locale('contactPage.inputField.placeholder.lastName')}
                                             ref={lastNameRef}
                                             onChange={updateInputs}/>
                                {inputs['lastName'] === '' ?
                                    <small>{locale('contactPage.inputField.errorMessage.lastNameError')}</small> :
                                    <small/>}
                                <S.TextInput type="text" name="email"
                                             placeholder={locale('contactPage.inputField.placeholder.emailAddress')}
                                             ref={emailRef}
                                             onChange={updateInputs}/>
                                {inputs['email'] === '' ?
                                    <small>{locale('contactPage.inputField.errorMessage.emailAddressError')}</small> :
                                    <small/>}
                                <S.TextInput type="tel" name="phone"
                                             placeholder={locale('contactPage.inputField.placeholder.phoneNumber')}
                                             ref={phoneRef}
                                             onChange={updateInputs}/>
                                {(inputs['phone'] === '' && inputs['contactPhone']) ?
                                    <small>{locale('contactPage.inputField.errorMessage.phoneNumberError')}</small> :
                                    <small/>}
                            </S.FormColumn>
                            <S.FormColumn>
                                <S.TextArea name="comment"
                                            placeholder={locale('contactPage.inputField.placeholder.message')}
                                            ref={commentRef}
                                            onChange={updateInputs}/>
                                {inputs['comment'] === '' ?
                                    <small>{locale('contactPage.inputField.errorMessage.messageError')}</small> :
                                    <small/>}
                                <S.SmallSpan>{locale('contactPage.inputField.label.contactPreference')}</S.SmallSpan>
                                <S.RadioInput>
                                    <input type="radio" name="contactType" value="email"
                                           checked={inputs['contactEmail']}
                                           onChange={() => {
                                               setInputs({
                                                   ...inputs,
                                                   'contactEmail': true,
                                                   'contactPhone': false,
                                               })
                                           }}/>
                                    <p>{locale('contactPage.inputField.label.email')}</p>
                                    <input type="radio" name="contactType" value="phone"
                                           checked={inputs['contactPhone']}
                                           onChange={() => {
                                               setInputs({
                                                   ...inputs,
                                                   'contactEmail': false,
                                                   'contactPhone': true,
                                               })
                                           }}/>
                                    <p>{locale('contactPage.inputField.label.phone')}</p>
                                </S.RadioInput>
                                <S.RecaptchaWrapper>
                                <ReCAPTCHA ref={re => setRecaptchaRef(re)}
                                           sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                                           onChange={token => validateUser(token)}
                                           onExpired={() => {
                                               setUserValidated(false);
                                               updateState({});
                                           }}/>
                                </S.RecaptchaWrapper>
                                <S.SubmitButton onClick={handleSubmit} type="submit"
                                                disabled={!userValidated}>{locale('contactPage.button.submit')}</S.SubmitButton>
                            </S.FormColumn>
                        </form>
                        <S.PrivacyStatementWrapper>
                            <p>{locale('contactPage.privacyStatement.text1')}
                                <S.A href={linksStore.privacyPolicy} target="_blank"
                                     rel="noopener noreferrer"> {locale("contactPage.privacyStatement.link")}</S.A>
                                {locale('contactPage.privacyStatement.text2')}</p>
                            <p>{locale('contactPage.privacyStatement.text3')}</p>
                        </S.PrivacyStatementWrapper>
                    </S.FormContainer>
                </S.ContactWrapper>
                <SubscribeBanner header={locale("subscribeBanner.headings.subscribeHeader.dop")}
                                 link={'/Subscription'}/>
                <Footer/>
            </S.PageWithNavigationContainer>
        </div>
    );
};

export default ContactUs;