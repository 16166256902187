// action types
import {IArea, IOutage,  OutageDisplay} from "./index";

export const SET_OUTAGES = "SET_OUTAGES";
export const CLEAR_OUTAGES_LOADED = "CLEAR_OUTAGES_LOADED";
export const SET_AREAS = "SET_AREAS";
export const SET_OUTAGES_LOADED = "SET_OUTAGES_LOADED";
export const SET_OUTAGE_DISPLAY = "SET_OUTAGE_DISPLAY";

// actions
interface ISetOutagesAction {
    type: typeof SET_OUTAGES;
    outages: IOutage[];
    publishDate: string;
}

interface IClearOutagesLoaded {
    type: typeof CLEAR_OUTAGES_LOADED;
}

interface ISetAreasAction {
    type: typeof SET_AREAS;
    areas: IArea[];
}

interface ISetAreasLoadedAction {
    type: typeof SET_OUTAGES_LOADED;
    loaded: boolean;
}

interface ISetOutageDisplay {
    type: typeof SET_OUTAGE_DISPLAY;
    display: OutageDisplay;
}


export type OutageActionTypes =
    ISetOutagesAction
    | ISetOutageDisplay
    | ISetAreasAction
    | ISetAreasLoadedAction
    | IClearOutagesLoaded;
