const linksStore = {
    'gasDaySummaryReport': 'http://www.tccustomerexpress.com/gasdaysummaryreport.html',
    'currentSystemReport': 'http://www.tccustomerexpress.com/Alberta/absysreport.html',
    'ngtlSegmentCodes': 'http://www.tccustomerexpress.com/assets/NGTL_System_Map_Seg_Codes_2021_Mar24.pdf',
    'ngtlOperationalAreasMap': 'http://www.tccustomerexpress.com/docs/ab_system_maps/ngtl-system-map-operational-areas.pdf',
    'plantTurnaroundForm': 'http://www.tccustomerexpress.com/docs/ab_operations_planning_outage_forecast/PTA%20form%20PDF.pdf',
    'privacyPolicy': 'https://www.tcenergy.com/privacy/',
    'customerExpressContact': 'http://www.tccustomerexpress.com/contacts.html',
    'customerExpressDop': 'http://www.tccustomerexpress.com/ngtl-fh-daily-operating-plan.html',
    'contactUs': '/#ContactUs',
};

export default linksStore;