import React, {createRef, useState, useLayoutEffect, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as S from "../styles";
import {
    TileLayer,
    Marker,
    Polygon, ZoomControl, Map
} from 'react-leaflet';
import {LatLngTuple, LeafletMouseEvent} from "leaflet";
import "../../node_modules/leaflet/dist/leaflet.css";
import L from 'leaflet';
import currentOutagesIcon from "../icons/outages_current.svg";
import upcoming30daysIcon from "../icons/upcoming_30_black.svg";
import upcoming30plusDaysIcon from "../icons/upcoming_30_plus_black.svg";
import heatValueIcon from "../icons/thermometer.svg";
import useTypedSelector from "../hooks/useTypedSelector";
import {IMapData, IArea, IOutageComparison} from "../types";
import {locale} from "../locale";
import {IAreaOutagesSummary, AreaOutagesSummary} from "../types/AreaOutagesSummary";
import LocalAreaDisclaimer from "./LocalAreaDisclaimer";
import MoreOptions, {MoreOptionsItems} from "./MoreOptions";
import SortFunctions from "../utilities/SortFunctions";
import GetSelectedPublishDateAreaCapability from "../utilities/GetSelectedPublishDateAreaCapability";
import {useHistory} from "react-router";
import {size} from "../styles/DeviceSizes";
import GoogleAnalyticsUtilities from "../utilities/GoogleAnalyticsUtilities";
import BetaBanner from "./BetaBanner";
import {PublishDateUtilities} from "../utilities/PublishDateUtilities";
import { convertVolumeUnit } from '../utilities/UnitConversions';
import {HeatValueUtilities} from "../utilities/HeatValueUtilities";
import useHeatValues from "../hooks/useHeatValues";
import ChartDisclaimerModal from "./ChartDisclaimerModal";
import OutageControls from "./OutageControls";
import {useDispatch} from "react-redux";
import {setHasEndedInteracting, setIsOpen} from "../actions/outagesControlsActions"
import OutageGasDayChangesRibbon from './OutageGasDayChangesRibbon';

const OutageMap: React.FC = () => {
    useEffect(() => {
        GoogleAnalyticsUtilities.PageView("OutageMap");
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();
    const outages = useTypedSelector(state => state.outageComparison.outageHistory.comparison);
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);
    const areas = useTypedSelector(state => state.outages.areas);
    const heatValue = useTypedSelector(x => x.heatValue.heatValues);
    const heatValueLoaded = useTypedSelector(x => x.heatValue.heatValueLoaded);
    const unit = useTypedSelector(state => state.app.volumeUnit);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)
    const [areaZoomOverride, setAreaZoomOverride] = useState<boolean>(false);
    const [viewHeight, setViewHeight] = useState<number>(0);
    const [showInformationModal, setShowInformationModal] = useState<boolean>(false);

    useHeatValues();

    // TODO move this to DB
    const [mapData, setMapData] = useState<IMapData>({
        mapCenter: [54.592238, -109.431458],
        minZoom: 5,
        maxZoom: 8,
        zoom: 6,
        maxBounds: [[61.88893689676585, -128.72795976850033], [44.800059446787316, -99.64396452878294]]
    });

    useLayoutEffect(() => {
        function updateSize() {
            setViewHeight(window.innerHeight);
            if (window.innerWidth <= size.mobile) { //mobile choose different zoom.
                setMapData(mapData => ({...mapData, zoom: 5}));
            }
        }
        console.info("Layouteffect");
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // Used to show the lat,lng on the mouse pointer click
    function handleClick(event: LeafletMouseEvent) {
        console.log(event.latlng);
    }

    function filterCurrentAndUpcoming(area: IArea, allOutages: IOutageComparison[]): IAreaOutagesSummary {
        return new AreaOutagesSummary(allOutages.filter(o => o.areaId === area.id));
    }

    function createOutagesAreaIndicatorIcon(area: IArea, allOutages: IOutageComparison[], addBorder = false) {
        const summary = filterCurrentAndUpcoming(area, allOutages);
        const creator = () => createOutagesAreaIndicator(area, summary);

        return L.divIcon({
            className: '',
            iconSize: [-1, -1],
            html: ReactDOMServer.renderToString(addBorder ?
                <S.OutageAreaIndicatorContainer color={area.color}>{creator()}</S.OutageAreaIndicatorContainer> :
                <div>{creator()}</div>)
        });
    }

    function createOutagesAreaIndicator(area: IArea, summary: IAreaOutagesSummary) {
        return <S.OutageAreaIndicatorSubContainer>
            <S.OutageAreaIndicatorHeader>{area.acronym}</S.OutageAreaIndicatorHeader>
            <S.OutageAreaIndicatorLine>
                <S.OutageAreaIndicatorLineIconContainer>
                    <img src={currentOutagesIcon} width={17} height={17} alt={'current'}/>
                </S.OutageAreaIndicatorLineIconContainer>
                <S.OutageAreaIndicatorNumber>{summary.current.totalCount}</S.OutageAreaIndicatorNumber>
                <S.OutageAreaIndicatorStatus>{locale("gasDay").replace("{gasDay}", PublishDateUtilities.FormatPublishedDate(publishDates.selectedPublishDate, publishDates.recentPublishDates))}</S.OutageAreaIndicatorStatus>
            </S.OutageAreaIndicatorLine>
            <S.OutageAreaIndicatorLine>
                <S.OutageAreaIndicatorLineIconContainer>
                    <img src={upcoming30daysIcon} width={21} height={17} alt={'current'}/>
                </S.OutageAreaIndicatorLineIconContainer>
                <S.OutageAreaIndicatorNumber>{summary.upcoming30Days.totalCount}</S.OutageAreaIndicatorNumber>
                <S.OutageAreaIndicatorStatus>{locale('next30days')}</S.OutageAreaIndicatorStatus>
            </S.OutageAreaIndicatorLine>
            <S.OutageAreaIndicatorLine>
                <S.OutageAreaIndicatorLineIconContainer>
                    <img src={upcoming30plusDaysIcon} width={25} height={17} alt={locale('upcoming')}/>
                </S.OutageAreaIndicatorLineIconContainer>
                <S.OutageAreaIndicatorNumber>{summary.upcomingAfter30Days.totalCount}</S.OutageAreaIndicatorNumber>
                <S.OutageAreaIndicatorStatus>{locale('next30plusDays')}</S.OutageAreaIndicatorStatus>
            </S.OutageAreaIndicatorLine>
            {area.capabilities.length > 0 && (area.id !== 4 || showFHBCBaseCapability ) &&
            <S.OutageAreaIndicatorLine>
                <S.OutageAreaIndicatorNumber>
                    {convertVolumeUnit(GetSelectedPublishDateAreaCapability(area)?.capability ?? 0, unit).toLocaleString()}
                </S.OutageAreaIndicatorNumber>
                <S.OutageAreaIndicatorStatus>{unit}</S.OutageAreaIndicatorStatus>
            </S.OutageAreaIndicatorLine>}
            <S.OutageAreaIndicatorLine>
                <S.OutageAreaIndicatorLineIconContainer>
                    <img src={heatValueIcon} width={25} height={17} alt='HV'/>
                </S.OutageAreaIndicatorLineIconContainer>
                {heatValueLoaded && <S.OutageAreaIndicatorNumber>{HeatValueUtilities.getHeatValue(heatValue, area.acronym, publishDates.selectedPublishDate)}</S.OutageAreaIndicatorNumber>}
                <S.OutageAreaIndicatorStatus>{locale('heatValue.units')}</S.OutageAreaIndicatorStatus>
            </S.OutageAreaIndicatorLine>
        </S.OutageAreaIndicatorSubContainer>;
    }

    function onAreaClick(area: IArea) {
        history.push(`/OutagesList/${area.acronym}`);
    }

    function onZoomEnd() {
        if (mapRef !== null && mapRef.current != null) {
            const zoom = mapRef.current.leafletElement.getZoom();
            if (zoom >= mapData.maxZoom - 1) {
                setAreaZoomOverride(true);
            }

            if (areaZoomOverride && zoom < mapData.maxZoom - 1) {
                setAreaZoomOverride(false);
            }
        }
        dispatch(setHasEndedInteracting(true))
    }

    function toLatLngTuple(lat: number, lng: number): LatLngTuple {
        return [lat, lng];
    }

    const mapRef = createRef<Map>();

    const chartAssumptions = () => (
        <S.ChartDisclaimer>
            <S.AssumptionsButton
                onClick={() => setShowInformationModal(true)}>*{locale('chartAssumptions')}</S.AssumptionsButton>
        </S.ChartDisclaimer>
    );

    return (
        <div>
            <S.BackNavigationHeader style={{cursor: "default"}} isHiddenMobile={true}>
                <S.CenteredPageTitle>{locale("outages")}</S.CenteredPageTitle>
                <MoreOptions isVisible={true} options={[
                    MoreOptionsItems.DownloadOutages
                ]}/>
            </S.BackNavigationHeader>
            <BetaBanner />
            <OutageControls hasBanner={showBanner} noCompareTo={true} showIfTopScroll={false} renderers={{
                center: () => chartAssumptions()
            }}/>

            <LocalAreaDisclaimer localAreaCount={outages.filter(o => o.area.centerLng === null).length}/>

            <S.LeafletMap hasBanner={showBanner} center={mapData.mapCenter} zoom={mapData.zoom} minZoom={mapData.minZoom}
                          maxZoom={mapData.maxZoom} id={'outageMap'} zoomControl={false}
                          onclick={handleClick}
                          ref={mapRef}
                          onzoomend={onZoomEnd}
                          onmoveend={() => {
                              dispatch(setHasEndedInteracting(true));
                          }}
                          onmovestart={() => {
                              dispatch(setIsOpen(true));
                              dispatch(setHasEndedInteracting(false));
                          }}
                          viewHeight={viewHeight}
                          maxBounds={mapData.maxBounds}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <ZoomControl position={"bottomleft"}/>

                {/*Areas*/}
                {areas && areas.filter(f => f.centerLng).map(area => {
                    return (<Polygon color={area.color} fillOpacity={1} stroke={false} key={area.acronym}
                                     positions={area.locations.sort(SortFunctions.LocationSortFunction())}
                                     onclick={() => onAreaClick(area)}>
                    </Polygon>)
                })}

                {/*Indicators*/}
                {areas && areas.filter(f => f.centerLng).map(area => {
                    return (
                        <Marker
                            position={toLatLngTuple((area.offsetLat && !areaZoomOverride) ? area.offsetLat : area.centerLat, (area.offsetLng && !areaZoomOverride) ? area.offsetLng : area.centerLng)}
                            key={area.centerLat}
                            icon={createOutagesAreaIndicatorIcon(area, outages, true)}
                            onclick={() => onAreaClick(area)}>
                        </Marker>);
                })}
            </S.LeafletMap>
            
            {showInformationModal && <ChartDisclaimerModal
                areas={areas}
                onExit={() => setShowInformationModal(false)}
            />}
            <S.MobileOptions>
                <MoreOptions isVisible={true} options={[
                    MoreOptionsItems.DownloadOutages
                ]}/>
            </S.MobileOptions>
        </div>
    );
};

export default OutageMap;