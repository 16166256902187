import {IArea, IOutage, IOutageComparison, Status, INVALID} from "./index";
import DateUtilities from "../utilities/DateUtilities";
import NumberUtilities from "../utilities/NumberUtilities";

export default class OutageComparison implements IOutageComparison {
    current: IOutage | null;
    previous: IOutage | null;
    underlying: IOutage;

    constructor(current: IOutage | null, historical: IOutage | null) {
        this.underlying = (current ?? historical) as IOutage;
        this.current = this.normalize(current);
        this.previous = this.normalize(historical);
        this.changeStatus = this.calculateChangeStatus();
    }

    private calculateChangeStatus():  Status {
        //TODO - is this removed once the end date has passed?  could end up being removed & changed then... does that make sense?
        if (this.current === null) {
            return Status.REMOVED;
        }

        if (this.previous === null) {
            return Status.NEW;
        }

        if (this.hasChanged()) {
            return Status.CHANGED;
        }

        return Status.UNCHANGED;
    }

    private hasChanged(): boolean {
        return this.previous?.flowCapability !== this.current?.flowCapability ||
            this.previous?.startDateTime !== this.current?.startDateTime ||
            this.previous?.endDateTime !== this.current?.endDateTime ||
            this.previous?.description !== this.current?.description ||
            this.previous?.impact !== this.current?.impact ||
            this.previous?.typicalFlow !== this.current?.typicalFlow ||
            this.previous?.localAreaBaseCapability !== this.current?.localAreaBaseCapability ||
            this.previous?.localAreaOutageCapability !== this.current?.localAreaOutageCapability ||
           this.hasImpactValueChanged();
    }

    private hasImpactValueChanged(): boolean{
        if(this.previous?.areaBaseCapability !== null && this.current?.areaBaseCapability !== null &&
            this.previous?.areaBaseCapability !== 'null' && this.current?.areaBaseCapability !== 'null') 
            return this.previous?.areaBaseCapability !== this.current?.areaBaseCapability;
        return false;
    }
    // normalization to ignore changes that are not visible to the user (hours not displayed, leading/trailing whitespace not noticeable)
    private normalize(outage: IOutage | null): IOutage | null {
        // Truncated the Time will impact entering only an 8H duration for an end date. Waiting on business decision on these inputs.
        return  (outage !== null) ? {
            ...outage,
            endDateTime: DateUtilities.TruncateTime(outage.endDateTime, outage.endDateTime),
            startDateTime: DateUtilities.TruncateTime(outage.startDateTime, outage.startDateTime),
            description: outage.description != null ? outage.description.trim() : INVALID,
            impact: outage.impact != null ? outage.impact.trim() : INVALID,
            flowCapability: NumberUtilities.FormatFlow(outage.flowCapability),
            localAreaBaseCapability: NumberUtilities.FormatFlow(outage.localAreaBaseCapability),
            localAreaOutageCapability: NumberUtilities.FormatFlow(outage.localAreaOutageCapability),
            areaBaseCapability: NumberUtilities.FormatFlow(outage.areaBaseCapability ?? String(outage.areaBaseCapability)),
        } : null;
    }

    readonly changeStatus: Status;

    get area(): IArea {
        return this.underlying.area;
    }

    get areaId(): number {
        return this.underlying.areaId;
    }

    get description(): string {
        return this.underlying.description;
    }

    get hasDescriptionChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.description !== this.previous?.description;
    }

    get endDateTime(): string {
        return this.underlying.endDateTime;
    }

    get hasEndDateTimeChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.endDateTime !== this.previous?.endDateTime;
    }

    get flowCapability(): string {
        return this.underlying.flowCapability;
    }

    get hasFlowCapabilityChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.flowCapability !== this.previous?.flowCapability;
    }

    get id(): number {
        return this.underlying.id;
    }

    get impact(): string {
        return this.underlying.impact;
    }

    get hasImpactChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.impact !== this.previous?.impact;
    }

    get impactId(): number {
        return this.underlying.impactId;
    }

    get outageId(): number {
        return this.underlying.outageId;
    }

    get publishedDateTimeUtc(): string {
        return this.underlying.publishedDateTimeUtc;
    }

    get startDateTime(): string {
        return this.underlying.startDateTime;
    }

    get hasStartDateTimeChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.startDateTime !== this.previous?.startDateTime;
    }

    get typicalFlow(): string {
        return this.underlying.typicalFlow;
    }

    get hasTypicalFlowChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.typicalFlow !== this.previous?.typicalFlow;
    }

    get hasOutageIdChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.outageId !== this.previous?.outageId;
    }

    get localAreaBaseCapability(): string {
        return this.underlying.localAreaBaseCapability;
    }

    get areaBaseCapability(): string {
        return (this.underlying.areaBaseCapability ?? String(this.underlying.areaBaseCapability));
    }

    get hasLocalAreaBaseCapabilityChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.localAreaBaseCapability !== this.previous?.localAreaBaseCapability;
    }

    get localAreaOutageCapability(): string {
        return this.underlying.localAreaOutageCapability;
    }

    get hasLocalAreaOutageCapabilityChanged(): boolean {
        return this.changeStatus === Status.CHANGED && this.current?.localAreaOutageCapability !== this.previous?.localAreaOutageCapability;
    }
    get hasAreaBaseCapabilityChanged(): boolean {
        return (this.changeStatus === Status.CHANGED && this.current?.areaBaseCapability !== this.previous?.areaBaseCapability);
    }
    
}
