import {IServiceProvider, ISubscription, SearchKey} from '../types';
import {
	CLEAR_SEARCH_OPTIONS_LOADED,
	RESET_SEARCH_CRITERIA,
	SearchActionTypes,
	SET_SEARCH_OPTIONS,
	SET_SEARCH_STATE,
	SET_SEARCH_DRAWER_IS_OPEN
} from '../types/SearchActionTypes';
import {ISearchState} from '../reducers/searchReducer';

export function setSearchOptions(
	key: SearchKey, 
	serviceProviders: IServiceProvider[],
	subscriptionCategories: ISubscription[]
): SearchActionTypes {
	return {
		type: SET_SEARCH_OPTIONS,
		key,
		serviceProviders,
		subscriptionCategories
	};
}

export function clearSearchOptionsLoaded(key: SearchKey): SearchActionTypes {
	return { type: CLEAR_SEARCH_OPTIONS_LOADED, key };
}

export function resetSearchCriteria(key: SearchKey): SearchActionTypes {
	return {
		type: RESET_SEARCH_CRITERIA,
		key
	}
}

export function setSearchState(key: SearchKey, state: ISearchState): SearchActionTypes {
	return {
		type: SET_SEARCH_STATE,
		state, 
		key
	}
}

export function setSearchDrawerIsOpen(key: SearchKey, searchDrawerIsOpen: boolean): SearchActionTypes {
	return {
		type: SET_SEARCH_DRAWER_IS_OPEN,
		key,
		searchDrawerIsOpen: searchDrawerIsOpen
	}
}
