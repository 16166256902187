import {DateTime} from "luxon";
import DateUtilities from "../DateUtilities";
import {ValidationFunctionResult} from "./Validation";
import {IArea} from "../../types";

export default class ValidationFunctions {
    static RequiredValidationFunction = (newValue: string) => {
        return Boolean(newValue) ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static IntegerValidationFunction = (newValue: string) => {
        if (!newValue) {
            // precondition fails, no value defined.
            return ValidationFunctionResult.NOT_RUN;
        }
        return parseInt(newValue).toString() === newValue ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static FloatValidationFunction = (newValue: string) => {
        if (!newValue) {
            // precondition fails, no value defined.
            return ValidationFunctionResult.NOT_RUN;
        }
        return parseFloat(newValue).toString() === newValue ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static IntegerOrTBDValidationFunction = (newValue: string) => {
        if (newValue === "TBD") {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctions.IntegerValidationFunction(newValue);
    };

    static IntegerOrTbdOrNaValidationFunction = (newValue: string) => {
        if (newValue === "TBD" || newValue === "N/A") {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctions.IntegerValidationFunction(newValue);
    };

    static MaxNumericValueValidationFunction = (maxValue: number) => (newValue: string) => {
        const intValue = parseFloat(newValue);
        if (intValue.toString() === "NaN") {
            // precondition fails, not a number
            return ValidationFunctionResult.NOT_RUN;
        }
        return intValue <= maxValue ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static MinValueNumericValueValidationFunction = (minValue: number) => (newValue: string) => {
        const intValue = parseFloat(newValue);
        if (intValue.toString() === "NaN") {
            // precondition fails, not a number
            return ValidationFunctionResult.NOT_RUN;
        }
        return intValue >= minValue ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static MaxDateValueValidationFunction = (maxValue: string) => (newValue: string) => {
        const dateValue = DateUtilities.EditDateUtility.ParseDate(newValue);
        const compareDate = DateUtilities.EditDateUtility.ParseDate(maxValue);
        if (!dateValue.isValid || !compareDate.isValid) {
            // precondition fails, not a date
            return ValidationFunctionResult.NOT_RUN;
        }
        return dateValue <= compareDate ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static MinDateIntegerValueValidationFunction = (minValue: string) => (newValue: string) => {
        const dateValue = DateUtilities.EditDateUtility.ParseDate(newValue);
        const compareDate = DateUtilities.EditDateUtility.ParseDate(minValue);
        if (!dateValue.isValid || !compareDate.isValid) {
            // precondition fails, not a date
            return ValidationFunctionResult.NOT_RUN;
        }
        return dateValue >= compareDate ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static DateOrTBDValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }

        if (newValue === "TBD") {
            return ValidationFunctionResult.PASS;
        }

        return ValidationFunctions.DateValidationFunction(DateUtilities.editDateFormat)(newValue);
    };

    static DateHasCapabilityValidationFunction = (area: IArea) => (newValue: string) => {
        const parsedDate = DateTime.fromFormat(newValue, DateUtilities.editDateFormat);
        if (!parsedDate.isValid) {
            // precondition for this validation is that the string represents a valid date
            return ValidationFunctionResult.NOT_RUN;
        }

        if (area.capabilities.length === 0) {
            // this area does not keep track of capabilities.  Either a pseudo-area like LCLR or "Plant Turnaround Activity",
            // or a real area that we don't graph like EGAT, so capabilities are irrelevant.
            return ValidationFunctionResult.PASS;
        }

        return area.capabilities.some(x => DateTime.fromFormat(x.startDate, DateUtilities.serviceDateFormat) <= parsedDate &&
            parsedDate <= DateTime.fromFormat(x.endDate, DateUtilities.serviceDateFormat)) ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static DateValidationFunction = (format: string = DateUtilities.editDateFormat) => (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }

        return DateTime.fromFormat(newValue, format).isValid ?
            ValidationFunctionResult.PASS :
            ValidationFunctionResult.FAIL;
    };

    static DateOrTBDOrHourDurationValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        // Disabling 8H validation as this is not fully supported right now.
        // if (/^\d\d?H$/g.test(newValue)) {
        //     return ValidationFunctionResult.PASS;
        // }

        return ValidationFunctions.DateOrTBDValidationFunction(newValue);
    };

    static IntegerOrRangeOfIntegersValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }

        if (/^\d+$/g.test(newValue) || /^\d+-\d+$/g.test(newValue)) {
            // value is either a number or a range of numbers
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static StringMaximumLengthValidationFunction = (maxLength: number) => (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (newValue.length <= maxLength) {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static DateWithOptionalTimeValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        return DateTime.fromFormat(newValue, DateUtilities.publishDateTimestamp).isValid ?
            ValidationFunctionResult.PASS :
            ValidationFunctionResult.FAIL;
    };

    static DateGreaterOrEqualThenGivenDateValidationFunction = (date: string) => (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (date === '') {
            return ValidationFunctionResult.PASS;
        }
        const date1 = new Date(date);
        const date2 = new Date(newValue);
        if (date2.getDate() >= date1.getDate()) {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static NameFormatValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (/^[A-Za-zÀ-ÖØ-öø-ÿ ]{2,40}$/.test(newValue)) {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static PhoneNumberFormatValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (/^([0-9]{1,2}?[-. ])?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(newValue)) {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static EmailFormatValidationFunction = (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (/^\w+([.-/+/-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(newValue)) {
            return ValidationFunctionResult.PASS;
        }
        return ValidationFunctionResult.FAIL;
    };

    static StationNumberNotInListValidationFunction = (numberList: number[]) => (newValue: string) => {
        if (!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        if (numberList.includes(parseInt(newValue)))
            return ValidationFunctionResult.FAIL;
        return ValidationFunctionResult.PASS;
    };

    static DateIsFutureDateOnlyValidationFunction = (newValue: string) => {
        if(!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        const currentDate = new Date();
        const givenDate = new Date(newValue);
        if (currentDate > givenDate)
            return ValidationFunctionResult.FAIL;
        return ValidationFunctionResult.PASS;
    };

    static BodyValidationExists = (content: string) => {
        if (!content) {
            return ValidationFunctionResult.FAIL;
        }
        return ValidationFunctionResult.PASS;
    };

    static BodyValidationDoesNotExceedLengthFunction = (content: string) => {
        if (content.length > 245000) {
            return ValidationFunctionResult.FAIL;
        }
        return ValidationFunctionResult.PASS;
    };

    static ValueInRangeFunction = (newValue: string) => {
        if(!newValue) {
            return ValidationFunctionResult.NOT_RUN;
        }
        const value = parseFloat(newValue);
        if (value < 30 || value > 45 || newValue === 'NaN')
            return ValidationFunctionResult.FAIL;
        return ValidationFunctionResult.PASS;

    }

}