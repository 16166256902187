import jwtDecode from "jwt-decode";
import { DecodedToken, AuthProviderPropTypes } from "./interfaces";
import { TC_ACCESS_TOKEN, TC_SESSION_KEY } from "./constants";
import Log from "../../utilities/Log";

/* eslint @typescript-eslint/no-var-requires: "off" */
const queryString = require('query-string');

export function getTcAccessToken(): string | null {
	return localStorage.getItem(TC_ACCESS_TOKEN);
}

export const getAccessTokenStatus = (refreshThreshold: number | undefined) => {
	const rawToken = getTcAccessToken();
	if (!rawToken) {
		Log.debug('isValid = false; requiresRefresh: false');
		return {isValid: false, requiresRefresh: false};
	}

	const token = jwtDecode<DecodedToken>(rawToken);
	const now = Math.round(new Date().getTime() / 1000);

	Log.debug(`token expiry = ${token.exp}; now = ${now}; diff = ${token.exp - now}`);

	// If the token expires within refreshThreshold (5 minutes), it requiresRefresh
	const toReturn = {isValid: token.exp - now - (refreshThreshold || 0) > 0, requiresRefresh: token.exp - now - (refreshThreshold || 0) <= 0};
	Log.debug(`isValid = ${toReturn.isValid}; requiresRefresh: ${toReturn.requiresRefresh}`);
	return toReturn;
};

export const extractTokenIfRequired = (props: AuthProviderPropTypes) => () => {
	let search = window.location.search;
	let hash = "";
	if (!window.location.search) {
		// In this scenario we will assume we have a hash
		search = window.location.hash.replace(/#.*\?/g, "?");
		hash = window.location.hash.replace(/\?.*/g,  ""); // Matching literal ? instead of negative lookahead
	}
	if (!search) return;
	const parameters = queryString.parse(search);
	if (parameters.errorMsg) return parameters.errorMsg.toString();
	if (!parameters.id_token) return;
	if (props.onAccessTokenChange) props.onAccessTokenChange(parameters.id_token.toString());
	localStorage.setItem(TC_ACCESS_TOKEN, parameters.id_token.toString());
	localStorage.setItem(TC_SESSION_KEY, parameters.ccsSessionKey?.toString() ?? ""); // should we check if this is empty too?

	if (props.push) {
		if (hash) {
			props.push(hash.replace("#", "/"));
		} else {
			props.push(window.location.pathname);
		}

	}

};

export const getUser = () => {
	return jwtDecode<DecodedToken>(getTcAccessToken() || "");
};