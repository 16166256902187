import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect, useCallback} from "react";
import {setDailyOperatingPlanComparisons, setOutageHistory} from "../actions/outageComparisonActions";
import {clearOutagesLoaded, setOutages} from "../actions/outagesActions";
import {OutageComparisonBuilder} from "../types/OutageComparisonBuilder";
import usePublishDates from "./usePublishDates";
import {Fetch} from "../utilities/Fetch";

function useDailyOperatingPlanCompare(): void {
    const outages = useTypedSelector(x => x.outages.outages);
    const outagesPublishDate = useTypedSelector(x => x.outages.publishDate);
    const outagesLoaded = useTypedSelector(x => x.outages.outagesLoaded);
    const outagesHistory = useTypedSelector(x => x.outageComparison.outageHistory);
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);
    const error = useTypedSelector(x => x.outages.error);
    const dispatch = useDispatch();
    usePublishDates();

    useEffect(() => {
        if (outagesLoaded && publishDates.selectedPublishDate !== outagesPublishDate) {
            // Current outages are not for the selected publish date.  Trigger reload of outages.
            dispatch(clearOutagesLoaded())
        }
    }, [outagesLoaded, outages, publishDates.selectedPublishDate, outagesPublishDate, dispatch]);

    const fetchOutages = useCallback( (date: string, action: (data: any) => void) => {
        if (date) {
            Fetch.Build(`/outages/history/${date}`, dispatch).success(action).get();
        } else {
            action([]);
        }
    }, [dispatch]);

    // load outages if not already loaded
    useEffect(() => {
        if (publishDates.loaded && !outagesLoaded && !error) {
            fetchOutages(publishDates.selectedPublishDate, x => dispatch(setOutages(x, publishDates.selectedPublishDate)));
        }
    }, [outagesLoaded, error, publishDates.selectedPublishDate, dispatch, publishDates.loaded, fetchOutages]);

    // load outage history if not already loaded
    useEffect(() => {
        if (publishDates.loaded && !outagesHistory.loaded && !error) {
            fetchOutages(outagesHistory.publishDate, x => dispatch(setOutageHistory(x, outagesHistory.publishDate)));
        }
    }, [publishDates.loaded, outagesHistory.loaded, outagesHistory.publishDate, dispatch, error, fetchOutages]);


    // once everything is loaded build up the comparisons
    useEffect(() => {
        if (outagesHistory.loaded && outagesLoaded && !error) {
            dispatch(setDailyOperatingPlanComparisons(OutageComparisonBuilder.Build(outages, outagesHistory.history)));
        }
    }, [outagesHistory.loaded, outagesHistory.history, outagesLoaded, outages, dispatch, error]);
}

export default useDailyOperatingPlanCompare;