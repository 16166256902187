import Validation from "./Validation";
import ValidationFunctions from "./ValidationFunctions";
import DateUtilities from "../DateUtilities";
import ChartExportValidation from "./ChartExportValidation";

export default class HeatValueValidation {

    static UpdateHeatValueValidation: (value: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.ValueInRangeFunction,
            validationMessageKey: "validation.heatValue.outOfRange"
        }
    ])

    static ScheduledDateValidation: (date: string, startDate: string) => Validation = (date: string, startDate: string) => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.export.endDate.requiredMessage"
        },
        {
            validationFunction: ValidationFunctions.DateValidationFunction(DateUtilities.serviceDateOnlyFormat),
            validationMessageKey: "validation.export.endDate.invalidDate"
        },
        {
            validationFunction: ChartExportValidation.DateOrderValidation(startDate, date),
            validationMessageKey: "validation.export.endDate.afterStartDateMessage"
        }
    ]);
}