import {ICurrentSystemReportData } from './index';
export const SET_CURRENT_SYSTEM_REPORT_DATAGRID = 'SET_CURRENT_SYSTEM_REPORT_DATAGRID';
export const SET_CSR_DURATION = 'SET_CSR_DURATION';

interface ISetCurrentSystemReportDataGrid{
    type: typeof SET_CURRENT_SYSTEM_REPORT_DATAGRID;
    duration: number;
    receiptGrid: ICurrentSystemReportData[];
    deliveryGrid: ICurrentSystemReportData[];
    linepackGrid: ICurrentSystemReportData[];
}

interface ISetCSRDuration {
    type: typeof SET_CSR_DURATION;
    duration: number;
}

export type CurrentSystemReportActionTypes = 
    ISetCurrentSystemReportDataGrid
    | ISetCSRDuration