import {IServiceProvider, ISubscription, SearchKey} from "./index";
import {ISearchState} from "../reducers/searchReducer";

export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS_LOADED = 'SET_SEARCH_OPTIONS_LOADED';
export const CLEAR_SEARCH_OPTIONS_LOADED = 'CLEAR_SEARCH_OPTIONS_LOADED';
export const RESET_SEARCH_CRITERIA = "RESET_SEARCH_CRITERIA";
export const SET_SEARCH_STATE = "SET_SEARCH_STATE";
export const SET_SEARCH_DRAWER_IS_OPEN = "SET_SEARCH_DRAWER_IS_OPEN";


interface ISetSearchOptionsAction {
    type: typeof SET_SEARCH_OPTIONS;
    key: SearchKey;
    serviceProviders: IServiceProvider[];
    subscriptionCategories: ISubscription[];
}

interface ISetSearchOptionsLoaded {
    type: typeof SET_SEARCH_OPTIONS_LOADED;
    key: SearchKey;
    loaded: boolean;
}

interface IClearSearchOptionsLoaded {
    type: typeof CLEAR_SEARCH_OPTIONS_LOADED;
    key: SearchKey;
}

interface IResetSearchCriteria {
    type: typeof RESET_SEARCH_CRITERIA;
    key: SearchKey;
}

interface ISetSearchState {
    type: typeof SET_SEARCH_STATE;
    state: ISearchState;
    key: SearchKey;
}

interface ISetSearchDrawerIsOpen {
    type: typeof SET_SEARCH_DRAWER_IS_OPEN;
    key: SearchKey;
    searchDrawerIsOpen: boolean;
}

export type SearchActionTypes =
    ISetSearchOptionsAction
    | ISetSearchOptionsLoaded
    | IClearSearchOptionsLoaded
    | IResetSearchCriteria
    | ISetSearchState
    | ISetSearchDrawerIsOpen;
