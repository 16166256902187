import styled from "styled-components";
import {device} from "./DeviceSizes";

export const Menu = styled.div`      
    display: flex;   
    flex-direction: column;     
    text-align: center;
    align-self: flex-start;
    background: #ffffff;  
    z-index: 900000; 
    @media ${device.tablet} {
      position: fixed;
      bottom: 0;
      flex-direction: row;
      order: 2;
      justify-content: center;
      width: 100vw;
      max-height: 64px;
      padding-bottom: 10px;
    }
    @media ${device.mobile} {
      display: none;
    }
    @media ${device.desktop} {
      width: 97px;
      height: 100vh;
      position: sticky;
      top: 0;
      border-right: 1px solid #CFCDCE;
    }
    @media print {
        display: none !important;
    }
`;

export const Logo = styled.div`
  img {
    padding: 16px 0;
  }
`;

export const NavIcon = styled.div`
  text-align: center;
  min-height: 50px;
  padding: 10px 15px;
  cursor: pointer;
  p {
    padding: 0;
    margin: 0;
    font-size: 11px;
  }
  @media ${device.tablet} {
    margin: 0 10px;
  }
  @media ${device.mobile} {
    display: flex;
    min-height: auto;
    div {
      display: flex;
      align-items: center;
    }
    margin: 0;
    p {
      font-size: 14px;
      margin-left: 10px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const NavLogo = styled.div`
  min-width: 77px;
  align-self: center;
   @media ${device.tablet} {
    margin: 0 10px;
  }
  @media ${device.mobile} {
    margin: 0;
  }
`;
