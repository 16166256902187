import React, {useEffect, useState} from 'react';
import * as S from "../../styles";
import {useHistory} from "react-router";
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {
    IDictionary,
    IHasValidationResults,
    IStationIntegration,
    IStationIntegrationForEdit,
    StationListStatus
} from "../../types";
import PageSelector, {PagingInfo} from "../PageSelector";
import useTypedSelector from "../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {clearIsDirty, setIsDirty, showConfirm} from "../../actions/appActions";
import {Fetch} from "../../utilities/Fetch";
import ResultModal from "../ResultModal";
import Validation from "../../utilities/validation/Validation";
import StationListValidation from "../../utilities/validation/StationListValidation";
import useAreas from "../../hooks/useAreas";
import fetchHelper from "../../utilities/fetchHelper";

const StationList: React.FC = () => {
    const initialStation: IStationIntegrationForEdit = {
        id: -1,
        areaId: 1,
        number: NaN,
        currentStatus: StationListStatus.NEW,
        isApproved: true,
        hasFieldDirty: {},
        validationResults: {},
        area: '',
        isVisible: true,
    };

    const [currentPage, setCurrentPage] = useState<PagingInfo<IStationIntegrationForEdit> | undefined>(undefined);
    const [allStationsList, setAllStationsList] = useState<IStationIntegrationForEdit[]>([]);
    const [newStation, setNewStation] = useState<IStationIntegrationForEdit>(initialStation);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>('');
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [loadStations, setLoadStations] = useState<boolean>(true);
    const [stations, setStations] = useState<IStationIntegration[]>([]);
    const [areaDict, setAreaDict] = useState<IDictionary<string>>({});
    const [searchField, setSearchField] = useState<string>('');
    const isDirty = useTypedSelector(x => x.app.isDirty);
    const isBusy = useTypedSelector(x => x.app.isBusy);
    const areas = useTypedSelector(state => state.outages.areas);
    const areasLoaded = useTypedSelector(state => state.outages.areasLoaded);
    const history = useHistory();
    const dispatch = useDispatch();

    const columns = {
        statusWidth: '200',
        areaWidth: '150',
        stationNumberWidth: '150',
        actionWidth: '125',
    };

    useAreas();

    useEffect(() => {
        if (loadStations) {
            fetchHelper('stations', x => setStations(x), dispatch);
            setLoadStations(false);
        }
    }, [loadStations, dispatch]);

    useEffect(() => {
        if (stations?.length > 0 && areasLoaded) {
            const stationData = prepareForEdit(stations);
            const areaDictionary: IDictionary<string> = {};
            areas.forEach(a => areaDictionary[a.id] = a.acronym);
            stationData.map(station => {
                return station.area = areaDictionary[station.areaId];
            });
            setAllStationsList(stationData);
            setAreaDict(areaDictionary);
            setCurrentPage(new PagingInfo(stationData));
        } else {
            setCurrentPage(new PagingInfo([]));
        }
    }, [stations, areasLoaded, areas]);

    const prepareForEdit = (stations: IStationIntegration[]) => {
        const stationsForEdit: IStationIntegrationForEdit[] = stations.map(station => {
            return {
                id: station.id,
                number: station.number,
                areaId: station.areaId,
                isApproved: station.isApproved,
                currentStatus: station.isApproved ? StationListStatus.APPROVED : StationListStatus.EXCLUDE,
                hasFieldDirty: {},
                validationResults: {},
                area: '',
                isVisible: true,
            };
        });
        return stationsForEdit;
    };

    const prepareForSave = (stations: IStationIntegrationForEdit[]) => {
        const stationsForSave: IStationIntegration[] = stations.map(station => {
            return {
                id: station.id,
                number: station.number,
                areaId: station.areaId,
                isApproved: station.isApproved,
            };
        });
        return stationsForSave;
    };

    const saveClick = () => {
        dispatch(showConfirm({
            headerText: locale('stationList.save.header'),
            bodyText: locale('stationList.save.body'),
            buttonCancelText: locale('cancel'),
            buttonConfirmText: locale('save'),
            background: undefined,
            border: undefined,
            onConfirm: () => save(prepareForSave(allStationsList))
        }));
    };

    const cancelClick = () => {
        if (isDirty) {
            dispatch(showConfirm({
                headerText: locale('discardChangesHeader'),
                bodyText: locale('discardChangesBody'),
                buttonCancelText: locale('cancel'),
                buttonConfirmText: locale('discard'),
                background: undefined,
                border: undefined,
                onConfirm: () => history.push('/Admin')
            }))
        } else {
            history.push('/Admin');
        }
    };

    const save = (payload: IStationIntegration[]) => {
        Fetch.Build('/stations/update', dispatch)
            .withPayload(payload)
            .error(e => {
                setResultText(`${locale('bulletin.publish.error')} ${e}`);
                setSaveSuccess(false);
            })
            .success(() => {
                setResultText(locale('stationList.save.successMessage'));
                setSaveSuccess(true);
                setLoadStations(true);
                setSearchField('');
            })
            .finally(() => {
                setShowResult(true);
            })
            .post();
    };

    const updateStatus = (station: IStationIntegrationForEdit) => {
        if (!currentPage) {
            return;
        }
        const updateItems = allStationsList;
        updateItems.filter(a => a.id === station.id)[0].isApproved = !station.isApproved;
        setAllStationsList(updateItems);
        renderTable(currentPage.currentPage);
        if (!isDirty)
            dispatch(setIsDirty());
    };

    const updateStationNumber = (station: IStationIntegrationForEdit, fieldName: string, newValue: number, validation: Validation | null) => {
        const hasDirty = station.hasFieldDirty ? station.hasFieldDirty : {};
        hasDirty[fieldName] = !isNaN(newValue);

        validateInput(station, fieldName, newValue, validation);

        setNewStation({
            ...newStation,
            [fieldName]: newValue,
            area: areaDict[newStation.areaId],
            hasFieldDirty: hasDirty
        });
        if (!isDirty)
            dispatch(setIsDirty());
    };

    const addNewStation = () => {
        if (currentPage?.allItems.map(x => x.number).includes(newStation.number) || isNaN(newStation.number)) {
            return;
        }
        const station = newStation;
        if (searchField !== '') {
            station.isVisible = RegExp(searchField).test(station.number.toString());
        }
        let currentStations: IStationIntegrationForEdit[];
        let pageNumber: number;
        if (!currentPage) {
            currentStations = [station];
            pageNumber = 1;
        } else {
            currentStations = allStationsList;
            currentStations.unshift(station);
            pageNumber = currentPage.currentPage;
        }
        setAllStationsList(currentStations);
        setNewStation(initialStation);
        renderTable(pageNumber);
        if (!isDirty)
            dispatch(setIsDirty());
    };

    const preformSearch = (text: string) => {
        setSearchField(text);
        const ex = new RegExp(text);
        let allStationsWithVisibilityUpdated: IStationIntegrationForEdit[];
        if (text === '') {
            allStationsWithVisibilityUpdated = allStationsList.map(s => {
                s.isVisible = true;
                return s;
            });
        } else {
            allStationsWithVisibilityUpdated = allStationsList.map(s => {
                s.isVisible = ex.test(s.number.toString());
                return s;
            })
        }
        setAllStationsList(allStationsWithVisibilityUpdated);
        renderTable(1);
    };

    const renderTable = (pageNumber: number) => {
        const rowsToRender = allStationsList.filter(s => {
            return s.isVisible;
        }).map(s => {
            return s
        });
        setCurrentPage(new PagingInfo(rowsToRender, 15, pageNumber, 3));
    };

    const validateInput = (station: IStationIntegrationForEdit, fieldName: string, newValue: number, validation: Validation | null = null) => {
        const validationResults = station.validationResults;
        if (validation) {
            validationResults[fieldName] = validation.validate(isNaN(newValue) ? '' : newValue.toString());
        }
    };

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : '';
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('admin.options.stationList')}/>
            <div style={{padding: '12px 24px'}}>
            <div>
                <S.StationListHeaders>{locale('stationList.header.addNewStation')}</S.StationListHeaders>
                <S.AddStationRow>
                    <S.AddStationTextInput type="text" placeholder={'Station Number'}
                                           isDirty={newStation.hasFieldDirty ? newStation.hasFieldDirty['number'] : false}
                                           title={errorMessage(newStation, 'number')}
                                           hasError={hasError(newStation, 'number')}
                                           value={newStation.number >= 0 ? newStation.number : ''}
                                           onChange={text =>
                                               updateStationNumber(newStation, 'number', parseInt(text.target.value), StationListValidation.NewStationNumberValidation(allStationsList.map(x => x.number)))}/>
                    <input type="radio" checked={newStation.isApproved}
                           onChange={() => setNewStation({
                               ...newStation,
                               isApproved: true,
                           })}/><span>{locale('stationList.status.approve')}</span>
                    <input type="radio" checked={!newStation.isApproved}
                           onChange={() => setNewStation({
                               ...newStation,
                               isApproved: false,
                           })}/><span>{locale('stationList.status.exclude')}</span>
                </S.AddStationRow>
                <S.ActionButtonContainer style={{justifyContent: 'flex-start'}}>
                    <S.SecondaryAction>
                        <div onClick={() => setNewStation(initialStation)}>{locale('clear')}</div>
                    </S.SecondaryAction>
                    <S.SaveButton
                        disabled={!newStation?.validationResults['number']?.isValid || isNaN(newStation.number)}
                        onClick={addNewStation}>{locale('stationList.option.addStation')}</S.SaveButton>
                </S.ActionButtonContainer>
            </div>
            <div>
                <S.StationListHeaders>{locale('stationList.header.stationList')}</S.StationListHeaders>
                <S.StationListSubHeader>{locale('stationList.header.search')}</S.StationListSubHeader>
                <S.StationListHeaderOptions>
                    <S.StationListSearchBar>
                        <input type="text"
                               value={searchField}
                               onChange={text => preformSearch(text.target.value)}
                               placeholder={locale('stationList.search.placeholderText')}
                        />
                    </S.StationListSearchBar>
                    <S.ActionButtonContainer>
                        <S.SecondaryAction>
                            <div onClick={cancelClick}>{locale('cancel')}</div>
                        </S.SecondaryAction>
                        <S.SaveButton disabled={!isDirty} onClick={saveClick}>{locale('save')}</S.SaveButton>
                    </S.ActionButtonContainer>
                </S.StationListHeaderOptions>
            </div>
            <div>
                <S.StationListTableHeaderRow key={"stations-header-row"}>
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumnNoGrow width={columns.stationNumberWidth}>
                            <S.StationListHeaderTextLeft>{locale('stationList.table.header.stationNumber')}</S.StationListHeaderTextLeft>
                        </S.TableColumnNoGrow>
                    </S.TableCellWrapperNoGrow>
                    {/*<S.TableCellWrapperNoGrow>*/}
                    {/*    <S.TableColumnNoGrow width={columns.areaWidth}>*/}
                    {/*        <S.StationListHeaderTextLeft>{locale('stationList.table.header.area')}</S.StationListHeaderTextLeft>*/}
                    {/*    </S.TableColumnNoGrow>*/}
                    {/*</S.TableCellWrapperNoGrow>*/}
                    <S.TableCellWrapper>
                        <S.TableColumn width={columns.statusWidth}>
                            <S.StationListHeaderTextLeft>{locale('stationList.table.header.isActive')}</S.StationListHeaderTextLeft>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumnNoGrow width={columns.actionWidth}>
                            <S.StationListHeaderText>{locale('stationList.table.header.approved')}</S.StationListHeaderText>
                        </S.TableColumnNoGrow>
                        <S.TableColumnNoGrow width={columns.actionWidth}>
                            <S.StationListHeaderText>{locale('stationList.table.header.excluded')}</S.StationListHeaderText>
                        </S.TableColumnNoGrow>
                    </S.TableCellWrapperNoGrow>
                </S.StationListTableHeaderRow>
                <S.StationListTableContentWrapper>
                    {currentPage !== undefined && currentPage.items.length !== 0 && currentPage.items.filter(station => station.isVisible).map(station => {
                        return (
                            <S.StationListTableRow key={`${station.number}-publication-row`}>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={columns.stationNumberWidth}>
                                        <S.StationListContent>{station.number}</S.StationListContent>
                                    </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                                {/*<S.TableCellWrapperNoGrow>*/}
                                {/*    <S.TableColumnNoGrow width={columns.areaWidth}>*/}
                                {/*        <S.StationListContent>{station.area}</S.StationListContent>*/}
                                {/*    </S.TableColumnNoGrow>*/}
                                {/*</S.TableCellWrapperNoGrow>*/}
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.statusWidth}>
                                        <S.StationListContent>
                                            {station.currentStatus === StationListStatus.APPROVED ?
                                                locale('stationList.status.yes') :
                                                station.currentStatus === StationListStatus.EXCLUDE ?
                                                    locale('stationList.status.no') :
                                                    StationListStatus.NEW}
                                        </S.StationListContent>
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={columns.actionWidth}>
                                        <div style={{textAlign: 'center'}}>
                                            <input type="radio" checked={station.isApproved}
                                                    onChange={() => {
                                                        updateStatus(station)
                                                    }}/>
                                        </div>
                                    </S.TableColumnNoGrow>
                                    <S.TableColumnNoGrow width={columns.actionWidth}>
                                        <div style={{textAlign: 'center'}}>
                                            <input style={{marginLeft: '16px'}} type="radio"
                                                    checked={!station.isApproved}
                                                    onChange={() => {
                                                        updateStatus(station)
                                                    }}/>
                                        </div>
                                    </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                            </S.StationListTableRow>
                        )
                    })}
                </S.StationListTableContentWrapper>
                {currentPage !== undefined && currentPage?.totalPages > 1 &&
                <PageSelector saveState={setCurrentPage} pagingInfo={currentPage as PagingInfo<any>}/>}
            </div>
            {showResult && !isBusy &&
            <ResultModal text={resultText}
                         success={saveSuccess}
                         onExit={() => {
                             setShowResult(false);
                             if (saveSuccess) {
                                 dispatch(clearIsDirty());
                             }
                         }}/>}
            </div>
        </S.PageWithNavigationContainer>
    )
};

export default StationList;