import styled from "styled-components";
import colors from "./Colors";
import {AdminTextInput} from "./AdminStyles";
import {device} from "./DeviceSizes";
import {SaveButton} from "./index";

export const InfoLabel = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;      
    color:  ${colors.black_text};
    margin-bottom: 16px;
    max-width: 1080px;
`;

export const InfoLabelSpecial = styled(InfoLabel)`
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.charcoal_text};    
`;

export const WelcomeLabel = styled(InfoLabel)`    
    font-size: 18px;
    line-height: 21px;
`;

export const InfoLabelNoSpacing = styled(InfoLabel)`
     margin-bottom: 0;
`;

export const SeparatorLine = styled.hr`     
    margin-bottom: 16px; 
    border: 0.5px solid #CFCDCE;
`;

export const UnsubscribeHeader = styled(InfoLabel)`  
    font-size: 32px;
    line-height: 38px;        
    margin-top: 75px;
`;

export const UnsubscribeDisclaimer = styled(InfoLabelSpecial)`     
    max-width: 800px;
`;

export const UnsubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  margin: 40px;
  text-align: center;     
`;

interface ICollapsible{
    collapse: boolean;
}

export const FlexContainerRowCollapsible = styled.div<ICollapsible>`
  display: flex;    
  flex-direction: row;
  flex-wrap: wrap;  
  ${props => props.collapse ? "display: none;" : ""};
`;

export const UserTypeHeaderContainer = styled.div`
  display: flex;
  font-family: "Maven Pro";
  font-weight: 500;
  font-size: 24px;
  cursor: pointer;
  padding: 8px 0 16px 0;
  img {
    padding: 0 12px;
    width: 28px;
  }
`;

export const SubscribeUserWrapper = styled.div`
  margin-left: 52px;
  @media ${device.mobile} {
    margin: 16px;
  }
`;

export const SubscribeInfoItem = styled.div`
  margin: 8px 0 8px 0;
`;

export const SubscribeRecaptchaWrapper = styled(SubscribeInfoItem)`
    @media ${device.mobile} {
        display: flex;
        justify-content: center;
    }
`;

export const SubscriptionInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${device.mobile} {
    display: block;
  }
`;

export const SubscriptionTextInput = styled(AdminTextInput)`
  min-width: 375px;
  @media ${device.mobile} {
    min-width: 0;
    width: 100%;
  }
`;
