import {IBulletin, IBulletinForEdit, IEditorState, ILookupValueForEdit, SORT_ORDER} from "../types";
import {ISearchState} from "../reducers/searchReducer";
import {EditorUtilities} from "./EditorUtilities";
import DateUtilities from "./DateUtilities";

export class BulletinUtilities {
    static prepareBulletin(currentBulletin: IBulletinForEdit, isDraft: boolean): IBulletin {
        return {
            id: currentBulletin.id,
            subscriptionId: parseInt(currentBulletin.category.id),
            isCritical: currentBulletin.isCritical,
            subject: currentBulletin.subject,
            contactName: currentBulletin.contactName,
            contactPhone: currentBulletin.contactPhone,
            startDateTimeUtc: currentBulletin.startDateTimeUtc === '' || currentBulletin.startDateTimeUtc === null ? null : DateUtilities.ServiceDateUtility.ReformatTzToUtc(currentBulletin.startDateTimeUtc, DateUtilities.cctTimeZone),
            body: EditorUtilities.getRawHTML(currentBulletin.editor),
            editorState: EditorUtilities.prepareForSave(currentBulletin.editor),
            lastSavedDateTimeUtc: currentBulletin.lastSavedDateTimeUtc,
            isDraft: isDraft,
            serviceProvider: null,
            category: null,
            publishedBy: '',
            subscription: null,
            publishedDateTimeUtc: null,
            scheduleDateTimeUtc: currentBulletin.scheduleDateTimeUtc === '' || currentBulletin.scheduleDateTimeUtc === null ? null : DateUtilities.ServiceDateUtility.ReformatTzToUtc(currentBulletin.scheduleDateTimeUtc, DateUtilities.cctTimeZone),
        };
    }

    static prepareBulletinForEdit(bulletin: IBulletin, isCopy: boolean, editor: IEditorState): IBulletinForEdit {
        const defaultLookupValue: ILookupValueForEdit = {id: "", name: ""};
        const editBulletin: IBulletinForEdit = {
            ...bulletin,
            startDateTimeUtc: bulletin.startDateTimeUtc === null ? '' : DateUtilities.UiDateTimeUtility.ReformatUtcToTz(bulletin.startDateTimeUtc, DateUtilities.cctTimeZone),
            editor: editor,
            hasFieldDirty: {},
            validationResults: {},
            subject: bulletin.subject,
            category: bulletin.subscription !== null ? {
                name: bulletin.subscription.name,
                id: bulletin.subscription.id.toString()
            } : defaultLookupValue,
            serviceProvider: bulletin.subscription !== null ? {
                name: bulletin.subscription.serviceProvider.name,
                id: bulletin.subscription.serviceProviderId.toString()
            } : defaultLookupValue,
            subscriptionId: bulletin.subscriptionId,
            scheduleDateTimeUtc: bulletin.scheduleDateTimeUtc === null ? '' : DateUtilities.UiDateTimeUtility.ReformatUtcToTz(bulletin.scheduleDateTimeUtc, DateUtilities.cctTimeZone),
        };
        if (isCopy) {
            editBulletin.id = null;
            editBulletin.isDraft = true;
            editBulletin.scheduleDateTimeUtc = '';
            editBulletin.publishedDateTimeUtc = '';
        }
        return editBulletin;
    }

    static buildQuery = (endPoint: string, pageNumber = 1, rowsPerPage: number, sortColumn: string, sortOrder: SORT_ORDER, searchState: string | ISearchState = '') => {
        const result = `page=${pageNumber}&page_size=${rowsPerPage}&sort_column=${sortColumn}&sort_order=${sortOrder}`;
        if (typeof searchState === "string") {
            return `${result}&search_text=${searchState}&search_subject=1&search_body=1`;
        }
        return `/${endPoint}?` + encodeURI(`${result}&search_text=${searchState.searchText}&search_subject=${searchState.searchSubject}&search_body=${searchState.searchBody}&exact_phrase=${searchState.searchExactPhrase}&severity=${searchState.severity}&service_provider=${searchState.selectedServiceProvider}&notice_type=${searchState.selectedSubscriptionCategory}&submission_date_start=${DateUtilities.FromJSDate(searchState.submittedStartDate)?.toISODate()}&submission_date_end=${DateUtilities.FromJSDate(searchState.submittedEndDate)?.toISODate()}&effective_date_start=${DateUtilities.FromJSDate(searchState.effectiveStartDate)?.toISODate()}&effective_date_end=${DateUtilities.FromJSDate(searchState.effectiveEndDate)?.toISODate()}`);
    };

    static BoldText(str: string | undefined, searchStr: string) {
        if (!str) return "";
        searchStr = this.escapeRegex(searchStr)
        const re = new RegExp(searchStr.split(' ').join('|'), 'gi');
        return str.replace(re, (match) => {
            return `<strong>${match}</strong>`;
        });
    }

    private static escapeRegex(str: string) {
        return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
}