import React from 'react'
import * as S from "../../styles";
import {locale} from "../../locale";
import {Link, useRouteMatch} from 'react-router-dom';
import BetaBanner from "../BetaBanner";
import useTypedSelector from "../../hooks/useTypedSelector";
import {DateTime} from "luxon";
import DateUtilities from "../../utilities/DateUtilities";
import Footer from "../Footer";
import SubscribeBanner from "../subscriptions/SubscribeBanner";
import {useDispatch} from "react-redux";
import TableColumnSortHeader from './TableColumnSortHeader';
import BulletinTable from "./BulletinTable";
import { setBulletinListSort } from '../../actions/bulletinActions';
import { ColumnSort, SearchKeyTypes } from "../../types";
import { BulletinUtilities } from "../../utilities/BulletinUtilities";

const BulletinList: React.FC = () => {
    const dispatch = useDispatch();

    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const columnSortOrder = useTypedSelector(state => state.bulletins.bulletinListSort);
    const noticeMissing = useRouteMatch('/Bulletin/:id');
    function setColumnSortOrder(columnSortOrder: ColumnSort){
        dispatch(setBulletinListSort(columnSortOrder))
    }

    const columns = {
        startDateWidth: '100',
        submittedDateWidth: '100',
        serviceProviderWidth: '85',
        categoryWidth: '85',
        subjectWidth: '200',
        actionWidth: '75',
        totalWidth: '645'
    };

    return (
        <div>
            <BetaBanner />
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <S.BackNavigationHeader style={{cursor: "default"}}>
                    <S.CenteredPageTitle>{locale("page.header.bulletins")}</S.CenteredPageTitle>
                </S.BackNavigationHeader>

                <BulletinTable endpoint={"bulletin/sent"}
                    noticeMissing={noticeMissing?.isExact}
                    columnSortOrder={columnSortOrder}
                    section={SearchKeyTypes.PUBLIC}
                    renderHeader={() => (
                    <S.BulletinListTableHeaderRow key={"publication-header-row"} minWidth={columns.totalWidth}>
                        <TableColumnSortHeader
                            width={columns.serviceProviderWidth}
                            columnName={"subscription.serviceProvider.name"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.serviceProvider')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader
                            width={columns.categoryWidth}
                            columnName={"subscription.name"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.category')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader
                            width={columns.submittedDateWidth}
                            columnName={"publishedDateTimeUtc"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.list.submitted')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader
                            width={columns.startDateWidth}
                            columnName={"startDateTimeUtc"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.list.effectiveDate')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader
                            width={columns.subjectWidth}
                            columnName={"subject"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.subject')}
                        </TableColumnSortHeader>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumnNoGrow width={columns.actionWidth}>
                            </S.TableColumnNoGrow>
                        </S.TableCellWrapperNoGrow>
                    </S.BulletinListTableHeaderRow>)}>
                    {(bulletin,x,lastSearchedTerm) => {
                        return (
                            <S.BulletinListTableRow key={bulletin.id + "-publication-row"} minWidth={columns.totalWidth}>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.serviceProviderWidth}>
                                        <S.GenericTableRowContent dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(bulletin.subscription?.serviceProvider.name, lastSearchedTerm) }} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.categoryWidth}>
                                    <S.GenericTableRowContent dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(bulletin.subscription?.name, lastSearchedTerm) }} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.submittedDateWidth} title={locale('displayTimeZone.disclaimer')}>
                                        <S.GenericTableRowContent
                                            dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(
                                                DateTime.fromFormat(bulletin.publishedDateTimeUtc ?? '', DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.publishDateTimestamp),
                                                lastSearchedTerm) + " " + locale("bulletins.displayTimeZone")}}
                                        />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.startDateWidth} title={locale('displayTimeZone.disclaimer')}>
                                        <S.GenericTableRowContent
                                            dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(
                                                DateTime.fromFormat(bulletin.startDateTimeUtc ?? bulletin.publishedDateTimeUtc ?? '', DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.publishDateTimestamp),
                                                lastSearchedTerm) + " " + locale("bulletins.displayTimeZone") }}
                                        />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.subjectWidth}>
                                        <S.GenericTableRowContent dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(bulletin.subject, lastSearchedTerm) }} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={columns.actionWidth}>
                                        <S.ActionLink><Link to={`/Bulletin/${bulletin.id || -1}`}
                                                            style={{textDecoration: 'none'}}>{locale("view")}</Link></S.ActionLink>
                                    </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                            </S.BulletinListTableRow>
                        );
                    }}
                </BulletinTable>

                {/*TODO - Add proper link to subscribe*/}
                <SubscribeBanner header={locale("subscribeBanner.headings.subscribeHeader.bulletin")} link={'/Subscription'}/>
                <Footer/>

            </S.PageWithNavigationContainer>
        </div>
    )
};

export default BulletinList;