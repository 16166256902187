import React from 'react';
import * as S from '../styles';
import closeIcon from '../icons/close_x.svg';
import { locale } from '../locale';
import { IArea } from '../types';

interface Props {
	areas: IArea[];

	onExit(): any;
}

const ChartDisclaimerModal: React.FC<Props> = (props) => {
	return (
		<div>
			<S.ModalOverlay onClick={props.onExit} />
			<S.InformationModal>
				<S.ModalHeader>
					<S.InformationModalHeaderText>{locale('chartAssumptions')}</S.InformationModalHeaderText>
					<S.ClickableIcon src={closeIcon} width={18} height={20} onClick={props.onExit} />
				</S.ModalHeader>
				<S.ConfirmModalBody>
					<S.InformationModalBodyItem>
						<S.InformationModalBodyHeader>
							*{locale('localAreaChartDisclaimer')}
						</S.InformationModalBodyHeader>
					</S.InformationModalBodyItem>
					<S.InformationModalBodyItem>
						<S.InformationModalBodyHeader>
							**{locale("chart.legend.disclaimer1")}
						</S.InformationModalBodyHeader>
					</S.InformationModalBodyItem>
					<S.InformationModalBodyItem>
						<S.InformationModalBodyHeader>
							***{locale("chart.legend.disclaimer2")}<a href="https://ngtl.tcenergy.com/Menu/External/Default.aspx?psCode=AB" target="_blank" rel="noopener noreferrer">{locale("chart.legend.disclaimer.link")}</a>
						</S.InformationModalBodyHeader>
					</S.InformationModalBodyItem>
					{props.areas.map((a) => {
						console.log(a);
						return (
							a.capabilityAssumptions !== null && a.capabilityAssumptionsHeader !== null ? <S.InformationModalBodyItem key={a.id}>
								<S.InformationModalBodyHeader>
									{a.capabilityAssumptionsHeader}
								</S.InformationModalBodyHeader>
								<S.InformationModalBodyAssumptionHeader>
									{locale('chartAssumptions')}:
								</S.InformationModalBodyAssumptionHeader>
								<S.WhitespacePreserve>{a.capabilityAssumptions}</S.WhitespacePreserve>
							</S.InformationModalBodyItem> : <div></div>
						);
					})}
				</S.ConfirmModalBody>
			</S.InformationModal>
		</div>
	);
};

export default ChartDisclaimerModal;
