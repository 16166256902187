import {IEditorState, IBulletin} from "../types";
import draftToHtml from "draftjs-to-html";
import {convertFromRaw, convertToRaw, EditorState, ContentState} from "draft-js";


export class EditorUtilities {

    static getRawHTML = (editor: IEditorState) => {
        return editor.isHtml ? editor.editorState.getCurrentContent().getPlainText() : draftToHtml(convertToRaw(editor.editorState.getCurrentContent()));
    };

    static prepareForSave = (editor: IEditorState) => {
        const editorForSave = {
            editorState: convertToRaw(editor.editorState.getCurrentContent()),
            isHtml: editor.isHtml
        };
        return JSON.stringify(editorForSave);
    };

    // TODO - use when pulling data from DB to turn back into IEditorState
    static prepareForEdit = (editor: string, bulletin: IBulletin) => {
        console.debug(editor)
        let content, isHtml;
        try {
            const editorObject = JSON.parse(editor);
            const editorState = editorObject.editorState;
            content = convertFromRaw(editorState);
            isHtml = editorObject.isHtml
        } catch {
            content = ContentState.createFromText(bulletin.body);
            isHtml = true;
        }

        const newEditorState = EditorState.createWithContent(content);
        return {
            editorState: newEditorState,
            isHtml: isHtml
        };
    };
}

