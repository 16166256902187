import React from "react";
import NgtlToleranceChangeWidget from "./widgets/NgtlToleranceChangeWidget";
import * as S from "../../styles";

const NgtlDashboard: React.FC = () => {
    return <S.DashboardGridContainer>
        <S.DashboardGridItem size={1}>
            <NgtlToleranceChangeWidget/>
        </S.DashboardGridItem>
    </S.DashboardGridContainer>
};

export default NgtlDashboard;