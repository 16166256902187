import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(128, 128, 128, 0.5);
    opacity: 0.5;
    cursor: pointer;
    z-index: 999;
`;

export const BusyModalOverlay = styled(ModalOverlay)`   
    cursor: default;
`;
    
export const Modal = styled.div`        
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);       
    width: 328px;       
    padding: 16px;
    background: #FFFFFF;
    border-radius: 4px;
    z-index: 1000;        
`;


interface ResultModalProps {
    success?: boolean;
}

const ResultModalDefaultProps = {
    success: true,
};

export const ResultModal = styled(Modal)`        
    min-width: 328px;
    min-height: 32px;
    background: ${(props: ResultModalProps) => props.success ? `${colors.tc_green}` : `${colors.diff_red}`}; 
`;

ResultModal.defaultProps = ResultModalDefaultProps;

export const BusyModal = styled(Modal)`            
    background: transparent;   
`;

export const ModalHeader = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;     
   align-items: center;
`;

export const ModalHeaderText = styled.div`   
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;      
`;

export const ConfirmModalBody = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
    white-space: pre-wrap;
`;

export const ConfirmModalFooter = styled.div`         
    display: flex;    
    flex-direction: row;  
    justify-content: flex-end;
`;

export const ResultModalText = styled.div`        
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    vertical-align: top;
    margin: auto;
`;

export const ResultModalDecisionText = styled(ResultModalText)`        
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const InformationModal = styled.div`              
     position: fixed;
     max-height: 100%;
     @media ${device.desktop} {      
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);             
    }       
    @media ${device.tablet} {        
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);                
    }                  
    @media ${device.mobile} {     
        top: 5%;
        left: 5%;
        transform: translate(-2.5%, -2.5%);
        max-height: 600px;        
    }  
             
    padding: 16px;
    background: #FFFFFF;
    border-radius: 4px;
    z-index: 1000;        
    overflow-y : auto;
`;

export const InformationModalHeaderText = styled.div`   
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;      
`;

export const InformationModalBodyItem = styled.div`              
   margin-bottom: 10px;
   font-size: 12px;
   font-style: italic;
`;

export const InformationModalBodyHeader = styled.div`           
    font-weight: bold;    
`;

export const InformationModalBodyAssumptionHeader = styled.div`           
    text-decoration: underline;
`;
