import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtilities from "../utilities/DateUtilities";
import { CalenderInfoContainer } from '../styles/DatePickerStyles';


interface Props {
    date: Date | null;
    handleChange: (date: Date) => void;
    isDirty?: boolean;
    hasError?: boolean;
    customClassName?: string;
    placeholderText?: string;
    showTimeSelect?: boolean;
    showYearSelect?: boolean;
    showMonthSelect?: boolean;
    minDate?: Date;
    maxDate?: Date;
    isClearable?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    calenderInfo?: JSX.Element;
}

const DateInput: React.FC<Props> = (props) => {
    const format = props.showTimeSelect ? DateUtilities.publishDateTimestamp : DateUtilities.uiDateFormat;

    return (
        <React.Fragment>
            <DatePicker
                className={`dateInput ${props.isDirty ? "dateInputDirty" : ""} ${props.hasError ? "dateInputError" : ""} ${props.customClassName}`}
                dateFormat={format}
                selected={props.date}
                onChange={props.handleChange}
                placeholderText={props.placeholderText}
                showTimeSelect={props.showTimeSelect}
                timeIntervals={15}
                minDate={props.minDate ? props.minDate : new Date('1978-01-01')}
                maxDate={props.maxDate}
                isClearable={props.isClearable}
                timeFormat={'HH:mm'}
                title={props.errorMessage}
                showYearDropdown={props.showYearSelect}
                showMonthDropdown={props.showMonthSelect}
                disabled={props.disabled}
                todayButton={
                    <CalenderInfoContainer>
                        {props.calenderInfo}
                    </CalenderInfoContainer>
                }
            />
        </React.Fragment>
    );
};

export default DateInput;