import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router-dom";
import {locale} from '../locale/'
import * as S from "../styles"
import {IArea, IOutageComparison, OutageDisplay} from "../types";
import {useDispatch} from "react-redux";
import {setOutageDisplay} from "../actions/outagesActions";
import {OutageCardWide} from "./OutageCardWide";
import useTypedSelector from "../hooks/useTypedSelector";
import useDailyOperatingPlanCompare from "../hooks/useDailyOperatingPlanCompare";
import useAreas from "../hooks/useAreas";
import {AreaOutagesSummary, AreaOutagesSummaryItem} from "../types/AreaOutagesSummary";
import DateUtilities from "../utilities/DateUtilities";
import arrow from "../icons/chevron_left.svg";
import MoreOptions, {MoreOptionsItems} from "./MoreOptions";
import {OutageCompareBubbles} from "./OutageCompareBubbles";
import OutageToggleRow from "./OutageToggleRow";
import GetSelectedPublishDateAreaCapability from "../utilities/GetSelectedPublishDateAreaCapability";
import {AdminUtilities} from "../utilities/AdminUtilities";
import NumberUtilities from "../utilities/NumberUtilities";
import Footer from "./Footer";
import SortFunctions from "../utilities/SortFunctions";
import GoogleAnalyticsUtilities from "../utilities/GoogleAnalyticsUtilities";
import BetaBanner from "./BetaBanner";
import {PublishDateUtilities} from "../utilities/PublishDateUtilities";
import {convertVolumeUnit} from '../utilities/UnitConversions';
import useHeatValues from "../hooks/useHeatValues";
import {HeatValueUtilities} from "../utilities/HeatValueUtilities";
import {FileDownloadUtilities} from "../utilities/FileDownloadUtilities";
import OutageControls from "./OutageControls";
import {isMobile} from "react-device-detect";

interface Params {
    area: string;
}

interface IGroupedOutages {
    areaOutages: IAreaOutages[];
    loaded: boolean;
    genericHeader: boolean;
    includeAreaInformation: boolean;
}

interface IGroup {
    name: string;
    outages: AreaOutagesSummaryItem;
    foregroundColour: string;
    backgroundColour: string;
    includeRemoved: boolean;
}

interface IAreaOutages {
    area: IArea;
    baseCapability: string | null;
    count: number;
    statusCounts: AreaOutagesSummaryItem;
    groupSummary: AreaOutagesSummary;
    groups: IGroup[];
}

const OutagesList: React.FC<RouteComponentProps> = (props) => {
    const p = props.match.params as Params;
    useEffect(() => {
        GoogleAnalyticsUtilities.PageView(`OutagesList/${p.area ?? ""}`);
    }, [p.area]);

    const history = useHistory();
    const dispatch = useDispatch();

    useAreas();
    useHeatValues();
    useDailyOperatingPlanCompare();

    const allAreas = useTypedSelector(x => x.outages.areas);
    const outageHistory = useTypedSelector(x => x.outageComparison.outageHistory);
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const selectedPublishDate = useTypedSelector(x => x.outageComparison.publishDates.selectedPublishDate);
    const unit = useTypedSelector(state => state.app.volumeUnit);
    const heatValue = useTypedSelector(x => x.heatValue.heatValues);
    const heatValueLoaded = useTypedSelector(x => x.heatValue.heatValueLoaded);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)
    const [groupedOutages, setGroupedOutages] = useState<IGroupedOutages>({
        areaOutages: [],
        loaded: false,
        genericHeader: true,
        includeAreaInformation: true
    });

    function buildForArea(area: IArea, allOutages: IOutageComparison[]): IAreaOutages {
        const publishDate = allOutages[0].publishedDateTimeUtc;
        const areaOutages = allOutages.filter(x => (x.area.acronym === area.acronym));
        const outageStatus = new AreaOutagesSummaryItem(areaOutages);
        const summary = new AreaOutagesSummary(areaOutages);
        const capability = AdminUtilities.GetAreaCapabilityByDate(DateUtilities.Parse(publishDate), area.capabilities);

        return {
            area: area,
            count: areaOutages.length,
            groupSummary: summary,
            baseCapability: capability < 0 ? null : NumberUtilities.FormatFlow(capability.toString()),
            statusCounts: outageStatus,
            groups: [
                {
                    name: "gasDay",
                    foregroundColour: "#000000",
                    backgroundColour: "#F5F5F5",
                    outages: summary.current,
                    includeRemoved: false
                },
                {
                    name: "next30days",
                    foregroundColour: "#000000",
                    backgroundColour: "#F5F5F5",
                    outages: summary.upcoming30Days,
                    includeRemoved: false
                },
                {
                    name: "next30plusDays",
                    foregroundColour: "#000000",
                    backgroundColour: "#F5F5F5",
                    outages: summary.upcomingAfter30Days,
                    includeRemoved: false
                },
                {
                    name: "removed",
                    foregroundColour: "#000000",
                    backgroundColour: "#F5F5F5",
                    outages: summary.removed,
                    includeRemoved: true
                },
            ],
        };
    }

    useEffect(() => {

        if (!outageHistory.loaded || outageHistory.comparison.length === 0) {
            return;
        }

        const area = allAreas.find(x => x.acronym === p.area);
        const areaOutages = (area ? [area] : allAreas.sort(SortFunctions.DefaultAreaSortFunction())).map(x => buildForArea(x, outageHistory.comparison));
        setGroupedOutages({
            includeAreaInformation: p.area === null || areaOutages.length > 1,
            genericHeader: p.area === null || areaOutages.length !== 1,
            loaded: true,
            areaOutages: areaOutages
        });
    }, [allAreas, outageHistory, p.area]);

    function goBack() {
        props.history.goBack();
    }

    function navigationOptions() {
        return (
            <OutageToggleRow onMapClick={() => {
                history.push("/");
                dispatch(setOutageDisplay(OutageDisplay.Map));
            }} onChartClick={() => {
                history.push("/");
                dispatch(setOutageDisplay(OutageDisplay.Graph));
            }} onListClick={() => {
                history.push("/");
                dispatch(setOutageDisplay(OutageDisplay.List));
            }} display={OutageDisplay.List}/>
        );
    }

    const HeadingInfo: React.FC = () => {
        const capability = groupedOutages && p.area
            && groupedOutages.areaOutages.length === 1
            && groupedOutages.areaOutages[0].area.capabilities.length > 0
            && (groupedOutages.areaOutages[0].area.id !== 4 || showFHBCBaseCapability) ?
                GetSelectedPublishDateAreaCapability(groupedOutages.areaOutages[0].area)
            : null;
        const startDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.startDate) : '';
        const endDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.endDate) : '';
        return (
            <S.HeaderInfoContainer>
                <S.HeaderInfoBox isVisible={capability != null} style={{marginRight: "48px"}}>
                    <S.HeaderText>{locale('baseCapability')}</S.HeaderText>
                    <S.HeaderInfo>{convertVolumeUnit(capability?.capability ?? 0, unit).toLocaleString()}{" "}<S.HeaderUnits>{unit}</S.HeaderUnits></S.HeaderInfo>
                    <S.HeaderNotes>
                        {locale("outageList.header.fromTo")
                            .replace('{start-date}', startDate)
                            .replace('{end-date}', endDate)}
                    </S.HeaderNotes>
                </S.HeaderInfoBox>
                <S.HeaderInfoBox
                    isVisible={!isNaN(HeatValueUtilities.getHeatValue(heatValue, p.area, publishDates.selectedPublishDate))}>
                    <S.HeaderText>Heat Value</S.HeaderText>
                    {heatValueLoaded &&
                    <S.HeaderInfo>{HeatValueUtilities.getHeatValue(heatValue, p.area, publishDates.selectedPublishDate)}
                        <S.HeaderUnits> {locale('heatValue.units')}</S.HeaderUnits></S.HeaderInfo>}
                </S.HeaderInfoBox>
            </S.HeaderInfoContainer>
        )
    };

    function summaryHeaderInfo(area: IArea) {
        const capability = area && (area.id !== 4 || showFHBCBaseCapability)? AdminUtilities.GetAreaCapabilityAndRangeByDate(DateUtilities.ParseDate(selectedPublishDate), area.capabilities) : null;

        const startDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.startDate) : '';
        const endDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.endDate) : '';
        if (!Boolean(capability)) {
            return null;
        }
        return (
            <S.ViewAllHeaderInfoBox>
                <S.ViewAllHeaderInfo>
                    <div>
                        {locale('baseCapability')}:&nbsp;
                    </div>
                    <div>
                        <span style={{fontWeight: 400}}>
                            {convertVolumeUnit(capability?.capability ?? 0, unit).toLocaleString()}{" "}
                        </span>
                        <span style={{fontSize: "14px", color: "#555759"}}>
                        {unit}
                    </span>
                    </div>
                </S.ViewAllHeaderInfo>
                <S.HeaderNotesCentered>
                    {locale("outageList.header.fromTo")
                        .replace('{start-date}', startDate)
                        .replace('{end-date}', endDate)}
                </S.HeaderNotesCentered>
            </S.ViewAllHeaderInfoBox>
        )
    }

    function headerCount(count: number, label: string) {
        return <div>
            <S.HeaderOutageSummaryCount>{count}</S.HeaderOutageSummaryCount><S.HeaderOutageSummaryCountLabel>{label}</S.HeaderOutageSummaryCountLabel>
        </div>
    }


    function renderGroups(area: IAreaOutages) {
        return area.groups.map(group => {
                const outages = group.outages.getOutages(group.includeRemoved);
                // Decision made to not group if no outages in group
                if (outages.length === 0) {
                    return null;
                }
                return <div key={`${area.area.acronym}-${group.name}`}>
                    <S.GroupHeader key={`${group.name}OutagesHeading`} textColor={group.foregroundColour}
                                   backgroundColor={group.backgroundColour}>{locale(`${group.name}`).replace("{gasDay}", PublishDateUtilities.FormatPublishedDate(publishDates.selectedPublishDate, publishDates.recentPublishDates))}
                    </S.GroupHeader>
                    { // Decision, show area if nothing is there?
                        outages.length ?
                            outages.map(outage => <OutageCardWide key={outage.id} outage={outage}/>) :
                            <S.NoOutagesMessage>{locale(`${group.name}NoOutagesMessage`)}</S.NoOutagesMessage>
                    }
                </div>
            }
        );
    }

    const renderHeadingInfo = () => <HeadingInfo/>;

    return (
        <div>
            <BetaBanner/>
            {groupedOutages.loaded &&
            <S.PageWithNavigationContainer hasBanner={showBanner} hasStickyControls={true}>
                <S.HeaderBackground> </S.HeaderBackground>
                {!groupedOutages.genericHeader &&
                <S.BackNavigationHeader onClick={goBack} backgroundColor={groupedOutages.areaOutages[0].area.color}
                                        style={{cursor: 'pointer'}}>
                    <S.BackNavigationDetails>
                        <S.BackIcon src={arrow} alt={locale("back")}/>
                        <S.HeaderAreaName>{groupedOutages.areaOutages[0].area.displayName}</S.HeaderAreaName>
                        <S.HeaderCountDesktop>
                            {headerCount(groupedOutages.areaOutages[0].groupSummary.current.totalCount, locale("gasDay").replace("{gasDay}", PublishDateUtilities.FormatPublishedDate(publishDates.selectedPublishDate, publishDates.recentPublishDates)))}
                            {headerCount(groupedOutages.areaOutages[0].groupSummary.upcoming.totalCount, locale("upcoming"))}
                        </S.HeaderCountDesktop>
                        <S.HeaderCountMobile>
                            <OutageCompareBubbles summary={groupedOutages.areaOutages[0].statusCounts}/>
                        </S.HeaderCountMobile>
                    </S.BackNavigationDetails>
                    <S.HiddenOnMobile>
                        {!groupedOutages.areaOutages[0].area.isPlantTurnAround &&
                        <MoreOptions isVisible={true} options={[
                            MoreOptionsItems.DownloadOutages,
                        ]}/>}
                        {groupedOutages.areaOutages[0].area.isPlantTurnAround &&
                        <MoreOptions isVisible={true} options={[
                            MoreOptionsItems.DownloadPlantTurnAround,
                        ]}/>}
                    </S.HiddenOnMobile>
                </S.BackNavigationHeader>}
                {groupedOutages.genericHeader &&
                <S.BackNavigationHeader onClick={goBack}>
                    <S.CenteredPageTitle>{locale("outages")}</S.CenteredPageTitle>
                    <S.HiddenOnMobile>
                        <MoreOptions isVisible={true} options={[
                            MoreOptionsItems.DownloadOutages,
                            MoreOptionsItems.CollapseAllOutages
                        ]}/>
                    </S.HiddenOnMobile>
                </S.BackNavigationHeader>
                }
                <OutageControls hasBanner={showBanner} showIfTopScroll={true}
                                renderers={{center: () => !isMobile && renderHeadingInfo()}}/>
                <div>
                    {isMobile && renderHeadingInfo()}
                    {
                        groupedOutages.areaOutages.map(area =>
                            groupedOutages.includeAreaInformation ?
                                <div key={`${area.area.acronym}`}>
                                    <S.OutageAreaGroupHeader color={area.area.color}>
                                        <S.OutageGroupHeaderAreaName>
                                            {area.area.typeName} - {area.area.displayName}
                                        </S.OutageGroupHeaderAreaName>
                                        <S.OutageAreaGroupHeaderBaseCapability>
                                            {summaryHeaderInfo(area.area)}
                                        </S.OutageAreaGroupHeaderBaseCapability>
                                        {
                                            area.area.isPlantTurnAround &&
                                            <S.PlantTurnaroundCsvDownload>
                                        <span
                                            onClick={() => FileDownloadUtilities.initiateCsvFileDownload(`plantturnaroundactivity/csv/${selectedPublishDate}`, dispatch)}>{locale('plantTurnaroundDownloadCsv')}</span>
                                            </S.PlantTurnaroundCsvDownload>
                                        }
                                        {heatValueLoaded && !isNaN(HeatValueUtilities.getHeatValue(heatValue, area.area.acronym, publishDates.selectedPublishDate)) &&
                                        <S.OutageAreaGroupHeaderHeatValue>
                                            <S.OutageAreaGroupHeaderSubHeader>Heat
                                                Value</S.OutageAreaGroupHeaderSubHeader>
                                            {HeatValueUtilities.getHeatValue(heatValue, area.area.acronym, publishDates.selectedPublishDate)}
                                            <S.HeaderUnits> {locale('heatValue.units')}</S.HeaderUnits>
                                        </S.OutageAreaGroupHeaderHeatValue>}
                                    </S.OutageAreaGroupHeader>
                                    {renderGroups(area)}
                                    {area.area?.notes && <S.NotesContainer>
                                        <S.NotesHeader>{locale('notes')}</S.NotesHeader>
                                        <S.WhitespacePreserve>{area.area?.notes}</S.WhitespacePreserve>
                                    </S.NotesContainer>}
                                </div> :
                                <div key={`${area.area.acronym}`}>
                                    {renderGroups(area)}
                                    {area.area?.notes && <S.NotesContainer>
                                        <S.NotesHeader>{locale('notes')}</S.NotesHeader>
                                        <S.WhitespacePreserve>{area.area?.notes}</S.WhitespacePreserve>
                                    </S.NotesContainer>}
                                </div>
                        )
                    }
                </div>

                <Footer/>

                {p.area && groupedOutages && groupedOutages.areaOutages.length === 1 && !groupedOutages.areaOutages[0].area.isPlantTurnAround &&
                <S.MobileOptions>
                    <MoreOptions isVisible={true} options={[MoreOptionsItems.DownloadOutages]}/>
                </S.MobileOptions>}
                
                {p.area && groupedOutages && groupedOutages.areaOutages.length === 1 && groupedOutages.areaOutages[0].area.isPlantTurnAround &&
                <S.MobileOptions>
                    <MoreOptions isVisible={true} options={[MoreOptionsItems.DownloadPlantTurnAround]}/>
                </S.MobileOptions>}

                {!p.area &&
                <S.MobileOptions>
                    <MoreOptions isVisible={true} options={[
                        MoreOptionsItems.DownloadOutages,
                        MoreOptionsItems.CollapseAllOutages
                    ]}/>
                </S.MobileOptions>}

                {/*NOTE: There is another way to do this, can move outagesList to outages and have setOutageDisplay set it but then you lose url for potential bookmarking of specific area.
                 Adding this here was the least impact to the flow and matches our desired system flow/navigation.*/}
                {(p.area && groupedOutages && groupedOutages.areaOutages.length === 1) ?
                    <S.HiddenOnMobile>
                        {navigationOptions()}
                    </S.HiddenOnMobile> :
                    navigationOptions()
                }
            </S.PageWithNavigationContainer>}
        </div>
    );
};

export default OutagesList;