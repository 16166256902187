import {locale} from "../locale";

const navSubPages = [
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/'},
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/Outages'},
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/OutageMap'},
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/OutageMapArea'},
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/OutagesList'},
    {tabName: `${locale('tab.outages')}`,name: `${locale('navigation.menu.outages')}`, path: '/OutageChart'},
    {tabName: `${locale('tab.contact')}`,name: `${locale('navigation.menu.contact')}`, path: '/ContactUs'},
    {tabName: `${locale('tab.bulletin')}`,name: `${locale('page.header.bulletins')}`, path: '/Bulletins'},
    {tabName: `${locale('tab.bulletin')}`,name: `${locale('page.header.bulletins')}`, path: '/Bulletin'},
    {tabName: `${locale('tab.currentSystemReport')}`,name: `${locale('page.header.currentSystemReport')}`, path: '/CurrentSystemReport'},
    {tabName: `${locale('tab.gasDaySummaryReport')}`,name: `${locale('page.header.gasDaySummaryReport')}`, path: '/GasDaySummaryReport'},
    {tabName: `${locale('tab.dashboard')}`,name: `${locale('navigation.menu.dashboard')}`, path: '/Dashboard'},
    {tabName: `${locale('tab.dashboard')}`,name: `${locale('navigation.menu.dashboard')}`, path: '/Dashboard/Mainline'},
    {tabName: `${locale('tab.dashboard')}`,name: `${locale('navigation.menu.dashboard')}`, path: '/Dashboard/Ngtl'},
    {tabName: `${locale('tab.dashboard')}`,name: `${locale('navigation.menu.dashboard')}`, path: '/Bulletins'},
    {tabName: `${locale('tab.subscription')}`,name: `${locale('navigation.menu.subscriptions')}`, path: '/Subscriptions'},
    {tabName: `${locale('tab.subscription')}`,name: `${locale('navigation.menu.subscriptions')}`, path: '/Subscription'},
    {tabName: `${locale('tab.subscription')}`,name: `${locale('navigation.menu.subscriptions')}`, path: '/Verify'},
    {tabName: `${locale('tab.subscription')}`,name: `${locale('navigation.menu.subscriptions')}`, path: '/Unsubscribe'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/Admin'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminPublicationList'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminDailyOperatingPlan'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/ViewDailyOperatingPlan'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminBaseCapability'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/stationList'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/BulletinManagement'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/BulletinEdit'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminMainlineMaintenance'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminHeatValues'},
    {tabName: `${locale('tab.admin')}`, name: 'Admin', path: '/AdminChartExport'},
];

class NavigationUtilities {
    static getPage = (location: any) => {
        const re = new RegExp('^/\\w*');
        const getPath = re.exec(location);
        const currentPath = getPath !== null ? getPath[0] : '';
        return navSubPages.find(({path}) => path.toLowerCase() === currentPath.toLowerCase()) || {tabName: 'TC Energy', name: 'default', path: ''};
    };
}

export default NavigationUtilities;