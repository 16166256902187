import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import BetaBanner from "../BetaBanner";
import {locale} from "../../locale";
import useTypedSelector from "../../hooks/useTypedSelector";
import ReCAPTCHA from "react-google-recaptcha";
import SubscriptionValidation from "../../utilities/validation/SubscriptionValidation";
import {IValidationResult} from "../../utilities/validation/Validation";
import {useDispatch} from "react-redux";
import {Fetch} from "../../utilities/Fetch";
import {clearIsDirty, setIsDirty} from "../../actions/appActions";
import ResultModal from "../ResultModal";
import SubscriptionDisclaimer from "./SubscriptionDisclaimer";
import arrowUp from '../../icons/chevron-up.svg';
import arrowDown from '../../icons/chevron-down.svg';

const Subscriptions: React.FC = () => {
    const initialValidationResult = {message: "", isValid: true};
    const [emailValidationResult, setEmailValidationResult] = useState<IValidationResult>(initialValidationResult);
    const [email, setEmail] = useState<string>("");
    const [recaptchaRef, setRecaptchaRef] = useState();
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const [newUserClicked, setNewUserClicked] = useState<boolean>(true);
    const [reCaptchaValidationResult, setRecaptchaValidationResult] = useState<IValidationResult>({
        message: "",
        isValid: false
    });
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const isDirty = useTypedSelector(state => state.app.isDirty);

    const dispatch = useDispatch();

    useEffect(() => {
        setEmail('');
        setEmailValidationResult(initialValidationResult);
        resetRecaptcha();
    }, [newUserClicked]);

    function actionItems(label: string, successMessage: string) {
        return <S.ActionButtonContainer>
            <S.SubscribeSaveButton disabled={!reCaptchaValidationResult.isValid}
                          onClick={() => saveClick(successMessage)}>{label}</S.SubscribeSaveButton>
        </S.ActionButtonContainer>
    }

    function saveClick(successMessage: string) {
        if (!validate(email, reCaptchaToken)) {
            return;
        }
        save(successMessage);
    }

    function save(successMessage: string) {
        Fetch.Build("/subscriber/subscriptions", dispatch)
            .withPayload([
                {
                    reCaptchaToken: reCaptchaToken
                },
                [],
                {
                    email: email.trim(),
                    uniqueId: ''
                }])
            .success(() => {
                setResultText(successMessage);
                setResultSuccess(true);
                dispatch(clearIsDirty());
            })
            .error(() => {
                setResultText(locale("error.generic"));
                setResultSuccess(false);
            })
            .finally(() => {
                setShowResult(true);
                resetRecaptcha();
            })
            .post();
    }


    function validate(email: string, reCaptchaToken: string | null) {
        const emailValidationResultUpdate = SubscriptionValidation.EmailValidation.validate(email);
        setEmailValidationResult(emailValidationResultUpdate);
        const reCaptchaValidationResultUpdate = SubscriptionValidation.ReCaptchaValidation.validate(reCaptchaToken ? reCaptchaToken : "");
        setRecaptchaValidationResult(reCaptchaValidationResultUpdate);

        return emailValidationResultUpdate.isValid && reCaptchaValidationResultUpdate.isValid;
    }

    function updateEmail(value: string) {
        setEmail(value);
        if (!emailValidationResult.isValid) {
            validate(value, reCaptchaToken)
        }
        if (!isDirty) {
            dispatch(setIsDirty());
        }
    }

    function resetRecaptcha() {
        if (recaptchaRef) {
            recaptchaRef.reset();
            setReCaptchaToken("");
            setRecaptchaValidationResult({
                message: '',
                isValid: false
            });
        }
    }

    return (
        <div>
            <BetaBanner/>
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <S.BackNavigationHeader style={{cursor: "default"}}>
                    <S.CenteredPageTitle>{locale("page.header.subscriptions")}</S.CenteredPageTitle>
                </S.BackNavigationHeader>
                <S.UserTypeHeaderContainer onClick={() => setNewUserClicked(true)}>
                    <img src={newUserClicked ? arrowUp : arrowDown} alt=""/>
                    <span>{locale('subscription.email.newUser.header')}</span>
                </S.UserTypeHeaderContainer>
                {/*TODO - Create a component to wrap the SubscribeUserWrapper*/}
                {newUserClicked &&
                <S.SubscribeUserWrapper>
                    <S.FlexContainerRow>
                        <S.SubscribeInfoItem>
                            <S.InfoLabelNoSpacing>{locale('subscription.email.newUser.instructions')}</S.InfoLabelNoSpacing>
                        </S.SubscribeInfoItem>
                    </S.FlexContainerRow>
                    <S.SubscriptionInputContainer>
                        <S.SubscribeInfoItem>
                            <S.SubscriptionTextInput
                                placeholder={locale('contactPage.inputField.label.email')}
                                isDirty={false} type="text"
                                title={emailValidationResult.message}
                                hasError={!emailValidationResult.isValid}
                                onChange={text => updateEmail(text.target.value)}
                                value={email}/>
                            <S.ErrorLabel>{emailValidationResult.message}</S.ErrorLabel>
                        </S.SubscribeInfoItem>
                    </S.SubscriptionInputContainer>
                    <S.SubscribeRecaptchaWrapper>
                        <ReCAPTCHA ref={re => setRecaptchaRef(re)}
                                   sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                                   onChange={(token) => {
                                       setReCaptchaToken(token);
                                       validate(email, token);
                                   }}
                                   onExpired={() => {
                                       setReCaptchaToken("");
                                       validate(email, "");
                                   }}/>
                        <S.ErrorLabel>{reCaptchaValidationResult.message}</S.ErrorLabel>
                    </S.SubscribeRecaptchaWrapper>
                    <S.FlexContainerRow>
                        <S.InfoLabelSpecial>{locale('subscription.email.newUser.info')}</S.InfoLabelSpecial>
                    </S.FlexContainerRow>
                    {actionItems(locale("subscription.subscribe"), locale("subscription.subscribe.success"))}
                </S.SubscribeUserWrapper>}
                <S.SeparatorLine/>

                <S.UserTypeHeaderContainer onClick={() => setNewUserClicked(false)}>
                    <img src={newUserClicked ? arrowDown : arrowUp} alt=""/>
                    <span>{locale('subscription.email.existingUser.header')}</span>
                </S.UserTypeHeaderContainer>
                {!newUserClicked &&
                <S.SubscribeUserWrapper>
                    <S.FlexContainerRow>
                        <S.SubscribeInfoItem>
                            <S.InfoLabelNoSpacing>{locale('subscription.email.existingUser.instructions')}</S.InfoLabelNoSpacing>
                        </S.SubscribeInfoItem>
                    </S.FlexContainerRow>
                    <S.SubscriptionInputContainer>
                        <S.SubscribeInfoItem>
                            <S.SubscriptionTextInput
                                placeholder={locale('contactPage.inputField.label.email')}
                                isDirty={false} type="text"
                                title={emailValidationResult.message}
                                hasError={!emailValidationResult.isValid}
                                onChange={text => updateEmail(text.target.value)}
                                value={email}/>
                            <S.ErrorLabel>{emailValidationResult.message}</S.ErrorLabel>
                        </S.SubscribeInfoItem>
                    </S.SubscriptionInputContainer>
                    <S.SubscribeRecaptchaWrapper>
                        <ReCAPTCHA ref={re => setRecaptchaRef(re)}
                                   sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                                   onChange={(token) => {
                                       setReCaptchaToken(token);
                                       validate(email, token);
                                   }}
                                   onExpired={() => {
                                       setReCaptchaToken("");
                                       validate(email, "");
                                   }}/>
                        <S.ErrorLabel>{reCaptchaValidationResult.message}</S.ErrorLabel>
                    </S.SubscribeRecaptchaWrapper>
                    <S.FlexContainerRow>
                        <S.InfoLabelSpecial>{locale('subscription.email.existingUser.info')}</S.InfoLabelSpecial>
                    </S.FlexContainerRow>
                    <S.InfoLabel style={{margin: '16px 0'}}>{locale('subscription.manage.info')} <S.A
                        style={{fontSize: '14px'}} href="tel:4039207473">(403) 920-7473</S.A> {locale('banner.beta.or')}
                        <S.A style={{fontSize: '14px'}} href="tel:18779207473"> 1 (877)
                            920-7473</S.A> {locale('banner.beta.or')} <S.A style={{fontSize: '14px'}}
                                                                           href="mailto: nominations@tcenergy.com">nominations@tcenergy.com</S.A></S.InfoLabel>
                    {actionItems(locale("contactPage.button.submit"), locale("subscription.manage.success"))}
                </S.SubscribeUserWrapper>
                }
                <S.SeparatorLine/>
                <SubscriptionDisclaimer/>

                {showResult && <ResultModal text={resultText} onExit={() => {
                    setShowResult(false);
                    if (resultSuccess) {
                        setEmail("");
                    }
                }} success={resultSuccess}/>}

            </S.PageWithNavigationContainer>
        </div>
    )
};

export default Subscriptions;