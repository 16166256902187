import styled from "styled-components";
import colors from "./Colors";
import {FontSize} from "./FontStyles";

export const Widget = styled.div`
    background: ${colors.background};
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    margin: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: ${colors.charcoal_text};
    padding: 20px 15px 15px 15px
`;

export const WidgetTitle = styled.div`
    margin: 15px;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    ${FontSize("32px")};
    text-align: center;
`;

export const WidgetTableCell = styled.div`
    display: flex;
    flex: 1;
    padding: 15px;
    justify-content: space-around;
    text-align: center;
`;

export const WidgetTableRow = styled.div`
    display: flex;
    flex-direction: row;
    ${WidgetTableCell}:nth-child(even) {
        background: #1A72C81A;
    }
`;

export const WidgetTable = styled.div`
    ${WidgetTableRow}:nth-child(even) {
        background: #E6EEF5;
    }
`;

export const WidgetFlexContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const WidgetCsvLink = styled.div`
    text-align: center;
    margin: 5px;
    justify-content: space-around;

    span {
        font-weight: 500;
        ${FontSize("12px")};
        color: #1A72C8;
        cursor: pointer;
    }
`;