import {
    IOutage, IOutageComparison,
} from "./index";

export const SET_DAILY_OPERATING_PLAN_COMPARE_DATE = "SET_DAILY_OPERATING_PLAN_COMPARE_DATE";
export const SET_DAILY_OPERATING_PLAN_COMPARISONS = "SET_DAILY_OPERATING_PLAN_COMPARISONS";
export const SET_OUTAGE_HISTORY = "SET_OUTAGE_HISTORY";
export const SET_PUBLISH_DATES = "SET_PUBLISH_DATES";
export const SET_PUBLISH_DATE = "SET_PUBLISH_DATE";
export const SET_OUTAGE_COMPARISON_LOADED = "SET_OUTAGE_COMPARISON_LOADED";

interface ISetPublishDates {
    type: typeof SET_PUBLISH_DATES;
    publishDates: string[];
}

interface ISetPublishDate {
    type: typeof SET_PUBLISH_DATE;
    publishDate: string;
}

interface ISetPublishDatesLoaded {
    type: typeof SET_OUTAGE_COMPARISON_LOADED;
    loaded: boolean;
}

interface ISetOutageHistory {
    type: typeof SET_OUTAGE_HISTORY;
    publishDate: string;
    outages: IOutage[];
}

interface ISelectDailyOperatingPlanCompareDate {
    type: typeof SET_DAILY_OPERATING_PLAN_COMPARE_DATE;
    compareDate: string;
}

interface ISetDailyOperatingPlanComparisons {
    type: typeof SET_DAILY_OPERATING_PLAN_COMPARISONS;
    comparison: IOutageComparison[];
}

export type OutageComparisonActionTypes = ISetPublishDates | ISetPublishDate | ISetOutageHistory | ISelectDailyOperatingPlanCompareDate | ISetDailyOperatingPlanComparisons | ISetPublishDatesLoaded;