import React from "react";
import * as S from "../styles";
import colors from "../styles/Colors";
import {AreaOutagesSummaryItem} from "../types/AreaOutagesSummary";

interface OutageCompareBubblesProp {
    summary: AreaOutagesSummaryItem | null;
}

export const OutageCompareBubbles: React.FC<OutageCompareBubblesProp> = (props) => {

    return (
        props.summary === null ?
            <div></div> :
            <div style={{width: "78px"}}>
                {(props.summary.changedCount > 0) && <S.Dot style={{margin: "5px"}} color={colors.changed} textColor={colors.changedForeground}>{props.summary.changedCount}</S.Dot>}
                {(props.summary.removedCount > 0) && <S.Dot style={{margin: "5px"}} color={colors.removed} textColor={colors.removedForeground}>{props.summary.removedCount}</S.Dot>}
                {(props.summary.newCount > 0) && <S.Dot style={{margin: "5px"}} color={colors.new} textColor={colors.newForeground}>{props.summary.newCount}</S.Dot>}
            </div>
    )

};
