import styled from 'styled-components';
import colors from "./Colors";

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;

`;

export const ErrorContent = styled.div`
  text-align: center;
  max-width: 1080px;

  img {
    width: 180px;
    margin-top: 70px;
  }
  h1 {
    font-family: "Maven Pro";
    font-weight: 500;
    font-size: 150px;
    margin: 60px 0;
  }
  h2 {
    font-family: "Maven Pro";
    font-size: 40px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-size: 18px;
  }
  a {
    color: ${colors.primary};
    text-decoration: none;
    cursor: pointer;
  }
  a:visited {
    color: ${colors.primary};
  }
  a:hover {
    color: ${colors.selected};
  }
`;