import React from 'react';
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from "@createnl/grouped-checkboxes";

import * as S from "../../styles";
import {locale} from "../../locale";
import { CurrentSystemReportColumnWidths } from '../../styles';

import {convertVolumeUnit} from '../../utilities/UnitConversions';

import {ICurrentSystemReportActive, ICurrentSystemReportData, INVALID, VolumeUnits} from "../../types";
import useTypedSelector from '../../hooks/useTypedSelector';
import {CurrentSystemReportEnum} from "../../types";


interface Props {
    active: ICurrentSystemReportActive;
    setActiveChartDataMultiple: any;
    setActiveChartData: any;
    dataGridName: string;
    dataGridContents: ICurrentSystemReportData[];
}

export class CurrentSystemReportDataGridModel {
    currentSystemReportData: ICurrentSystemReportData;
    units: VolumeUnits;

    constructor(currentSystemReportData: ICurrentSystemReportData, units: VolumeUnits) {
        this.currentSystemReportData = currentSystemReportData;
        this.units = units;
    }

    currentValueNumber() {
        return parseFloat(this.currentSystemReportData.currentValue);
    }

    get currentValueString() {
        return this.isCurrentValueValid() ? Math.round(convertVolumeUnit(this.currentValueNumber(), this.units)).toLocaleString() : INVALID;
    }

    private isCurrentValueValid() {
        return this.currentValueNumber().toString() !== "NaN";
    }
    
    get description() {
        return this.currentSystemReportData.description;
    }

    get color() {
        return this.currentSystemReportData.color;
    }

    get id() {
        return this.currentSystemReportData.id;
    }

    get isTotal() {
        return this.currentSystemReportData.description.toLowerCase().includes('total')
    }

    get displayUnits() {
        if (this.id === CurrentSystemReportEnum.CURRENTLINEPACK ) {
            return this.units === VolumeUnits.THOUSAND_CUBIC_METERS ? "10\u00b3m\u00b3" : "MMcf"
        }
        if (this.id === CurrentSystemReportEnum.LINEPACKTARGET) {
            return this.units === VolumeUnits.THOUSAND_CUBIC_METERS ? "10\u00b3m\u00b3" : "MMcf"
        }
        if (this.id === CurrentSystemReportEnum.LINEPACK4HR) {
            return this.units === VolumeUnits.THOUSAND_CUBIC_METERS ? "10\u00b3m\u00b3" : "MMcf"
        }
        return this.units === VolumeUnits.THOUSAND_CUBIC_METERS ? "10\u00b3m\u00b3/day" : "MMcf/day"
    }
}

export const DataGridDetail: React.FC<Props> = (props) =>
{
    const units = useTypedSelector(x => x.app.volumeUnit);

    return (
        <S.CurrentSystemReportTable>
            <CheckboxGroup onChange={props.setActiveChartDataMultiple}>
                <S.CurrentSystemReportTableHeaderRow key={"deliver-datagrid-header-row"} minWidth={CurrentSystemReportColumnWidths.totalWidth}>
                        <S.CheckboxHeaderStyle>
                            <S.CurrentSystemReportDatagridHeaderText><AllCheckerCheckbox /></S.CurrentSystemReportDatagridHeaderText>
                        </S.CheckboxHeaderStyle>
                        <S.TableColumnforDescription>
                            <S.CurrentSystemReportDatagridHeaderText>{props.dataGridName}</S.CurrentSystemReportDatagridHeaderText>
                        </S.TableColumnforDescription>
                        <S.TableColumnforCurrentValues>
                            <S.CurrentSystemReportDatagridHeaderText>{locale('currentSystemReport.dataGrid.tableHeader.currentValues')}</S.CurrentSystemReportDatagridHeaderText>
                        </S.TableColumnforCurrentValues> 
                </S.CurrentSystemReportTableHeaderRow>
                {props.dataGridContents.map(item => {
                    const model = new CurrentSystemReportDataGridModel(item, units);

                    if (model.isTotal === false) 
                    {
                        return (
                            <S.CurrentSystemReportTableRow key={model.id}  minWidth={CurrentSystemReportColumnWidths.totalWidth}>
                                    <S.TableColumnForCheckbox>
                                        <Checkbox value={model.id} checked={props.active[model.id]}/>
                                    </S.TableColumnForCheckbox>
                                    <S.TableColumnforDescription>
                                        <S.GraphLinePadding>
                                        {model.currentSystemReportData.category === "DELIVERY" && <svg height="4" width="25">
                                            <g fill="none" stroke={model.color} strokeWidth="10">
                                                <path strokeDasharray="14,2,2,7" d="M5 2 300 0" />
                                            </g>
                                        </svg>}  
                                        {model.currentSystemReportData.category === "RECEIPTS" && <svg height="4" width="25">
                                            <g fill="none" stroke={model.color} strokeWidth="10">
                                                <path strokeDasharray="null" d="M5 1 300 0" />
                                            </g> 
                                        </svg>}
                                        {model.currentSystemReportData.category === "LINEPACK" && <svg height="4" width="25">
                                            <g fill="none" stroke={model.color} strokeWidth="10">
                                                <path strokeDasharray="4,4" d="M5 2 300 0" />
                                            </g>
                                        </svg>} 
                                        </S.GraphLinePadding>
                                        <S.DescriptionPadding>
                                        {model.description}
                                        </S.DescriptionPadding>
                                    </S.TableColumnforDescription>
                                    <S.TableColumnforCurrentValues>
                                        <S.CurrentValuesNumber> {model.currentValueString} </S.CurrentValuesNumber>  
                                        <S.CurrentValuesUnits>{model.displayUnits}</S.CurrentValuesUnits>
                                    </S.TableColumnforCurrentValues>
                            </S.CurrentSystemReportTableRow>
                        )
                    }
                    if (model.isTotal===true) 
                    {
                        return (
                            <S.CurrentSystemReportTableRow key={model.id}  minWidth={CurrentSystemReportColumnWidths.totalWidth}>
                                <S.TableColumnForCheckbox>
                                    <input type='checkbox' onChange={(event) => props.setActiveChartData(model.currentSystemReportData.id, event.target.checked)} />
                                </S.TableColumnForCheckbox>
                                <S.TableColumnforDescription>
                                <S.GraphLinePadding>
                                    {model.currentSystemReportData.category === "DELIVERY" && <svg height="4" width="25">
                                        <g fill="none" stroke={model.color} strokeWidth="10">
                                            <path strokeDasharray="14,2,2,7" d="M5 2 300 0" />
                                        </g>
                                    </svg>} 
                                    {model.currentSystemReportData.category === "RECEIPTS" && <svg height="4" width="25">
                                        <g  fill="none" stroke={model.color} strokeWidth="10">
                                        <path strokeDasharray="null" d="M5 2 300 0" />
                                        </g>
                                    </svg>} 
                                    </S.GraphLinePadding>
                                    <S.DescriptionPadding>
                                    {model.description}
                                    </S.DescriptionPadding>
                                 </S.TableColumnforDescription>                                    
                                <S.TableColumnforCurrentValues>
                                <S.CurrentValuesNumber> {model.currentValueString} </S.CurrentValuesNumber>
                                <S.CurrentValuesUnits> {units} </S.CurrentValuesUnits>
                                </S.TableColumnforCurrentValues>
                            </S.CurrentSystemReportTableRow>
                        )
                    }
                })}
            </CheckboxGroup>
        </S.CurrentSystemReportTable>
    );
};