import styled from "styled-components";
import colors from "./Colors";
import {ErrorBorderStyle} from "./index";
import {PublicationStatus} from "../types";
// TODO - Change "select_down_arrow_disabled.svg" to "select_down_arrow.svg" when enabling area drop down
import selectDownArrowImage from "../icons/select_down_arrow_disabled.svg";
import {device} from "./DeviceSizes";

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 12px;
    @media ${device.mobile} {
      margin: auto;
    }
`;

export const InformationContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const InformationItem = styled.div`
    margin: 14px;
`;

export const Label = styled.div`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #8C878A;
`;

export const Value = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
`;

export const AdminAreaCard = styled.div`
    background: #FFFFFF;
    border: 1px solid #CFCDCE;
    border-radius: 4px;
    margin: 20px;
`;

export const AdminAreaHeader = styled.div`
    background: ${props => props.color ? props.color : "#F5F5F5"};
    border-radius: 4px 4px 0 0;
    height: 48px;
`;

export const AdminAreaHeaderText = styled.div`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    padding: 14px;
`;

export const TableRow = styled.div`
  display: flex;          display: -webkit-flex;
  flex-direction: row;    -webkit-flex-direction: row;
  flex-wrap: nowrap;     -webkit-flex-wrap: nowrap;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const TableHeaderRow = styled(TableRow)`
  margin-top: 15px;
`;

interface TableCellWrapperProps {
    sortable?: boolean;
}

export const TableCellWrapper = styled.div<TableCellWrapperProps>`
   display: flex; display: -webkit-flex;
   flex-direction: row; -webkit-flex-direction: row;
   flex-grow: 1; -webkit-flex-grow: 1;
   ${props => props.sortable ? "cursor: pointer;" : ""};
`;

export const TableCellWrapperNoGrow = styled.div`
   display: flex; display: -webkit-flex;
   flex-direction: row; -webkit-flex-direction: row;
   flex-grow: 0; -webkit-flex-grow: 0;
`;

interface TableColumnProps {
    width: string;
    viewOnly?: boolean;
}

export const TableColumn = styled.div<TableColumnProps>`
   flex-grow: 1; -webkit-flex-grow: 1;
   vertical-align: top;
   width: ${props => props.width}px;
   padding-left: 4px;
   padding-right: 4px;
   ${props => props.viewOnly ? "border-bottom: 1px solid #CFCDCE; " : ""};
`;

export const TableColumnNoGrow = styled.div<TableColumnProps>`
   flex-grow: 0; -webkit-flex-grow: 0;
   vertical-align: top;
   width: ${props => props.width}px;
   padding-left: 4px;
   padding-right: 4px;
   justify-content: space-between;
   ${props => props.viewOnly ? "border-bottom: 1px solid #CFCDCE; " : ""};
`;

export const TableCellNotEditable = styled.div`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  padding: 7px;
`;

interface SortHeaderProps {
    isSorted?: boolean;
}

export const TableCellHeaderText = styled.div<SortHeaderProps>`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: ${p => (p.isSorted) ? colors.tc_blue_100 : colors.charcoal};
    text-align: center;
    img {
      filter: ${p => (p.isSorted) ? "invert(33%) sepia(98%) saturate(1251%) hue-rotate(190deg) brightness(85%) contrast(86%)" : ""};
    }
`;

export const TableCellHeaderTextLeft = styled(TableCellHeaderText)`
    text-align: left;
`;

export const TableCellHeaderTextUnit = styled(TableCellHeaderText)`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    color: ${colors.primary};
`;

export const TableCellAddText = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4px;
    margin-right: 4px;
    color: #4D8BD2;
    cursor: pointer;
`;

interface AdminTextInputProperties {
    isDirty?: boolean;
    hasError?: boolean;
}

export const AdminTextInput = styled.input<AdminTextInputProperties>`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  color: ${props => props.isDirty ? colors.changed : "#8C878A;"};
  border: ${props => (props.isDirty) ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
  ${props => props.hasError ? `
    border: ${ErrorBorderStyle} !important;
    background: ${colors.error_background} !important;
    color: black;
  ` : ""};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 7px;
  :invalid {
    box-shadow: none;
  }
`;

export const AdminTextArea = styled.textarea<AdminTextInputProperties>`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  border: ${props => props.isDirty ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
  ${props => props.hasError ? `
    border: ${ErrorBorderStyle} !important;
    background: ${colors.error_background} !important;
    color: black;
  ` : ""};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.isDirty ? colors.changed : "#8C878A;"};
  padding: 7px;
  resize: none;
`;

export const ViewTextArea = styled.div`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  box-sizing: border-box;
  color: #8C878A;
  padding: 7px;
  resize: none;
`;

export const HeaderLine = styled.hr`
    margin-left: 20px;
    margin-right: 20px;
    border: 0.5px solid #CFCDCE;
`;

export const ViewHeaderLine = styled(HeaderLine)`
    margin-left: 14px;
    margin-right: 14px;
`;

export const TableCellIcon = styled.img`
  display: block;
  margin: auto;
  cursor: pointer;
`;

export const TableCellIconRemove = styled.img`
  display: block;
  margin-top: 7px;
  cursor: pointer;
`;

export const ActionLink = styled.span`
  text-transform: lowercase;
  cursor: pointer;
  color: #4D8BD2;
  font-size: 14px;
  line-height: 16px;
  a {
    text-decoration: none;
    color: #4D8BD2;
  }
  a:visited {
    color: #4D8BD2;
  }
`;

interface ListContentProp {
    italic?: boolean;
}

export const PublicationListContent = styled.div<ListContentProp>`
  font-size: 14px;
  line-height: 16px;
  font-style: ${props => props.italic ? 'italic' : 'normal'};
`;

interface StatusSpanProps {
    status: string;
}

export const StatusSpan = styled.span<StatusSpanProps>`
  font-weight: ${props => props.status === PublicationStatus.DRAFT ? 'bold' : 'normal'};
  font-style: ${props => props.status === PublicationStatus.SCHEDULED ? 'italic' : 'normal'};
`;

export const PublicationListTableHeaderRow = styled(TableHeaderRow)`
  border-bottom: solid #E5E5E5 1px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

export const PublicationListTableRow = styled(TableHeaderRow)`
  border-bottom: solid #E5E5E5 1px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

export const StationListHeaders = styled.div`
  font-family: Maven Pro;
  font-size: 24px;
  line-height: 28px;
  margin: 24px 0;
`;

export const StationListSubHeader = styled.div`
    font-family: Maven Pro;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 18px;
`;

export const AdminPageHeader = styled.div`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
`;

export const StationListSearchBar = styled.div`
  display: flex;
  input {
    height: 40px;
    min-width: 400px;
    border: 1px solid #CFCDCE;
    border-radius: 4px;
    font-size: 18px;
    padding: 0 8px;
    ::placeholder {
      font-style: italic;
    }
  }
`;

export const StationListHeaderOptions = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const StationListTableRow = styled(TableRow)`
  margin: 0;
  padding: 9px 18px;
`;

export const StationListTableHeaderRow = styled(TableHeaderRow)`
  margin-bottom: 16px;
`;

export const StationListHeaderText = styled.div`
  font-family: Maven Pro;
  font-size: 16px;
  color: ${colors.charcoal_text};
  text-align: center;
`;

export const StationListHeaderTextLeft = styled(StationListHeaderText)`
  text-align: left;
`;

export const StationListContent = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
`;

export const StationListTableContentWrapper = styled.div`
  ${StationListTableRow}:nth-child(even) {
    background: rgba(215, 225, 243, 0.5);
  }
`;

export const AddStationRow = styled.div`
  margin: 24px 0;
  span {
    margin: 0 40px 0 0;
  }
`;

interface ValidationProps {
    isDirty?: boolean;
    hasError?: boolean;
}

export const AddStationTextInput = styled.input<ValidationProps>`
  height: 32px;
  border: 1px solid #CFCDCE;
  border-radius: 4px;
  width: 200px;
  padding: 0 8px;
  margin: 0 40px 0 0;
  color: ${props => props.isDirty ? colors.changed : "#000000;"};
  border: ${props => (props.isDirty) ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
  ${props => props.hasError ? `
    border: ${ErrorBorderStyle} !important;
    background: ${colors.error_background} !important;
    color: black;
  ` : ""};
  ::placeholder {
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-size: 14px;
    color: ${colors.charcoal};
  }
`;

export const AddStationDropDown = styled.select`
  height: 32px;
  border: 1px solid #CFCDCE;
  border-radius: 4px;
  width: 200px;
  padding: 0 8px;
  margin: 0 40px 0 0;
  appearance: none;
  background-image: url('${selectDownArrowImage}');
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  background-clip: padding-box;
  background-color: #ffffff;
`;

export const AdminContainer = styled.div`
    padding: 24px;
`;

export const AdminRow = styled.div`
    margin-top: 24px;
`;

export const AdminHeader = styled.h2`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${colors.charcoal_text};
`;

export const AdminButtonRow = styled.div`
    background: #EDF4F7;
    border-top: 1px solid #CFCDCE;
    padding: 24px 24px 0 24px;
`;

export const AdminButton = styled.button`
    background: #FFFFFF;
    border: 1px solid ${colors.tc_blue_100};
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.tc_blue_100};
    cursor: pointer;
    margin: 0 24px 24px 0;
    padding: 16px;
    min-height: 48px;
    max-width: 300px;
    @media ${device.tablet} {
      min-width: 300px;
    }

    @media ${device.desktop} {
      min-width: 300px;
    }

    @media ${device.mobile} {
      min-width: calc(100% - 24px);
    }

    &:hover {
        background-color: ${colors.tc_blue_100};
        color: white;
    }
`;

export const AdminUserTitle = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: ${colors.black_text};
`;

export const HeatValueDateWrapper = styled.div`
  padding: 16px 0;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin-right: 40px;
  span {
      font-family: "Maven Pro";
      font-weight: bold;
      font-size: 18px;
      color: ${colors.charcoal};
      padding-bottom: 6px;
  }
`;

export const HeatValueInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    padding: 24px 0;
  }
`;

export const HeatValueInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HeatValue = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Maven Pro";
  font-size: 14px;
  color: ${colors.charcoal_text};
  margin-right: 20px;
  label {
    padding: 8px 0;
  }
  span {
    padding: 0 8px;
  }
`;

interface HeatValueProps {
    isDirty: boolean;
    hasError: boolean;
}

export const HeatValueInput = styled.input<HeatValueProps>`
    border: 1px solid #CFCDCE;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    width: 52px;
    appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    margin: 0;
    }
    -moz-appearance: textfield;
    padding: 8px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: ${props => props.isDirty ? colors.changed : `${colors.charcoal_text}`};
    border: ${props => (props.isDirty) ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
    ${props => props.hasError ? `
        border: ${ErrorBorderStyle} !important;
        background: ${colors.error_background} !important;
        color: black;
        ` : ""};
`;

export const ChartExportRow = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 15px;
  color: black;
`;

export const ChartExportArea = styled.div`
  font-family: Maven Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.charcoal_text};
  margin-bottom: 5px;
`;

export const ChartExportContainer = styled.div`
  padding: 40px;
`;

export const ChartExportValue = styled.div`
margin-right: 20px;
label {
  padding: 8px 0;
}
`;

export const CurrentHeatValueList = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CurrentHeatValue = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Maven Pro";
  font-size: 14px;

  color: ${colors.black_text};
  margin-right: 16px;
  label {
    padding: 0;
  }
  span {  
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const HeatValueHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeatValueDatePickerLabel = styled.div`
  font-family: "Maven Pro";
  font-size: 14px;
  color: #555759;
`;

export const HeatValueTitle = styled.div`
  font-family: Maven Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.charcoal};
`;

export const HeatValueDatePickerWrapper = styled.div`
 width: max-content;
 margin-top: 15px;
`;

export const HeatValueDisclaimer = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 10px;
`;