import Validation, {ValidationFunctionResult} from "./Validation";
import ValidationFunctions from "./ValidationFunctions";
import DateUtilities from "../DateUtilities";
import {DateTime} from "luxon";

export default class ChartExportValidation {
    static DateOrderValidation = (startDate: string, endDate: string) => () => {
        const start = DateTime.fromFormat(startDate, DateUtilities.editDateFormat);
        const end = DateTime.fromFormat(endDate, DateUtilities.editDateFormat);
        if (!start.isValid || !end.isValid) {
            return ValidationFunctionResult.NOT_RUN;
        }
        return start.toMillis() <= end.toMillis() ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static StartDateValidation: (date: string, endDate: string) => Validation = (date: string, endDate: string) => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.export.startDate.requiredMessage"
        },
        {
            validationFunction: ValidationFunctions.DateValidationFunction(DateUtilities.serviceDateOnlyFormat),
            validationMessageKey: "validation.export.startDate.invalidDate"
        },
        {
            validationFunction: ChartExportValidation.DateOrderValidation(date, endDate),
            validationMessageKey: "validation.export.startDate.beforeEndDateMessage"
        }
    ]);

    static EndDateValidation: (date: string, startDate: string) => Validation = (date: string, startDate: string) => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.export.endDate.requiredMessage"
        },
        {
            validationFunction: ValidationFunctions.DateValidationFunction(DateUtilities.serviceDateOnlyFormat),
            validationMessageKey: "validation.export.endDate.invalidDate"
        },
        {
            validationFunction: ChartExportValidation.DateOrderValidation(startDate, date),
            validationMessageKey: "validation.export.endDate.afterStartDateMessage"
        }
    ]);
}