import { VolumeUnits, EnergyUnits, ENERGY_CONVERSION_FACTOR } from "../types";

export enum Unit {
    M3,
    E3M3,
    E6M3,
    E9M3,
    E12M3,
    J,
    KJ,
    MJ,
    GJ,
    TJ,
}

const VolumeUnit = [Unit.M3, Unit.E3M3, Unit.E6M3, Unit.E9M3, Unit.E12M3];
const EnergyUnit = [Unit.J, Unit.KJ, Unit.MJ, Unit.GJ, Unit.TJ];


const from_base_unit_conversions: Map<Unit, number> = new Map([
        [Unit.M3, 1],
        [Unit.E3M3, 1000],
        [Unit.E6M3, 1000000],
        [Unit.E9M3, 1000000000],
        [Unit.E12M3, 1000000000000],
        [Unit.J, 1],
        [Unit.KJ, 1000],
        [Unit.MJ, 1000000],
        [Unit.GJ, 1000000000],
        [Unit.TJ, 1000000000000],
    ]);

const aliases: {[key: string]: Unit} = {
    "M3": Unit.M3,
    "E3M3": Unit.E3M3,
    "E6M3": Unit.E6M3,
    "E9M3": Unit.E9M3,
    "E12M3": Unit.E12M3,
    "103M3": Unit.E3M3,
    "106M3": Unit.E6M3,
    "109M3": Unit.E9M3,
    "1012M3": Unit.E12M3,
    "J": Unit.J,
    "KJ": Unit.KJ,
    "MJ": Unit.MJ,
    "GJ": Unit.GJ,
    "TJ": Unit.TJ,
};

export function isValidUnit(unit: string | Unit) {
    unit = convertToUnitEnum(unit);

    return VolumeUnit.includes(unit) || EnergyUnit.includes(unit);
}

function convertToUnitEnum(unit: string | Unit): Unit {
    if (typeof unit === "string") {
        return aliases[unit];
    }
    return unit;
}

export function convertUnit(fromUnit: string | Unit, toUnit: string | Unit, fromValue: number, heatValueJoulesPerM3: number | null = null): number {
    fromUnit = convertToUnitEnum(fromUnit);
    toUnit = convertToUnitEnum(toUnit);

    const conversion1 = from_base_unit_conversions.get(fromUnit);
    const conversion2 = from_base_unit_conversions.get(toUnit);

    if (!conversion1 || !conversion2) {
        throw new Error("Invalid units provided provided")
    }

    // convert to base
    let val = fromValue * conversion1;

    if (EnergyUnit.includes(fromUnit) && VolumeUnit.includes(toUnit)) {
        if (heatValueJoulesPerM3 === null) {
            throw new Error("Heat value is required when converting between volume and energy units")
        }
        // convert base energy to base volume
        val = val / heatValueJoulesPerM3;
    }

    if (VolumeUnit.includes(fromUnit) && EnergyUnit.includes(toUnit)) {
        if (heatValueJoulesPerM3 === null) {
            throw new Error("Heat value is required when converting between volume and energy units")
        }
        // convert base volume to base energy
        val = val * heatValueJoulesPerM3;
    }

    // convert from base
    val = val / conversion2;

    return val
}

export function convertVolumeUnit(value: number, unit: VolumeUnits, round = true){
    // Assumes all incoming values are in thousand cubic meters
    switch(unit) {
        case VolumeUnits.THOUSAND_CUBIC_METERS:
            return value;
        case VolumeUnits.MILLION_CUBIC_METERS:
            return value * 0.001;
        case VolumeUnits.MILLION_CUBIC_FEET:
            return round ? Math.round(((value * 0.0353147) + Number.EPSILON) * 10) / 10 : value * 0.0353147;
        case VolumeUnits.BILLION_CUBIC_FEET:
            return round ? Math.round(((value * 0.0000353147) + Number.EPSILON) * 1000) / 1000 : value * 0.0000353147;
        default:
            return value;
    }
}

export function convertAndDisplayVolumeUnit(value: number, unit: VolumeUnits, digits = 2, displayUnit = true) {
    return  `${convertVolumeUnit(value, unit).toLocaleString([], {maximumFractionDigits: digits})} ${displayUnit ? unit : ""}`
}

export function convertEnergyUnit(value: number, unit: EnergyUnits, round = true){
    // Assumes all incoming values are in thousand cubic meters
    switch(unit) {
        case EnergyUnits.TERAJOULE:
            return value;
        case EnergyUnits.GIGAJOULE:
            return value * 1000;
        case EnergyUnits.DEKATHERM_OR_MILLION_BRITISH_THERMAL_UNITS:
            return round ? Math.round((value * 1000) / ENERGY_CONVERSION_FACTOR) : (value * 1000) / ENERGY_CONVERSION_FACTOR;
        default:
            return value;
    }
}