import React from 'react';
import { parse } from 'query-string';

import * as S from '../../styles';
import {locale} from "../../locale";
import MoreOptions, {MoreOptionsItems} from "../MoreOptions";
import GasDaySummaryReportControl from './GasDaySummaryReportControl';
import { RouteComponentProps } from 'react-router-dom';

interface GDSRParams{
    date: string;
    unit: string;
}

const GasDaySummaryReport: React.FC<RouteComponentProps<GDSRParams>> = (props) => {
    const params = parse(props.history.location.search);

    const getQueryStringDate = (): Date | undefined => {
        if (params.date && typeof(params.date) === "string") {
            const date = new Date(params.date)
            date.setDate(date.getDate())
            return date;
        }
        return undefined;
    }

    const selectedDate = getQueryStringDate();

    return (
        <S.GDSRWrapper>
            <S.BackNavigationHeader>
                <S.ReportPageTitle>{locale("page.header.gasDaySummaryReport")}</S.ReportPageTitle>
                <MoreOptions isVisible={true} selectedDate={selectedDate} options={[
                    MoreOptionsItems.PrintGasDaySummaryReport,
                    MoreOptionsItems.DownloadGasDaySummaryReport
                ]}/>
            </S.BackNavigationHeader>
            <S.PageWithNavigationContainer hasBanner={false} hasStickyControls={true}>
                <S.ChartHeaderContainer>
                    <GasDaySummaryReportControl hasBanner={false} history={props.history}/>
                </S.ChartHeaderContainer>
            </S.PageWithNavigationContainer>
        </S.GDSRWrapper>
    )
};

export default GasDaySummaryReport