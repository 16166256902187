import {IOutage, IOutageComparison,} from "../types";
import {
    OutageComparisonActionTypes,
    SET_DAILY_OPERATING_PLAN_COMPARE_DATE,
    SET_DAILY_OPERATING_PLAN_COMPARISONS,
    SET_OUTAGE_HISTORY, SET_PUBLISH_DATE,
    SET_PUBLISH_DATES, SET_OUTAGE_COMPARISON_LOADED
} from "../types/OutageComparisonActionTypes";

interface IOutageComparisonState {
    publishDates: {
        recentPublishDates: string[];
        selectedPublishDate: string;
        loaded: boolean;
    };
    outageHistory: {
        history: IOutage[];
        publishDate: string;
        loaded: boolean;
        comparison: IOutageComparison[];
    };
}

const initialState: IOutageComparisonState =  {
    publishDates: {
        recentPublishDates: [],
        selectedPublishDate: "",
        loaded: false,
    },
    outageHistory: {
        history: [],
        loaded: false,
        publishDate: "",
        comparison: []
    },
};

export function outageComparisonReducer(state = initialState, action: OutageComparisonActionTypes): IOutageComparisonState {
    switch (action.type) {
        case SET_PUBLISH_DATES:
            // assumption - the first element in the array is the most recent publication date for history
            const [latestPublicationDate, latestHistoricalPublicationDate,] = action.publishDates;
            return {...state, publishDates: {loaded: true, recentPublishDates: action.publishDates.slice(0, 21), selectedPublishDate: latestPublicationDate ??""},
                outageHistory: {...state.outageHistory, publishDate: latestHistoricalPublicationDate ?? latestPublicationDate ?? ""}};
        case SET_OUTAGE_COMPARISON_LOADED:
            return {...state, publishDates: {...state.publishDates, loaded: action.loaded}, outageHistory: {...state.outageHistory, loaded: action.loaded}};
        case SET_OUTAGE_HISTORY:
            return {...state, outageHistory: {...state.outageHistory, history: action.outages, loaded: true, publishDate: action.publishDate}};
        case SET_DAILY_OPERATING_PLAN_COMPARE_DATE:
            return {...state, outageHistory: {...state.outageHistory, publishDate: action.compareDate, loaded: false}};
        case SET_DAILY_OPERATING_PLAN_COMPARISONS:
            return {...state, outageHistory: {...state.outageHistory, comparison: action.comparison}};
        // HOW TO SET OUTAGES AS NOT LOADED?
        case SET_PUBLISH_DATE:
            return {...state, publishDates: {...state.publishDates, selectedPublishDate: action.publishDate}};
        default:
            return state;
    }
}
