import Validation from "./Validation";
import ValidationFunctions from "./ValidationFunctions";

export default class BulletinValidation {

    static ServiceProviderValidation: () => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.bulletin.serviceProvider.requiredMessage"
        },
    ]);

    static NoticeTypeValidation: () => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.bulletin.noticeType.requiredMessage"
        },
    ]);

    static SubjectValidation: (subject: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.bulletin.subject.requiredMessage"
        },
        {
            validationFunction: ValidationFunctions.StringMaximumLengthValidationFunction(60),
            validationMessageKey: "validation.bulletin.subject.tooManyCharacters"
        }
    ]);

    static StartDateValidation: (date: string, scheduledDate: string) => Validation = (date: string, scheduledDate: string) => new Validation([
        {
            validationFunction: ValidationFunctions.DateWithOptionalTimeValidationFunction,
            validationMessageKey: "validation.bulletin.startDate.invalidDate"
        },
        {
            validationFunction: ValidationFunctions.DateGreaterOrEqualThenGivenDateValidationFunction(scheduledDate),
            validationMessageKey: "validation.bulletin.startDate.dateLessThanScheduled"
        }
    ]);

    static ScheduledDateValidation: (date: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.DateWithOptionalTimeValidationFunction,
            validationMessageKey: "validation.bulletin.scheduleDate.invalidDate"
        },
        {
            validationFunction: ValidationFunctions.DateIsFutureDateOnlyValidationFunction,
            validationMessageKey: "validation.bulletin.scheduleDate.dateIsInPast"
        }
    ]);

    static TitleValidation: (title: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.bulletin.title.requiredMessage"
        },
    ]);

    static ContactNameValidation: (name: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.NameFormatValidationFunction,
            validationMessageKey: "validation.bulletin.contactName.invalidFormat"
        },
    ]);

    static PhoneNumberValidation: (phone: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.PhoneNumberFormatValidationFunction,
            validationMessageKey: "validation.bulletin.phoneNumber.invalidNumber"
        }
    ]);

    static BodyValidation: (body: string) => Validation = () => new Validation([
        {
            validationFunction: ValidationFunctions.BodyValidationExists,
            validationMessageKey: "validation.bulletin.body.notExists"
        },
        {
            validationFunction: ValidationFunctions.BodyValidationDoesNotExceedLengthFunction,
            validationMessageKey: "validation.bulletin.body.tooLong"
        }
    ])

}