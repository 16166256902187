import DateUtilities from "./DateUtilities";

export class PublishDateUtilities {

    static FormatPublishedDate(date: string, publishedDates: string[]) {
        const dayInMilliseconds = DateUtilities.ServiceDateUtility.UtcToLocalDateTime(date).startOf("day").valueOf();

        //published dates does not include the latest, that is why we are adding it to the list for comparison purposes.
        const sameDays = publishedDates.filter(published => DateUtilities.ServiceDateUtility.UtcToLocalDateTime(published).startOf("day").valueOf() === dayInMilliseconds);

        return sameDays.length === 1 ? // don't include timestamp in date for UI unless there are multiple publications on that day
            DateUtilities.UiDateUtility.ReformatUtcToLocal(date) :
            DateUtilities.UiDateTimeUtility.ReformatUtcToLocal(date);
    }
}