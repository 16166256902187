import React, { useEffect, useState }  from 'react';
import * as S from "../../styles";
import { locale } from "../../locale";
import { Fetch } from "../../utilities/Fetch";
import trashIcon from "../../icons/trash-2.svg";
import copyIcon from "../../icons/copy-svgrepo-com.svg";
import ResultModal from "../ResultModal";

interface Props {
    bulletinId: number;
    pipelineProvider: string;
}

interface FileInfo{
    id: number;
    fileKey: string;
}

const BulletinFileUpload: React.FC<Props> = (props) => {
    const [selectedFile, setSelectedFile] = useState<any>('');
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [signedUploadUrl, setSignedUploadUrl] = useState<any>([]);
    const [fileListInfo, setFileListInfo] = useState<FileInfo[]>();
    const [isReloadFileInfo, setReloadFileInfo] = useState(true)
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>('');

    useEffect(()=>{
        if(isFilePicked  || isReloadFileInfo){
            setReloadFileInfo(false);
                
            Fetch
            .Build(`/bulletin/getfileupload/${props.bulletinId}`)
            .error((e) =>{
                console.log('Error is getting get url' + JSON.stringify(e))
            })
            .success((data) =>{
                setFileListInfo(data);
                setIsFilePicked(false);
                //console.log(JSON.stringify(data))
            })
            .get();
        }
    },[isFilePicked])

    useEffect(()=>{
        if(signedUploadUrl.fields !== undefined){
            const formData = new FormData();
            Object.keys(signedUploadUrl.fields).forEach(key => {
                formData.append(key, signedUploadUrl.fields[key]);
            });           

            // Actual file has to be appended last.
            formData.append('file', selectedFile);
            const xhr = new XMLHttpRequest();
            xhr.open("POST", signedUploadUrl.url, true);
            
            xhr.send(formData);
            xhr.onload = function() {
                        //console.log(JSON.stringify(this));
                        //console.log(xhr.statusText)
                };   
        }
    },[signedUploadUrl])

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        
        if (!event.target.value) {
            return;
        }
        const files: FileList = event.target.files, f = files[0];
        setSelectedFile(f);

        event.target.value = null;

        Fetch
            .Build('/bulletin/savefileupload')
            .withPayload(({'bulletinId': props.bulletinId, 'pipeline' : props.pipelineProvider, 'fileName': f.name}))
            .error((e) =>{
                console.log('Error is getting post url')
            })
            .success((data) =>{
                setIsFilePicked(true);
                setSignedUploadUrl(data);
            })
            .post();
    };

    function removeRow(ItemId: number)
    {
            Fetch
            .Build(`/bulletin/deletefile/${ItemId}`)
            .error((e) =>{
                console.log('Error is deleting ' + JSON.stringify(e))
            })
            .success((data) =>{
                setIsFilePicked(true);
                console.log(JSON.stringify(data))
            })
            .get();
    }

    function copyUrl(ItemId: number)
    {
        navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/bulletin/downloadfile/` + ItemId.toString());
        setResultText("Link copied.");
        setShowResult(true);
    }

    return(
     <div>
        <S.FileUploadLabel htmlFor="file-upload" className="custom-file-upload">
                {locale("bulletin.file.upload")}
        </S.FileUploadLabel>
        <input id="file-upload" type="file" name="file" style={{display: "none"}}  onChange={changeHandler} />

        <S.PanelHeader>
            <S.Text>Files(s) attached to bulletin</S.Text>
        </S.PanelHeader>
        {
                (fileListInfo) ? (
                    fileListInfo.map(
                        function(item){
                        return ( 
                            <S.TableRow key={item.id}>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumn width={'800'} >
                                        {item.fileKey}
                                    </S.TableColumn>
                                </S.TableCellWrapperNoGrow>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={'20'}>
                                        {<S.TableCellIconRemove src={copyIcon} width={18} height={18}
                                            onClick={() => copyUrl(item.id)}/>}
                                        </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={'20'}>
                                        {<S.TableCellIconRemove src={trashIcon} width={18} height={18}
                                            onClick={() => removeRow(item.id)}/>}
                                        </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                            </S.TableRow>
                            );
                        }       
                ))  : <S.CenterText>No Files</S.CenterText> 
            
        }

            {showResult && 
                <ResultModal text={resultText}
                    success={true}
                    onExit={() => {
                        setShowResult(false);
                    }}
                />}
    </div>   
    )
};

export default BulletinFileUpload;