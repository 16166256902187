import styled from "styled-components";
import {FontSize} from "./FontStyles";
import {device} from "./DeviceSizes";
import {TableColumnNoGrow, TableHeaderRow} from "./AdminStyles";

export const ReportPageTitle = styled.div`
  font-family: Maven Pro;
  font-style: normal;
  font-weight: normal;
  ${FontSize("34px", "24px")};
  line-height: 56px;
  text-transform: uppercase;
  color: #555759;
  position: fixed;
  left: 50vw;
  text-align: center;
  transform: translate(-50%, 0);

  @media ${device.tablet} {
    font-size: 26px;
  }

  @media ${device.mobile} {
    font-size: 16px;
    width:max-content;
  }
`;

export const ReportPageUpdateTimeInfo = styled.div`
  font-family:  Maven Pro;
  font-weight: normal;
  ${FontSize("18px", "18px")};
  line-height: 18px;
  color: #555759;
  left: 40vw;

  @media ${device.tablet} {
    font-size: 18px;
  }

  @media ${device.mobile} {
    font-size: 12px;
    width:80%;
  }
`;

export const CurrentSystemReportColumnWidths = {
  checkBox: '30',
  gridLine: '150',
  description: '0',
  currentValues: '150',
  unit: '0',
  gridLineAndDescription: '175',
  currentValueAndUnit: '150',
  totalWidth: '150'
};

export const CurrentSystemReportHeaderContainer = styled.div`
   display: flex;    
   flex-direction: row; 
   position: relative;
   align-items: center;  
   height: 100%;
   @media ${device.tablet} {
    flex-wrap: wrap;
   }
   @media ${device.mobile}{
     flex-direction: column; 
     flex-wrap: nowrap;   
   }
`;

export const CurrentSystemReportTable = styled.div`\
  justify-content: center;
  margin: 1vh;
  display: table;
  vertical-align: middle;
  
`;

interface CurrentSystemReportTableHeaderProps {
  minWidth: string;
}

export const CurrentSystemReportTableHeaderRow = styled.div<CurrentSystemReportTableHeaderProps>`
  
  display: table-row;
  border-bottom: solid #E5E5E5 1px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: left;
  height: 40px;
  background: rgb(14,50,110);
  width:50%;
`;

export const CurrentSystemReportDatagridHeaderText = styled.div`\
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: white;
    text-transform: capitalize;
    
`;

export const CurrentSystemReportTableRow = styled.div<CurrentSystemReportTableHeaderProps>`
  display: table-row; 
  min-width: ${props => props.minWidth}px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 5px;
  margin-top: 0px;
  height: 40px;
  font-size: 14px;
  color: rgb(85,87,89);
 
`;
/* TO-DO:
- cleanup check if used */
export const CurrentSystemReportTableLastRow = styled(TableHeaderRow)<CurrentSystemReportTableHeaderProps>`
  min-width: ${props => props.minWidth}px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 5px;
  height: 45px;
  font-size: 12px;
  vertical-align: middle;
`;

interface TableColumnProps {
  width: string;
  viewOnly?: boolean;
}

export const CSRTableCurrentValues = styled.div<TableColumnProps>`
  flex-grow: 0; -webkit-flex-grow: 0;
  vertical-align: top;
  width: ${props => props.width}px;
  padding-left: 0px;
  padding-right: 15px;
  padding-top:5px;
  border-top: solid #E3E3E3 2px;
  border-bottom: solid #E3E3E3 2px;
  border-right: solid #E3E3E3 2px;
  justify-content: center;
  font-weight: 600;
  font-size:16px;
   ${props => props.viewOnly ? "border-bottom: 1px solid #CFCDCE; " : ""};
`;

export const CSRTableDescription = styled.div<TableColumnProps>`
  display:table-cell;
  flex-grow: 0; -webkit-flex-grow: 0;
  vertical-align: top;
  width: ${props => props.width}px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top:5px;
  
  border-bottom: solid #E3E3E3 2px;
  border-right: solid #E3E3E3 2px;
  justify-content: space-between;
  
  ${props => props.viewOnly ? "border-bottom: 1px solid #CFCDCE; " : ""};
`;

export const TableColumnWithRightBorder=styled(TableColumnNoGrow)`
border-right: solid #E3E3E3 2px;
`
export const TableColumnWithUpperBorder=styled(TableColumnNoGrow)`
border-top: solid #E3E3E3 2px;
padding-top: 5px;
`
export const TableColumnWithRightAndUpperBorder=styled(TableColumnNoGrow)`
display: table-cell;
border-right: solid #E3E3E3 2px;
border-top: solid #E3E3E3 2px;
border-bottom: solid #E3E3E3 2px;
padding-top: 5px;
padding-bottom: 5px;
padding-left: 6px;
padding-right: 2px;
`
/* TO-DO:
  - tried to use this to change the background of the checkbox and it did not work bc the user agent stylesheet values are taking effect*/
export const CheckboxHeaderStyle = styled.div`
display: table-cell;
width:10%;
border-bottom: solid #E3E3E3 2px;
border-left: solid #E3E3E3 2px;
border-right: solid #E3E3E3 2px;
padding-top: 5px;
padding-bottom: 5px;
padding-left: 2px;
padding-right: 2px;
color:unset;
input[type="checkbox" i] {
  color: -internal-light-dark(rgb(255,255,255), rgb(14,50,110));}

`

export const TableColumnForCheckbox = styled.div`
display: table-cell;
width:10%;
border-bottom: solid #E3E3E3 2px;
border-left: solid #E3E3E3 2px;
border-right: solid #E3E3E3 2px;
padding-top: 5px;
padding-bottom: 5px;
padding-left: 2px;
padding-right: 2px;
`
export const TableColumnforDescription = styled.div`
display: table-cell;
width: inherit;
border-bottom: solid #E3E3E3 2px;
border-right: solid #E3E3E3 2px;
padding-top: 5px;
padding-bottom: 5px;
padding-left:4px;
padding-right: 2px;
`

export const TableColumnforCurrentValues = styled.div`
display: table-cell;

border-bottom: solid #E3E3E3 2px;
border-right: solid #E3E3E3 2px;
padding-top: 5px;
padding-bottom: 5px;
padding-left:4px;
padding-right: 2px;
p{
  justif-content: center;
}
`
interface ICurrentSystemReport {
  color: string;
}


export const DataGridLine = styled.hr<ICurrentSystemReport>`
  display:flex;   
  width: 25px;
   height: 4px;
   background-color: ${props => props.color};
   border: none;
   align-items:start;
   /* align-items: center;
   vertical-align: middle; */
`;

export const DatagridTableWrapper = styled.div`\
  display: flex;
  flex-flow: column;
  margin-top:15px;
  @media ${device.tablet}{
    margin-inline:auto;
  }
  
`;

export const CurrentSystemReportLink=styled.div`\
  flex: 1 1 auto;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: blue;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5px;
  @media ${device.mobile} {
    font-size: 10px;
    margin: 5px;
  }
  hover {
    text-decoration: underline;
  }
  @media ${device.tablet}{
    margin: 5px;
  }
`;

interface IDurationIsProps {
  isSelected: boolean;
}

export const CurrentSystemReportLinkSelected = styled.div<IDurationIsProps>`
    text-decoration: ${props => props.isSelected ? "underline" : "none"};
    cursor: pointer;
`;

export const CurrentSystemReportLinkRow = styled.div`
  display: flex;    
  flex-direction: row;
  flex-wrap: wrap;  
  justify-content: center;
  min-height: 30px;
  div {
      margin-left: 30px;
      margin-right: 10px;
  }
  margin-right: 220px;
  @media ${device.tablet}{
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  @media ${device.mobile}{
    flex-wrap: wrap;
    justify-content: space-around;
    div {
        margin: 2px;
    }
  } 
`;

export const TableCellWrapperNumber = styled.div`
   display: flex; display: -webkit-flex;
   flex-direction: row; -webkit-flex-direction: row;
   flex-grow: 0; -webkit-flex-grow: 0;
   text-align: right;
`;

export const CurrentSystemReportWrapper = styled.div`
  @media ${device.tablet}{
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

export const CSRTableCellWrapper = styled.div`
   display: flex; display: -webkit-flex;
   flex-direction: row; -webkit-flex-direction: row;
   flex-grow: 0; -webkit-flex-grow: 0;
`;

export const CurrentValuesNumber = styled.div`
  font-weight: 500;
  font-size: 16px;
  color:rgb(85,87,89);
  justify-content: left;
  display: inline-flex;
  width:35%;
  padding-left:10px;
  padding-right:15px;
  @media ${device.mobile}{
    width:31%;
    padding-left:3px;
  }
`;

export const CurrentValuesUnits = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: rgb(85,87,89);
  justify-content: right;
  display:-webkit-inline-box;
`;


export const DescriptionPadding = styled.div`
  display: inline-flex;
  width:78%;
  padding-left:3px;
`;

export const GraphLinePadding = styled.div`
  display:inline-flex;
  padding-right:2px;
`;

export const WrapperContainer = styled.div`
overflow: auto;
  display: flex;
  height:auto;
  @media ${device.tablet} {
    height:700px;
    display: flex;
    flex-direction: column;
  }
  
  @media ${device.mobile} {
    display: inherit;
    flex-direction: column;
    height: 650px;
    overflow:scroll;
    width:100%;
	}
`;


export const MobileDateBanner = styled.div`
  display:none;
  @media ${device.mobile} {
    display:block;
  }
  @media ${device.tablet} {
    padding-top: 90px;
    padding-bottom:15px;
    display:block;
  }
`;


