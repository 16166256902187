import React, {ReactNode, useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {isMobile} from 'react-device-detect';
import DisplayValuesSelector from "../DisplayValuesSelector";
import {OutageControlsContainer, OutageControlsInner, RightControlsContainer} from "../../styles/OutageControlStyles";
import useTypedSelector from "../../hooks/useTypedSelector";
import * as S from "../../styles";
import DateUtilities from "../../utilities/DateUtilities";
import { locale } from "../../locale";
import { VolumeUnits } from '../../types';
import { CurrentSystemReportVolumeUnits } from "../../types";
import { setVolumeUnit } from '../../actions/appActions';

type Renderable = void | boolean | JSX.Element | ReactNode;

interface Props {
    publishedDateTime: string;
    hasBanner: boolean;
    showIfTopScroll?: boolean;
    renderers?: {
        left?: () => Renderable;
        center?: () => Renderable;
        right?: () => Renderable;
    };
}

const CurrentSystemReportControls: React.FC<Props> = ({publishedDateTime, hasBanner = false, renderers = {}, showIfTopScroll = false }: Props) => {
    const dispatch = useDispatch();
    const isOpen = useTypedSelector(state => state.outageControls.isOpen);
    const [selectedUnit, setSelectedUnit] = useState<string>(useTypedSelector(x => x.app.csrDefaultVolumeUnit));

    function getLastUpdateDateTime(): string {
        const lastDateTime=DateUtilities.UiDateTimeUtility.Reformat(publishedDateTime);
        const lastUpdateDate=lastDateTime.substring(0,lastDateTime.length-5);
        const lastUpdateHour=lastDateTime.substring(lastDateTime.length-5,lastDateTime.length-3);
        if (parseInt(lastUpdateHour)>=12){
            return lastUpdateDate + 'at '+ (lastUpdateHour === "12" ? 12 : parseInt(lastUpdateHour)-12).toString() + lastDateTime.substring(lastDateTime.length-3,lastDateTime.length)+'PM MST'
        }else{
            return lastUpdateDate + 'at '+ lastUpdateHour.toString() + lastDateTime.substring(lastDateTime.length-3,lastDateTime.length)+'AM MST'
        }
    }

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (selectedUnit === VolumeUnits.MILLION_CUBIC_FEET || selectedUnit === VolumeUnits.BILLION_CUBIC_FEET) {
            dispatch(setVolumeUnit(VolumeUnits.MILLION_CUBIC_FEET));
            setSelectedUnit(VolumeUnits.MILLION_CUBIC_FEET);
        } else {
            dispatch(setVolumeUnit(VolumeUnits.THOUSAND_CUBIC_METERS));
            setSelectedUnit(VolumeUnits.THOUSAND_CUBIC_METERS);
        }
    }, []);

    const units=Object.values(CurrentSystemReportVolumeUnits);

    return (
        //todo, CurrentSystemReportControl adopt style of OutageControl. GDSR will adopt samilar style.
        <OutageControlsContainer hasBanner={false}>
            <OutageControlsInner  isOpen={!isMobile || (isOpen || (showIfTopScroll && scrollPosition === 0))}>
                <div>
                    <S.ReportPageUpdateTimeInfo>
                        <b>Last Updated: </b>{publishedDateTime && getLastUpdateDateTime()}
                        &nbsp; ({locale("currentReportUpdatedInfo")})
                    </S.ReportPageUpdateTimeInfo>
                
                    {renderers.left && renderers.left()}
                </div>
            <RightControlsContainer>
                    {renderers.right && renderers.right()}
                    <DisplayValuesSelector 
                        units={units} 
                        defaultValue={selectedUnit}
                        onChange={(newValue) => {
                            setSelectedUnit(newValue);
                        }}
                    />
                </RightControlsContainer>
            </OutageControlsInner>
        </OutageControlsContainer>
    )
}

export default CurrentSystemReportControls