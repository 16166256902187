import {IHeatValue} from "./index";

export const SET_HEAT_VALUE = 'SET_HEAT_VALUE';
export const CLEAR_HEAT_VALUE = 'CLEAR_HEAT_VALUE';

interface ISetHeatValueAction {
    type: typeof SET_HEAT_VALUE;
    heatValue: IHeatValue[];
}

interface IClearHeatValue {
    type: typeof CLEAR_HEAT_VALUE;
}

export type HeatValueActionTypes = ISetHeatValueAction | IClearHeatValue;