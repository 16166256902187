import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router'
import { useDispatch } from 'react-redux';
import DateInput from '../../components/DateInput'
import DateUtilities from '../../utilities/DateUtilities';
import { OutageControlsContainer, RightControlsContainer } from '../../styles/OutageControlStyles';
import { DatePickerContainer, DateInformationContainer, GDSRDateDisplayUpper, GDSRDateDisplayLower, GDSRGasDayDisplay, GDSRBannerContainer, GDSRControlsInner, GDSRBannerInner } from '../../styles/GasDaySummaryReportStyles'
import DisplayValuesSelector from '../DisplayValuesSelector';
import { isMobile } from 'react-device-detect';
import { GasDaySummaryReportVolumeUnits, VolumeUnits } from '../../types';
import GasDaySummaryDataTable, { GasDaySummaryReportData } from './GasDaySummaryDataTable';
import useTypedSelector from '../../hooks/useTypedSelector';
import { setVolumeUnit } from '../../actions/appActions';
import { Fetch } from '../../utilities/Fetch';
import { parse } from 'query-string'
import alertCircle from "../../icons/alert-circle.svg"
import { Icon } from '../../styles';

interface Props{
    hasBanner: boolean;
    showIfTopScroll?: boolean;
    history: any;
}

const GasDaySummaryReportControl: React.FC<Props> = (props) => {
    const units = Object.values(GasDaySummaryReportVolumeUnits);
    const [selectedGasDay, setSelectedGasDay] = useState<Date>(DateUtilities.getPreviousGasDay(new Date(), -3));
    const [asofDay, setasofDay] = useState<string>(DateUtilities.formatDate(DateUtilities.getGasDay(new Date())));
    const [data, setData] = useState<GasDaySummaryReportData | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false)

    const dispatch = useDispatch();
    const [selectedUnit, setSelectedUnit] = useState<string>(useTypedSelector(x => x.app.gdsrDefaultVolumeUnit));

    const formatDateForLink = (date: Date): string => {
        return DateUtilities.ServiceDateOnlyUtility.Reformat(date.toString());
    }

    const earliestAllowableDate = new Date("Nov 15, 2021");
    const liveDate = new Date("Nov 16, 2021");
    const legacyPageUrl = "http://www.tccustomerexpress.com/gasdaysummaryreport.html"

    const loadData = (useDate?: Date) => {
        Fetch.Build(`/gdsr?date=${formatDateForLink(useDate || selectedGasDay)}`, dispatch)
        .error((e) => {
            console.log(e)
            setData(undefined);
        })
        .success((data) => {
            setData(data);
            setasofDay(data.asofDay);
            console.log(data.asofDay);
        })
        .get();
    };

    useEffect(() => {
        const params = parse(props.history.location.search);
        const date = params.date && typeof(params.date) === "string" ? new Date(params.date) : undefined;
        date && date.setDate(date.getDate())
        const unit = params?.unit ? params.unit : selectedUnit;
        if (date){
            setSelectedGasDay(date);
        }
        if (unit === VolumeUnits.MILLION_CUBIC_FEET || unit === VolumeUnits.BILLION_CUBIC_FEET){
            dispatch(setVolumeUnit(VolumeUnits.MILLION_CUBIC_FEET));
            setSelectedUnit(VolumeUnits.MILLION_CUBIC_FEET);
        } else {
            dispatch(setVolumeUnit(VolumeUnits.MILLION_CUBIC_METERS));
            setSelectedUnit(VolumeUnits.MILLION_CUBIC_METERS);
        }
        setLoaded(true)
        loadData(date);
    }, [])

    const setUrl = (date: Date, unit?: string) => {
        const unitString = unit ? `unit=${unit}&` : "";
        const dateString = date ? `date=${date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + ("0" + date.getDate()).slice(-2)}` : "";
        props.history.replace(`/GasDaySummaryReport?${unitString}${dateString}`)
    }

    return loaded ? (  
        <OutageControlsContainer hasBanner={props.hasBanner}>
        <GDSRBannerContainer>
            <GDSRBannerInner>
                <Icon src={alertCircle}/>
                <div>
                    <p>Starting from {DateUtilities.formatDate(liveDate)}all deliveries/injection will show values as negative(-) and receipts/withdrawal as positive values.</p>
                    <a href={legacyPageUrl}>For historical data prior to gas day {DateUtilities.formatDate(earliestAllowableDate)} please go to this site.</a>
                </div>
            </GDSRBannerInner>
        </GDSRBannerContainer>
            <GDSRControlsInner isOpen={!!(!isMobile || (props.showIfTopScroll))}>
                <DatePickerContainer>
                    <div>
                        <GDSRGasDayDisplay>Gas Day:</GDSRGasDayDisplay>
                        <DateInput
                            date={selectedGasDay}
                            handleChange={(date: Date) => {
                                setSelectedGasDay(DateUtilities.getGasDay(date));
                                setUrl(date, selectedUnit)
                                loadData(date);
                            }}
                            maxDate={DateUtilities.getPreviousGasDay(new Date(), -3)}
                            minDate={earliestAllowableDate}
                            calenderInfo={
                                <a href={legacyPageUrl}>
                                    For dates prior to {DateUtilities.formatDate(earliestAllowableDate)}
                                </a>
                            }
                        />
                    </div>
                    <DateInformationContainer style={{marginLeft: "10px"}}>
                        <b>For:</b>
                        <GDSRDateDisplayUpper>{DateUtilities.formatDate(selectedGasDay)} as of</GDSRDateDisplayUpper>
                        <GDSRDateDisplayLower>{DateUtilities.formatDateTime(DateUtilities.Parse(asofDay))} MST</GDSRDateDisplayLower>
                    </DateInformationContainer>
                </DatePickerContainer>
                <RightControlsContainer>
                    <DisplayValuesSelector 
                        units={units} 
                        defaultValue={selectedUnit}
                        onChange={(newValue) => {
                            setUrl(selectedGasDay, newValue);
                            setSelectedUnit(newValue);
                        }}
                    />
                </RightControlsContainer>
            </GDSRControlsInner>
            <GasDaySummaryDataTable data={data}/>
        </OutageControlsContainer>
    ) : (
        <div/>
    )
}

export default GasDaySummaryReportControl