import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import * as S from "../../styles";

import {convertVolumeUnit} from '../../utilities/UnitConversions';
import {IChartTooltip,
    ICurrentSystemReportChartData,
    ICurrentSystemReportActive,
    CurrentSystemReportColorEnum,
    VolumeUnits
} from "../../types";
import DateUtilities from '../../utilities/DateUtilities';
import useTypedSelector from '../../hooks/useTypedSelector';


interface Props {
    active: ICurrentSystemReportActive;
    chartData: ICurrentSystemReportChartData[];
    duration: number;
}

function convertUnits(active: boolean, value: number, conversion: VolumeUnits) {
    return active ? Math.round(convertVolumeUnit(value, conversion)) : Infinity;
}

function processChartData(chartData: ICurrentSystemReportChartData[], active: ICurrentSystemReportActive, duration: number, units: VolumeUnits) {
    let min = 0;
    let max = 0;
    const newChartData: ICurrentSystemReportChartData[] = [];
    const length = (chartData.length >= duration * 48) ? chartData.length - duration * 48 : 0;

    for (let x = length, y = 0; x < chartData.length; x++, y++) {
        newChartData.push({
            albertaBorderFlow: convertUnits(active.albertaBorderFlow, chartData[x].albertaBorderFlow, units),
            willowValleyInterconnect: convertUnits(active.willowValleyInterconnect, chartData[x].willowValleyInterconnect, units),
            currentLinepack: convertUnits(active.currentLinepack, chartData[x].currentLinepack, units),
            empressBorderFlow: convertUnits(active.empressBorderFlow, chartData[x].empressBorderFlow, units),
            fieldReceipts: convertUnits(active.fieldReceipts, chartData[x].fieldReceipts, units),
            flowDifferential: convertUnits(active.flowDifferential, chartData[x].flowDifferential, units),
            gordondaleReceipt: convertUnits(active.gordondaleReceipt, chartData[x].gordondaleReceipt, units),
            groundBirchEastReceipt: convertUnits(active.groundBirchEastReceipt, chartData[x].groundBirchEastReceipt, units),
            intraprovincialDemand: convertUnits(active.intraprovincialDemand, chartData[x].intraprovincialDemand, units),
            lastUpdated: chartData[x].lastUpdated,
            linepackTarget: convertUnits(active.linepackTarget, chartData[x].linepackTarget, units),
            linepack4Hr: convertUnits(active.linepack4Hr, chartData[x].linepack4Hr, units),
            mcneilBorderFlow: convertUnits(active.mcneilBorderFlow, chartData[x].mcneilBorderFlow, units),
            netStorageFlow: convertUnits(active.netStorageFlow, chartData[x].netStorageFlow, units),
            totalDeliveries: convertUnits(active.totalDeliveries, chartData[x].totalDeliveries, units),
            totalReceipts: convertUnits(active.totalReceipts, chartData[x].totalReceipts, units)
        });

        const { lastUpdated, ...newData } = newChartData[y];
        const object = Object.values(newData);
        const lowest = Math.min(...object);
        const highest = Math.max(...object);

        if (min === 0 && lowest !== Infinity) {
            min = lowest;
        }
        if (max === 0 && highest !== Infinity) {
            max = highest;
        }

        if (lowest < min && lowest !== Infinity){
            min = lowest;
        }
        if (highest > max && highest !== Infinity) {
            max = highest;
        }
    }

    return {min: min, max: max, chartData: newChartData};
}

const CurrentSystemReportChart: React.FC<Props> = ({ active, chartData, duration }) => {
    const units = useTypedSelector(x => x.app.volumeUnit);
    
    if (chartData.length === 0) {
        return null;
    }

    const newData = processChartData(chartData, active, duration, units);

    const CustomizedXAxisTick = (props: any) => {
        const {x, y, payload} = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <S.RotatedText degree={-35} x={0} y={0} dy={16} textAnchor="end"
                               fill="#666">{DateUtilities.UiDateTimeUtility.Reformat(payload.value)}</S.RotatedText>
            </g>
        );
    };

    const CustomTooltip = (props: IChartTooltip) => {
        // props.label is coming back as undefined sometimes, not sure why, but is causing chart to fail on tooltip creation
        if (!props.label) {
            return null;
        }

        const keyToString = {
            albertaBorderFlow: "Alberta-BC Border Flow",
            willowValleyInterconnect: "Willow Valley Interconnect",
            currentLinepack: "Current Linepack",
            empressBorderFlow: "Empress Border Flow",
            fieldReceipts: "NGTL - Field Receipts",
            flowDifferential: "Flow Differential (Receipts - Deliveries)",
            gordondaleReceipt: "Gordondale Receipt",
            groundBirchEastReceipt: "Groundbirch East Receipt",
            intraprovincialDemand: "Intraprovincial Demand",
            linepackTarget: "Linepack Target",
            linepack4Hr: "Linepack 4Hr ROC",
            mcneilBorderFlow: "McNeil Border Flow",
            netStorageFlow: "Net Storage Flow",
            totalDeliveries: "Total Deliveries",
            totalReceipts: "Total Receipts"
        } as any;

        const selectedDate = Object.assign({}, newData.chartData.filter(day => day.lastUpdated?.toString() === props.label)[0]);

        if (selectedDate !== undefined) {
            delete selectedDate.lastUpdated;
            const sortedDate = Object.entries(selectedDate).sort(([,a],[,b]) => b - a);
            const adjustedUnits = units.replace("/day", "");

            return <S.ChartTooltip>
                <S.ChartTooltipItem>
                    <S.ChartTooltipHeader>{DateUtilities.UiDateTimeUtility.Reformat(props.label)}</S.ChartTooltipHeader>
                </S.ChartTooltipItem>
                <S.ChartTooltipItem>
                    <S.ChartTooltipAreaSection>
                        {sortedDate.map((element: [string, number]) =>
                            active[element[0]] && <S.FlexContainerRow key={element[0]}>
                                <S.CurrentSystemReportChartCircle color={CurrentSystemReportColorEnum[element[0].toUpperCase() as keyof typeof CurrentSystemReportColorEnum]} />
                                <S.ChartTooltipLabel color={CurrentSystemReportColorEnum[element[0].toUpperCase() as keyof typeof CurrentSystemReportColorEnum]}>
                                    {keyToString[element[0]]}: {new Intl.NumberFormat("en").format(element[1])} {(element[0] === "currentLinepack" || element[0] === "linepackTarget" || element[0] === "linepack4Hr") ? adjustedUnits : units}
                                </S.ChartTooltipLabel>
                            </S.FlexContainerRow>
                        )}
                    </S.ChartTooltipAreaSection>
                </S.ChartTooltipItem>
            </S.ChartTooltip>;
        } else {
            return null;
        }
    }

    return (
        <S.CsrChartFlexContainerRow>
            <S.CsrReportChart>
                <ResponsiveContainer width="95%" height="95%">
                    <LineChart
                        height = {500}
                        data = {newData.chartData}
                        margin = {{
                            top: 5,
                            right: 5,
                            left: 5,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray = "3 3" />
                        <XAxis dataKey = "lastUpdated" tick={<CustomizedXAxisTick/>} height={75} />
                        <YAxis 
                            type = "number"
                            yAxisId = "1"
                            orientation = "left"
                            width={75}
                            domain = {[
                                newData.min,
                                newData.max
                            ]}
                            tickFormatter = {(tick) => {
                                return new Intl.NumberFormat("en").format(tick);
                            }}
                        />
                        <YAxis 
                            type = "number"
                            yAxisId = "2"
                            orientation = "right"
                            domain = {[
                                newData.min,
                                newData.max
                            ]}
                            tickFormatter = {(tick) => {
                                return new Intl.NumberFormat("en").format(tick);
                            }}
                        />
                        <Tooltip content = {CustomTooltip} offset = {-100} />
                        {active.albertaBorderFlow && <Line 
                            yAxisId = "1"
                            dataKey = "albertaBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.ALBERTABORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.albertaBorderFlow && <Line 
                            yAxisId = "2"
                            dataKey = "albertaBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.ALBERTABORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.willowValleyInterconnect && <Line 
                            yAxisId = "1"
                            dataKey = "willowValleyInterconnect"
                            stroke = {CurrentSystemReportColorEnum.WILLOWVALLEYINTERCONNECT}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.willowValleyInterconnect && <Line 
                            yAxisId = "2"
                            dataKey = "willowValleyInterconnect"
                            stroke = {CurrentSystemReportColorEnum.WILLOWVALLEYINTERCONNECT}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.currentLinepack && <Line
                            yAxisId = "1"
                            dataKey = "currentLinepack"
                            stroke = {CurrentSystemReportColorEnum.CURRENTLINEPACK}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.currentLinepack && <Line
                            yAxisId = "2"
                            dataKey = "currentLinepack"
                            stroke = {CurrentSystemReportColorEnum.CURRENTLINEPACK}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.empressBorderFlow && <Line
                            yAxisId = "1"
                            dataKey = "empressBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.EMPRESSBORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.empressBorderFlow && <Line
                            yAxisId = "2"
                            dataKey = "empressBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.EMPRESSBORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.fieldReceipts && <Line
                            yAxisId = "1"
                            dataKey = "fieldReceipts"
                            stroke = {CurrentSystemReportColorEnum.FIELDRECEIPTS}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.fieldReceipts && <Line
                            yAxisId = "2"
                            dataKey = "fieldReceipts"
                            stroke = {CurrentSystemReportColorEnum.FIELDRECEIPTS}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.flowDifferential && <Line
                            yAxisId = "1"
                            dataKey = "flowDifferential"
                            stroke = {CurrentSystemReportColorEnum.FLOWDIFFERENTIAL}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.flowDifferential && <Line
                            yAxisId = "2"
                            dataKey = "flowDifferential"
                            stroke = {CurrentSystemReportColorEnum.FLOWDIFFERENTIAL}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.gordondaleReceipt && <Line
                            yAxisId = "1"
                            dataKey = "gordondaleReceipt"
                            stroke = {CurrentSystemReportColorEnum.GORDONDALERECEIPT}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.gordondaleReceipt && <Line
                            yAxisId = "2"
                            dataKey = "gordondaleReceipt"
                            stroke = {CurrentSystemReportColorEnum.GORDONDALERECEIPT}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.groundBirchEastReceipt && <Line
                            yAxisId = "1"
                            dataKey = "groundBirchEastReceipt"
                            stroke = {CurrentSystemReportColorEnum.GROUNDBIRCHEASTRECEIPT}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.groundBirchEastReceipt && <Line
                            yAxisId = "2"
                            dataKey = "groundBirchEastReceipt"
                            stroke = {CurrentSystemReportColorEnum.GROUNDBIRCHEASTRECEIPT}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.intraprovincialDemand && <Line
                            yAxisId = "1"
                            dataKey = "intraprovincialDemand"
                            stroke = {CurrentSystemReportColorEnum.INTRAPROVINCIALDEMAND}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.intraprovincialDemand && <Line
                            yAxisId = "2"
                            dataKey = "intraprovincialDemand"
                            stroke = {CurrentSystemReportColorEnum.INTRAPROVINCIALDEMAND}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.linepackTarget && <Line
                            yAxisId = "1"
                            dataKey = "linepackTarget"
                            stroke = {CurrentSystemReportColorEnum.LINEPACKTARGET}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.linepackTarget && <Line
                            yAxisId = "2"
                            dataKey = "linepackTarget"
                            stroke = {CurrentSystemReportColorEnum.LINEPACKTARGET}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.linepack4Hr && <Line
                            yAxisId = "1"
                            dataKey = "linepack4Hr"
                            stroke = {CurrentSystemReportColorEnum.LINEPACK4HR}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.linepack4Hr && <Line
                            yAxisId = "2"
                            dataKey = "linepack4Hr"
                            stroke = {CurrentSystemReportColorEnum.LINEPACK4HR}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.mcneilBorderFlow && <Line
                            yAxisId = "1"
                            dataKey = "mcneilBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.MCNEILBORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.mcneilBorderFlow && <Line
                            yAxisId = "2"
                            dataKey = "mcneilBorderFlow"
                            stroke = {CurrentSystemReportColorEnum.MCNEILBORDERFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.netStorageFlow && <Line
                            yAxisId = "1"
                            dataKey = "netStorageFlow"
                            stroke = {CurrentSystemReportColorEnum.NETSTORAGEFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.netStorageFlow && <Line
                            yAxisId = "2"
                            dataKey = "netStorageFlow"
                            stroke = {CurrentSystemReportColorEnum.NETSTORAGEFLOW}
                            strokeWidth = {2}
                            strokeDasharray = "4 4"
                            dot = {false}
                        />}
                        {active.totalDeliveries && <Line
                            yAxisId = "1"
                            dataKey = "totalDeliveries"
                            stroke = {CurrentSystemReportColorEnum.TOTALDELIVERIES}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.totalDeliveries && <Line
                            yAxisId = "2"
                            dataKey = "totalDeliveries"
                            stroke = {CurrentSystemReportColorEnum.TOTALDELIVERIES}
                            strokeWidth = {2}
                            strokeDasharray = "14 2 2 7"
                            dot = {false}
                        />}
                        {active.totalReceipts && <Line
                            yAxisId = "1"
                            dataKey = "totalReceipts"
                            stroke = {CurrentSystemReportColorEnum.TOTALRECEIPTS}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.totalReceipts && <Line
                            yAxisId = "2"
                            dataKey = "totalReceipts"
                            stroke = {CurrentSystemReportColorEnum.TOTALRECEIPTS}
                            strokeWidth = {2}
                            dot = {false}
                        />}
                        {active.linepack4Hr && <ReferenceLine
                            yAxisId = "1"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                        {active.linepack4Hr && <ReferenceLine
                            yAxisId = "2"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                        {active.netStorageFlow && <ReferenceLine
                            yAxisId = "1"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                        {active.netStorageFlow && <ReferenceLine
                            yAxisId = "2"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                        {active.flowDifferential && <ReferenceLine
                            yAxisId = "1"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                        {active.flowDifferential && <ReferenceLine
                            yAxisId = "2"
                            y = {0}
                            stroke = "black"
                            strokeWidth = {2}
                        />}
                    </LineChart>
                </ResponsiveContainer>
                <div style={{display:"flex", justifyContent:"center", paddingTop:"10px"}}>The Current System Report contains real time data. The information should 
                            only be used for the purpose of trending.</div>
            </S.CsrReportChart>
        </S.CsrChartFlexContainerRow>
    );
};

export default CurrentSystemReportChart;
