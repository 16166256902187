import {ISubscription} from "./index";

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_SUBSCRIPTIONS_LOADED = 'SET_SUBSCRIPTIONS_LOADED';
export const CLEAR_SUBSCRIPTIONS_LOADED = 'CLEAR_SUBSCRIPTIONS_LOADED';

interface ISetSubscriptionsAction {
    type: typeof SET_SUBSCRIPTIONS;
    subscriptions: ISubscription[];
}

interface ISetSubscriptionsLoaded {
    type: typeof SET_SUBSCRIPTIONS_LOADED;
    loaded: boolean;
}

interface IClearSubscriptionsLoaded {
    type: typeof CLEAR_SUBSCRIPTIONS_LOADED;
}

export type SubscriptionActionTypes = ISetSubscriptionsAction | ISetSubscriptionsLoaded | IClearSubscriptionsLoaded;