import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

interface IOutageChartSingle {
    heightAdd: number;
    showingCount: number;
    hasBanner: boolean;
}

export const OutageChartSingle = styled.div<IOutageChartSingle>`   
    width: 99%;       
    @media ${device.mobile}{
         height: calc(((99vh - ${props => props.hasBanner ? "270px" : "245px"}) / ${props => props.showingCount}) + ${props => props.heightAdd}px);
    }     
    @media ${device.tablet}{
         height: calc(((99vh - ${props => props.hasBanner ? "270px" : "245px"}) / ${props => props.showingCount}) + ${props => props.heightAdd}px);
    } 
    @media ${device.desktop}{
         height: calc(((99vh - ${props => props.hasBanner ? "270px" : "245px"}) / ${props => props.showingCount}) + ${props => props.heightAdd}px);
    }     
`;

export const SystemReportChart = styled.div`
    width: 99%;
    height: 500px; 
`;

export const CsrReportChart = styled.div`
  width: 80%;
  margin-top: 25px;
  margin-bottom: 35px;
  @media ${device.mobile}{
    width: 395px !important;
    height:498px !important;
    }
    @media ${device.tablet}{
      margin-inline:auto;
      width: 795px;
      height:575px;
    }
`;

export const CsrChartFlexContainerRow = styled.div`
display: contents;
flex-direction: row;
flex-wrap: wrap;
 @media ${device.tablet}{
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   flex: 1 1 auto;
 }
`;

export const ChartTooltip = styled.div`   
  border: solid 1px black;
  border-radius: 8px;
  padding: 5px;
  background: #FFFFFF;
`;

export const ChartTooltipItem = styled.div`   
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
`;

interface IChartTooltipLabel {
    color: string;
}

export const CurrentSystemReportChartCircle = styled.div<IChartTooltipLabel>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-top: 1px;
  margin-right: 5px;
`

export const ChartTooltipLabel = styled.div<IChartTooltipLabel>`   
    color: ${props => props.color};    
    margin-right: 5px;
    font-size: 12px;   
`;

export const ChartTooltipLabelMini = styled.div<IChartTooltipLabel>`   
    color: ${props => props.color};    
    margin-right: 3px;
    font-size: 10px;   
`;

export const ChartTooltipAreaSection = styled.div`
    margin: 0 0 10px 10px;
`;

export const ChartTooltipHeader = styled.div`       
    margin-bottom: 8px;
    font-weight: bold;    
    color: #555759;
    font-size: 14px;
`;

interface IRotatedDegree {
    degree: number;
}

export const RotatedText = styled.text<IRotatedDegree>`
  font-size: 11px;
  transform: rotate(${props => props.degree}deg);
  font-weight: bold;
  
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
 -webkit-transform: rotate(${props => props.degree}deg);
  /* Firefox */
  -moz-transform: rotate(${props => props.degree}deg);
  /* IE */
  -ms-transform: rotate(${props => props.degree}deg);
  /* Opera */
  -o-transform: rotate(${props => props.degree}deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
`;

export const OutageChartDetail = styled.div`
  max-width: 550px;
  height: 100vh;
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  //TODO Change media to standard device variable
  @media ${device.tablet}{
    max-width: 370px;
  }
  @media ${device.mobile} {
    max-width: 100vw;
    width: 100vw;
  }
  overflow-y: auto;
`;

export const ChartHeaderContainer = styled.div`
   display: flex;    
   flex-direction: row; 
   position: relative;
   align-items: center;  
   height: 100%;
   @media ${device.tablet} {
    flex-wrap: wrap;
   }
   @media ${device.mobile}{
     flex-direction: column; 
     flex-wrap: nowrap;   
   }
`;

export const ChartLegend = styled.div`
   display: flex;    
   justify-content: center;
   flex-direction: row;
   flex-wrap: wrap;
   flex: 1 1 auto;
   height: 50px;
   margin: auto;
   // padding-top: ${window.innerWidth >= 376 ? 15 : 60 }px;
   @media ${device.tablet}{
    order: 3;
    height: auto;
    flex: 1 1 auto;
   }
   @media ${device.mobile}{
    order: 2;
    height: auto;
   } 
`;

export const ChartLegendRow = styled.div`
    display: flex;    
    flex-direction: row;
    flex-wrap: wrap;  
    justify-content: center;
    min-height: 30px;
    div {
        margin-left: 10px;
        margin-right: 10px;
    }
       @media ${device.tablet}{
    flex-wrap: nowrap;
    margin-right: 24px;
    justify-content: flex-end;
   }
   @media ${device.mobile}{
    margin: 5px 15px;
    flex-wrap: wrap;
    justify-content: space-around;
    div {
        margin: 2px 0;
    }
   } 
`;

export const ChartLegendDisclaimers = styled.div`
    display: flex;    
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 30px;
    margin-bottom: -22px;
    @media ${device.tablet}{
      margin-bottom: 0;
    }
    @media ${device.mobile}{
      margin-bottom: 0;
    } 

`;

export const ChartLegendText = styled.div`
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  max-width: 610px;
  color: ${colors.charcoal_text};
`;

export const ChartLegendItem = styled.div`
    display: flex;    
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;  
    cursor: pointer;
`;

export const ChartDisclaimer = styled.div`
   display: flex;
   flex-direction: column;
   margin-left: auto;
   margin-right: 20px;
   margin-top: auto;
   font-family: Roboto, sans-serif;
   font-style: italic;
   font-size: 12px;
   text-align: center;   
   cursor: pointer; 
   background: #FFFFFF;
   span {
     color: #000000;
  }
  
  div {
    color: ${colors.primary};
  }
  
  @media ${device.mobile}{
    margin: 0;
    margin-top: 30px;
    margin-bottom: 7px;
    margin-left: 5px;
    margin-right: 5px;
    flex: 1;
    width: auto;
  }
`;

interface ILegendBox {
    color: string;
    borderColor?: string;
}

export const ChartLegendBox = styled.hr<ILegendBox>`
   width: 10px;
   height: 10px;
   margin: auto;
   margin-left: 5px;
   margin-right: 5px;
   background-color: ${props => props.color};
   border: 1px solid ${props => props.borderColor ? props.borderColor : props.color};
`;

export const ChartLegendBoxBorder = styled.hr<ILegendBox>`
   height: 10px;
   width: 10px;
   background-color: white;
   margin: auto;
   margin-left: 5px;
   margin-right: 5px;
   border: 1px solid ${props => props.borderColor ? props.borderColor : props.color};
`;

export const ChartLegendLine = styled.hr<ILegendBox>`
   width: 10px;
   height: 3px;
   margin-top: auto;
   margin-bottom: auto;
   margin-left: 5px;
   margin-right: 5px;
   background-color: ${props => props.color};
   border: none;
`;

export const ChartLegendDashedLine = styled.hr<ILegendBox>`
   width: 5px;
   height: 3px;
   margin-top: auto;
   margin-bottom: auto;
   margin-left: 3px;
   margin-right: 3px;
   background-color: ${props => props.color};
   border: none;
`;

export const ChartLegendLabel = styled.div`
   margin: 5px;
`;

interface IChartLegendLabelCanDisableProps {
    isDisabled: boolean;
}

export const ChartLegendLabelCanDisable = styled(ChartLegendLabel)<IChartLegendLabelCanDisableProps>`
    text-decoration: ${props => props.isDisabled ? "line-through" : "none"};
    cursor: pointer;
`;

export const ChartYAxisLabel = styled.div`
  font-size: 14px;
  color: ${colors.primary};
  margin: auto 15px auto 5px;
  min-width: 75px;
  text-align: center;
`;

export const ChartWrapper = styled.div`
  @media ${device.tablet}{
    padding-bottom: 30px;
  }
`;