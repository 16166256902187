import React, { useMemo } from "react";
import {useDispatch} from "react-redux";
import DateUtilities from "../../../utilities/DateUtilities";
import * as S from "../../../styles"
import useApiService from "../../../hooks/useApiService";
import {locale} from "../../../locale";
import styled from "styled-components";
import { FontSize } from "../../../styles/FontStyles";
import {DateTime} from "luxon";
import {ITolerance} from "../../../types";
import {FileDownloadUtilities} from "../../../utilities/FileDownloadUtilities";

const Label = styled.div`
    ${FontSize("16px")};
    margin: 0;
`;

const ToleranceTimestamp = styled.div`
    ${FontSize("12px")};
    margin-bottom: 14px;
`;

const ToleranceChange = styled.div`
    ${FontSize("14px")};
`;

const FlexItem = styled.div`
    width: 50%;
    margin-bottom: 25px;
`;

const MaintenanceToleranceChangeWidget: React.FC = () => {
    const dispatch = useDispatch();
    const toleranceData = useApiService<[ITolerance | undefined, ITolerance | undefined]>([undefined, undefined], "ngtl/tolerance");
    
    const current = toleranceData.data[0];
    const previous = toleranceData.data[1];
    
    const currentLastUpdatedDateTime = useMemo(() => current ? DateTime
        .fromFormat(current.lastUpdatedDateTimeUtc, DateUtilities.serviceDateFormat, {zone: DateUtilities.universalTimeZone})
        .setZone(DateUtilities.mountainTimeZone)
        .toFormat(DateUtilities.uiDateTimeWithTimezone) : "N/A", [current]);

    const previousLastUpdatedDateTime = useMemo(() => previous ? DateTime
        .fromFormat(previous.lastUpdatedDateTimeUtc, DateUtilities.serviceDateFormat, {zone: DateUtilities.universalTimeZone})
        .setZone(DateUtilities.mountainTimeZone)
        .toFormat(DateUtilities.uiDateTimeWithTimezone) : "N/A", [previous]);

    return <S.Widget>
        <S.WidgetTitle style={{ marginBottom: "20px"}}>{locale("widgets.mainline.tolerance.title")}</S.WidgetTitle>
        <S.WidgetFlexContainer>
            {/* Current */}
            {current && <FlexItem>
                <Label>
                {locale("widgets.mainline.tolerance.currently")}
                </Label>
                <ToleranceTimestamp>
                    {currentLastUpdatedDateTime}
                </ToleranceTimestamp>
                <ToleranceChange>
                    {current.tolerance} (%)
                </ToleranceChange>
            </FlexItem>}
            
            {previous && <FlexItem>
                <Label>
                {locale("widgets.mainline.tolerance.previously")}
                </Label>
                <ToleranceTimestamp>
                    {previousLastUpdatedDateTime}
                </ToleranceTimestamp>
                <ToleranceChange>
                    {previous.tolerance} (%)
                </ToleranceChange>
            </FlexItem>}

        </S.WidgetFlexContainer>
        <S.WidgetCsvLink>
            <span onClick={() => FileDownloadUtilities.initiateCsvFileDownload("ngtl/tolerance/csv", dispatch)}>{locale("widgets.mainline.capability.downloadLinkText")}</span>
        </S.WidgetCsvLink>
    </S.Widget>

};

export default MaintenanceToleranceChangeWidget;