import {useDispatch, shallowEqual} from "react-redux";
import useTypedSelector from "./useTypedSelector";
import { ISearchState } from "../reducers/searchReducer"
import fetchHelper from "../utilities/fetchHelper";
import {useEffect, useState} from "react";
import { IServiceProvider, ISubscription, SearchKey, SearchKeyTypes } from "../types";
import {setSearchOptions} from "../actions/searchActions";



interface IBulletinSearch {
    page: number;
    setPage(x: number): void;
    pageSize: number;
    setPageSize(x: number): void;
    searchState: ISearchState;
}

function useBulletinSearch(key: SearchKey = SearchKeyTypes.PUBLIC): IBulletinSearch {
    const dispatch = useDispatch();

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);

    const searchState: ISearchState = useTypedSelector((state) => state.bulletinSearch[key], shallowEqual);

    // Fetch automatically
    useEffect(() => {
        let url = "bulletin/options";
        if (key === SearchKeyTypes.ADMIN) {
            url += `?is_admin=${true}`;
        }
        if (!searchState.loaded) {
            fetchHelper(url, (payload: { serviceProviders: IServiceProvider[]; subscriptionCategories: ISubscription[] }) => {
                dispatch(setSearchOptions(key, payload.serviceProviders, payload.subscriptionCategories));
            }, dispatch)
        }
    }, [key, dispatch, searchState.loaded]);

    useEffect(() => {
        // Reset selectedServiceProvider if it is no longer in the list of service providers
        if (searchState.loaded) {
            if (!searchState.serviceProviders.find(sp => sp.name === searchState.selectedServiceProvider))
                searchState.selectedServiceProvider = '';
        }
    }, [searchState.serviceProviders, searchState.loaded, searchState.selectedServiceProvider]);

    return {
        searchState,
        page,
        setPage,
        pageSize,
        setPageSize

    }
}

export default useBulletinSearch;