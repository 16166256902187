import fetchHelper from "../utilities/fetchHelper";
import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setSubscriptions} from "../actions/subscriptionActions";

function useSubscriptions(): void {
    const dispatch = useDispatch();
    const subscriptionsLoaded = useTypedSelector(x => x.subscriptions.subscriptionsLoaded);
    const error = useTypedSelector(state => state.subscriptions.error);

    useEffect(() => {
        if (!subscriptionsLoaded && !error) {
            fetchHelper("subscriptions", x => dispatch(setSubscriptions(x)), dispatch);
        }
    }, [subscriptionsLoaded, error, dispatch]);

}

export default useSubscriptions;