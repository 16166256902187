import Validation from "./Validation";
import ValidationFunctions from "./ValidationFunctions";

export default class StationListValidation {

    static NewStationNumberValidation: (numberList: number[]) => Validation = (numberList: number[]) => new Validation([
        {
            validationFunction: ValidationFunctions.StationNumberNotInListValidationFunction(numberList),
            validationMessageKey: "validation.stationList.numberAlreadyExists"
        },
        {
            validationFunction: ValidationFunctions.MaxNumericValueValidationFunction(999999999),
            validationMessageKey: "validation.stationList.numberTooLarge"
        }
    ])
}