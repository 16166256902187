import React, {useEffect, useState} from "react";
import * as S from '../../styles';
import BackNavigationHeader from "../BackNavigationHeader";
import {locale} from "../../locale";
import {useHistory} from "react-router";
import {IHasValidationResults, IHeatValue, IHeatValueForEdit} from "../../types";
import {useDispatch} from "react-redux";
import {Fetch} from "../../utilities/Fetch";
import useTypedSelector from "../../hooks/useTypedSelector";
import {clearIsDirty, setIsDirty, showDiscardConfirm, showConfirm} from "../../actions/appActions";
import {DateTime} from "luxon";
import DateUtilities from "../../utilities/DateUtilities";
import ResultModal from "../ResultModal";
import Validation from "../../utilities/validation/Validation";
import HeatValueValidation from "../../utilities/validation/HeatValueValidation";
import {clearHeatValue} from "../../actions/heatValueAction";
import useHeatValues from "../../hooks/useHeatValues";
import DateInput from "../DateInput";
import colors from "../../styles/Colors";

const AdminHeatValues: React.FC = () => {
    const [currentHeatValues, setCurrentHeatValues] = useState<IHeatValue[]>([]);
    const [scheduledHeatValues, setScheduledHeatValues] = useState<IHeatValueForEdit[]>([]);
    const [areasForEdit, setAreasForEdit] = useState<IHeatValueForEdit[]>([]);
    const [lastHeatValue, setLastHeatValue] = useState<IHeatValue>();
    const [scheduledDate, setScheduledDate] = useState<string>();
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>('');
    const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [areScheduledBulletins, setAreScheduledBulletins] = useState<boolean>(false);
    const [modifyMode, setModifyMode] = useState<boolean>(true);
    const isDirty = useTypedSelector(state => state.app.isDirty);
    const isBusy = useTypedSelector(x => x.app.isBusy);
    const heatValues = useTypedSelector(x => x.heatValue.heatValues);
    const heatValueLoaded = useTypedSelector(x => x.heatValue.heatValueLoaded);
    const history = useHistory();
    const dispatch = useDispatch();
    
    useHeatValues();

    useEffect(() => {
        if (heatValueLoaded) {
            let editVals;
            let scheduleDate: string;
            const heatValueForEdit: IHeatValueForEdit[] = [];
            const currentHV = heatValues.filter(hv => {
                return DateUtilities.Parse(hv.startDateTimeUtc).startOf("day") <= DateUtilities.Now().startOf("day") &&
                    (hv.endDateTimeUtc ? DateUtilities.Parse(hv.endDateTimeUtc).startOf("day") > DateUtilities.Now().startOf("day") : true)
            });
            if(heatValues.filter(hv => DateUtilities.Parse(hv.startDateTimeUtc).startOf("day") > DateUtilities.Now().startOf("day")).length > 0) {
                const newVals = heatValues.filter(hv => DateUtilities.Parse(hv.startDateTimeUtc).startOf("day") > DateUtilities.Now().startOf("day"));
                editVals = currentHV.map(area => {
                    const newArea = newVals.find(a => a.areaId === area.areaId);
                    return newArea ? newArea : area;
                });
                setAreScheduledBulletins(true);
                setModifyMode(true);
                scheduleDate = DateUtilities.Parse(newVals.sort((a, b) => (a.startDateTimeUtc <= b.startDateTimeUtc) ? 1 : -1)[0].startDateTimeUtc).toFormat(DateUtilities.serviceDateOnlyFormat)
            } else {
                editVals = currentHV;
                setAreScheduledBulletins(false);
                setModifyMode(false);
                scheduleDate = DateUtilities.Today().toFormat(DateUtilities.serviceDateOnlyFormat)
            }

            editVals.forEach(area => {
                const x: IHeatValueForEdit = {
                    ...area,
                    startDateTimeUtc: scheduleDate,
                    isDirty: false,
                    validationResults: {
                        value: {
                            isValid: true,
                            message: ''
                        },
                        startDateTimeUtc: {
                            isValid: true,
                            message: ''
                        }
                    }
                };
                heatValueForEdit.push(x);
            });
            
            setScheduledHeatValues(heatValueForEdit);
            setAreasForEdit(heatValueForEdit);
            setCurrentHeatValues(currentHV);
            setScheduledDate(scheduleDate);
            setLastHeatValue(heatValues.sort((a, b) => (a.updatedDateTimeUtc <= b.updatedDateTimeUtc) ? 1 : -1)[0])
        }
    }, [heatValues, heatValueLoaded]);

    useEffect(() => {
        let hasDirtyValues = false;
        let hasValidationErrors = false;
        areasForEdit.forEach(area => {
            if (area.isDirty) {
                hasDirtyValues = true;
            }
            if (area.validationResults.value.isValid !== undefined && !area.validationResults.value.isValid) {
                hasValidationErrors = true;
            }
        });
        hasDirtyValues ? dispatch(setIsDirty()) : dispatch(clearIsDirty());
        setHasErrors(hasValidationErrors);
    }, [areasForEdit, dispatch]);


    const cancel = () => {
        Fetch.Build('/heatValues/cancel', dispatch)
            .error((e) => {
                setResultText(locale('bulletin.publish.error'));
                setUpdateSuccess(false);
                console.log(e)
            })
            .success(() => {
                setResultText(locale('heatValue.cancel.success'));
                setUpdateSuccess(true)
            })
            .finally(() => {
                setShowResult(true);
            })
            .post()
    };

    const cancelClicked = () => {
        if (!modifyMode && !isDirty) {
            history.push('/Admin');
        } else if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                history.push('/Admin');
            }));
        } else if(!modifyMode) {
            console.log("set modify mode true")
            setModifyMode(true)
        } else if(areScheduledBulletins) {
            dispatch(showConfirm({
                headerText: locale("heatValue.cancel.header"),
                bodyText: locale("heatValue.cancel.body"),
                buttonCancelText: locale("no"),
                buttonConfirmText: locale("yes"),
                background:  colors.background,
                onConfirm: cancel
            }));
        }
    };

    const saveClicked = () => {
        areasForEdit.forEach(area => {
            if (area.isDirty) {
                const payload = {
                    "areaId": area.areaId,
                    "value": area.value,
                    "startDateTimeUtc" : DateUtilities.ParseDate(area.startDateTimeUtc).toFormat(DateUtilities.serviceDateOnlyFormat)
                };
                Fetch.Build('/heatValues', dispatch)
                    .withPayload(payload)
                    .error((e) => {
                        setResultText(locale('bulletin.publish.error'));
                        setUpdateSuccess(false);
                        console.log(e)
                    })
                    .success(() => {
                        setResultText(locale('heatValue.update.success'));
                        setUpdateSuccess(true)
                    })
                    .finally(() => {
                        setShowResult(true);
                    })
                    .post()
            }
        });
    };

    const updateHeatValue = (area: IHeatValueForEdit, newValue: number, id: number, validation: Validation) => {
        validateInput(area, 'value', newValue.toString(), validation);

        const re = new RegExp('^[0-9]?[0-9].?[0-9]?$');
        if (re.test(newValue.toString()) || isNaN(newValue)) {
            const updatedValue = areasForEdit.map(area => area.areaId === id ? {
                ...area,
                value: newValue,
                isDirty: newValue !== scheduledHeatValues.filter(x => {
                    if (x.areaId === area.areaId) {
                        return x
                    }
                })[0].value
            } : area);
            setAreasForEdit(updatedValue);
        }
    };

    const updateScheduledDate = (date: string) => {
        const validation = HeatValueValidation.ScheduledDateValidation(date !== null ? date: '', DateUtilities.Today().toFormat(DateUtilities.serviceDateOnlyFormat));
        areasForEdit.forEach( area => validateInput(area, 'startDateTimeUtc', date, validation));
        
        if(date !== ''){
            const updatedValue = areasForEdit.map(area => ({
                ...area,
                startDateTimeUtc: date,
                isDirty: true
            }));
            setAreasForEdit(updatedValue);
            setScheduledDate(date);
        }
    };

    const validateInput = (area: IHeatValueForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        const validationResults = area.validationResults;
        if (validation) {
            validationResults[fieldName] = validation.validate(newValue.toString());
        }
    };

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : '';
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    function BlueText(str: string | undefined, searchStr: string) {
        if (!str) return "";
        const re = new RegExp(searchStr.split(' ').join('|'), 'g');
        return str.replace(re, (match) => {
            return `<span style="color:#1A72C8">${match}</span>`;
        });
    }
    
    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('admin.options.heatValues')}/>
            <div style={{padding: "0 40px"}}>
                <S.HeatValueHeaderWrapper>
                    {lastHeatValue && <S.HeatValueDateWrapper>
                        <span>{locale('heatValue.date.header')}</span>
                        <div>{DateTime.fromFormat(lastHeatValue.updatedDateTimeUtc || '', DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.uiDateFormat)}</div>
                    </S.HeatValueDateWrapper>}
                    {lastHeatValue && <S.HeatValueDateWrapper>
                        <span>{locale("heatValue.header.updatedBy")}</span>
                        <div>{lastHeatValue.scheduledBy}</div>
                    </S.HeatValueDateWrapper>}
                </S.HeatValueHeaderWrapper>
                <S.HeatValueInputContainer>
                    <span style={{paddingBottom: 0, color: '#555759'}}>{locale("heatValue.note")}</span>
                    <span style={{paddingTop: 0, paddingBottom: 0, color: '#555759'}}>{locale("heatValue.note2")}</span>
                    {areScheduledBulletins && <React.Fragment>
                        <span>{locale("heatValue.currentValues.label")}</span>
                        <S.CurrentHeatValueList>
                            {heatValueLoaded && currentHeatValues.sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map(area => {
                                return (
                                    <S.CurrentHeatValue key={`${area.areaId}`}>
                                        <label htmlFor={`${area.areaId}`}>
                                            {area.acronym}
                                        </label>
                                        <span>
                                            {area.value}
                                        </span>
                                    </S.CurrentHeatValue>
                                )
                            })}
                        </S.CurrentHeatValueList>
                        <span dangerouslySetInnerHTML={{ __html: BlueText(locale("heatValue.note.changes"), `${locale("modify")} ${locale("cancel")}`.toLocaleUpperCase())}}/>
                        <S.HeatValueTitle>
                            {locale("heatValue.header.scheduled")}
                        </S.HeatValueTitle>
                    </React.Fragment>}
                    {scheduledDate && <S.HeatValueDatePickerWrapper>
                        <S.HeatValueDatePickerLabel>
                            {locale("bulletin.list.effectiveDate")}
                        </S.HeatValueDatePickerLabel>
                        <DateInput
                            date={DateTime.fromISO(scheduledDate).toJSDate()}
                            disabled={modifyMode}
                            placeholderText={''}
                            handleChange={(date: Date) => updateScheduledDate(date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.serviceDateOnlyFormat): '')}
                            hasError={areasForEdit[0] ? hasError(areasForEdit[0], 'startDateTimeUtc') : false}
                            errorMessage={areasForEdit[0] ? errorMessage(areasForEdit[0], "startDateTimeUtc") : ""}
                            showYearSelect={true}
                            showMonthSelect={true}
                            isClearable={false}
                            minDate={DateUtilities.Today().toJSDate()}
                        />

                    </S.HeatValueDatePickerWrapper>}
                    <S.HeatValueDisclaimer>{locale("heatValue.disclaimer")}</S.HeatValueDisclaimer>
                    <S.HeatValueInputsWrapper>
                        {heatValueLoaded && areasForEdit.sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map(area => {
                            return (
                                <S.HeatValue key={`${area.areaId}`}>
                                    <label htmlFor={`${area.areaId}`}>
                                        {area.acronym}
                                    </label>
                                    <div>
                                        <S.HeatValueInput
                                            disabled={modifyMode}
                                            id={`${area.areaId}`}
                                            type="number"
                                            step={"0.1"}
                                            value={area.value}
                                            isDirty={area.isDirty}
                                            title={errorMessage(area, 'value')}
                                            hasError={hasError(area, 'value')}
                                            onChange={value => updateHeatValue(area, parseFloat(value.target.value), parseInt(value.target.id), HeatValueValidation.UpdateHeatValueValidation(value.target.value))}/>
                                        <span>{locale('heatValue.units')}</span>
                                    </div>
                                </S.HeatValue>
                            )
                        })}
                    </S.HeatValueInputsWrapper>
                </S.HeatValueInputContainer>
                <S.ActionButtonContainer style={{marginTop: '24px'}}>
                    <S.SecondaryAction>
                        <div onClick={cancelClicked}>{locale('cancel')}</div>
                    </S.SecondaryAction>
                    <S.SaveButton
                        disabled={!modifyMode && (!isDirty || hasErrors)}
                        onClick={!modifyMode ? saveClicked : () => setModifyMode(false)}>
                            {!modifyMode ? locale('save') : locale("modify")}
                        </S.SaveButton>
                </S.ActionButtonContainer>
            </div>
            {showResult && !isBusy &&
            <ResultModal text={resultText}
                         success={updateSuccess}
                         onExit={() => {
                             setShowResult(false);
                             if (updateSuccess) {
                                 dispatch(clearIsDirty());
                                 dispatch(clearHeatValue());
                             }
                         }}
            />}
        </S.PageWithNavigationContainer>
    )
};

export default AdminHeatValues;
