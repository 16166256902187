import React, {useEffect} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import Outages from "./components/Outages";
import * as S from "./styles";
import OutageMap from "./components/OutageMap";
import Admin from "./components/admin/Admin";
import ContactUs from "./components/ContactUs";
import OutagesList from "./components/OutagesList";
import Menu from "./components/Menu";
import AdminDailyOperatingPlan from "./components/admin/AdminDailyOperatingPlan";
import AdminChartData from "./components/admin/AdminChartData";
import BusyModal from "./components/BusyModal";
import useTypedSelector from "./hooks/useTypedSelector";
import ConfirmModal from "./components/ConfirmModal";
import OutageChart from "./components/OutageChart";
import OutageChartDetails from "./components/OutageChartDetails";
import AdminPublicationList from "./components/AdminPublicationList";
import {AuthProvider} from "./components/Auth"
import AdminRoute from "./components/Auth/AdminRoute";
import {Fetch} from "./utilities/Fetch";
import AdminAreaCapability from "./components/AdminAreaCapability";
import GoogleAnalytics from "./utilities/GoogleAnalyticsUtilities";
import CookiesDisclaimer from "./components/CookiesDisclaimer";
import ViewDailyOperatingPlan from "./components/admin/ViewDailyOperatingPlan";
import ViewDailyOperatingPlanChart from "./components/admin/ViewDailyOperatingPlanChart";
import Dashboard from "./components/Dashboard";
import {useDispatch} from "react-redux";
import {clearSelectedChartOutages} from "./actions/outageChartActions";
import BulletinManagement from "./components/Bulletin/BulletinManagement";
import BulletinForm from "./components/Bulletin/BulletinForm";
import AdminMainlineMaintenance from "./components/admin/mainline/AdminMainlineMaintenance";
import ViewBulletin from "./components/Bulletin/ViewBulletin";
import Verify from "./components/subscriptions/Verify";
import Unsubscribe from "./components/subscriptions/Unsubscribe";
import UnderConstruction from "./components/UnderConstruction";
import stationList from "./components/admin/StationList";
import useToggles from "./hooks/useToggles";
import BulletinList from "./components/Bulletin/BulletinList";
import Error404 from "./components/Error404";
import useAppStatus from './hooks/useAppStatus';
import { AppStatus } from './types';
import Subscriptions from "./components/subscriptions/Subscriptions";
import SubscriptionManagement from "./components/subscriptions/SubscriptionManagement";
import NavigationUtilities from "./utilities/NavigationUtilities";
import {useLocation} from "react-router";
import AdminHeatValues from "./components/admin/AdminHeatValues";
import AdminChartExport from "./components/admin/AdminChartExport";
import CurrentSystemReport from './components/CurrentSystemReport/CurrentSystemReport';
import GasDaySummaryReport from './components/GasDaySummaryReport/GasDaySummaryReport';

const adminPublishADGroup = process.env.REACT_APP_AD_GROUP_ADMIN_PUBLISH || "";
const adminBulletinADGroup = process.env.REACT_APP_AD_GROUP_ADMIN_BULLETIN || "";


const App: React.FC = () => {
    useEffect(() => {
        GoogleAnalytics.Initialize();
    }, []);
    useAppStatus();
    useToggles();
    const status = useTypedSelector(state => state.app.status);
    const isBusy = useTypedSelector(state => state.app.isBusy);
    const error = useTypedSelector(state => state.app.error);
    const confirm = useTypedSelector(state => state.app.confirm);
    const showConfirm = useTypedSelector(state => state.app.showConfirm);
    const showDisclaimer = useTypedSelector(state => state.app.showDisclaimer);
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (window.location.pathname !== '/') {
            window.location.replace(`/#${window.location.pathname}`);
        }
    }, []);

    useEffect(() => {
        const name = NavigationUtilities.getPage(location.pathname);
        document.title = name.tabName;
    }, [location]);

    if (status === AppStatus.DOWN) {
        return (
            <S.AppBody>
                <Menu/>
                <UnderConstruction/>
            </S.AppBody>
        )
    }
    return (
        <React.Fragment>
            <S.GlobalStyles />
            {error && <Error404/>}
            {!error &&
            <S.AppBody>
                <Menu/>
                {(cookiesAccepted !== 'true' && showDisclaimer) && <CookiesDisclaimer/>}
                <S.AppContent>
                    <OutageChartDetails/>
                    <AuthProvider
                        tokenRefreshUrl={process.env.REACT_APP_API_URL + "/refreshtoken"}
                        oauthUrl={process.env.REACT_APP_OAUTH_URL || ""}
                        push={history.replace}
                        onAccessTokenChange={Fetch.SetAccessToken}
                    >
                        <Switch>
                            <Route exact path={'/'} component={Outages}
                                   onLeave={dispatch(clearSelectedChartOutages())}/>
                            <Route path={'/Dashboard'} component={Dashboard}/>
                            <Route path={'/Bulletins'} component={BulletinList}/>
                            <Route path={'/CurrentSystemReport'} component={CurrentSystemReport}/>
                            <Route path={'/GasDaySummaryReport'} component={GasDaySummaryReport}/>
                            <Route path={'/ContactUs'} component={ContactUs}/>
                            <AdminRoute path={'/Admin'} component={Admin}/>
                            <Route path={'/OutageMap'} component={OutageMap}/>
                            <Route path={'/OutagesList/:area'} component={OutagesList}/>
                            <Route path={'/OutagesList/'} component={OutagesList}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminPublicationList'}
                                        component={AdminPublicationList}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminDailyOperatingPlan'}
                                        component={AdminDailyOperatingPlan}/>
                            <AdminRoute groups={[adminPublishADGroup]}
                                        path={'/ViewDailyOperatingPlan/:publicationDateUtc'}
                                        component={ViewDailyOperatingPlan}/>
                            <AdminRoute groups={[adminPublishADGroup]}
                                        path={'/ViewDailyOperatingPlanChart/:publicationDateUtc'}
                                        component={ViewDailyOperatingPlanChart}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminBaseCapability'}
                                        component={AdminAreaCapability}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminChartData'}
                                        component={AdminChartData}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminHeatValues'}
                                        component={AdminHeatValues}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/AdminChartExport'}
                                        component={AdminChartExport}/>
                            <Route path={'/OutageChart'} component={OutageChart}/>
                            <Route path={'/Subscription/:uniqueId'} component={SubscriptionManagement}/>
                            <Route path={'/Subscription'} component={Subscriptions}/>
                            <Route path={'/Verify/:uniqueId'} component={Verify}/>
                            <Route path={'/Unsubscribe/:uniqueId'} component={Unsubscribe}/>
                            <AdminRoute groups={[adminPublishADGroup]} path={'/stationList'} component={stationList}/>
                            <Route path={'/Bulletin/:id'} component={ViewBulletin}/>
                            <AdminRoute groups={[adminBulletinADGroup]} path={'/BulletinManagement'} component={BulletinManagement}/>
                            <AdminRoute groups={[adminBulletinADGroup]} path={'/BulletinEdit/Copy/:id'} component={BulletinForm}/>
                            <AdminRoute groups={[adminBulletinADGroup]} path={'/BulletinEdit/:id'} component={BulletinForm}/>
                            <AdminRoute groups={[adminBulletinADGroup]} path={'/BulletinEdit/'} component={BulletinForm}/>
                            <AdminRoute groups={[adminBulletinADGroup]} path={'/AdminMainlineMaintenance'} component={AdminMainlineMaintenance}/>
                            <Route component={Error404}/>
                        </Switch>
                    </AuthProvider>
                    {isBusy && <BusyModal/>}
                    {showConfirm && <ConfirmModal headerText={confirm.headerText} bodyText={confirm.bodyText}
                                                  buttonCancelText={confirm.buttonCancelText}
                                                  buttonConfirmText={confirm.buttonConfirmText}
                                                  onConfirm={confirm.onConfirm} background={confirm.background}
                                                  border={confirm.border}/>}
                </S.AppContent>
            </S.AppBody>
            }
        </React.Fragment>
    );
};

export default App;