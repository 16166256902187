import Validation from "./Validation";
import ValidationFunctions from "./ValidationFunctions";

export default class SubscriptionValidation {

    static EmailValidation: Validation = new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.subscription.email"
        },
        {
            validationFunction: ValidationFunctions.EmailFormatValidationFunction,
            validationMessageKey: "validation.subscription.email"
        }
    ]);

    static ReCaptchaValidation: Validation = new Validation([
        {
            validationFunction: ValidationFunctions.RequiredValidationFunction,
            validationMessageKey: "validation.subscription.recaptcha"
        },
    ]);
}