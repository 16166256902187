import React from 'react';
import * as S from "../../styles";
import {locale} from "../../locale";
import {useHistory} from "react-router";
import {useAuth} from "../Auth";
import {DecodedToken} from "../Auth/interfaces";
import useTypedSelector from "../../hooks/useTypedSelector";

const adminPublishADGroup = process.env.REACT_APP_AD_GROUP_ADMIN_PUBLISH || "";
const adminBulletinADGroup = process.env.REACT_APP_AD_GROUP_ADMIN_BULLETIN || "";

const Admin: React.FC = () => {
    const history = useHistory();
    const showDashboard = useTypedSelector(state => state.toggles.showDashboard);
    const {getUser} = useAuth();
    const user: DecodedToken = getUser();

    return (
        <React.Fragment>
            <S.PageWithNavigationContainer>
                <S.BackNavigationHeader style={{cursor: "default"}}>
                    <S.CenteredPageTitle>{locale("administration")}</S.CenteredPageTitle>
                </S.BackNavigationHeader>
                <S.AdminContainer>
                    <S.AdminUserTitle>
                        {locale('adminWelcome')}{", "}{user.name}{" - "}{user.email}
                    </S.AdminUserTitle>
                    {user["custom:groups"].indexOf(adminBulletinADGroup) > -1 && <S.AdminRow>
                        <S.AdminHeader>Bulletins</S.AdminHeader>
                        <S.AdminButtonRow>
                            <S.AdminButton onClick={() => history.push("BulletinManagement")}>
                                {locale('admin.options.bulletinManagement')}
                            </S.AdminButton>
                        </S.AdminButtonRow>
                    </S.AdminRow>}
                    <S.AdminRow>
                        <S.AdminHeader>NGTL System</S.AdminHeader>
                        <S.AdminButtonRow>
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminPublicationList")}>
                                {locale("admin.options.dailyOperatingPlan")}
                            </S.AdminButton>}
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminBaseCapability")}>
                                {locale("admin.options.areaCapability")}
                            </S.AdminButton>}
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminChartData")}>
                                {locale("admin.options.updateDailyValues")}
                            </S.AdminButton>}
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminChartExport")}>
                                {locale("admin.options.exportChartData")}
                            </S.AdminButton>}
                            {showDashboard && user["custom:groups"].indexOf(adminBulletinADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminMainlineMaintenance")}>
                                {locale('admin.options.updateMainlineMaintenanceValues')}
                            </S.AdminButton>}
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("stationList")}>
                                {locale('admin.options.stationList')}
                            </S.AdminButton>}
                            {user["custom:groups"].indexOf(adminPublishADGroup) > -1 && <S.AdminButton onClick={() => history.push("AdminHeatValues")}>
                                {locale('admin.options.heatValues')}
                            </S.AdminButton>}
                        </S.AdminButtonRow>
                    </S.AdminRow>
                    <S.AdminRow>
                        <S.AdminHeader>Quick Reference Guides</S.AdminHeader>
                        <S.AdminButtonRow>
                            <S.AdminButton onClick={() => window.open(`/DOP_QRG_Admin_R3_01.pdf`, "_blank")}>
                                {locale('admin.button.dop.qrg')}
                            </S.AdminButton>
                            <S.AdminButton onClick={() => window.open(`/Bulletin_QRG_Admin_R3_01.pdf`, "_blank")}>
                                {locale('admin.button.bulletin.qrg')}
                            </S.AdminButton>
                        </S.AdminButtonRow>
                    </S.AdminRow>
                </S.AdminContainer>
            </S.PageWithNavigationContainer>
        </React.Fragment>
    );
};

export default Admin;