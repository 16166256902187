import React from 'react';
import {Switch, Route, useRouteMatch, Redirect, useHistory} from "react-router-dom";
import TCLogo from '../icons/menu_TC_logo.svg';
import MainlineDashboard from "./mainline/MainlineDashboard";
import NgtlDashboard from "./ngtl/NgtlDashboard";
import * as S from "../styles";
import {locale} from "../locale";
import useTypedSelector from "../hooks/useTypedSelector";
import BetaBanner from "./BetaBanner";

const DashboardHeader: React.FC = () => {
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    return <React.Fragment>
        <S.HeaderContainer hasBanner={showBanner}>
            <img width={176} src={TCLogo} alt="TCLogo"/>
        </S.HeaderContainer>
        <S.HeroImage/>
    </React.Fragment>
};

const TabNavigation: React.FC = () => {
    return <S.TabNavigationContainer>
        <S.TabNavigationItem to={"/Dashboard/Ngtl"} active={Boolean(useRouteMatch("/Dashboard/Ngtl"))}>
            NGTL
        </S.TabNavigationItem>
        <S.TabNavigationItem to={"/Dashboard/Mainline"} active={Boolean(useRouteMatch("/Dashboard/Mainline"))}>
            MAINLINE
        </S.TabNavigationItem>
    </S.TabNavigationContainer>
};

const Dashboard: React.FC = () => {
    const history = useHistory();


    return (
        <div>
            <DashboardHeader/>
            <BetaBanner/>
            <TabNavigation/>
            <Redirect exact={true} from={"/Dashboard"} to={"/Dashboard/Ngtl"}/>
            <Switch>
                <Route path={'/Dashboard/Mainline'} component={MainlineDashboard}/>
                <Route path={'/Dashboard/Ngtl'} component={NgtlDashboard}/>
            </Switch>

            <S.SubscribeContainer>
                <S.Button style={{width: "328px", height: "48px"}}
                          onClick={() => history.push("/Subscription")}>{locale('subscription.subscribe')}</S.Button>
            </S.SubscribeContainer>
        </div>
    );
};

export default Dashboard;