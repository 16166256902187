import React from 'react';
import * as S from "../styles";
import closeIcon from "../icons/close_x.svg";
import {useDispatch} from "react-redux";
import {hideConfirm} from "../actions/appActions";

interface Props {
    headerText: string;
    bodyText: string;
    buttonCancelText: string;
    buttonConfirmText?: string;
    background?: string;
    border?: string;
    onConfirm(): any;
}

const ConfirmModal: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    function onCancel() {
        dispatch(hideConfirm());
    }

    function onConfirmClick() {
        onCancel();
        setTimeout(() => props.onConfirm(), 25);
    }

    return (
        <div>
            <S.ModalOverlay onClick={onCancel}>
            </S.ModalOverlay>
            <S.Modal style={{background: props.background, border: props.border}}>
                <S.ModalHeader>
                    <S.ModalHeaderText>
                        {props.headerText}
                    </S.ModalHeaderText>
                    <S.ClickableIcon src={closeIcon} width={18} height={20} onClick={onCancel}/>
                </S.ModalHeader>
                <S.ConfirmModalBody>{props.bodyText}</S.ConfirmModalBody>
                <S.ConfirmModalFooter>
                        <S.Button onClick={onCancel}>{props.buttonCancelText}</S.Button>
                    {props.buttonConfirmText && <S.Button onClick={onConfirmClick}>{props.buttonConfirmText}</S.Button>}
                </S.ConfirmModalFooter>
            </S.Modal>
        </div>
    );
};

export default ConfirmModal;