import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import useApiService from "../../hooks/useApiService";
import {
    AdminDailyOperationPlanColumnWidths,
    IArea,
    IAreaForEdit,
    IAreaHistory,
    IDopOutage,
    IHasValidationResults,
    IOutage,
    IOutageForEdit,
    IDailyChartData,
} from "../../types";
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {useHistory} from "react-router";
import ResultModal from "../ResultModal";
import SortFunctions from "../../utilities/SortFunctions";
import {useDispatch} from "react-redux";
import {clearBusy, clearIsDirty, setBusy, setIsDirty, showConfirm} from "../../actions/appActions";
import {setAreasLoaded} from "../../actions/outagesActions";
import DateUtilities from "../../utilities/DateUtilities";
import {AdminUtilities} from "../../utilities/AdminUtilities";
import useAreas from "../../hooks/useAreas";
import useTypedSelector from "../../hooks/useTypedSelector";
import addIcon from "../../icons/plus-circle.svg";
import trashIcon from "../../icons/trash-2.svg";
import {setOutageComparisonLoaded} from "../../actions/outageComparisonActions";
import useDailyOperatingPlanCompare from "../../hooks/useDailyOperatingPlanCompare";
import {DateTime} from "luxon";
import {Fetch} from "../../utilities/Fetch";
import {clearOutageChartDataLoaded} from "../../actions/outageChartActions";
import Validation from "../../utilities/validation/Validation";
import OutageValidation from "../../utilities/validation/OutageValidation";
import colors from "../../styles/Colors";
import HeaderRowOutage from "./HeaderRowOutage";
import {SendToWorker} from "../../workers/SendToWorker";
import {IBuildDopDataWorkerResponse, IResponse} from "../../workers";

const StartDateFieldName = "startDateTime";
const EndDateFieldName = "endDateTime";

const AdminDailyOperatingPlan: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const areas = useTypedSelector(state => state.outages.areas);
    const areasLoaded = useTypedSelector(state => state.outages.areasLoaded);
    const outages = useTypedSelector(x => x.outages.outages);
    const outagesHistory = useTypedSelector(x => x.outageComparison.outageHistory);
    const outagesLoaded = useTypedSelector(x => x.outages.outagesLoaded);
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);
    const isBusy = useTypedSelector(state => state.app.isBusy);
    const dop = useApiService<IDopOutage[]>([], "dop");
    const draftPublication = useApiService<{ outages: IOutage[]; area_histories: IAreaHistory[]; outage_publication: { id?: number; pullDateTimeUtc: string } }>
    ({outages: [], area_histories: [], outage_publication: {id: 0, pullDateTimeUtc: ""}}, "publications/draft");
    const plantTurnAround = useApiService<IDopOutage[]>([], "plantturnaroundactivity");
    const [outagesToEdit, setOutagesToEdit] = useState<IOutageForEdit[]>([]);
    const [areasToEdit, setAreasToEdit] = useState<IAreaForEdit[]>([]);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultEmailPublicationId, setResultEmailPublicationId] = useState<number>(-1);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const [datePulled, setDatePulled] = useState<DateTime | null>(null);
    const [lastUpdatedDate, setLastUpdatedDate] = useState<string>("");
    const [dopLoaded, setDopLoaded] = useState<boolean>(false);
    const [dailyCapabilities, setDailyCapabilities] = useState<IDailyChartData[]>([]);

    // We check outage_publication as it may be null during loading
    const isDraftExisting: boolean = Boolean(draftPublication.data.outage_publication) && Boolean(draftPublication.data.outage_publication.id);
    // set isBusy to false first, hooks are always run in order, order is important
    useEffect(() => {
        dispatch(setBusy());
    }, [dispatch]);

    useAreas();
    useDailyOperatingPlanCompare();

    useEffect(() => {
        if (areasLoaded && outagesLoaded && publishDates.loaded && !dop.loading && !plantTurnAround.loading && !draftPublication.loading && !dopLoaded) {
            let editableOutages: IOutageForEdit[] = [];

            if (draftPublication.data.outages.length !== 0) {
                editableOutages = AdminUtilities.PrepareDraftOutagesForEdit(draftPublication.data.outages);
                setDatePulled(DateTime.fromFormat(draftPublication.data.outage_publication.pullDateTimeUtc, DateUtilities.serviceDateFormat, {zone: "UTC"}));
            } else {
                if (dop.data) {
                    editableOutages = editableOutages.concat(AdminUtilities.PrepareDopOutagesForEdit(dop.data, areas));
                }
                if (plantTurnAround.data) {
                    editableOutages = editableOutages.concat(AdminUtilities.PrepareDopPlantTurnAroundActivitiesForEdit(plantTurnAround.data, areas));
                }

                setDatePulled(DateTime.local().toUTC());
            }

            // sorting once on data load.  Not re-sorting as data is edited.
            setOutagesToEdit(editableOutages.sort(SortFunctions.DefaultOutageSortFunction(DateUtilities.EditDateUtility)));

            setAreasToEdit(areas.map(a => AdminUtilities.PrepareAreaForEdit(a, draftPublication.data.area_histories.find(x => x.areaId === a.id) ?? null)));

            setLastUpdatedDate(outages.length > 0 ? outages[0].publishedDateTimeUtc : DateUtilities.ServiceDateUtility.Max(publishDates.recentPublishDates));

            setDopLoaded(true);
            dispatch(clearBusy());
        }
    }, [areasLoaded, outagesLoaded, dop, areas, dispatch, draftPublication, outages, plantTurnAround, publishDates, dopLoaded]);

    function publish() {
        save("/outages/publish", "dailyOperatingPlanPublishSuccess", true, true);
    }

    function saveDraft() {
        save("/publications/draft", "saveDraftSuccess");
    }

    function emailNotification() {
        setShowResult(false);
        email(`/publications/email/${resultEmailPublicationId}`, "emailDailyOperatingPlanSuccess");
    }

    function deleteDraft() {
        Fetch.Build("/publications/draft", dispatch)
            .success(serviceSuccess("deleteSuccess", () => {
            }))
            .error(serviceError())
            .finally(serviceFinally())
            .delete();
    }

    function save(url: string, successMessageKey: string, includeDailyCapabilities = false, showEmailDecision = false) {
        dispatch(setBusy());

        const workerSuccessAction = (payload: IResponse<IBuildDopDataWorkerResponse>) => {
            Fetch.Build(url, dispatch)
                .withPayload([
                    ...payload.data.outages,
                    ...payload.data.areas,
                    ...payload.data.chartData,
                    {pullDateTimeUtc: datePulled?.toFormat(DateUtilities.serviceDateFormat)}])
                .success((publicationId: number) => {
                    if (publicationId && showEmailDecision) {
                        setResultEmailPublicationId(publicationId);
                    }
                    dispatch(setAreasLoaded(false));
                    dispatch(setOutageComparisonLoaded(false));
                    dispatch(clearOutageChartDataLoaded());

                    setResultText(locale(successMessageKey));
                    setResultSuccess(true);
                    dispatch(clearIsDirty());
                })
                .error(serviceError())
                .finally(serviceFinally())
                .post();
            dispatch(clearBusy());
        };

        const startDateString = outagesToEdit.sort(SortFunctions.OutageStartDateSortFunction(true, DateUtilities.EditDateUtility))[0]?.startDateTime;
        const endDate = DateUtilities.Today();
        const format = DateUtilities.ServiceDateOnlyUtility.Format;
        if(startDateString) {
            const startDate = DateTime.fromFormat(startDateString, DateUtilities.editDateFormat);
            console.log(startDate, endDate);
            Fetch.Build(`/chart?start=${format(startDate)}&end=${format(endDate)}`)
                .success(x => SendToWorker.buildDopSaveData(outagesToEdit, outages, outagesHistory.history, areasToEdit, x, includeDailyCapabilities).success(workerSuccessAction).execute())
                .error(serviceError())
                .get();
        }
    }

    function email(url: string, successMessageKey: string) {
        Fetch.Build(url, dispatch)
            .success(emailServiceSuccess(successMessageKey))
            .error(emailServiceError())
            .finally(emailServiceFinally())
            .post();
    }

    function serviceSuccess(successMessageKey: string, successAction: () => void) {
        return () => {
            successAction();
            setResultText(locale(successMessageKey));
            setResultSuccess(true);
            dispatch(clearIsDirty());
        };
    }

    function serviceError() {
        return () => {
            setResultText(locale("genericError"));
            setResultSuccess(false);
        };
    }

    function serviceFinally() {
        return () => {
            dispatch(clearBusy(true));
            setShowResult(true);
        };
    }

    function emailServiceSuccess(successMessageKey: string) {
        return () => {
            setResultEmailPublicationId(-1);
            setResultText(locale(successMessageKey));
            setResultSuccess(true);
            dispatch(clearIsDirty());
        };
    }

    function emailServiceError() {
        return () => {
            setResultText(locale("genericError"));
            setResultSuccess(false);
        };
    }

    function emailServiceFinally() {
        return () => {
            dispatch(clearBusy(true));
            setShowResult(true);
        };
    }

    function publishClick() {
        const hasOutages = outagesToEdit.length > 0;
        const isValid: boolean = isEverythingValid();
        dispatch(showConfirm({
            headerText: locale('publish'),
            bodyText: hasOutages && isValid ? locale('dopPublishConfirm') : isValid ? locale('dopPublishConfirmNoRecords') : locale("validation.outage.publish.validationError"),
            buttonCancelText: hasOutages && isValid ? locale('cancel') : locale("ok"),
            buttonConfirmText: hasOutages && isValid ? locale('publish') : null,
            background: isValid ? undefined : colors.error_background,
            border: isValid ? undefined : S.ErrorBorderStyle,
            onConfirm: publish
        }));
    }

    function isEverythingValid() {
        return outagesToEdit.every(outage => Object.keys(outage.validationResults).every(field => outage.validationResults[field].isValid))
            && areasToEdit.every(area => Object.keys(area.validationResults).every(field => area.validationResults[field].isValid));
    }

    function draftClick() {
        const isValid: boolean = isEverythingValid();
        dispatch(showConfirm({
            headerText: locale('saveDraft'),
            bodyText: isValid ? locale('dopDraftConfirm') : locale("validation.outage.draft.validationError"),
            buttonCancelText: isValid ? locale('cancel') : locale("ok"),
            buttonConfirmText: isValid ? locale('saveDraft') : null,
            background: isValid ? undefined : colors.error_background,
            border: isValid ? undefined : S.ErrorBorderStyle,
            onConfirm: saveDraft
        }));
    }

    function deleteClick() {
        dispatch(showConfirm({
            headerText: locale('deleteDraft'),
            bodyText: locale('deleteDraftConfirm'),
            buttonCancelText: locale('cancel'),
            buttonConfirmText: locale('delete'),
            onConfirm: deleteDraft
        }));
    }

    const updateInputs = (selectedOutage: IOutageForEdit, fieldName: string, newValue: string, validation: Validation | null = null, capitalize = false) => {
        const isDirty = selectedOutage.hasFieldDirty ? selectedOutage.hasFieldDirty : {};
        isDirty[fieldName] = true;

        if (capitalize) {
            newValue = newValue.toUpperCase();
        }

        validateInput(selectedOutage, fieldName, newValue, validation);

        if (fieldName === "outageId") {
            // if the outageId changes we need to ensure we have a unique outageId/impactId pair.  Calculate impactId here to ensure.
            const matches = outagesToEdit.filter(x => x.outageId === newValue && x.impactId === selectedOutage.impactId && x.temporaryId !== selectedOutage.temporaryId);

            if (matches.length > 0) {
                selectedOutage.impactId = Math.max(...outagesToEdit.filter(x => x.outageId.toString() === newValue).map(x => x.impactId)) + 1
            }
        }

        const updatedOutages = outagesToEdit.map(outage =>
            outage.temporaryId === selectedOutage.temporaryId ? {
                ...outage,
                [fieldName]: newValue,
                hasFieldDirty: isDirty
            } : outage
        );

        setOutagesToEdit(updatedOutages);
        dispatch(setIsDirty());
    };

    const validateInput = (selectedOutage: IOutageForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        const validationResults = selectedOutage.validationResults;

        if ([StartDateFieldName, EndDateFieldName].indexOf(fieldName) !== -1) {
            // validation for these two fields are dependent on each other, simplest just to validate them both here
            const outageToValidate = {...selectedOutage, [fieldName]: newValue};
            validationResults[StartDateFieldName] = OutageValidation.StartDateValidation(outageToValidate).validate(outageToValidate.startDateTime);
            validationResults[EndDateFieldName] = OutageValidation.EndDateValidation(outageToValidate).validate(outageToValidate.endDateTime);
        }

        if (validation) {
            validationResults[fieldName] = validation.validate(newValue);
        }
    };

    const updateAreaInputs = (selectedArea: IAreaForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        const isDirty = selectedArea.hasFieldDirty;
        isDirty[fieldName] = true;

        if (validation) {
            selectedArea.validationResults[fieldName] = validation.validate(newValue);
        }

        const updatedAreas = areasToEdit.map(area =>
            area.id === selectedArea.id ? {
                ...area,
                [fieldName]: newValue,
                hasFieldDirty: isDirty
            } : area
        );

        setAreasToEdit(updatedAreas);
        dispatch(setIsDirty());
    };

    function addRow(area: IArea) {
        setOutagesToEdit([...outagesToEdit, AdminUtilities.PrepareNewOutageForEdit(area, DateTime.local().valueOf())]);
        dispatch(setIsDirty());
    }

    function removeRow(outage: IOutageForEdit) {
        setOutagesToEdit(outagesToEdit.filter(x => x.temporaryId !== outage.temporaryId));
        dispatch(setIsDirty());
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : "";
    }

    function createDopOutageRow(outage: IOutageForEdit, area: IArea) {
        return <S.TableRow key={`${outage.id}-${outage.impactId}`}>
            {!area.isPlantTurnAround &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.outageId} >
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["outageId"] : false}
                        title={errorMessage(outage, "outageId")}
                        hasError={hasError(outage, "outageId")}
                        type="text"
                        onChange={text => updateInputs(outage, "outageId", text.target.value, OutageValidation.OutageIdValidation(outage.area))}
                        value={outage.outageId}/>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {!area.isPlantTurnAround &&
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.description}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["description"] : false}
                        title={errorMessage(outage, "description")}
                        hasError={hasError(outage, "description")}
                        type="text"
                        onChange={text => updateInputs(outage, "description", text.target.value, OutageValidation.DescriptionValidation(outage.area))}
                        value={outage.description}/>
                </S.TableColumn>
            </S.TableCellWrapper>}
            {!area.isPlantTurnAround &&
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.impact}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["impact"] : false} type="text"
                        title={errorMessage(outage, "impact")}
                        hasError={hasError(outage, "impact")}
                        onChange={text => updateInputs(outage, "impact", text.target.value, OutageValidation.ImpactValidation(outage.area))}
                        value={outage.impact}/>
                </S.TableColumn>
            </S.TableCellWrapper>}
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.start}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["startDateTime"] : false}
                        title={errorMessage(outage, "startDateTime")}
                        hasError={hasError(outage, "startDateTime")}
                        type="text"
                        onChange={text => updateInputs(outage, "startDateTime", text.target.value, null, true)}
                        value={outage.startDateTime}/>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.end}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["endDateTime"] : false}
                        title={errorMessage(outage, "endDateTime")}
                        hasError={hasError(outage, "endDateTime")}
                        type="text"
                        onChange={text => updateInputs(outage, "endDateTime", text.target.value, null, true)}
                        value={outage.endDateTime}/>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
            {!area.isPlantTurnAround &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.capability}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["flowCapability"] : false}
                        title={errorMessage(outage, "flowCapability")}
                        hasError={hasError(outage, "flowCapability")}
                        type="text"
                        onChange={text => updateInputs(outage, "flowCapability", text.target.value, OutageValidation.CapabilityValidation(outage.area), true)}
                        value={outage.flowCapability}/>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {area.hasLocalOutages &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.capability}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["localAreaBaseCapability"] : false}
                        title={errorMessage(outage, "localAreaBaseCapability")}
                        hasError={hasError(outage, "localAreaBaseCapability")}
                        type="text"
                        onChange={text => updateInputs(outage, "localAreaBaseCapability", text.target.value, OutageValidation.LocalAreaCapabilityValidation(outage.area))}
                        value={outage.localAreaBaseCapability}/>
                </S.TableColumn>
            </S.TableCellWrapperNoGrow>}
            {area.hasLocalOutages &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.capability}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["localAreaOutageCapability"] : false}
                        title={errorMessage(outage, "localAreaOutageCapability")}
                        hasError={hasError(outage, "localAreaOutageCapability")}
                        type="text"
                        onChange={text => updateInputs(outage, "localAreaOutageCapability", text.target.value, OutageValidation.LocalAreaCapabilityValidation(outage.area))}
                        value={outage.localAreaOutageCapability}/>
                </S.TableColumn>
            </S.TableCellWrapperNoGrow>}
            {area.hasTypicalFlow &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.typicalFlow}>
                    <S.AdminTextInput
                        isDirty={outage.hasFieldDirty ? outage.hasFieldDirty["typicalFlow"] : false}
                        title={errorMessage(outage, "typicalFlow")}
                        hasError={hasError(outage, "typicalFlow")}
                        type="text"
                        onChange={text => updateInputs(outage, "typicalFlow", text.target.value, OutageValidation.TypicalFlowValidation(outage.area))}
                        value={outage.typicalFlow}/>
                </S.TableColumn>
            </S.TableCellWrapperNoGrow>}
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.removeRow}>
                    <S.TableCellIconRemove src={trashIcon} width={18} height={18} onClick={() => removeRow(outage)}/>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
        </S.TableRow>
    }

    function createAddIconRow(area: IArea) {
        return <S.TableRow key={area.id + '-AddRow'}>
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.addRow}>
                    <S.TableCellIcon src={addIcon} width={15} height={15} onClick={() => addRow(area)}/>
                </S.TableColumnNoGrow>
                <S.TableCellAddText onClick={() => addRow(area)}>{locale('addNewEntry')}</S.TableCellAddText>
            </S.TableCellWrapperNoGrow>
        </S.TableRow>
    }

    function createAreaFooterRow(area: IAreaForEdit) {
        return <S.TableRow key={area.id + '-FooterRow'}>
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.notes}>
                    <S.AdminTextArea
                        isDirty={area.hasFieldDirty ? area.hasFieldDirty["notes"] : false}
                        value={area.notes ?? ""}
                        onChange={text => updateAreaInputs(area, "notes", text.target.value)}
                        rows={4}/>
                </S.TableColumn>
            </S.TableCellWrapper>
        </S.TableRow>
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push("/AdminPublicationList")}
                                  leftContent={locale('dailyOperatingPlan')}/>

            <S.InformationContainer>
                <S.FlexContainerRow>
                    <S.InformationItem>
                        <S.Label>{locale("lastUpdatedDate")}</S.Label>
                        <S.Value>{lastUpdatedDate && DateUtilities.UiDateTimeUtility.ReformatUtcToLocal(lastUpdatedDate)}</S.Value>
                    </S.InformationItem>
                    <S.InformationItem>
                        <S.Label>{locale("currentGasDay")}</S.Label>
                        <S.Value>{DateUtilities.UiDateUtility.Now()}</S.Value>
                    </S.InformationItem>
                    {Boolean(datePulled !== null) && <S.InformationItem>
                        <S.Label>{locale("datePulled")}</S.Label>
                        <S.Value>{datePulled?.toFormat(DateUtilities.uiDateFormat)}</S.Value>
                    </S.InformationItem>
                    }
                </S.FlexContainerRow>

                {!dop.loading && !draftPublication.loading && <S.ActionButtonContainer>
                    {isDraftExisting && <S.SecondaryAction>
                        <div onClick={deleteClick}>{locale('delete')}</div>
                    </S.SecondaryAction>}
                    <S.SecondaryAction>
                        <div onClick={draftClick}>{locale('saveDraft')}</div>
                    </S.SecondaryAction>
                    <S.Button onClick={publishClick}>{locale('publish')}</S.Button>
                </S.ActionButtonContainer>}

            </S.InformationContainer>

            {areasToEdit && areasToEdit.sort(SortFunctions.DefaultAreaSortFunction()).map(area => {
                const outagesForArea: IOutageForEdit[] = outagesToEdit.filter(outage => outage.area.id === area.id);
                return <S.AdminAreaCard key={area.acronym}>
                    <S.AdminAreaHeader color={area.color}>
                        <S.AdminAreaHeaderText>{area.typeName}: {area.displayName}</S.AdminAreaHeaderText>
                    </S.AdminAreaHeader>

                    <HeaderRowOutage area={area} columnWidths={AdminDailyOperationPlanColumnWidths} hasRemoveColumn={true}/>
                    <S.HeaderLine/>

                    {outagesForArea.length > 0 ?
                        outagesForArea.map(outage => createDopOutageRow(outage, area)) :
                        <S.TableCellNotEditable>{locale('none')}</S.TableCellNotEditable>}

                    {createAddIconRow(area)}
                    <S.HeaderLine/>
                    {createAreaFooterRow(area)}
                </S.AdminAreaCard>
            })}

            <S.Footer>
                {!dop.loading && !draftPublication.loading && !isBusy && <S.ActionButtonContainer>
                    {isDraftExisting && <S.SecondaryAction>
                        <div onClick={deleteClick}>{locale('delete')}</div>
                    </S.SecondaryAction>}
                    <S.SecondaryAction>
                        <div onClick={draftClick}>{locale('saveDraft')}</div>
                    </S.SecondaryAction>
                    <S.Button onClick={publishClick}>{locale('publish')}</S.Button>
                </S.ActionButtonContainer>}
            </S.Footer>

            {showResult && <ResultModal text={resultText} success={resultSuccess} onExit={() => {
                setShowResult(false);
                history.push("AdminPublicationList");
            }}
                optionalAction={resultEmailPublicationId > 0 ? () => emailNotification() : undefined}
                optionalQuestionText={locale("emailDailyOperatingPlanDecision")}
                optionalOkayText={locale("email")}
                optionalCancelText={locale("no")}
            />}
        </S.PageWithNavigationContainer>
    );
};

export default AdminDailyOperatingPlan;