import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  p {
    margin: 0 0 0 4px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media ${device.mobile}{
    display: none;
  }
`;

export const LegendContainer = styled.div`
  bottom: 0;
  right: 0;
  position: fixed;
  padding: 8px;
  background-color: ${colors.background};
  border-radius: 4px;
  @media ${device.tablet}{
    bottom: 74px;
  }
  @media ${device.mobile}{
    display: none;
  }
`;