import React from 'react';
import * as S from '../styles';
import {OutageDisplay} from "../types";
import map from "../icons/map.svg";
import {locale} from "../locale";
import graph from "../icons/graph.svg";
import listSelected from "../icons/list_selected.svg";
import mapSelected from "../icons/map_selected.svg";
import graphSelected from "../icons/graph_selected.svg";
import list from "../icons/list.svg";

interface Props {
    onMapClick(): any;
    onChartClick(): any;
    onListClick(): any;
    display: OutageDisplay;
}

const OutageToggleRow: React.FC<Props> = (props) => {
    return (
        <S.ToggleRow>
            <S.ToggleOptions>
                <S.ToggleRowItem onClick={() => props.onMapClick()}>
                    <S.DisplayOptionIcon>
                        <img src={props.display === OutageDisplay.Map ? mapSelected : map} alt=""/>
                        <S.UpperText selected={props.display === OutageDisplay.Map}>{locale("navigation.outageToggle.map")}</S.UpperText>
                    </S.DisplayOptionIcon>
                </S.ToggleRowItem>
                <S.ToggleRowItem onClick={() => props.onChartClick()}>
                    <S.DisplayOptionIcon>
                        <img src={props.display === OutageDisplay.Graph ? graphSelected : graph} alt=""/>
                        <S.UpperText selected={props.display === OutageDisplay.Graph}>{locale("navigation.outageToggle.chart")}</S.UpperText>
                    </S.DisplayOptionIcon>
                </S.ToggleRowItem>
                <S.ToggleRowItem onClick={() => props.onListClick()}>
                    <S.DisplayOptionIcon>
                        <img src={props.display === OutageDisplay.List ? listSelected : list} alt=""/>
                        <S.UpperText selected={props.display === OutageDisplay.List}>{locale("navigation.outageToggle.list")}</S.UpperText>
                    </S.DisplayOptionIcon>
                </S.ToggleRowItem>
            </S.ToggleOptions>
        </S.ToggleRow>
    )
};

export default OutageToggleRow;