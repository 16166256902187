import styled from "styled-components";


interface BadgeProps {
    textColor: string;
    backgroundColor: string;
}

export const Badge = styled.div<BadgeProps>`
  display: flex;
  p {
    padding: 8px;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    background: ${props => props.backgroundColor};
    color: ${props => props.textColor};
  }
`;