import React from 'react';
import TCLogo from '../assets/TCLogo.png';
import * as S from '../styles';
import {locale} from "../locale";

const Error404: React.FC = () => {
    return (
        <S.ErrorContainer>
            <S.ErrorContent>
                <img src={TCLogo} alt="TC Energy"/>
                <h1>404</h1>
                <h2>{locale('Error.404.whoops')}</h2>
                <p>{locale('Error.404.mainMessage')}</p>
                <br/><br/>
                <p>{locale('Error.404.assistance')} <a href="tel:4039207473">(403) 920-7473.</a></p>
            </S.ErrorContent>

        </S.ErrorContainer>
    )
};

export default Error404;