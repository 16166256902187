import React from 'react';
import * as S from '../../styles';
import linksStore from "../../utilities/linksStore";
import {locale} from "../../locale";

const SubscriptionDisclaimer: React.FC = () => {
    return (
        <S.UnsubscribeContainer>
            <S.UnsubscribeDisclaimer>{locale('unsubscribe.disclaimer.note1')} <a href={linksStore.privacyPolicy} target="_blank"
                            rel="noopener noreferrer">{locale("links.privacyPolicy")}</a>. {locale('unsubscribe.disclaimer.note2')}</S.UnsubscribeDisclaimer>
            <S.UnsubscribeDisclaimer>{locale('unsubscribe.disclaimer.note3')}</S.UnsubscribeDisclaimer>
        </S.UnsubscribeContainer>
    )
};

export default SubscriptionDisclaimer;