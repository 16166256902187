import React, { useState, useEffect } from 'react';
import { GDSRTableContainer, GDSRBigTableContainer, GDSRDataTable, GDSRFooterCell, GDSRDataRow, GDSRDataHeader, GDSRDataTitleContainer, GDSRDataCenterTitleContent, GDSRDataLeftTitleContent, GDSRDataNameContainer, GDSRDataCell, GDSRDiffContainer, GDSRValueContainer, GDSRIconContainer, GDSRDataTableSpacer,GDSRNameContainerWithIcon, GDSRDataNameContainerDiv, GDSRFooterInner, GDSRFooterContainer } from '../../styles/GasDaySummaryReportStyles';
import changeDown from "../../icons/ChangeDown.svg";
import changeUp from "../../icons/ChangeUp.svg";
import changeNeutral from "../../icons/ChangeNeutral.svg";
import DateUtilities from '../../utilities/DateUtilities';
import alertCircle from "../../icons/alert-circle.svg";
import DateInput from '../../components/DateInput';
import alertCircleRed from "../../icons/alert-circle-red.svg"
import { Icon } from '../../styles';
import { isNullOrUndefined } from 'util';
import useTypedSelector from '../../hooks/useTypedSelector';
import { VolumeUnits } from '../../types';
import {convertVolumeUnit} from '../../utilities/UnitConversions';
import { FontSize } from '../../styles/FontStyles';



interface GDSRDict {
    [key: string]: number;
}

interface GasDaySummaryReportDifferenceData {
    volume: GDSRDict;
    eight: GDSRDict;
    prorated: GDSRDict;
}

export interface GasDaySummaryReportData {
    gasDay: string;
    asofDay: string;
    toleranceLastChanged: string;
    volume: GDSRDict;
    eight: GDSRDict;
    prorated: GDSRDict;
    difference: GasDaySummaryReportDifferenceData;
}

interface Props{
    data?: GasDaySummaryReportData;
}

    const earliestAllowableDate = new Date("Nov 15, 2021");
    const liveDate = new Date("Nov 16, 2021");
    const legacyPageUrl = "http://www.tccustomerexpress.com/gasdaysummaryreport.html"

const GasDaySummaryDataTable: React.FC<Props> = (props) => {
    const data: GasDaySummaryReportData | undefined = props.data;

    const units = useTypedSelector(x => x.app.volumeUnit);
     
    const convertAndRound = (number: number, displayAsIs?: boolean): number => {
        number = displayAsIs ? number : convertVolumeUnit(number, units, false) ;
        if (units === VolumeUnits.MILLION_CUBIC_FEET && !displayAsIs){
            return Math.round(number);
        }
        return Math.round(number * 10) / 10;
    }

    const formatNumber = (number: number, displayAsIs?: boolean): string => {
        const fractionDigits = displayAsIs ? 1 : units === VolumeUnits.MILLION_CUBIC_FEET ? 0 : 1;
        return number.toLocaleString("en-US", {minimumFractionDigits:fractionDigits, maximumFractionDigits: fractionDigits})
    }

    const volume = (identifier: string): number | undefined =>
        data?.volume[identifier];

    const volumeDiff = (identifier: string): number | undefined =>
        data?.difference?.volume[identifier];

    const prorated = (identifier: string): number | undefined =>
        data?.prorated[identifier];

    const proratedDiff = (identifier: string): number | undefined =>
        data?.difference?.prorated[identifier];

    const eightNom = (identifier: string): number | undefined =>
        data?.eight[identifier];

    const eightNomDiff = (identifier: string): number | undefined =>
        data?.difference?.eight[identifier];

    const changeIcon = (difference: number) => 
        difference === 0 ? changeNeutral : (difference > 0 ? changeUp : changeDown);
    

    const displayData = (value?: number, diff?: number | string, noBorderRight?: boolean, displayAsIs?: boolean): JSX.Element => {
        if (typeof value === "number" && typeof diff === "number"){
            return (
                <GDSRDataCell noBorderRight={noBorderRight}>
                    <GDSRValueContainer>
                        <p>{!isNullOrUndefined(value) ? formatNumber(convertAndRound(value, displayAsIs), displayAsIs) : '-'}</p>
                    </GDSRValueContainer>
                    <GDSRIconContainer>
                        <Icon src={changeIcon(diff ? diff : 0)}/>
                    </GDSRIconContainer>
                    <GDSRDiffContainer>
                        <p>{!isNullOrUndefined(diff) ? formatNumber((diff < 0 ? convertAndRound(diff * -1, displayAsIs) : convertAndRound(diff, displayAsIs)), displayAsIs) : '-'}</p>
                    </GDSRDiffContainer>
                </GDSRDataCell>
            )
           } 
        else {
            return (
                <GDSRDataCell noBorderRight={noBorderRight}>
                    <p></p>
                </GDSRDataCell>
            )
        }
    }

    const displayRow = (column: number, rowName: string, rowIdentifier: string, noBorderLeft?: boolean, noBorderRight?: boolean, noBorderBottom?: boolean, displayAsIs?: boolean, cellBackgroundColor?: string, fontStyle?: string): JSX.Element => {
        if (column === 4){
            return (
                <GDSRDataRow noBorderBottom={noBorderBottom} cellBackgroundColor={cellBackgroundColor}>
                    <GDSRDataNameContainer noBorderLeft={noBorderLeft} fontStyle= {fontStyle}><p style={{ whiteSpace: "pre-wrap" }}>{rowName}</p></GDSRDataNameContainer>
                    {displayData(prorated(rowIdentifier), proratedDiff(rowIdentifier), false, displayAsIs)}
                    {displayData(volume(rowIdentifier), volumeDiff(rowIdentifier), false, displayAsIs)}
                    {displayData(eightNom(rowIdentifier), eightNomDiff(rowIdentifier), noBorderRight, displayAsIs)}
                </GDSRDataRow>
            )
        }
        else{
            return (
                <GDSRDataRow noBorderBottom={noBorderBottom} cellBackgroundColor={cellBackgroundColor}>
                    <GDSRDataNameContainer noBorderLeft={noBorderLeft} fontStyle= {fontStyle}><p style={{ whiteSpace: "pre-wrap" }}>{rowName}</p></GDSRDataNameContainer>
                    {displayData(volume(rowIdentifier), volumeDiff(rowIdentifier), false, displayAsIs)}
                </GDSRDataRow>
            )
        }
    }

    const displayTotalNetStorageRow = () => {
        const rowIdentifier = "netStorage";
        return (
            <GDSRDataRow cellBackgroundColor={"rgb(207, 205, 206)"}>
                <GDSRNameContainerWithIcon noBorderLeft={false}>
                    <GDSRDataNameContainerDiv  noBorderRight={true}  fontStyle = {"Italic"}><p>Total Net Storage</p></GDSRDataNameContainerDiv>
                    <Icon src={alertCircleRed} title={"Negative value indicates injection, otherwise withdrawal."}/>
                </GDSRNameContainerWithIcon>
                {displayData(prorated(rowIdentifier), proratedDiff(rowIdentifier))}
                {displayData(volume(rowIdentifier), volumeDiff(rowIdentifier))}
                {displayData(eightNom(rowIdentifier), eightNomDiff(rowIdentifier))}
            </GDSRDataRow>
        )
    }

    const displayFooterRow = () => {
        return(
            <GDSRFooterCell>
                <GDSRFooterInner>
                    Starting from {DateUtilities.formatDate(liveDate)}all deliveries/injection will show values as negative(-) and receipts/withdrawal as positive values.
                    <a href={legacyPageUrl}> For historical data prior to gas day  {DateUtilities.formatDate(earliestAllowableDate)} please go to this site.</a>
                </GDSRFooterInner>
            </GDSRFooterCell>
        )
    }
    const displayHeaders = (column: number, headers: string[], noBorderLeft?: boolean, noBorderRight?: boolean, noBorderTop?: boolean): JSX.Element => {
       if (column === 4){
            console.assert(headers.length === 4, "Must give 4 column names");
            return (
                <GDSRDataHeader>
                    <GDSRDataTitleContainer noBorderLeft={noBorderLeft} minWidthFFPrint={230}><GDSRDataLeftTitleContent style={{ whiteSpace: "pre-line"}}>{headers[0]}</GDSRDataLeftTitleContent></GDSRDataTitleContainer>
                    <GDSRDataTitleContainer minWidthFFPrint={150}><GDSRDataCenterTitleContent style={{ whiteSpace: "pre-line"}}>{headers[1]}</GDSRDataCenterTitleContent></GDSRDataTitleContainer>
                    <GDSRDataTitleContainer minWidthFFPrint={220}><GDSRDataCenterTitleContent style={{ whiteSpace: "pre-line"}}>{headers[2]}</GDSRDataCenterTitleContent></GDSRDataTitleContainer>
                    <GDSRDataTitleContainer noBorderRight={noBorderRight} minWidthFFPrint={100}><GDSRDataCenterTitleContent style={{ whiteSpace: "pre-line"}}>{headers[3]}</GDSRDataCenterTitleContent></GDSRDataTitleContainer>
                </GDSRDataHeader>
            )
          }
        else {
            console.assert(headers.length === 2, "Must give 2 column names");
        return (
            <GDSRDataHeader>
                <GDSRDataTitleContainer noBorderLeft={noBorderLeft} minWidthFFPrint={230}><GDSRDataLeftTitleContent style={{ whiteSpace: "pre-line"}}>{headers[0]}</GDSRDataLeftTitleContent></GDSRDataTitleContainer>
                <GDSRDataTitleContainer minWidthFFPrint={150}><GDSRDataCenterTitleContent style={{ whiteSpace: "pre-line"}}>{headers[1]}</GDSRDataCenterTitleContent></GDSRDataTitleContainer>
            </GDSRDataHeader>
        )
        }
    }

    const displayToleranceRow = () => {
        const rowIdentifier = "tolerance";
        const toleranceMin = volume("toleranceMin")
        const toleranceMax = volume("toleranceMax")
        const toleranceMinDiff = volumeDiff("toleranceMin")
        const toleranceMaxDiff = volumeDiff("toleranceMax")
        const toleranceValue = `${!isNullOrUndefined(toleranceMin) ? toleranceMin : "-"}/${!isNullOrUndefined(toleranceMax) ? toleranceMax : "-"}`
        const toleranceDiffValue = `${!isNullOrUndefined(toleranceMinDiff) ? toleranceMinDiff < 0 ? toleranceMinDiff * -1 : toleranceMinDiff : "-"}/${!isNullOrUndefined(toleranceMaxDiff) ? toleranceMaxDiff < 0 ? toleranceMaxDiff * -1 : toleranceMaxDiff : "-"}`
        return (
            <GDSRDataRow noBorderBottom={false}>
                <GDSRDataNameContainer>
                    <div>
                        <p>Tolerance (%)</p>
                        <div><br/><br/></div>
                        <p style={{ whiteSpace: "pre-line" }} className={"subColumnName"}>{`(Last Changed\n ${data?.toleranceLastChanged ? data.toleranceLastChanged : "-"})`}</p>
                    </div>
                </GDSRDataNameContainer>
                {/*displayData(prorated(rowIdentifier), proratedDiff(rowIdentifier), false, true)*/} 
                <GDSRDataCell>
                    <GDSRValueContainer>
                        <div className={"tolerance"}>
                            <p>{!isNullOrUndefined(toleranceValue) ? toleranceValue : '-'}</p>
                        </div>
                    </GDSRValueContainer>
                    <div>&nbsp;</div>
                    <GDSRDiffContainer>
                        <div className={"tolerance"}>
                            <Icon src={changeIcon(toleranceMinDiff ? toleranceMinDiff : 0)} className={"leftSideIcon"}/>
                            <p className={"tolerance"}>{toleranceDiffValue}</p>
                            <Icon src={changeIcon(toleranceMaxDiff ? toleranceMaxDiff : 0)} className={"rightSideIcon"}/>
                        </div>
                    </GDSRDiffContainer>
                </GDSRDataCell>
                {/*{displayData(eightNom(rowIdentifier), eightNomDiff(rowIdentifier), true)} */} 
            </GDSRDataRow>
        )
    }

    return (
        <GDSRBigTableContainer id={"GDSRBigTableContainer"}>
            <GDSRTableContainer id={"GDSRTable"}>
                <GDSRDataTable id={"receiptsGDSRTable"}>
                    <tbody>
                        {displayHeaders(4,["Receipts", "Prorated \nNom", "Extrapolated \nVolume", "8:00 \nNom"], false, false,false)}
                        {displayRow(4,"NGTL Field Receipts       ", "fieldReceipts", false)}
                        {displayRow(4,"Groundbirch East", "groundBirchEast", false)}
                        {displayRow(4,"Gordondale Border", "gordondaleBorder", false)}
                        {displayRow(4,"Total NGTL Receipts", "totalReceipts", false, false, false, false,"rgb(207, 205, 206)", "Italic")}
                    </tbody>
                </GDSRDataTable>
                <GDSRDataTableSpacer/>
                <GDSRDataTable id={"deliveriesGDSRTable"}>
                    <tbody>
                        {displayHeaders(4,["Deliveries", "Prorated \nNom", "Extrapolated \nVolume", "8:00 \nNom"], false, false,false)}
                        {displayRow(4,"Empress Border               ", "empressBorder", false,false)}
                        {displayRow(4,"McNeill Border", "mcneilBorder", false,  false)}
                        {displayRow(4,"Alberta - BC Border", "albertaBorder", false,false)}
                        {displayRow(4,"Willow Valley \nInterconnect", "willowValley", false,false)}
                        {displayRow(4,"Other Borders", "otherBorders", false,false)}
                        {displayRow(4,"Total Export Deliveries", "totalExportDeliveries", false,false, false, false,"rgb(243, 243, 243)", "Italic")}
                        {displayRow(4,"Intraprovincial", "intraprovincial", false,false)}
                        {displayRow(4,"Total Storage + \nIntraprovincial", "totalStorageIntraprovincial", false, false,false, false,"rgb(243, 243, 243)", "Italic")}
                        {displayRow(4,"Total NGTL Deliveries", "totalDeliveries", false, false, false, false, "rgb(207, 205, 206)", "Italic")}
                    </tbody>
                </GDSRDataTable>
                <GDSRDataTableSpacer/>
                <GDSRDataTable id={"storageGDSRTable"}>
                    <tbody>   
                        {displayHeaders(4,["Storage", "Prorated \nNom", "Extrapolated \nVolume", "8:00 \nNom"], false)}
                        {displayRow(4,"Total Storage Receipts", "storageReceipts", false)}
                        {displayRow(4,"Total Storage Deliveries", "storageDeliveries", false)}
                        {displayTotalNetStorageRow()}
                    </tbody>
                </GDSRDataTable>
            </GDSRTableContainer>
            <GDSRDataTableSpacer/>
            <GDSRTableContainer id={"GDSRTable1"}>
            <GDSRDataTable id={"linepackGDSRTable"}>
                    <tbody>
                        {displayHeaders(2,["Linepack", "Total Volume"], false,false,false)}
                        {displayRow(2,"Linepack Target           ", "linepackTarget", false, false, false)}
                        {displayRow(2,"End Of Day Linepack", "endDayLinepack",false, true)}
                        {displayRow(2,"Linepack Rate Of \nChange", "linepackRateOfChange",false, true)}
                    </tbody>
            </GDSRDataTable>
            <GDSRDataTableSpacer/>
            <GDSRDataTable id={"toleranceGDSRTable"}>
                    <tbody>  
                        {displayHeaders(2,["Tolerance & Accounts","Value"], false, true)}
                        {displayToleranceRow()}
                        {displayRow(2,"Total SD Account (TJ)", "totalSDAccount", false, true, false, true)}
                        {displayRow(2,"Total OBA Account (TJ)", "totalOBAAccount", false, true, false, true)}
                        {displayFooterRow()}
                    </tbody>
            </GDSRDataTable>
           
            </GDSRTableContainer>
        </GDSRBigTableContainer>
    );
}

export default GasDaySummaryDataTable