import React, { useCallback, useEffect, useState} from 'react';
import { isMobile } from "react-device-detect";
import * as S from "../../styles";
import {locale} from "../../locale";
import useTypedSelector from "../../hooks/useTypedSelector";
import DateUtilities from "../../utilities/DateUtilities";
import CurrentSystemReportDataGrid from "./CurrentSystemReportDataGrid";
import CurrentSystemReportChart from "./CurrentSystemReportChart";
import CurrentsystemReportControl from "./CurrentSystemReportControls"

import {ICurrentSystemReportDataFromDatabase, 
    ICurrentSystemReportData,
    CurrentSystemReportDataGridTypes,
    ICurrentSystemReportChartData,
    ICurrentSystemReportActive,
    ICurrentSystemReportCheckbox} from "../../types";
import {setSelectedCurrentSystemReport, setCurrentSystemReportDuration} from "../../actions/currentSystemReportActions";
import {Fetch} from "../../utilities/Fetch";
import {useDispatch} from "react-redux";
import {clearBusy} from "../../actions/appActions";
import {CurrentSystemReportEnum, CurrentSystemReportColorEnum} from "../../types";
import MoreOptions, {MoreOptionsItems} from "../MoreOptions";
import {DateTime} from "luxon";


const CurrentSystemReport: React.FC = () => {
    const [chartData, setChartData] = useState<ICurrentSystemReportChartData[]>([]);
    const [active, setActive] = useState<ICurrentSystemReportActive>({
        albertaBorderFlow: false,
        willowValleyInterconnect: false,
        currentLinepack: true,
        empressBorderFlow: false,
        fieldReceipts: false,
        flowDifferential: false,
        gordondaleReceipt: false,
        groundBirchEastReceipt: false,
        intraprovincialDemand: false,
        linepackTarget:true,
        linepack4Hr: false,
        mcneilBorderFlow: false,
        netStorageFlow: false,
        totalDeliveries: false,
        totalReceipts: false
        
    });
    const showBanner = useTypedSelector(state => state.toggles.showBanner);

    const [publishDataLoaded, setPublishDataLoaded] = useState<boolean>(false);
    const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState<string>("");
    const [selectedDuration, setSelectedDuration] = useState<number>(1);

    const receiptGrid = useTypedSelector(state => state.currentSystemReport.receiptGrid);
    const deliveryGrid = useTypedSelector(state => state.currentSystemReport.deliveryGrid);
    const linepackGrid = useTypedSelector(state => state.currentSystemReport.linepackGrid);

    const dispatch = useDispatch();

    const setActiveChartDataMultiple = (value: ICurrentSystemReportCheckbox[]) => {
        const obj = Object.assign({}, active);

        value.forEach((checkbox: ICurrentSystemReportCheckbox) => {
            obj[checkbox.value] = checkbox.checked;
        });

        setActive(obj);
    }

    const setActiveChartData = (id: string, checked: boolean) => {
        setActive({
            ...active,
            [id]: checked
        })
    }

    const buildReportData = useCallback( () => {
        Fetch
            .Build(`/csr`, dispatch)
            .success(data => {
                setChartData(data.reverse());
                displayGridData(data);
            })
            .get();
        }, [dispatch],
    );

    function getLastUpdateDateTime(reportData: ICurrentSystemReportDataFromDatabase[]){
        const lastUpdatedDates: DateTime[] = []

        reportData.forEach(o => {
            lastUpdatedDates.push(DateUtilities.ServiceDateUtility.ParseDateTime(o[CurrentSystemReportEnum.LASTUPDATED].toString()))
        })
        const publishedDateUTC= DateTime.max(...lastUpdatedDates)

        return publishedDateUTC
    }
    
    function displayGridData(reportData: ICurrentSystemReportDataFromDatabase[]) {
        
        const publishedDateUTC = getLastUpdateDateTime(reportData);

        const selectedData = reportData.filter(x => 
            DateUtilities.ServiceDateUtility.ParseDateTime(x[CurrentSystemReportEnum.LASTUPDATED].toString()).toString()==publishedDateUTC.toString()
        )[0];

        const receiptGrid = buildReceiptGrid(selectedData);
        const deliveryGrid = buildDeliveryGrid(selectedData);
        const linepackGrid = buildLinepackGrid(selectedData);

        const lastUpdateDate = selectedData[CurrentSystemReportEnum.LASTUPDATED].toString()
        setPublishDataLoaded(true);
        setLastUpdatedDateTime(lastUpdateDate);
        dispatch(setSelectedCurrentSystemReport(selectedDuration, receiptGrid,deliveryGrid,linepackGrid));
        dispatch(clearBusy());
    }

    function changeDuration(newDuration: number){
        setSelectedDuration(newDuration);
        dispatch(setCurrentSystemReportDuration(newDuration));
    }

    useEffect(() => {
        if(!publishDataLoaded){
            buildReportData();
        }
    }, []);

    function buildReceiptGrid(selectedDataGridData: ICurrentSystemReportDataFromDatabase): ICurrentSystemReportData[] {
        return [
            {
                id: CurrentSystemReportEnum.FIELDRECEIPTS,
                color: CurrentSystemReportColorEnum.FIELDRECEIPTS,
                category: CurrentSystemReportDataGridTypes.RECEIPTS,
                description: locale("currentSystemReport.receiptDataGrid.category.fieldReceipts"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.FIELDRECEIPTS].toString()
            },
            {
                id: CurrentSystemReportEnum.GROUNDBIRCHEASTRECEIPT,
                color: CurrentSystemReportColorEnum.GROUNDBIRCHEASTRECEIPT,
                category: CurrentSystemReportDataGridTypes.RECEIPTS,
                description: locale("currentSystemReport.receiptDataGrid.category.groundBirchEastReceipt"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.GROUNDBIRCHEASTRECEIPT].toString()
            },
            {
                id: CurrentSystemReportEnum.GORDONDALERECEIPT,
                category: CurrentSystemReportDataGridTypes.RECEIPTS,
                color: CurrentSystemReportColorEnum.GORDONDALERECEIPT,
                description: locale("currentSystemReport.receiptDataGrid.category.gordondaleReceipt"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.GORDONDALERECEIPT].toString()
            },
            {
                id: CurrentSystemReportEnum.TOTALRECEIPTS,
                category: CurrentSystemReportDataGridTypes.RECEIPTS,
                color: CurrentSystemReportColorEnum.TOTALRECEIPTS,
                description: locale("currentSystemReport.receiptDataGrid.category.totalReceipts"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.TOTALRECEIPTS].toString()
            }] as ICurrentSystemReportData[];
    }

    function buildDeliveryGrid(selectedDataGridData: ICurrentSystemReportDataFromDatabase): ICurrentSystemReportData[] {
        return [
            {
                id: CurrentSystemReportEnum.INTRAPROVINCIALDEMAND,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.INTRAPROVINCIALDEMAND,
                description: locale("currentSystemReport.deliveryDataGrid.category.intraprovincialDemand"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.INTRAPROVINCIALDEMAND].toString()
            },
            {
                id: CurrentSystemReportEnum.EMPRESSBORDERFLOW,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.EMPRESSBORDERFLOW,
                description: locale("currentSystemReport.deliveryDataGrid.category.empressBorderFlow"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.EMPRESSBORDERFLOW].toString()
            },
            {
                id: CurrentSystemReportEnum.MCNEILBORDERFLOW,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.MCNEILBORDERFLOW,
                description: locale("currentSystemReport.deliveryDataGrid.category.mcneillBorderFlow"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.MCNEILBORDERFLOW].toString()
            },
            {
                id: CurrentSystemReportEnum.ALBERTABORDERFLOW,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.ALBERTABORDERFLOW,
                description: locale("currentSystemReport.deliveryDataGrid.category.AlbertaBCBorderFlow"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.ALBERTABORDERFLOW].toString()
            },
            {
                id: CurrentSystemReportEnum.WILLOWVALLEYINTERCONNECT,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.WILLOWVALLEYINTERCONNECT,
                description: locale("currentSystemReport.deliveryDataGrid.category.willowValleyInterconnect"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.WILLOWVALLEYINTERCONNECT].toString()
            },
            {
                id: CurrentSystemReportEnum.TOTALDELIVERIES,
                category: CurrentSystemReportDataGridTypes.DELIVERY,
                color: CurrentSystemReportColorEnum.TOTALDELIVERIES,
                description: locale("currentSystemReport.deliveryDataGrid.category.totalDeliveries"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.TOTALDELIVERIES].toString()
            }
            
        ]  as ICurrentSystemReportData[];
    }
    
    function buildLinepackGrid(selectedDataGridData: ICurrentSystemReportDataFromDatabase): ICurrentSystemReportData[] {
        return [
            {
                id: CurrentSystemReportEnum.CURRENTLINEPACK,
                category: CurrentSystemReportDataGridTypes.LINEPACK,
                color: CurrentSystemReportColorEnum.CURRENTLINEPACK,
                description: locale("currentSystemReport.linepackDataGrid.category.currentLinepack"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.CURRENTLINEPACK].toString()
            },
            {
                id: CurrentSystemReportEnum.LINEPACKTARGET,
                category: CurrentSystemReportDataGridTypes.LINEPACK,
                color: CurrentSystemReportColorEnum.LINEPACKTARGET,
                description: locale("currentSystemReport.linepackDataGrid.category.linepackTarget"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.LINEPACKTARGET]?.toString()
            },
            {
                id: CurrentSystemReportEnum.LINEPACK4HR,
                category: CurrentSystemReportDataGridTypes.LINEPACK,
                color: CurrentSystemReportColorEnum.LINEPACK4HR,
                description: locale("currentSystemReport.linepackDataGrid.category.linepack4HRRoc"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.LINEPACK4HR].toString()
            },
            {
                id: CurrentSystemReportEnum.FLOWDIFFERENTIAL,
                category: CurrentSystemReportDataGridTypes.LINEPACK,
                color: CurrentSystemReportColorEnum.FLOWDIFFERENTIAL,
                description: locale("currentSystemReport.linepackDataGrid.category.flowDifferential"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.FLOWDIFFERENTIAL].toString()
            },
            {
                id: CurrentSystemReportEnum.NETSTORAGEFLOW,
                category: CurrentSystemReportDataGridTypes.LINEPACK,
                color: CurrentSystemReportColorEnum.NETSTORAGEFLOW,
                description: locale("currentSystemReport.receiptDataGrid.category.netStorageFlow"),
                currentValue: selectedDataGridData[CurrentSystemReportEnum.NETSTORAGEFLOW].toString()
            }
            
        ]  as ICurrentSystemReportData[];
    }
    
    const desktopReportDurationLink = () => (
            <S.BlockContainer>
                <S.CurrentSystemReportLinkRow>
                    <S.CurrentSystemReportLink key={"last24h"} onClick={() => changeDuration(1)}>
                        <S.CurrentSystemReportLinkSelected isSelected={selectedDuration === 1}>{locale("currentSystemReport.chart.24hours")}</S.CurrentSystemReportLinkSelected>
                    </S.CurrentSystemReportLink>
                    <S.CurrentSystemReportLink key={"last7days"} onClick={() => changeDuration(7)}>
                        <S.CurrentSystemReportLinkSelected isSelected={selectedDuration === 7}>{locale("currentSystemReport.chart.7days")}</S.CurrentSystemReportLinkSelected>
                    </S.CurrentSystemReportLink>
                </S.CurrentSystemReportLinkRow>
            </S.BlockContainer>
          
    );

    const mobileReportDurationLink = () => (
            <S.MobileDateBanner>
            <S.CurrentSystemReportLinkRow>
                <S.CurrentSystemReportLink key={"last24h"} onClick={() => changeDuration(1)}>
                    <S.CurrentSystemReportLinkSelected isSelected={selectedDuration === 1}>{locale("currentSystemReport.chart.24hours")}</S.CurrentSystemReportLinkSelected>
                </S.CurrentSystemReportLink>
                <S.CurrentSystemReportLink key={"last7days"} onClick={() => changeDuration(7)}>
                    <S.CurrentSystemReportLinkSelected isSelected={selectedDuration === 7}>{locale("currentSystemReport.chart.7days")}</S.CurrentSystemReportLinkSelected>
                </S.CurrentSystemReportLink>
            </S.CurrentSystemReportLinkRow>
            </S.MobileDateBanner>      
);

    return (
            <S.CurrentSystemReportWrapper>
                <S.BackNavigationHeader>
                    <S.ReportPageTitle>{locale("page.header.currentSystemReport")}</S.ReportPageTitle>
                    <MoreOptions isVisible={true} options={[
                        MoreOptionsItems.DownloadCurrentSystemReport
                    ]}/>
                </S.BackNavigationHeader>
                <S.PageWithNavigationContainer hasBanner={false} hasStickyControls={true}>
                        <CurrentsystemReportControl
                            hasBanner={showBanner}
                            showIfTopScroll={true}
                            publishedDateTime={lastUpdatedDateTime}
                            renderers={{ left: () => !isMobile && desktopReportDurationLink()}} />
                    {isMobile && desktopReportDurationLink()}
                </S.PageWithNavigationContainer>
                <S.WrapperContainer>
                    <CurrentSystemReportDataGrid  active = {active} setActiveChartDataMultiple = {setActiveChartDataMultiple} setActiveChartData={setActiveChartData} receiptDataGridContents={receiptGrid} linepackDataGridContents={linepackGrid} deliveryDataGridContents={deliveryGrid}></CurrentSystemReportDataGrid>
                    {isMobile && mobileReportDurationLink()}
                    <CurrentSystemReportChart active = {active} chartData = {chartData!} duration={selectedDuration}></CurrentSystemReportChart>
                </S.WrapperContainer>
            </S.CurrentSystemReportWrapper>
    )
};


export default CurrentSystemReport;