import Validation, {ValidationFunctionResult} from "./Validation";
import ValidationFunctions from "./ValidationFunctions";
import {ICapabilityForEdit} from "../../types";
import DateUtilities from "../DateUtilities";
import {DateTime} from "luxon";

export default class AreaCapabilityValidation {

    static NoOverlapValidationFunction = (otherAreaCapabilities: ICapabilityForEdit[]) => (newValue: string) => {
        const date = DateTime.fromFormat(newValue, DateUtilities.uiDateFormat);
        if (!date.isValid) {
            return ValidationFunctionResult.NOT_RUN;
        }

        const validCapabilities = AreaCapabilityValidation.GetValidDateRangesFromCapabilities(otherAreaCapabilities);

        return validCapabilities.some(x => x.StartDate <= date && x.EndDate >= date) ? ValidationFunctionResult.FAIL : ValidationFunctionResult.PASS;
    };

    static NoPrecedingGapsValidationFunction = (otherAreaCapabilities: ICapabilityForEdit[]) => (newValue: string) => {
        const date = DateTime.fromFormat(newValue, DateUtilities.uiDateFormat);
        if (!date.isValid) {
            return ValidationFunctionResult.NOT_RUN;
        }
        const validCapabilities = AreaCapabilityValidation.GetValidDateRangesFromCapabilities(otherAreaCapabilities);

        if (validCapabilities.some(x => x.StartDate <= date && x.EndDate >= date)) {
            // overlaps supersedes this validation
            return ValidationFunctionResult.NOT_RUN;
        }

        const precedingEndDates = validCapabilities.map(x => x.EndDate).filter(x => x < date);

        if (precedingEndDates.length === 0) {
            // this is the first capability entered for this area.  so there is no gap.
            return ValidationFunctionResult.PASS
        }

        const previousDay = date.plus({days: -1});
        const max = DateTime.max(...precedingEndDates);
        return max.toMillis() === previousDay.toMillis() ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static NoSucceedingGapsValidationFunction = (otherAreaCapabilities: ICapabilityForEdit[]) => (newValue: string) => {
        const date = DateTime.fromFormat(newValue, DateUtilities.uiDateFormat);
        if (!date.isValid) {
            return ValidationFunctionResult.NOT_RUN;
        }
        const validCapabilities = AreaCapabilityValidation.GetValidDateRangesFromCapabilities(otherAreaCapabilities);

        if (validCapabilities.some(x => x.StartDate <= date && x.EndDate >= date)) {
            // overlaps supersedes this validation
            return ValidationFunctionResult.NOT_RUN;
        }

        const succeedingStartDates = validCapabilities.map(x => x.StartDate).filter(x => x > date);

        if (succeedingStartDates.length === 0) {
            // this is the last capability entered for this area.  so there is no gap.
            return ValidationFunctionResult.PASS
        }

        const min = DateTime.min(...succeedingStartDates);
        const nextDay = date.plus({days: 1});
        return min.toMillis() === nextDay.toMillis() ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    static DateOrderValidation = (capabilityAfterEdit: ICapabilityForEdit) => () => {
        const start = DateTime.fromFormat(capabilityAfterEdit.startDate, DateUtilities.uiDateFormat);
        const end = DateTime.fromFormat(capabilityAfterEdit.endDate, DateUtilities.uiDateFormat);
        if (!start.isValid || !end.isValid) {
            return ValidationFunctionResult.NOT_RUN;
        }
        return start.toMillis() <= end.toMillis() ? ValidationFunctionResult.PASS : ValidationFunctionResult.FAIL;
    };

    private static GetValidDateRangesFromCapabilities(otherAreaCapabilities: ICapabilityForEdit[]) {
        return otherAreaCapabilities.map(x => {
            return {
                StartDate: DateTime.fromFormat(x.startDate, DateUtilities.uiDateFormat),
                EndDate: DateTime.fromFormat(x.endDate, DateUtilities.uiDateFormat)
            }
        }).filter(x => x.EndDate.isValid && x.StartDate.isValid);
    }

    static StartDateValidation = (capabilityAfterEdit: ICapabilityForEdit, otherAreaCapabilities: ICapabilityForEdit[]): Validation => {
        return new Validation([
            // NOTE - DatePicker doesn't allow null or invalid dates so no need to validate for them
            {validationFunction: AreaCapabilityValidation.NoOverlapValidationFunction(otherAreaCapabilities), validationMessageKey: "validation.areaCapability.startDate.conflictMessage"},
            {validationFunction: AreaCapabilityValidation.NoPrecedingGapsValidationFunction(otherAreaCapabilities), validationMessageKey: "validation.areaCapability.startDate.gapsMessage"},
            {validationFunction: AreaCapabilityValidation.DateOrderValidation(capabilityAfterEdit), validationMessageKey: "validation.areaCapability.startDate.beforeEndDateMessage"},
        ]);
    };

    static EndDateValidation = (capabilityAfterEdit: ICapabilityForEdit, otherAreaCapabilities: ICapabilityForEdit[]): Validation => {
        return new Validation([
            // NOTE - DatePicker doesn't allow null or invalid dates so no need to validate for them
            {validationFunction: AreaCapabilityValidation.NoOverlapValidationFunction(otherAreaCapabilities), validationMessageKey: "validation.areaCapability.endDate.conflictMessage"},
            {validationFunction: AreaCapabilityValidation.NoSucceedingGapsValidationFunction(otherAreaCapabilities), validationMessageKey: "validation.areaCapability.endDate.gapsMessage"},
            {validationFunction: AreaCapabilityValidation.DateOrderValidation(capabilityAfterEdit), validationMessageKey: "validation.areaCapability.endDate.afterStartDateMessage"},
        ]);
    };

    static CapabilityValidation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.areaCapability.capability.requiredMessage"},
        {validationFunction: ValidationFunctions.IntegerValidationFunction, validationMessageKey: "validation.areaCapability.capability.validationMessage"},
        {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(1), validationMessageKey: "validation.areaCapability.capability.minValueMessage"},

    ]);
}
