import {IOutage, IOutageComparison, INVALID, VolumeUnits} from "../types";
import React, { useEffect } from "react";
import * as S from "../styles";
import StatusBadge from "./StatusBadge";
import {locale} from "../locale";
import {FlexContainerColumnNoWrap} from "../styles";
import arrow from "../icons/chevron_right.svg";
import DateUtilities from "../utilities/DateUtilities";
import NumberUtilities from "../utilities/NumberUtilities"
import CompareDetailBox from "./CompareDetailBox";
import {DateTime} from "luxon";
import {AdminUtilities} from "../utilities/AdminUtilities";
import OutageCardChip from "./OutageCardChip";
import alertCircle from "../icons/alert-circle-red.svg";
import CompareDetailBoxTooltip from "./CompareDetailBoxTooltip";
import useAreas from "../hooks/useAreas";
import useTypedSelector from "../hooks/useTypedSelector";
import ImpactTooltipInfo from "./tooltip/ImpactTooltipInfo";
import LocalAreaImpactTooltipInfo from "./tooltip/LocalAreaImpactTooltipInfo";
import { convertVolumeUnit } from "../utilities/UnitConversions";

interface OutageCardParameters {
    outage: IOutageComparison;
}

export class OutageModel {
    outage: IOutage;
    units: VolumeUnits;

    constructor(outage: IOutage, units: VolumeUnits) {
        this.outage = outage;
        this.units = units;
    }

    area() {
        return this.outage.area;
    }

    startDateString() {
        return DateUtilities.UiDateUtility.Reformat(this.outage.startDateTime);
    }

    endDateString() {
        return DateUtilities.UiDateUtility.Reformat(this.outage.endDateTime);
    }

    durationString() {
        const s = DateTime.fromFormat(this.outage.startDateTime, DateUtilities.serviceDateFormat).startOf("day");
        const e = DateTime.fromFormat(this.outage.endDateTime, DateUtilities.serviceDateFormat).startOf("day");

        if (!e.isValid) {
            return this.outage.endDateTime;
        }

        if (!s.isValid) {
            return this.outage.startDateTime;
        }

        const diff = Math.round(e.diff(s).as("days") + 1);

        return `${diff} day${diff===1?"":"s"}`;
        // @TODO - US 161593 When Outage time input is a thing. Such as adding 8H for the endDate.
        // const s = DateTime.fromFormat(this.outage.startDateTime, DateUtilities.serviceDateFormat);
        // const e = DateTime.fromFormat(this.outage.endDateTime, DateUtilities.serviceDateFormat);

        // if (!e.isValid) {
        //     return this.outage.endDateTime;
        // }

        // if (!s.isValid) {
        //     return this.outage.startDateTime;
        // }

        // const diff = e.diff(s, ["days", "hours"]).toObject();
        // if (diff.days === 0 && diff.hours) {
        //     const hours = Math.round(diff.hours);
        //     return `${hours} hour${hours===1?"":"s"}`;
        // } else if (diff.days) {
        //     const days = Math.round(diff.days + 1);
        //     return `${days} day${days===1?"":"s"}`;
        // }
        // return 'Invalid Duration';
    }

    outageCapabilityNumber() {
        return NumberUtilities.ParseFlow(this.outage.flowCapability);
    }

    outageAreaBaseCapabilityNumber() {
        return NumberUtilities.ParseFlow(this.outage.areaBaseCapability ?? String(this.outage.areaBaseCapability));
    }

    localAreaBaseCapabilityNumber() {
        return NumberUtilities.ParseFlow(this.outage.localAreaBaseCapability);
    }

    localAreaOutageCapabilityNumber() {
        return NumberUtilities.ParseFlow(this.outage.localAreaOutageCapability);
    }

    outageCapabilityString() {
        return this.isCapabilityValid() ?
            convertVolumeUnit(this.outageCapabilityNumber(), this.units).toLocaleString():
            this.outage.flowCapability;
    }

    localAreaBaseCapabilityString() {
        return this.isLocalAreaBaseCapabilityValid() ?
            convertVolumeUnit(this.localAreaBaseCapabilityNumber(), this.units).toLocaleString():
            this.outage.localAreaBaseCapability;
    }

    localAreaOutageCapabilityString() {
        return this.isLocalAreaOutageCapabilityValid() ?
            convertVolumeUnit(this.localAreaOutageCapabilityNumber(), this.units).toLocaleString():
            this.outage.localAreaOutageCapability;
    }

    typicalCapabilityString() {
        const outageCapability = AdminUtilities.GetAreaCapabilityByOutage(this.outage, this.outage.area.capabilities);
        return this.outage.area.capabilities.length > 0 && outageCapability > 0 ?
            convertVolumeUnit(outageCapability, this.units).toLocaleString() :
            INVALID;
    }

    typicalFlowString() {

        if (this.outage.typicalFlow) {
            let displayValue = "";
            if (isNaN(Number(this.outage.typicalFlow))) {
                    // Recursively apply toLocaleString based on the detection of
                    // the - value. If the value split on - is a number
                    // we apply toLocaleString() to it, if not we leave it as is.
                displayValue = this.outage.typicalFlow
                    .split("-")
                    .map(value => isNaN(Number(value)) ? value : convertVolumeUnit(Number(value), this.units).toLocaleString())
                    .join("-");
            } else {
                displayValue = convertVolumeUnit(Number(this.outage.typicalFlow), this.units).toLocaleString();
            }
            return `${displayValue}`;
        } else {
            return locale("outage.card.invalid");
        }
    }

   capabilityReductionString() {
       
        if (this.typicalCapabilityString() === INVALID)
            return INVALID;
       const area_base_capability = isNaN(this.outageAreaBaseCapabilityNumber()) ? 
       (this.outage.area.capabilities.length > 0 ? AdminUtilities.GetAreaCapabilityByOutage(this.outage, this.outage.area.capabilities) : 0 as number)
        : this.outageAreaBaseCapabilityNumber();
        return this.isCapabilityValid() ?
            convertVolumeUnit(
                area_base_capability 
                 - this.outageCapabilityNumber(), this.units).toLocaleString() :
            this.outageCapabilityString();
    }


    localAreaOutageCapabilityReductionString() {
        return this.isLocalAreaBaseCapabilityValid() && this.isLocalAreaOutageCapabilityValid() ?
            convertVolumeUnit(this.localAreaBaseCapabilityNumber() - this.localAreaOutageCapabilityNumber(), this.units).toLocaleString() :
            this.localAreaBaseCapabilityString();
    }

    private isCapabilityValid() {
        return this.outageCapabilityNumber().toString() !== "NaN";
    }

    private isLocalAreaBaseCapabilityValid() {
        return this.localAreaBaseCapabilityNumber().toString() !== "NaN";
    }

    private isLocalAreaOutageCapabilityValid() {
        return this.localAreaOutageCapabilityNumber().toString() !== "NaN";
    }

    capabilityPercentageString() {
        if (this.typicalCapabilityString() === INVALID)
            return INVALID;

        return this.isCapabilityValid() ?
            `${Math.round(this.outageCapabilityNumber() * 100 / (this.outage.area.capabilities.length > 0 ? AdminUtilities.GetAreaCapabilityByOutage(this.outage, this.outage.area.capabilities) : 0 as number)).toLocaleString()}%` :
            this.outageCapabilityString();
    }

    get description() {
        return this.outage.description;
    }

    get impact() {
        return this.outage.impact;
    }

    get outageId() {
        return this.outage.outageId;
    }

    set setUnits(units: VolumeUnits) {
        this.units = units;
    }

    isLocalArea() {
        return this.outage.area.centerLng === null && !this.outage.area.isPlantTurnAround;
    }

    includedLocalAreaOutages() {
        return this.outage.area.hasLocalOutages;
    }
}

export class OutageComparisonModel {
    current: OutageModel;
    previous: OutageModel | null;
    outage: IOutageComparison;
    units: VolumeUnits;
    isPlantTurnAround: boolean;

    constructor(outage: IOutageComparison, units: VolumeUnits) {
        this.outage = outage;
        this.current = new OutageModel((outage.current ?? outage.previous) as IOutage, units);
        this.previous = outage.previous === null ? null : new OutageModel(outage.previous, units);
        this.units = units;
        this.isPlantTurnAround = outage.area.isPlantTurnAround;
    }

    set setUnits(units: VolumeUnits) {
        this.units = units;
        this.current.setUnits = units;
        if(this.previous){
            this.previous.setUnits = units;
        }
    }
}

export const OutageCard: React.FC<OutageCardParameters> = (props) => {
    useAreas();

    const allAreas = useTypedSelector(x => x.outages.areas);
    const units = useTypedSelector(x => x.app.volumeUnit);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)
    const model = new OutageComparisonModel(props.outage, units);
    const showPrevious = true;

    useEffect(() => {
        model.setUnits = units
    }, [units]);

    function handleClick() {
        // this is temporarily disabled, since we don't have anything more to see that isn't already on the card.
        // this will change down-the-road
        // dispatch(setSelectedOutage(outage));
    }

    function plantTurnAroundActivityCard() {
        return <S.OutageCard key={model.outage.id}>
            <S.OutageCardHeader color={model.outage.area.color}><StatusBadge
                status={model.outage.changeStatus}/></S.OutageCardHeader>
            <S.OutageCardContentPlantTurnAround>
                <S.FlexContainerColumnNoWrap>
                    <S.FlexContainerRowNoWrap>
                        <S.InfoField minWidth={'150px'}>
                            <S.TextSmall>{locale('start')}</S.TextSmall>
                            <div>
                                <S.ComparedField
                                    hasChanged={model.outage.hasStartDateTimeChanged}>{model.current.startDateString()}</S.ComparedField>
                            </div>
                        </S.InfoField>
                        <S.InfoField minWidth={'150px'}>
                            <S.TextSmall>{locale('end')}</S.TextSmall>
                            <div>
                                <S.ComparedField
                                    hasChanged={model.outage.hasEndDateTimeChanged}>{model.current.endDateString()}</S.ComparedField>
                            </div>
                        </S.InfoField>
                        <S.InfoField minWidth={'150px'}>
                            <S.TextSmall>{locale('flow')}</S.TextSmall>
                            <div>
                                <S.ComparedField
                                    hasChanged={false}>{model.current.typicalFlowString()}</S.ComparedField>
                            </div>
                        </S.InfoField>
                    </S.FlexContainerRowNoWrap>
                </S.FlexContainerColumnNoWrap>
            </S.OutageCardContentPlantTurnAround>
        </S.OutageCard>;
    }

    function defaultCard() {
        return (
            <S.OutageCard onClick={() => handleClick()} key={model.outage.id}>
                <S.OutageCardHeader color={model.outage.area.color}>
                    <S.OutageCardHeaderContent>
                        <h1>{model.outage.area.acronym} {model.outage.outageId}</h1>
                        <StatusBadge status={model.outage.changeStatus}/>
                    </S.OutageCardHeaderContent>
                </S.OutageCardHeader>
                <S.OutageCardContent>
                    <S.FlexContainerColumnNoWrap>
                        <S.FlexContainerRowNoWrap>
                            <S.InfoField minWidth={'85px'}>
                                <CompareDetailBox showPrevious={showPrevious}
                                                  hasChanged={model.outage.hasEndDateTimeChanged || model.outage.hasStartDateTimeChanged}
                                                  label={locale('duration')}
                                                  data={model.current.durationString()}
                                                  previous={model.previous?.durationString()}/>
                            </S.InfoField>
                            <S.InfoField minWidth={'85px'}>
                                <CompareDetailBox showPrevious={showPrevious}
                                                  hasChanged={model.outage.hasStartDateTimeChanged}
                                                  label={locale('start')}
                                                  data={model.current.startDateString()}
                                                  previous={model.previous?.startDateString()}/>
                            </S.InfoField>
                            <S.InfoField minWidth={'85px'}>
                                <CompareDetailBox showPrevious={showPrevious}
                                                  hasChanged={model.outage.hasEndDateTimeChanged} label={locale('end')}
                                                  data={model.current.endDateString()}
                                                  previous={model.previous?.endDateString()}/>
                            </S.InfoField>
                        </S.FlexContainerRowNoWrap>
                        <S.FlexContainerRow>
                            {model.current.isLocalArea() ?
                                <S.InfoField minWidth={'150px'}
                                             isHidden={model.current.capabilityPercentageString() === INVALID}>
                                    <CompareDetailBox showPrevious={showPrevious}
                                                      hasChanged={model.outage.hasFlowCapabilityChanged}
                                                      label={locale('serviceAllowable')}
                                                      data={model.current.capabilityPercentageString()}
                                                      previous={model.previous?.capabilityPercentageString()}/>
                                </S.InfoField> :
                                (model.outage.area.id !== 4 || showFHBCBaseCapability) && <S.InfoField minWidth={'150px'}
                                             isHidden={model.current.capabilityReductionString() === INVALID}>
                                    <CompareDetailBox showPrevious={showPrevious}
                                                             hasChanged={model.outage.hasFlowCapabilityChanged || model.outage.hasAreaBaseCapabilityChanged}
                                                             label={locale('impact')}
                                                             data={model.current.capabilityReductionString()}
                                                             previous={model.previous?.capabilityReductionString()}
                                                             /*icon={alertCircle}*/
                                                             unit={units}>
                                        <ImpactTooltipInfo allAreas={allAreas} outage={model.outage}/>
                                    </CompareDetailBox>
                                </S.InfoField>}
                            <S.InfoField minWidth={'150px'}>
                                <CompareDetailBox showPrevious={showPrevious}
                                                  hasChanged={model.outage.hasFlowCapabilityChanged}
                                                  label={locale('outageCap')}
                                                  data={model.current.outageCapabilityString()}
                                                  previous={model.previous?.outageCapabilityString()}
                                                  unit={units}/>
                            </S.InfoField>
                            {(model.current.isLocalArea() || model.current.includedLocalAreaOutages()) &&
                                <S.InfoField minWidth={'150px'}
                                             isHidden={model.current.typicalFlowString() === INVALID}>
                                    <CompareDetailBox showPrevious={showPrevious}
                                                      hasChanged={model.outage.hasTypicalFlowChanged}
                                                      label={locale('typicalFlow')}
                                                      data={model.current.typicalFlowString()}
                                                      previous={model.previous?.typicalFlowString()}
                                                      unit={units}/>
                                </S.InfoField>
                             }
                        </S.FlexContainerRow>
                        {model.current.includedLocalAreaOutages() &&
                        <S.FlexContainerRow>
                            <S.InfoField minWidth={'150px'}
                                         isHidden={!model.current.localAreaOutageCapabilityReductionString()}>
                                {!isNaN(model.current.localAreaOutageCapabilityNumber()) ?
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasLocalAreaBaseCapabilityChanged}
                                    label={locale('localAreaImpact')}
                                    data={model.current.localAreaOutageCapabilityReductionString()}
                                    previous={model.previous?.localAreaOutageCapabilityReductionString()}
                                    /*icon={alertCircle}*/
                                    unit={units}>
                                        <LocalAreaImpactTooltipInfo outage={model.outage}/>
                                </CompareDetailBox> :
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasLocalAreaBaseCapabilityChanged}
                                    label={locale('localAreaImpact')}
                                    data={model.current.localAreaOutageCapabilityReductionString()}
                                    previous={model.previous?.localAreaOutageCapabilityReductionString()}
                                    unit={units}/>
                                }
                            </S.InfoField>
                            <S.InfoField minWidth={'150px'}
                                         isHidden={!model.current.localAreaOutageCapabilityString()}>
                                <CompareDetailBox showPrevious={showPrevious}
                                                  hasChanged={model.outage.hasLocalAreaOutageCapabilityChanged}
                                                  label={locale('localAreaCapability')}
                                                  data={model.current.localAreaOutageCapabilityString()}
                                                  previous={model.previous?.localAreaOutageCapabilityString()}
                                                  unit={units}/>
                            </S.InfoField>
                            <S.InfoField minWidth={'150px'}/>
                        </S.FlexContainerRow>}
                        <S.FlexContainerColumnNoWrap style={{marginTop: "16px", flex: "1 1 auto"}}>
                            <CompareDetailBox showPrevious={showPrevious}
                                              hasChanged={model.outage.hasDescriptionChanged}
                                              label={locale('outageDescription')}
                                              data={model.current.description}
                                              previous={model.previous?.description}/>
                        </S.FlexContainerColumnNoWrap>
                        <S.FlexContainerColumnNoWrap style={{marginTop: "16px", flex: "1 1 auto"}}>
                            <CompareDetailBox showPrevious={showPrevious} hasChanged={model.outage.hasImpactChanged}
                                              label={locale('serviceAllowableLocation')}
                                              data={model.current.impact}
                                              previous={model.previous?.impact}
                                              breakLineOn={";"}/>
                        </S.FlexContainerColumnNoWrap>
                        <S.FlexContainerRowNoWrap>
                            <OutageCardChip data={`${model.current.impact} ${model.current.description}`}/>
                        </S.FlexContainerRowNoWrap>
                    </S.FlexContainerColumnNoWrap>
                    <FlexContainerColumnNoWrap style={{margin: "auto", marginRight: "0px"}}>
                        <S.RightIcon style={{display: "none"}} src={arrow}/>
                    </FlexContainerColumnNoWrap>
                </S.OutageCardContent>
            </S.OutageCard>);
    }

    return model.isPlantTurnAround ? plantTurnAroundActivityCard() : defaultCard();
};
