import ValidationFunctions from "./ValidationFunctions";
import {IArea} from "../../types";
import Validation from "./Validation";

export default class AreaValidation {
    private static CapabilityAssumptionsRegularValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.area.capabilityAssumptions.requiredMessage"},
    ]);

    static CapabilityAssumptionsValidation = (area: IArea) => {
        if (area.capabilities.length > 0)
        {
            return AreaValidation.CapabilityAssumptionsRegularValidation;
        }
        return Validation.EmptyValidation;
    };
}
