import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import useApiService from "../../hooks/useApiService";
import {IArea, IOutage, AdminDailyOperationPlanColumnWidths} from "../../types";
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {RouteComponentProps, useHistory} from "react-router";
import SortFunctions from "../../utilities/SortFunctions";
import DateUtilities from "../../utilities/DateUtilities";
import HeaderRowOutage from "./HeaderRowOutage";

interface Params {
    publicationDateUtc: string;
}

const ViewDailyOperatingPlan: React.FC<RouteComponentProps> = (props) => {
    const parameter = props.match.params as Params;

    const history = useHistory();
    const areas = useApiService<IArea[]>([], `areas/history/${parameter.publicationDateUtc}`);
    const outages = useApiService<IOutage[]>([], `outages/history/${parameter.publicationDateUtc}`);
    const [outagesToView, setOutagesToView] = useState<IOutage[]>([]);
    const [areasToView, setAreasToView] = useState<IArea[]>([]);

    useEffect(() => {
        if (areas) {
            setAreasToView(areas.data);
        }
    }, [areas]);

    useEffect(() => {
        if (outages) {
            outages.data.forEach(o => {
                o.startDateTime = DateUtilities.EditDateUtility.Reformat(o.startDateTime);
                o.endDateTime = DateUtilities.EditDateUtility.Reformat(o.endDateTime);
            });
            setOutagesToView(outages.data.sort(SortFunctions.DefaultOutageSortFunction(DateUtilities.EditDateUtility)));
        }
    }, [outages]);

    function createDopOutageRow(outage: IOutage, area: IArea) {
        return <S.TableRow key={outage.id}>
            {!area.isPlantTurnAround &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.outageId} viewOnly={true}>
                    <S.ViewTextArea>{outage.outageId}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {!area.isPlantTurnAround &&
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.description} viewOnly={true}>
                    <S.ViewTextArea>{outage.description}</S.ViewTextArea>
                </S.TableColumn>
            </S.TableCellWrapper>}
            {!area.isPlantTurnAround &&
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.impact} viewOnly={true}>
                    <S.ViewTextArea>{outage.impact}</S.ViewTextArea>
                </S.TableColumn>
            </S.TableCellWrapper>}
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.start} viewOnly={true}>
                    <S.ViewTextArea>{outage.startDateTime}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.end} viewOnly={true}>
                    <S.ViewTextArea>{outage.endDateTime}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
            {!area.isPlantTurnAround &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.capability} viewOnly={true}>
                    <S.ViewTextArea>{outage.flowCapability}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {area.hasLocalOutages &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.capability} viewOnly={true}>
                    <S.ViewTextArea>{outage.localAreaBaseCapability}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {area.hasLocalOutages &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.capability} viewOnly={true}>
                    <S.ViewTextArea>{outage.localAreaOutageCapability}</S.ViewTextArea>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>}
            {area.hasTypicalFlow &&
            <S.TableCellWrapperNoGrow>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.typicalFlow} viewOnly={true}>
                    <S.ViewTextArea>{outage.typicalFlow}</S.ViewTextArea>
                </S.TableColumn>
            </S.TableCellWrapperNoGrow>}
        </S.TableRow>
    }

    function createNotesHeaderRow(area: IArea) {
        return <S.TableHeaderRow key={area.id + "-NotesHeaderRow"}>
            <S.TableCellWrapperNoGrow>
                <S.TableColumnNoGrow width={AdminDailyOperationPlanColumnWidths.notes}>
                    <S.TableCellHeaderTextLeft>{locale('notes')}</S.TableCellHeaderTextLeft>
                </S.TableColumnNoGrow>
            </S.TableCellWrapperNoGrow>
        </S.TableHeaderRow>
    }

    function createAreaFooterRow(area: IArea) {
        return <S.TableRow key={area.id + '-NotesFooterRow'}>
            <S.TableCellWrapper>
                <S.TableColumn width={AdminDailyOperationPlanColumnWidths.notes}>
                    <S.ViewTextArea>{area.notes}</S.ViewTextArea>
                </S.TableColumn>
            </S.TableCellWrapper>
        </S.TableRow>
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push("/AdminPublicationList")}
                                  leftContent={locale('dailyOperatingPlanView')}/>

            <S.InformationContainer>
                <S.FlexContainerRow>
                    <S.InformationItem>
                        <S.Label>{locale("publishDateTime")}</S.Label>
                        <S.Value>{parameter.publicationDateUtc && DateUtilities.UiDateTimeUtility.ReformatUtcToLocal(parameter.publicationDateUtc)}</S.Value>
                    </S.InformationItem>
                </S.FlexContainerRow>

            </S.InformationContainer>

            {areasToView && areasToView.sort(SortFunctions.DefaultAreaSortFunction()).map(area => {
                const outagesForArea: IOutage[] = outagesToView.filter(outage => outage.area.id === area.id);
                return <S.AdminAreaCard key={area.acronym}>
                    <S.AdminAreaHeader color={area.color}>
                        <S.AdminAreaHeaderText>{area.typeName}: {area.displayName}</S.AdminAreaHeaderText>
                    </S.AdminAreaHeader>

                    <HeaderRowOutage area={area} columnWidths={AdminDailyOperationPlanColumnWidths}/>
                    <S.ViewHeaderLine/>

                    {outagesForArea.length > 0 ?
                        outagesForArea.map(outage => createDopOutageRow(outage, area)) :
                        <S.TableCellNotEditable>{locale('none')}</S.TableCellNotEditable>}

                    {createNotesHeaderRow(area)}
                    {createAreaFooterRow(area)}
                </S.AdminAreaCard>
            })}


        </S.PageWithNavigationContainer>
    );
};

export default ViewDailyOperatingPlan;