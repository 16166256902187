import {combineReducers} from 'redux';
import {outageReducer} from "./outageReducer";
import {outageComparisonReducer} from "./outageComparisonReducer";
import {appReducer} from "./appReducer";
import {outageChartReducer} from "./outageChartReducer";
import {bulletinReducer} from "./bulletinReducer";
import {subscriptionReducer} from "./subscriptionReducer";
import {toggleReducer} from "./toggleReducer";
import { searchReducer } from "./searchReducer";
import {heatValueReducer} from "./heatValueReducer";
import { outageControlsReducer } from "./outagesControlsReducer";
import { currentSystemReportReducer } from './currentSystemReportReducer';

export const rootReducer = combineReducers({
    outages: outageReducer,
    outageComparison: outageComparisonReducer,
    app: appReducer,
    outageChart: outageChartReducer,
    bulletins: bulletinReducer,
    subscriptions: subscriptionReducer,
    toggles: toggleReducer,
    bulletinSearch: searchReducer,
    outageControls: outageControlsReducer,
    heatValue: heatValueReducer,
    currentSystemReport: currentSystemReportReducer
});

export type RootState = ReturnType<typeof rootReducer>