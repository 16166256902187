import {ISubscription} from "../types";
import {
    SubscriptionActionTypes,
    CLEAR_SUBSCRIPTIONS_LOADED,
    SET_SUBSCRIPTIONS,
    SET_SUBSCRIPTIONS_LOADED,
} from "../types/SubscriptionActionTypes";

export function setSubscriptions(subscriptions: ISubscription[]): SubscriptionActionTypes {
    return {
        type: SET_SUBSCRIPTIONS,
        subscriptions: subscriptions
    }
}

export function setSubscriptionsLoaded(loaded: boolean): SubscriptionActionTypes {
    return {
        type: SET_SUBSCRIPTIONS_LOADED,
        loaded: loaded
    }
}

export function clearSubscriptionsLoaded(): SubscriptionActionTypes {
    return {
        type: CLEAR_SUBSCRIPTIONS_LOADED,
    }
}

