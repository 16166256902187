import fetchHelper from "../utilities/fetchHelper";
import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setPublishDates} from "../actions/outageComparisonActions";
import SortFunctions from "../utilities/SortFunctions";
import DateUtilities from "../utilities/DateUtilities";

function usePublishDates(): void {
    const dispatch = useDispatch();
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);

    // load publish dates if not already loaded
    useEffect(() => {
        if (!publishDates.loaded) {

            fetchHelper("outages/publisheddates", x => dispatch(setPublishDates((x??[]).sort(SortFunctions.DateSortFunction<string>(x => x, DateUtilities.ServiceDateUtility, false)))), dispatch);
        }
    }, [publishDates.loaded, dispatch]);


}

export default usePublishDates;