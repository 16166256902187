import {locale} from "../../locale";
import ArrayUtilities from "../ArrayUtilities";

export enum ValidationFunctionResult {
    FAIL,
    PASS,
    NOT_RUN
}

interface ISubValidation {
    validationFunction: (newValue: string) => ValidationFunctionResult;
    validationMessageKey: string | null;
    validationFunctionOverride?: (newValue: string, compareValue: string) => ValidationFunctionResult;
    validationFunctionOverrideCompareValue?: string;
}

export interface IValidationResult {
    isValid: boolean;
    message: string;
}

export default class Validation {
    public static EmptyValidation: Validation = new Validation([]);

    constructor(validations: ISubValidation[], defaultValidationMessageKey = "validation.outage.defaultMessage") {
        this.defaultValidationMessageKey = defaultValidationMessageKey;
        this.validationFunctions = validations;
    }

    validationFunctions: ISubValidation[];
    defaultValidationMessageKey: string;

    validate(newValue: string): IValidationResult {
        const validationResult: IValidationResult = {
            isValid: true,
            message : ""
        };
        let messages: string[] = [];
        for (const validationFunction of this.validationFunctions) {
            const result = validationFunction.validationFunctionOverride && validationFunction.validationFunctionOverrideCompareValue ?
                validationFunction.validationFunctionOverride(newValue, validationFunction.validationFunctionOverrideCompareValue) :
                validationFunction.validationFunction(newValue);

            if (result === ValidationFunctionResult.FAIL) {
                validationResult.isValid = false;
                if (validationFunction.validationMessageKey) {
                    messages.push(locale(validationFunction.validationMessageKey));
                }
            }
        }
        if (!validationResult.isValid && messages.length === 0)
        {
            messages = [locale(this.defaultValidationMessageKey)];
        }
        validationResult.message = ArrayUtilities.Unique(messages).join("\n");
        return validationResult;
    }
}
