import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";
import {FontSize} from "./FontStyles";
import {Chips} from "../types";

export const OutageCard = styled.div`
    @media ${device.desktop} {
        border: 1px solid #CFCDCE;
        margin: 10px;
        border-radius: 4px;
        width: 500px;
        display: inline-block;
    }

    @media ${device.tablet} {
        border: 1px solid #CFCDCE;
        margin: 5px;
        border-radius: 4px;
        width: 350px;
        display: inline-block;
    }
    
    @media ${device.mobile}, print {
        border: none;
        border-bottom: 2px solid ${colors.charcoal};
        margin: 0;
        border-radius: 0;
        width: 100%;
    }
`;


export const OutageCardWide = styled.div`
    @media ${device.desktop} {
        border: 1px solid #CFCDCE;
        margin: 5px;
        border-radius: 4px;
        display: inline-block;
        width: calc(100% - 25px);
    }

    @media ${device.tablet} {
        border: 1px solid #CFCDCE;
        margin: 5px;
        border-radius: 4px;
        display: inline-block;
        width: calc(100% - 25px);
    }
    
    @media ${device.mobile}, print {
        border: none;
        border-bottom: 2px solid ${colors.charcoal};
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
`;

export const OutageCardWideHeader = styled.div`
  display: flex;
  min-height: 30px;
  div {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
  }
  div:first-child > span {
    margin-right: auto;
  }
  div:last-child > span {
    margin-left: auto;
  }
  span {
    align-self: center;
  }
  
  @media print {
    div { display: none; }
    div:first-child {display: block;}
  }
`;

export const OutageCardContent = styled.div`
  border-bottom: 1px solid #CFCDCE;
  padding: 10px;
  color: #000000;
  background-color: #FFFFFF; 
  font-family: Roboto, sans-serif;
  ${FontSize("12px")};
  font-style: normal;
  font-weight: normal;
  vertical-align: Top;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-self: stretch;
  flex: 1 1 auto;
  
  @media print {
    padding: 2px;  
  }  
`;

export const OutageCardContentPlantTurnAround = styled(OutageCardContent)`
  cursor: default;
`;

export const OutageCardLine = styled.div`
  font-family: Roboto, sans-serif;
  ${FontSize("12px")};
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  vertical-align: Top;
  display: flex;
  flex-direction: row;
`;

interface IColorProp {
    color: string;
}

export const OutageCardHeader = styled(OutageCardLine)<IColorProp>`
  border-bottom: 1px solid #CFCDCE;
  background-color: ${props => props.color};  
  cursor: default;
  position: relative;
  height: 30px;
`;

export const OutageCardHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  h1 {
    font-family: Maven Pro;
    font-weight: 500;
    font-size: 18px;
    margin: 0 15px;
    justify-self: flex-start;
    flex: 1 1 auto;
  }
  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    justify-self: center;
    flex: 1 1 auto;
  }
`;

interface IGroupHeaderProperties {
    textColor: string;
    backgroundColor: string;
}

export const GroupHeader = styled.div<IGroupHeaderProperties>`
    background: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    padding: 8px;
    width: calc(100% - 16px);
    text-transform: uppercase;    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
`;

export const NoOutagesMessage = styled.div`
    padding: 8px;
    width: calc(100% - 16px);
`;

interface IComparedFieldProperties {
    hasChanged: boolean;
}

export const ComparedField = styled.span<IComparedFieldProperties>`
    background: ${props => props.hasChanged ? `${colors.changed}` : "transparent"};
    color: ${props => props.hasChanged ? "#FFFFFF" : "#000000"};
    border-radius: ${props => props.hasChanged ? "5px" : "0"};
    padding: ${props => props.hasChanged ? "4px" : "0"};
    font-style: normal;
    font-weight: normal;
    ${FontSize("14px")};
    line-height: 16px;
    margin: 4px 4px 4px 0;
    display: inline-block;
`;

export const ComparedFieldUnit = styled.span`
  font-size: 10px;
  color: ${colors.charcoal_text};
`;

export const OutagesListPublishDatesSelectorContainer = styled.div`
  @media ${device.desktop}{
    align-items: center;
  }
  @media ${device.tablet} {
    padding: 12px 0;
  }
  @media ${device.mobile} {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }  
  
  @media print {
    margin: 0;
  }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: fixed;
    top: 105px;
    width: calc(100vw - 115px);
    background-color: white;
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2 0 auto;
  padding: 0 20px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.mobile} {
    padding: 0 22px 0 22px;
  }
  @media print {
    padding: 0;
  }
`;

export const ViewAllHeaderInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewAllHeaderInfo = styled.span`
  font: 18px Roboto;
  font-weight: 300;
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

interface HeaderInfoBoxProps {
  isVisible: boolean;
}

export const HeaderInfoBox = styled.div<HeaderInfoBoxProps>`
  display: ${props => props.isVisible ? "flex" : "none"};  flex-direction: column;
  @media ${device.mobile}, print {
   padding: 8px 0;
  }
`;

export const Units = styled.span`
  font-size: 14px;
  color: ${colors.primary};
`;

export const HeaderText = styled.span`
    ${FontSize("14px")};
  color: ${colors.selected};
`;

export const HeaderInfo = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 18px;
`;

export const HeaderUnits = styled.span`
  color: ${colors.charcoal_text};
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 14px;
`;

export const HeaderNotes = styled.span`
  font-family: Roboto, sans-serif;
  ${FontSize("12px")};
  color: ${colors.selected};
`;

export const HeaderNotesCentered = styled(HeaderNotes)`
  text-align: center;
  @media ${device.mobile} {
    text-align: start;
    margin-bottom: 6px;
  }
`;

export const NotesContainer = styled.div`
  margin: 40px 10px;
  font-family: Roboto, sans-serif;
  ${FontSize("14px")};
  
  @media print {
    margin: 5px;
  }
`;

export const NotesHeader = styled.div`
  font-family: Maven Pro;
    ${FontSize("18px")};
  font-weight: 500;
  text-transform: uppercase;
`;

export const PreviousValueLabel = styled.div`
  color: ${colors.diff_red};
    ${FontSize("9px")};
  line-height: 10px;
`;

export const PreviousValue = styled.div`
  color: ${colors.diff_red};
  font-size: 11px;
  line-height: 13px;
`;

export const BackNavigationDetails = styled.div`
    margin-right: auto;
    height: 56px;   
    @media ${device.desktop}{
        padding-left: 275px;
    }  
    @media ${device.mobile}, print {
        padding: 0;
    }  
`;

interface OutageAreaGroupHeaderProps {
    color: string;
}

export const OutageGroupHeaderAreaName = styled.div`
   margin: auto 15px;
   font-weight: bolder;
   width: 300px;
   ${FontSize("18px")};
   padding: 0;
`;

export const OutageAreaGroupHeaderCapabilityChartLink = styled.div`
    margin: auto 15px;
    width: 225px;
   ${FontSize("12px")};
    padding: 0;
    div {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
        display: flex;
        flex-direction: row-reverse;
    }
    cursor: pointer;
    color: ${colors.primary}
    @media ${device.mobile}, print {
        display: none;
    }
`;

export const OutageAreaGroupHeaderBaseCapability = styled.div`
    margin: auto;
    padding-right: 75px;
    @media print {
        display: none;
    }
    @media ${device.mobile} {
      margin: auto 15px;
      padding-right: 0;
    }
`;

export const OutageAreaGroupHeaderHeatValue = styled.div`
    margin: auto 0;
    padding-right: 24px;
    text-align: end;
    @media print {
        display: none;
    }
    @media ${device.mobile} {
      margin: auto 15px;
      padding-right: 0;
      text-align: start
    }
`;

export const OutageAreaGroupHeaderSubHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${colors.charcoal_text};
`;

export const OutageAreaGroupHeader = styled.div<OutageAreaGroupHeaderProps>`
   background: ${p => p.color};
   min-height: 64px;
   border-top: 1px solid #000000;
   display: flex;
   flex-direction:row;
   
   @media print {
    height: 24px;
   }

   @media ${device.mobile} {
    flex-direction: column;
  }
`;

interface IOutageCardWideItemProps {
    width: string;
}

export const OutageCardWideItem = styled.div<IOutageCardWideItemProps>`
    width: ${props => props.width};
    
    @media print {
        width: calc(${props => props.width} * .8);
    }
`;

export const ChipWrapper = styled.div`
  display: flex;
`;

interface IChipProps {
    type: string;
}

export const Chip = styled.div<IChipProps>`
  background: ${props => {
      if (props.type === Chips.TSP_ELIGIBLE)
          return colors.orange;
      if (props.type === Chips.PARTIAL_IMPACT)
          return colors.diff_red;
      return colors.primary;
    }};
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  padding: 5px 16px;
  border-radius: 28px;
  margin: 6px;
  text-align: center;
`;

export const PlantTurnaroundCsvDownload = styled.div`
  margin: auto 15px;
  width: 225px;  
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 53px;
  color: ${colors.selected};
  border-top: 1px solid #CFCDCE;
  a, span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: ${colors.primary};
    margin: 0 0 16px 0;
    text-decoration: none;
    cursor: pointer;
  }
  @media ${device.mobile} {
    text-align: left;
  }
`;

export const PlantTurnaroundCsvDownloadHeader = styled.div` 
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 53px;
  color: ${colors.selected};
  a, span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: ${colors.primary};
    margin: 0 0 16px 0;
    text-decoration: none;
    cursor: pointer;
  }
  @media ${device.mobile} {
    display: none;
  }
`;