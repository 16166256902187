import React from 'react';
import * as S from '../styles';
import colors from '../styles/Colors';
import {locale} from '../locale';

const StatusLegend: React.FC = () => {
    return (
        <S.Legend>
            <S.LegendItem>
                <S.Dot color={colors.changed}/>
                <p>{locale('changed')}</p>
            </S.LegendItem>
            <S.LegendItem>
                <S.Dot color={colors.removed}/>
                <p>{locale('removed')}</p>
            </S.LegendItem>
            <S.LegendItem>
                <S.Dot color={colors.new}/>
                <p>{locale('new')}</p>
            </S.LegendItem>
        </S.Legend>
    );
};

export default StatusLegend;