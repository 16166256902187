import styled from 'styled-components';
import {ErrorBorderStyle} from "./index";
import {TableHeaderRow} from "./AdminStyles";
import colors from "./Colors";
import {device} from "./DeviceSizes";
import selectDownArrowImage from "../icons/select_down_arrow_disabled.svg";

export const BulletinInputLabel = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
`;

export const BulletinInputWrapper = styled.div`
  display: flex;
  margin: 7px 12px 7px 0;
`;


interface BulletinInputProps {
    isDirty?: boolean;
    hasError?: boolean;
}
export const BulletinInput = styled.input<BulletinInputProps>`
  height: 38px;
  min-width: 450px;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
  margin: 0;
  color: ${props => props.isDirty ? colors.changed : "#000000;"};
  border: ${props => (props.isDirty) ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
  ${props => props.hasError ? `
    border: ${ErrorBorderStyle} !important;
    background: ${colors.error_background} !important;
    color: black;
  ` : ""};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 7px;
  ::placeholder {
    color: ${colors.charcoal};
    font-size: 14px;
  }
`;

export const BulletinFormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const BulletinFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 118px;
`;

export const PreviewWrapper = styled.div`
  cursor: pointer;
`;

export const BulletinPreview = styled.div`
  position: fixed;
  width: 100%;
  height: 90vh;
  max-width: 1440px;
  margin: 0 auto;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
`;

export const BulletinFormWrapper = styled.div`
  padding: 20px 40px;
  max-width: 1400px;
`;

export const BulletinEditorWrapper = styled.div`
  max-width: 1440px;
`;

export const BulletinWrapper = styled.div`
  background: #ffffff;
  display: flex;
`;

interface BulletinWrapperProps {
    pos: number; //1 = top div, 2 = middle div, 3 = bottom div
    color?: string;
}

export const BulletinSubWrapper = styled.div<BulletinWrapperProps>`
  padding: ${props => props.pos === 1 ? '48px 48px 0 48px' : props.pos === 2 ? '10px 48px' : '0 48px 48px 48px'};
  background: ${props => props.color || '#FFFFFF'};
  @media ${device.mobile} {
    padding: ${props => props.pos === 1 ? '12px 12px 0 12px' : props.pos === 2 ? '0 12px' : '0 12px 12px 12px'};
  }
`;

export const BulletinHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 100%;
  justify-items: center;
  img {
    grid-column: 1 / span 1;
    justify-self: left;
  }
  span {
    grid-column: 2 / span 1;
    font-family: Maven Pro;
    font-size: 32px;
    line-height: 38px;
  }

  @media ${device.tablet} {
    img {
      grid-column: 1 /span 3;
      justify-self: center;
    }
    span {
      grid-column: 1 /span 3;
    }
  }

  @media ${device.mobile} {
    span {
      font-size: 24px;
    }
  }
`;

export const BulletinInfoRow = styled.div`
  display: flex;
  div {
    margin-right: 44px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  }
  span {
    font-weight: bold;
    color: #6E6E6F;
  }
  @media ${device.mobile} {
    flex-direction: column;
    div {
      margin: 0;
    }
    p {
      margin: 8px 0;
    }
  }
`;

export const BulletinBody = styled.div`
  font-family: Roboto, sans-serif !important;
  font-weight: 300;
  font-size: 18px;
  h1 {
    font-family: Maven Pro;
    font-weight: normal;
    font-style: normal;
    font-size: 32px;
    margin: 0;
  }
  h2 {
    font-family: Maven Pro;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    margin: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }
  p {
    margin: 0;
  }
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
  a {
    color: #1a72c8;
    text-decoration: none;
  }
  a:visited {
    color: #1a72c8;
  }
  margin: 0;
`;

export const BulletinSummary = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 20px 0;
  span {
    font-weight: bold;
    color: #6E6E6F;
  }
  p {
    margin: 4px 0;
  }
`;

export const ResultsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin: 0 10px;
  }
`;

export const ResultPerPageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-top: 6px;
  margin-left: 40px;
  @media ${device.mobile} {
    margin: 6px 12px;
    font-size: 14px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const SearchMessageSpan = styled.span`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: ${colors.diff_red};
`;

export const NoResultMessage = styled.div`
  display: flex;
  span {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
    flex: 1 1 auto;
    padding: 150px 0;
    text-align: center;
  }
`;

export const AdvancedSearchButton = styled.div`
  display: flex;

  justify-content: center;
  div {
    display: flex;
    align-items: center;
    color: ${colors.tc_blue};
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }
`;

export const AdvancedSearchDrawerWrapper = styled.div`
  background: #F5F5F5;
  padding-top: 36px;
  padding-bottom: 30px;
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const AdvancedSearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 25px 0;
  padding: 0 12px;
  flex-flow: row wrap;
  select {
    display: flex;
  }
  a {
    text-decoration: underline;
    color: ${colors.tc_blue};
  }
  a:hover {
    color: ${colors.selected};
  }
`;

export const AdvancedSearchOptions = styled.div`
  display: flex;
  flex-direction: column;
  span {
    text-align: start;
    font-family: "Maven Pro";
    font-size: 14px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
`;

export const PageNumberSelect = styled.select`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 4px 20px 4px 8px;
  border: 1px solid #AAA7A9;
  border-radius: 4px;
  appearance: none;
  background-image: url('${selectDownArrowImage}');
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: 16px 16px;
  background-clip: padding-box;
  background-color: #ffffff;
`;

export const BulletinNotAvalible = styled.span`
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B2282E;
`;

export const ListTableWrapper = styled.div`\
  padding: 16px;
  @media ${device.tablet} {
    overflow-x: scroll;
  }
  @media ${device.mobile} {
    overflow-x: scroll;
  }
`;
interface BulletinListTableHeaderProps {
  minWidth: string;
}

export const BulletinListTableHeaderRow = styled(TableHeaderRow)<BulletinListTableHeaderProps>`
  min-width: ${props => props.minWidth}px;
  border-bottom: solid #E5E5E5 1px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-align: center;
`;

export const BulletinListTableRow = styled(TableHeaderRow)<BulletinListTableHeaderProps>`
  min-width: ${props => props.minWidth}px;
  border-bottom: solid #E5E5E5 1px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;