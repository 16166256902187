import React from 'react';
import * as S from "../styles";
import arrow from "../icons/chevron_right.svg";
import {useHistory} from "react-router-dom";
import {OutageCompareBubbles} from "./OutageCompareBubbles";
import {AreaOutagesSummaryItem} from "../types/AreaOutagesSummary";

interface AreaOutagesCardLineProperties {
    isVisible: boolean;
    icon: string;
    labelText: string;
    onClick: () => void;
}

export const AreaOutagesCardLine: React.FC<AreaOutagesCardLineProperties> = (props) => {
    const clickHandler = () => {
        if (props.isVisible) {
            props.onClick();
        }
    };
    return <S.OutageAreaCardLine isVisible={props.isVisible} onClick={clickHandler}><div style={{width: "45px"}}><S.Icon src={props.icon}/></div><S.AreaOutageSummaryLineContent >{props.labelText}</S.AreaOutageSummaryLineContent><S.RightIcon src={arrow} /></S.OutageAreaCardLine>
};

interface AreaOutagesSummaryCardLineProperties {
    outages: AreaOutagesSummaryItem;
    area: string;
    icon: string;
    labelText: string;
}

interface AreaOutagesSummaryCardSectionLineProperties {
    outages: AreaOutagesSummaryItem;
    icon: string;
    labelText: string;
}

export const AreaOutagesSummaryCardSectionLine: React.FC<AreaOutagesSummaryCardSectionLineProperties> = (props) => {
    return <S.FlexContainerRowNoWrap>
        <S.OutageAreaCardLineIconContainer>
            <S.Icon src={props.icon}/>
        </S.OutageAreaCardLineIconContainer>
        <S.AreaOutageSummaryLineContent style={{fontWeight: "bold"}}>{props.outages.totalCount}</S.AreaOutageSummaryLineContent>
        <S.AreaOutageSummaryLineContent>{props.labelText}</S.AreaOutageSummaryLineContent>
        { Boolean(props.outages) && <S.AreaOutageSummaryLineContent style={{marginLeft: "auto", marginRight: "50px"}}><OutageCompareBubbles summary={props.outages}/></S.AreaOutageSummaryLineContent> }
    </S.FlexContainerRowNoWrap>
};


interface AreaOutagesSummaryCardLineProperties {
    outages: AreaOutagesSummaryItem;
    area: string;
    icon: string;
    labelText: string;
}

const AreaOutagesSummaryCardLine: React.FC<AreaOutagesSummaryCardLineProperties> = (props) => {
    const areaListUrl = `/OutagesList/${props.area}`;
    const history = useHistory();

    function handleClick() {
        // set current area w/ outages
        // navigate to current component
        history.push(areaListUrl);
    }

    if (!props.area || !props.outages) {
        return null;
    }

    return <S.OutageAreaCardLine onClick={handleClick}>
        <div style={{width: "45px"}}>
            <S.Icon src={props.icon}/>
        </div>
        <S.AreaOutageSummaryLineContent style={{fontWeight: "bold"}}>{props.outages.totalCount}</S.AreaOutageSummaryLineContent> {props.labelText}
        <div style={{marginLeft: "auto", display: "flex"}}>
            <OutageCompareBubbles summary={props.outages}/>
            <span style={{width: "50px"}}/>
            <S.RightIcon src={arrow} />
        </div>
    </S.OutageAreaCardLine>
};

export default AreaOutagesSummaryCardLine;
