import styled from "styled-components";
import {Map} from "react-leaflet";
import {device} from "./DeviceSizes";
import Colors from "../styles/Colors";
import {IBannerProps} from "../types";

interface LeaftMapProps {
    viewHeight?: number;
    hasBanner: boolean;
}

export const LeafletMap = styled(Map)<LeaftMapProps>`      
    height: 85vh;
    background: white;
    z-index: 0;
    @media ${device.desktop}{
        height: 86vh;
    }  
    @media ${device.tablet}{
        height: calc(${props => props.viewHeight || window.innerHeight}px - 168px);
        top: 76px;
    }   
    @media ${device.mobile}{
        height: calc(${props => props.viewHeight || window.innerHeight}px - ${props => props.hasBanner ? '189' : '148'}px);
        top: ${props => props.hasBanner ? '107px' : '56px'};
    }
`;

interface IOutageAreaIndicatorContainerProps {
    color: string;
}

export const OutageAreaIndicatorContainer = styled.div<IOutageAreaIndicatorContainerProps>`
  background-color: ${props => props.color.replace('0.5', '0.75')};
  border: 1px solid ${Colors.selected};
  border-radius: 4px;
 
  box-sizing: border-box; 
  @media ${device.desktop}{      
        padding: 10px;
    }  
    @media ${device.tablet}{      
         padding: 10px;
    }  
    @media ${device.mobile}{    
         padding: 4px;
    }
`;

export const OutageAreaIndicatorSubContainer = styled.div`
    @media ${device.desktop}{      
        min-width: 115px;
    }  
    @media ${device.tablet}{      
        min-width: 115px;
    }  
    @media ${device.mobile}{    
        min-width: 85px;
    }
`;

export const OutageAreaIndicatorHeader = styled.div`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;  
    text-transform: uppercase;
    color: #000000;
     
    @media ${device.desktop}{
       font-size: 18px;
       line-height: 21px;
    }  
    @media ${device.tablet}{
       font-size: 18px;
       line-height: 21px;
    }  
    @media ${device.mobile}{
       font-size: 14px;
       line-height: 16px;
    }
`;

export const OutageAreaIndicatorLine = styled.div`
   display: flex;
   flex-direction: row;   
   align-items: center;
`;

export const OutageAreaIndicatorLineIconContainer = styled.div`
   display: flex;  
    @media ${device.desktop}{
       width: 24px;
    }  
    @media ${device.tablet}{
        width: 24px;
    }  
    @media ${device.mobile}{
       width: 18px;
    }
`;

export const OutageAreaIndicatorStatus = styled.div`
    margin-top: 2px;
    font-style: normal;
    font-weight: normal;  
    color: #000000;
    @media ${device.desktop}{
        font-size: 10px;
        line-height: 11px;
        padding-left: 4px;
    }  
    @media ${device.tablet}{
        font-size: 10px;
        line-height: 11px;
        padding-left: 4px;
    }  
    @media ${device.mobile}{
        font-size: 8px;
        line-height: 9px;
        padding-left: 2px;
    }
`;

export const OutageAreaIndicatorNumber = styled.div`
    font-style: normal;
    font-weight: bold;     
    
    @media ${device.desktop}{      
      font-size: 14px;
      line-height: 16px;
      padding-left: 4px;
    }  
    @media ${device.tablet}{      
       font-size: 14px;
       line-height: 16px;
       padding-left: 4px;
    }  
    @media ${device.mobile}{    
        font-size: 12px;
        line-height: 14px;
        padding-left: 4px;
    }
`;

export const OutageMapPublishDatesSelectorContainer = styled.div<IBannerProps>`
    position: absolute;
    top: ${props => props.hasBanner ? "109px;": "76px;"};
    background:transparent;
    z-index: 5;
    @media ${device.mobile}{
      background: #ffffff;
      top: ${props => props.hasBanner ? "108px;": "56px;"};
      width: 100vw;
    }
`;

export const LocalAreaDisclaimerMap = styled.div<IBannerProps>`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-size: 12px;
  text-align: center;
  color: ${Colors.primary};
  cursor: pointer;
  position: absolute;
  top: ${props => props.hasBanner ? "171px": "138px"};
  right: 0;
  z-index: 5;
  padding: 12px;
  margin: 12px 0;
  background: #FFFFFF;
  @media ${device.mobile} {
    display: none;
  }
`;
