import styled, {css, createGlobalStyle} from "styled-components";
import {device} from "./DeviceSizes";
import colors from './Colors';
import selectDownArrowImage from "../icons/select_down_arrow.svg";
import {FontSize} from "./FontStyles";
import {IBannerProps} from "../types";
export * from "./BadgeStyles";
export * from "./BulletinStyle";
export * from "./OutageStyles";
export * from "./OutageMapStyles";
export * from "./DropDownStyle";
export * from "./MenuStyle";
export * from "./AreaSummaryList";
export * from "./OutagesList";
export * from "./ContactUsStyle";
export * from "./LegendStyle";
export * from "./ImportantLinksStyle";
export * from "./ModalStyles";
export * from "./AdminStyles";
export * from "./ChartStyles";
export * from "./MoreOptionsStyles";
export * from "./SettingsStyles";
export * from "./CookiesDisclaimerStyle";
export * from "./WidgetStyles";
export * from "./DashboardStyles";
export * from "./SubscriptionStyles";
export * from "./BannerStyle";
export * from  "./DashboardStyles";
export * from "./TooltipStyles";
export * from "./SubscribeBannerStyles";
export * from "./Error404Styles";
export * from "./MaintenanceStyles";
export * from "./CurrentSystemReportStyle";
export * from "./GasDaySummaryReportStyles";

export const GlobalStyles = createGlobalStyle`
  *:focus {
    outline: none;
  }
  @-webkit-keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    @-webkit-keyframes slide-out-top {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
        opacity: 0;
      }
    }
    @keyframes slide-out-top {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
      }
    }

`;
export const AppBody = styled.div`
   display: flex;
   flex: 1 1 auto;
   min-height: 90vh;
   background-color: ${colors.background};
   Font-Family: 'Roboto', sans-serif;
   @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 64px;
   }
`;

export const AppContent = styled.div`
    flex: 12;
    align-items: stretch;
    align-self: flex-start;
    width: 100vw;  //Removing width temporarily
`;

export const H1 = styled.h1`
    margin: 0;
    padding: 8px;
    Font-Family: Maven Pro;
    text-transform: uppercase;
    @media ${device.desktop} {
      font-size: 48px;
    }
    @media ${device.tablet} {
      font-size: 36px;
    }
    @media ${device.mobile} {
      font-size: 24px;
    }
`;

export const A = styled.a`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  color: ${colors.primary};
  margin: 0 0 16px 0;
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: ${colors.primary};
  }
  &:hover, span:hover {
    color: ${colors.selected};
  }
`;

export const PageWithNavigationContainer = styled.div<IBannerProps>`
    margin-top: ${props => {
        let base = 76;
        
        if (props.hasBanner) {
            base += 34;
        }

        if (props.hasStickyControls) {
            base += 79;
        }
        return base + "px";
    }};
    @media ${device.mobile}{
      margin-top: ${props => {
          let base = 57;

          if (props.hasBanner) {
              base += 51;
          }
          if (props.hasStickyControls) {
              base += 134;
          }
          return base + "px";
      }};
    }
    @media print {
      margin-top: 0;
    }
`;

export const Back = styled.div`
    text-align: left;
    padding: 10px;
    margin: 10px;
`;

export const CenterText = styled.p`
   text-align: center;
   padding: 10px;
   margin: 10px;
`;

export const CenteredH2 = styled.h2`
   text-align: center;
`;

export const CenteredPageTitle = styled.div`
  font-family: Maven Pro;
  font-style: normal;
  font-weight: normal;
  ${FontSize("48px", "24px")};
  line-height: 56px;
  text-transform: uppercase;
  color: #555759;
  position: fixed;
  left: 50vw;
  transform: translate(-50%, 0);

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.mobile} {
    font-size: 24px;
    width:max-content;
  }
`;

export const LoadingH2 = styled.h2`
   text-align: center;
   position: absolute;
   top: 0;
   left: 50%;
`;

export const Footer = styled.div`
   padding: 42px 25px;
   font-size: 12px;
   font-style: italic;
   p {
    padding-right: 10px;
   }
   a {
    color: ${colors.primary};
    text-decoration: none;
   }
   a:hover {
    text-decoration: underline;
   }
   @media print {
    display: none;
   }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 4px 0;
`;

export const TextSmall = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  color: ${colors.secondary};
  margin: 0;
`;

interface TextProps {
    selected?: boolean;
}

export const UpperText = styled(Text)<TextProps>`
  text-transform: uppercase;
  color: ${props => props.selected ? colors.selected: colors.primary || colors.primary};
`;

export const PanelHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #CFCDCE;
  img {
    height: 100%;
    margin: 8px;
  }
`;

export const Icon = styled.img`
  margin: 5px;
`;


export const BackIcon = styled(Icon)`
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;
`;

export const MoreOptionsIcon = styled(Icon)`
   margin: auto 10px;
`;

export const RightIcon = styled(Icon)`
  margin-left: auto;
`;

export const ClickableIcon = styled.img`
  cursor: pointer;
`;

export const LargeIcon = styled(ClickableIcon)`
  width: 40px;
  height: 40px;
`;

export const LoadingIcon = styled(LargeIcon)`
  animation: spin 3s linear infinite;
  cursor: default;
  @keyframes spin {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
  }
`;

interface BackNavigationHeaderProps {
    isHiddenMobile?: boolean;
    backgroundColor?: string;
    leftAligned?: boolean;
}

export const BackNavigationHeader = styled.div<BackNavigationHeaderProps>`
  position: fixed;
  top: 0;
  z-index: 997;
  width: 100%;
  background: ${props => props.backgroundColor};

  @media ${device.mobile}{
    display: ${props => props.isHiddenMobile ? 'none' : 'flex'};
    padding: 0 12px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
    height: 56px !important;
    border-bottom: 1px solid #CFCDCE;
    height: auto;
    img {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  @media ${device.desktop} {
    left: 97px;
    width: calc(100% - 97px);
  }

  display: flex;
  flex-flow: row;
  padding: 5px 0;
  height: 65px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor: '#F5F5F5'};
  border-bottom: 1px solid #CFCDCE;
  cursor: default;
  justify-content: ${props => props.leftAligned ? "flex-start" : "flex-end"};

  div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    div {
      height: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @media print {
      height: 25px;
      position: static;
      width: auto;
      display: flex;
      flex-flow: row;
      align-content: space-around;
      margin: 0;
      top: 0;


      div {
        position: static;
        display: flex;
        margin: auto;
        transform: none;
      }

      div:last-child {
        display: none;
      }

  }
`;

export const HeaderBackground = styled(BackNavigationHeader)`
  background-color: #ffffff;
  z-index: 0;
  @media print {
    display: none;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const BackNavigationCenterContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
`;

interface Img {
    src: string;
    position?: string;
}

export const BackgroundImage = styled.div<Img>`
  background: url(${props => props.src});
  height: 320px;
  background-position: center ${props => props.position};
  background-size: cover;
  background-repeat: no-repeat;
  @media ${device.mobile}{
    height: 80px;
  }
`;

interface Dot {
    color: string;
    textColor?: string;
}

export const Dot = styled.span<Dot>`
  background-color: ${props => props.color};
  height: 16px;
  width: 16px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-style: normal;
  margin-top: 3px;
  color: ${props => props.textColor};
`;

export const FlexContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const FlexContainerRowSpaced = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const FlexContainerRowNoWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

export const FlexContainerColumnNoWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 1 auto;
`;

export const FlexContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;
`;

export const FlexContainerColumnNoWrapPrintAsRow = styled(FlexContainerColumnNoWrap)`
  @media print {
    flex-direction: row;
    padding: 0;
    width: auto;
  }
`;

export const BlockContainer = styled.div`
  display: block;
`;

interface InfoFieldProps {
    minWidth: string;
    width?: string;
    isHidden?: boolean;
}

export const InfoField = styled.div<InfoFieldProps>`
    min-width: ${props => props.minWidth};
    ${props => props.width ? `width: ${props.width};` : ""};
    margin-top: 16px;
    display: ${props => props.isHidden ? "none" : "flex"};
    flex: 1;
`;

const ButtonStyle = css`
  background: ${colors.primary};
  border-radius: 4px;
  border-color: transparent;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  min-width: 96px;
  height: 32px;
  margin: 0 8px;
  cursor: pointer;
`;

export const Button = styled.button`
  ${ButtonStyle}
`;

export const AssumptionsButton = styled.button`
  ${ButtonStyle};
  margin: 0;
  height: 33px;
`;

export const FileUploadLabel = styled.label`
  ${ButtonStyle};
  padding: 10px;
  margin: 5px;
  height: 12px;
  display: inline-block;
`;

export const SaveButton = styled(Button)`
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background-color: ${colors.charcoal};
    cursor: auto;
    :hover {
      opacity: 1;
    }
  }
`;

export const SubscribeSaveButton = styled(SaveButton)`
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SecondaryAction = styled.div`
  color: #1A72C8;
  border-radius: 4px;
  border-color: transparent;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  min-width: 96px;
  height: 32px;
  display: flex;
  margin-right: 50px;
  div {
    margin: auto;
    cursor: pointer;
  }
`;

export const LinkButton = styled(SecondaryAction)`
  margin: 5px;
  div {
    margin: auto;
  }
`;

interface SelectProps {
    hasError?: boolean;
    isPlaceholder?: boolean;
}

export const Select = styled.select<SelectProps>`
    display: block;
    width: 100%;
    padding: 4px 20px 4px 5px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
    color: ${props => props.isPlaceholder ? '#8C878A' : '#495057'};
    font-style: ${props => props.isPlaceholder ? 'italic' : ''};
    appearance: none;
    background-image: url('${selectDownArrowImage}');
    background-repeat: no-repeat, repeat;
    background-position: right 5px top 50%, 0 0;
    background-size: .65em auto, 100%;
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  ${props => props.hasError ? `
    border: ${ErrorBorderStyle} !important;
    background-color: ${colors.error_background} !important;
    color: black;
  ` : ""};
  }
  option {
    color: #000000;
    font-style: normal;
    &:disabled {
      color: #8C878A;
    }
  }

    @media ${device.mobile}, print {
      font-size: 100%;
    }
    @media print {
      padding: 3px;
      border: none;
      background: none;
      width: auto;
    }
`;

export const PublishDatesSelectorLabel = styled.div`
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding-left: 5px;
    padding-bottom: 2px;
    color: #555759;
    @media ${device.mobile}, print {
      font-size: 12px;
      font-weight: normal;
    }
    @media print {
      margin-top: auto;
      margin-bottom: auto;
    }
`;

interface PagingComponentProps {
    isEnabled: boolean;
    isActive: boolean;
    isHidden?: boolean;
}

export const PagingComponent = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
`;

export const PagingComponentLink = styled.span<PagingComponentProps>`
    color: ${p => (p.isActive) ? "#1A72C8" : "#6E6E6F"};
    cursor: ${p => (p.isEnabled) ? "pointer" : "default"};
    visibility: ${p => (p?.isHidden) ? "hidden" : "visible"};
    margin: 5px;
    line-height: 14px;
    font-size: 12px;
`;

export const HiddenOnMobile = styled.div`
  @media ${device.mobile}{
    display: none !important;
  }
`;

export const MobileOptions = styled.div`
  position: fixed;
  @media ${device.tablet}{
    display: none;
  }
  @media ${device.desktop} {
    display: none;
  }
  @media ${device.mobile}{
    display: flex;
    bottom: 90px;
    right: 0;
    z-index: 6;
   }
`;

export const PublishDateForm = styled.form`
  @media ${device.mobile}, print {
    width: 100%;
  }
`;

export const DisplayUnitForm = styled.form`
  margin-left: auto;
  @media ${device.mobile} {
    margin-top: auto;
  }
  @media print {
    width: 100%;
  }
`;

export const WhitespacePreserve = styled.div`
  white-Space: pre-line;
`;

export const ErrorBorderStyle = `solid 1px ${colors.error_border}`;

export const ErrorLabel = styled.small`
    color: #ff0000;
    font-size: 10px;
    font-style: italic;
    min-height: 13px;
`;

interface SortHeaderProps {
  isSorted?: boolean;
}

export const GenericTableCellHeaderText = styled.div<SortHeaderProps>`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: ${p => (p.isSorted) ? colors.tc_blue_100 : colors.charcoal};
    text-align: center;
    img {
      filter: ${p => (p.isSorted) ? "invert(33%) sepia(98%) saturate(1251%) hue-rotate(190deg) brightness(85%) contrast(86%)" : ""}
    }
`;

export const GenericTableRowContent = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

interface SearchProps {
  isDirty?: boolean;
}

export const SearchBar = styled.form`
  height: 40px;
  width: 80%;
  display: flex;
  margin: 60px auto 15px auto;
  select {
    flex: 3 3 auto;
    height: 40px;
    margin-right: 12px;
    min-width: 175px;
  }
  input {
    flex: 1 1 auto;
  }
`;

export const SearchBarInput = styled.input<SearchProps>`
  width: 100%;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  line-height: 16px;
  font-size: 14px;
  margin: 0;
  color: ${props => props.isDirty ? colors.changed : "#000000;"};
  border: ${props => (props.isDirty) ? "1px solid " + colors.changed : "1px solid #CFCDCE"};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 7px;
  padding-right: 30px;
  ::placeholder {
    color: #8C878A;
    font-style: italic;
    font-weight: 300;
  }
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;

`;

export const SearchButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  min-width: 110px;
  max-width:110px;
  height: 100%;
  background-color: white;
  color: ${colors.tc_blue};
  border: 1px solid #CFCDCE;
  margin: 0;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e6e6e6
  }
`;

export const BlueSearchButton = styled(SearchButton)`
  background-color: ${colors.tc_blue_100};
  color: white;
  &:hover {
    background-color: #1d80e1
  }
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media ${device.mobile} {
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
    justify-content: center;
  }
`;

export const SearchResetButton = styled.button`
    width: 130px;
    height: 100%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items:center;
    text-align: center;
    background-color: ${colors.background};
    border: 0;
    color: ${colors.tc_blue_100};
    @media ${device.mobile} {
      width: 100%;
      margin: 12px 0;
      align-self: center;
    }
`;

export const SearchBarInputContainer = styled.div`
  position: relative;
  max-width: 430px;
  width: 100%;
  @media ${device.mobile} {
    max-width: 100%;
    margin: 0 0 24px 0;
  }
`;

export const TimezoneIndicator = styled.div`
  padding: 7px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-style: italic;
`;