import {IArea, ICapability, ILocation, IOutageBase, IOutageForDateSort, IShowingChartKeys} from "../types";
import DateUtilities, {IDateUtility} from "./DateUtilities";

class SortFunctions {
    static OutageEndDateSortFunction(isAsc = true, dateHandler: IDateUtility = DateUtilities.ServiceDateUtility) {
        return this.DateSortFunction<IOutageForDateSort>(x =>  DateUtilities.Parse(x.endDateTime).isValid ? x.endDateTime : x.startDateTime, dateHandler, isAsc);
    }

    static OutageStartDateSortFunction(isAsc = true, dateHandler: IDateUtility = DateUtilities.ServiceDateUtility) {
        return this.DateSortFunction<IOutageForDateSort>(x =>  DateUtilities.Parse(x.startDateTime).isValid ? x.startDateTime : x.endDateTime, dateHandler, isAsc);
    }

    static OutagesAreaSortFunction(isAsc = true) {
        return SortFunctions.IntegerSortFunction<IOutageBase>(o => o.area.sortOrder, isAsc);
    }

    static CompositeSortFunction<T>(sortFunctions: ((a: T, b: T) => number)[]): ((a: T, b: T) => number) {
        return ((a, b) => {
            const returnValue = 0;
            for (const sortFunction of sortFunctions) {
                const value = sortFunction(a, b);
                if (value !== 0) {
                    return value;
                }
            }
            return returnValue;
        });
    }

    static DefaultAreaSortFunction(isAsc = true) {
        return SortFunctions.IntegerSortFunction<IArea>(o => o.sortOrder, isAsc);
    }

    static LocationSortFunction() {
        return SortFunctions.IntegerSortFunction<ILocation>(o => o.id, true);
    }

    static CapabilitySortFunction() {
        return this.DateSortFunction<ICapability>(x => x.startDate, DateUtilities.ServiceDateUtility, true);
    }

    static ChartKeySortFunction(isAsc = true) {
        return this.IntegerSortFunction<IShowingChartKeys>(x => x.order, isAsc);
    }

    static DefaultOutageSortFunction(dateHandler: IDateUtility = DateUtilities.ServiceDateUtility) {
      return this.CompositeSortFunction([
                SortFunctions.OutagesAreaSortFunction(),
                SortFunctions.OutageStartDateSortFunction(true, dateHandler),
                SortFunctions.OutageEndDateSortFunction(true, dateHandler),
            ])
    }

    static IntegerSortFunction<T>(numberGetter: (obj: T) => number, isAsc = true) {
        return (a: T, b: T) => {
            return (numberGetter(a) - numberGetter(b)) * (isAsc ? 1 : -1);
        };
    }

    static StringSortFunction<T>(stringGetter: (obj: T) => string, isAsc: boolean) {
        return (a: T, b: T) => {
            return stringGetter(a).localeCompare(stringGetter(b)) * (isAsc ? 1 : -1);
        };
    }

    static DateSortFunction<T>(stringGetter: (obj: T) => string, handler: IDateUtility, isAsc: boolean) {
        return (a: T, b: T) => {
            return handler.DateComparer(stringGetter(a), stringGetter(b)) * (isAsc ? 1 : -1);
        };
    }
}

export default SortFunctions;