import React, {useEffect, useState} from "react";
import * as S from '../../styles';
import BackNavigationHeader from "../BackNavigationHeader";
import {DateTime} from "luxon";
import {locale} from "../../locale";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {FileDownloadUtilities} from "../../utilities/FileDownloadUtilities";
import DateInput from '../DateInput';
import DateUtilities from "../../utilities/DateUtilities";
import Validation from "../../utilities/validation/Validation";
import {IExportChartDataForm, IHasValidationResults} from "../../types";
import ChartExportValidation from "../../utilities/validation/ChartExportValidation";


const AdminChartExport: React.FC = () => {
    console.info( DateTime.local().minus({months: 18}).toFormat(DateUtilities.serviceDateOnlyFormat));
    const initialState: IExportChartDataForm = {
        startDate: DateTime.local().minus({months: 18}).toFormat(DateUtilities.serviceDateOnlyFormat),
        endDate: DateTime.local().plus({months: 6}).toFormat(DateUtilities.serviceDateOnlyFormat),
        validationResults: {
            startDate: {
                message: '',
                isValid: true
            },
            endDate: {
                message: '',
                isValid: true
            }
        }
    };

    const history = useHistory();
    const dispatch = useDispatch();
    const [exportForm, setExportForm] = useState<IExportChartDataForm>(initialState);
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    useEffect(() => {
        let hasValidationErrors = false;
        if (exportForm.validationResults['startDate'].isValid !== undefined && !exportForm.validationResults['startDate'].isValid) {
            hasValidationErrors = true;
        }

        if (exportForm.validationResults['endDate'].isValid !== undefined && !exportForm.validationResults['endDate'].isValid) {
            hasValidationErrors = true;
        }

        setHasErrors(hasValidationErrors);
    }, [exportForm]);

    const exportClicked = () => {
        FileDownloadUtilities.initiateCsvFileDownload(`chart/admin/csv?start=${exportForm.startDate}&end=${exportForm.endDate}`, dispatch);
    };

    const reValidateStartDate = (newDate: string) => {
        exportForm.validationResults["startDate"] =
            ChartExportValidation.StartDateValidation(exportForm.startDate, newDate).validate(exportForm.startDate);
    };

    const reValidateEndDate = (newDate: string) => {
        exportForm.validationResults["endDate"] =
            ChartExportValidation.EndDateValidation(exportForm.endDate, newDate).validate(exportForm.endDate);
    };


    const updateInputs = (exportForm: IExportChartDataForm, fieldName: string, newValue: string, validation: Validation | null = null) => {
        validateInput(exportForm, fieldName, newValue, validation);

        if (fieldName === "endDate") {
            reValidateStartDate(newValue);
        }
        if (fieldName === "startDate") {
            reValidateEndDate(newValue);
        }
        setExportForm({
            ...exportForm,
            [fieldName]: newValue
        });
    };

    const validateInput = (exportForm: IExportChartDataForm, fieldName: string, newValue: string, validation: Validation | null = null) => {
        const validationResults = exportForm.validationResults;
        if (validation) {
            validationResults[fieldName] = validation.validate(newValue);
        }
    };

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : "";
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('admin.options.exportChartData')}/>
            <S.ChartExportContainer>
                <S.ChartExportRow style={{marginBottom: '30px'}}>
                    {locale('export.description')}
                </S.ChartExportRow>
                <S.ChartExportRow >
                    <S.ChartExportArea>EGAT:</S.ChartExportArea>
                    {locale('export.areaDescriptionWithContracts')}
                </S.ChartExportRow>
                <S.ChartExportRow>
                    <S.ChartExportArea>AB/BC:</S.ChartExportArea>
                    {locale('export.areaDescriptionWithContracts')}
                </S.ChartExportRow>
                <S.ChartExportRow>
                    <S.ChartExportArea>USJR:</S.ChartExportArea>
                    {locale('export.areaDescription')}
                </S.ChartExportRow>
                <S.ChartExportRow>
                    <S.ChartExportArea>OSDA:</S.ChartExportArea>
                    {locale('export.areaDescription')}
                </S.ChartExportRow>
                <S.ActionButtonContainer style={{justifyContent: 'flex-start', marginTop: '80px'}}>
                    <S.ChartExportValue>
                        <label>{locale('export.start')}</label>
                        <DateInput
                            date={exportForm.startDate ? DateTime.fromISO(exportForm.startDate).toJSDate() : null}
                            placeholderText={''}
                            handleChange={(date: Date) => updateInputs(exportForm, 'startDate', date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.serviceDateOnlyFormat): '', ChartExportValidation.StartDateValidation(date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.serviceDateOnlyFormat): '', exportForm.endDate))}
                            hasError={hasError(exportForm, "startDate")}
                            errorMessage={errorMessage(exportForm, "startDate")}
                            showYearSelect={true}
                            showMonthSelect={true}
                            isClearable={false}
                            maxDate={DateTime.local().plus({months: 6}).toJSDate()}
                            minDate={new Date(2012, 0, 1)}
                        />
                    </S.ChartExportValue>
                    <S.ChartExportValue>
                        <label>{locale('export.end')}</label>
                        <DateInput
                            date={exportForm.endDate ? DateTime.fromISO(exportForm.endDate).toJSDate() : null}
                            placeholderText={''}
                            handleChange={(date: Date) => updateInputs(exportForm, 'endDate', date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.serviceDateOnlyFormat): '', ChartExportValidation.EndDateValidation(date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.serviceDateOnlyFormat): '', exportForm.startDate))}
                            hasError={hasError(exportForm, "endDate")}
                            errorMessage={errorMessage(exportForm, "endDate")}
                            showYearSelect={true}
                            showMonthSelect={true}
                            isClearable={false}
                            maxDate={DateTime.local().plus({months: 18}).toJSDate()}
                            minDate={new Date(2012, 0, 1)}
                        />
                    </S.ChartExportValue>
                    <S.SaveButton
                        style={{marginTop: 'auto'}}
                        disabled={hasErrors}
                        onClick={exportClicked}>{locale('export')}
                    </S.SaveButton>
                    <S.SecondaryAction style={{marginTop: 'auto'}}>
                        <div onClick={() => history.push('/Admin')}>{locale('cancel')}</div>
                    </S.SecondaryAction>
                </S.ActionButtonContainer>
            </S.ChartExportContainer>
        </S.PageWithNavigationContainer>
    )
};

export default AdminChartExport;
