import {IOutageComparison, Status} from "../types";
import {DateTime} from "luxon";

class OutagePredicates {
    private static mountainTime = "America/Boise";
    private static format = "yyyy-MM-dd HH:mm:ss";

    private static getDateTimes(outage: IOutageComparison): {start: DateTime; end: DateTime; publish_date_in_local: DateTime} {
        return {
            start:DateTime.fromFormat(outage.startDateTime, this.format, {zone: this.mountainTime}).startOf('day'),
            end:DateTime.fromFormat(outage.endDateTime, this.format, {zone: this.mountainTime}).startOf('day'),
            publish_date_in_local:DateTime.fromFormat(outage.publishedDateTimeUtc, this.format, {zone: "UTC"}).toLocal().startOf('day'),
        };
    }

    static IsRemoved(outage: IOutageComparison): boolean {
        const dateTimes = OutagePredicates.getDateTimes(outage);

        return outage.changeStatus === Status.REMOVED || dateTimes.end < dateTimes.publish_date_in_local; // just putting completed ones with "removed" for now until we have a decision.
    }

    static IsCurrentOutage(outage: IOutageComparison): boolean {
        const dateTimes = OutagePredicates.getDateTimes(outage);

        return (!dateTimes.end.isValid || dateTimes.end >= dateTimes.publish_date_in_local) && (dateTimes.start.isValid && (dateTimes.start <= dateTimes.publish_date_in_local));
    }

    static IsUpcomingOutage(outage: IOutageComparison): boolean {
        const dateTimes = OutagePredicates.getDateTimes(outage);

        if (!dateTimes.start.isValid) {
            return true;
        }

        return (!dateTimes.end.isValid || dateTimes.end > dateTimes.publish_date_in_local) && ((dateTimes.start.isValid) && (dateTimes.start > dateTimes.publish_date_in_local));
    }

    static IsUpcomingOutage30Days(outage: IOutageComparison): boolean {
        const dateTimes = OutagePredicates.getDateTimes(outage);

        return (!dateTimes.end.isValid || dateTimes.end > dateTimes.publish_date_in_local) && ((dateTimes.start.isValid) && (dateTimes.start > dateTimes.publish_date_in_local) && (dateTimes.start <= dateTimes.publish_date_in_local.plus({days: 30})));
    }

    static IsUpcomingOutageAfter30Days(outage: IOutageComparison): boolean {
        const dateTimes = OutagePredicates.getDateTimes(outage);

        if (!dateTimes.start.isValid) {
            return true;
        }

        return (!dateTimes.end.isValid || dateTimes.end > dateTimes.publish_date_in_local) && ((dateTimes.start.isValid) && (dateTimes.start > dateTimes.publish_date_in_local) && (dateTimes.start > dateTimes.publish_date_in_local.plus({days: 30})));
    }
}

export default OutagePredicates;