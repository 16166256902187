import styled from "styled-components"
import { device } from "./DeviceSizes";

interface IOutageControlsContainerProps {
    isOpen: boolean;
}

interface OutageControlContainerProps {
    hasBanner: boolean;
}

export const OutageControlsContainer = styled.div<OutageControlContainerProps>`
    position: fixed;
    align-self: flex-start;
    width: calc((100vw - (100vw - 100%)) - 97px);
    top: ${props => props.hasBanner ? '109px' : '76px'};
    z-index: 996;
    padding-right: 0;
    padding-left: 0;
    @media ${device.tablet} {
        width: calc(100vw - (100vw - 100%));
        top: ${props => props.hasBanner ? '114px' : '76px'};
    }
    @media ${device.mobile} {
      top: ${props => props.hasBanner ? '108px' : '56px'};
    }

`;

export const OutageControlsInner = styled.div<IOutageControlsContainerProps>`
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    justify-content: space-between;
    -webkit-animation: ${props => props.isOpen ? "slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" : "slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"};
    animation: ${props => props.isOpen ? "slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" : "slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"};
    
    @media ${device.mobile} {
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 10px;
        
    }
    @media ${device.tablet} {
        height:75px;
        padding-right: 5px;
        padding-left: 5px;
       
        
    }
`;

export const RightControlsContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 200px;
    @media ${device.mobile} {
        display: block;
        min-width: auto;
        order: 3;
    }
`;