import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router }from "react-router-dom";
// import { BrowserRouter as Router }from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {I18n} from '@aws-amplify/core';
import {translations} from "./locale/";
import {rootReducer} from './reducers';
import RouterScrollToTop from "./utilities/ScrollUtilities";

I18n.putVocabularies(translations);
// uncomment to set override browser language to specific language
// I18n.setLanguage('fr');

const store = createStore(
    rootReducer,
    /*Setting to view store using redux devtools addon, available on both Chrome and Firefox*/
    (window as any).window.__REDUX_DEVTOOLS_EXTENSION__ && (window as any).window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={store}>
        <Router hashType={"noslash"}>
            <RouterScrollToTop/>
            <App/>
        </Router>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store
