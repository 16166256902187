import {css} from "styled-components";

export const FontSize = function(size: string, printSize: string | null = null) {
    printSize = printSize ? printSize : `calc(${size}*.8)`;

    return css`
    font-size: ` + size + `;
    line-height: calc( ` + size + `*1.1);
    
    @media print {
        font-size:`+ printSize +` !important;
        line-height: calc( ` + printSize + `*1.1);
    }`;
};
