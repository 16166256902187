import {
    SET_IS_OPEN,
    SET_HAS_ENDED_INTERACTING,
    SET_SCROLL_END_REF,
    SET_HIDE_REF,
    OutageControlsActionTypes
} from "../types/OutageControlsActionTypes";

export function setIsOpen(isOpen: boolean): OutageControlsActionTypes {
    return {
        type: SET_IS_OPEN,
        isOpen
    }
}

export function setHasEndedInteracting(hasEndedInteracting: boolean): OutageControlsActionTypes {
    return {
        type: SET_HAS_ENDED_INTERACTING,
        hasEndedInteracting
    }
}

export function setScrollEndRef(ref: number): OutageControlsActionTypes {
    return {
        type: SET_SCROLL_END_REF,
        ref
    }
}

export function setHideRef(ref: number): OutageControlsActionTypes {
    return {
        type: SET_HIDE_REF,
        ref
    }
}