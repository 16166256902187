import { I18n } from '@aws-amplify/core'
import en from './languages/en';

export const translations: any = {en};

export const locale = (key: string) => {
  const defaultEnglish = en[key];
  if (!defaultEnglish)
    console.log(`Missing key found for: ${key}`);
  return I18n.get(key, defaultEnglish);
};