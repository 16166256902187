interface En {
    [key: string]: string;
}

const en: En = {
    "action": "Action",
    "addNewEntry": "Add a new entry",
    "adminAccountInformation": "Account information",
    "admin.mainlineDailyCapabilities.capacityPercentage":"Capacity Factor (%)",
    "admin.mainlineDailyCapabilities.heatValue":"Heating Value (MJ/m³)",
    "admin.mainlineDailyCapabilities.primaryDisclaimer":"Primary Disclaimer",
    "admin.mainlineDailyCapabilities.secondaryDisclaimer":"Secondary  Disclaimer",
    "admin.mainlineDailyCapabilities.uploadConfirmModal.header": "Publish Maintenance Data",
    "admin.mainlineDailyCapabilities.uploadConfirmModal.message": "Would you like to publish new Mainline Maintenance Data?",
    "admin.mainlineDailyCapabilities.validationErrorModal.message": "There are validation errors.  They must be fixed before saving.",
    "admin.mainlineDailyCapabilities.submit":"Publish",
    "admin.mainlineDailyCapabilities.uploadConfirmModal.success":"Publish was successful.",
    "admin.dailyValues.daysFrom": "entries from",
    "admin.dailyValues.daysTo": "to",
    "admin.dailyValues.lastValue": "The last value is",
    "admin.dailyValues.flow": "Flow",
    "admin.dailyValues.contract": "Contract",
    "admin.dailyValues.capability": "Capability",
    "admin.dailyValues.invalidFile": "Invalid file",
    "admin.dailyValues.serverSummaryHeading": "Current Chart Data Summary",
    "admin.dailyValues.excelSummaryHeading": "Excel File Summary",
    "admin.dailyValues.uploadConfirmModal.header": "Update daily contract and flow values?",
    "admin.dailyValues.uploadConfirmModal.message": "Would you like to update?",
    "admin.dailyValues.uploadConfirmModal.success": "Update was successful.",
    "admin.dailyValues.submit.recentContractsAndFlows": "Update recent flow and contract data",
    "admin.dailyValues.submit.allContractsAndFlows": "Update ALL flow and contract data",
    "admin.dailyValues.submit.recentContractsOnly": "Update recent contract data only",
    "admin.dailyValues.submit.fullMigration": "Update ALL flow, contract and capability data",
    "admin.dailyValues.submit": "update",
    "admin.dailyValues.migrateAllSubmitButton": "Full Migration",
    "admin.dailyValues.fileUploader": "Choose File",
    "admin.options.areaCapability": "Base Capability",
    "admin.options.bulletinManagement": "Bulletin Management",
    "admin.options.createBulletin": "Create a Bulletin",
    "admin.options.dailyOperatingPlan": "NGTL/FH Daily Operating Plan",
    "admin.options.exportChartData": "Export Chart Data",
    "admin.options.heatValues": "NGTL Heat Values Assumptions",
    "admin.options.searchBulletin": "Bulletin Search",
    "admin.options.stationList": "USJR Station List",
    "admin.options.updateDailyValues": "Update Chart Data",
    "admin.options.updateMainlineMaintenanceValues": "Update Mainline Maintenance Data",
    "adminEmailLabel": "Email",
    "adminNameLabel": "Name",
    "adminWelcome": "Welcome",
    "administration": "Admin",
    "area": "Area",
    "back": "Back",
    "banner.beta.part1": "DOP publication will be paused from December 22, 2023 to January 1, 2024 with daily publication resuming on January 2, 2024. Any critical information relating to outages or service allowable will be communicated through bulletins. Happy Holidays!",
    "banner.beta.part2": "",
    "banner.beta.part3": "",
    "banner.beta.new": "",
    "banner.beta.or": "",
    "banner.beta.link1": "",
    "banner.beta.qual": "This is a testing-only version of the website. Your changes will not be reflected publicly. Please complete all tasks in ",
    "banner.gasdaysummaryreport": "Starting from <Live Data>, all deliveries/withdrawal will show values as negative(-) and receipts/injection as positive values.",
    "baseCapability": "Base Operational Capability",
    "bulletin.confirmation.invalidInputs": "Please make sure all required inputs are provided and valid",
    "bulletin.confirmation.publish": "Publish current notice? All subscribers to this notice type will be emailed automatically",
    "bulletin.confirmation.saveDraft": "Save a Draft of current notice?",
    "bulletin.form.category": "Category",
    "bulletin.form.contactName": "Contact Name",
    "bulletin.form.contactPhone": "Contact Phone",
    "bulletin.form.critical": "Critical",
    "bulletin.form.effectiveDate": "Effective Start Date and Time",
    "Bulletin.form.schedule": "Schedule a release time",
    "bulletin.form.schedulePlaceholder": "Leave blank to publish immediately",
    "bulletin.form.nonCritical": "Non-Critical",
    "bulletin.form.pageTitle": "Create a Notice",
    "bulletin.form.serviceProvider": "Service Provider",
    "bulletin.form.subject": "Subject",
    "bulletin.form.textEditorPlaceholder": "Enter notice information here",
    "bulletin.form.title": "Title",
    "bulletin.list.effectiveDate": "Effective Start Date",
    "bulletin.list.resultPerPage": "Result per page",
    "bulletin.list.submitted": "Submitted",
    "bulletin.managementPage.editScheduled.header" : "Edit Scheduled Bulletin?",
    "bulletin.managementPage.editScheduled.bodyText" : "Editing this bulletin will cancel its scheduled time and set the bulletin to draft. You will need re-schedule the bulletin release time after editing.",
    "bulletin.managementPage.tableHeader.status": "Status",
    "bulletin.managementPage.tableHeader.noticeNumber": "Notice #",
    "bulletin.managementPage.tableHeader.date": "Date",
    "bulletin.managementPage.tableHeader.serviceProvider": "Service Provider",
    "bulletin.managementPage.tableHeader.category": "Notice Type",
    "bulletin.managementPage.tableHeader.subject": "Subject",
    "bulletin.managementPage.tableHeader.username": "Username",
    "bulletin.managementPage.tableHeader.action": "Action",
    "bulletin.preview.contact": "Contact",
    "bulletin.preview.criticalNotice": "Critical Notice",
    "bulletin.preview.effectiveStartDate": "Effective Start Date Time",
    "bulletin.publish.error": "An error occurred, please try again.",
    "bulletin.preview.nonCriticalNotice": "Non-Critical Notice",
    "bulletin.preview.noticeNumber": "Notice Number",
    "bulletin.preview.noticeType": "Notice Type",
    "bulletin.preview.notPublishedTimeMessage": "Not yet posted",
    "bulletin.preview.phone": "Phone",
    "bulletin.preview.postingTime": "Posted Date/Time",
    "bulletin.preview.revisionNumber": "Revision Number",
    "bulletin.preview.subject": "Subject",
    "bulletin.preview.notAvailable" : "The bulletin you are looking for does not exist.",
    "bulletin.publish.success": "Notice has been published",
    "bulletin.saveDraft.success": "Notice draft has been saved",
    "bulletin.schedule.success": "Notice has been scheduled",
    "bulletin.file.upload":"Upload File",
    "bulletins.title": "Bulletins",
    "bulletins.searchPlaceholder": "Type your search here...",
    "bulletins.columns.serviceProvider": "Service Provider",
    "bulletins.columns.noticeType": "Notice Type",
    "bulletins.columns.submitted": "Submitted",
    "bulletins.columns.effectiveStartDate": "Effective Start Date",
    "bulletins.columns.subject": "Subject",
    "bulletins.columns.action": "Action",
    "bulletins.footer.header": "Stay up to date with the latest Bulletins",
    "bulletins.footer.text": "Click the button below to recieve notifications on the latest updates and new information.",
    "bulletins.footer.footerText": "After sending the message, we will send you a confirmation email that you need to confirm before we'll start sending you notifications. We will only send you notifications about this page. All future emails will contain a link to unsubscribe from future notifications.",
    "bulletins.footer.footerNote": "NOTE:",
    "bulletins.footer.subscribeButton": "Subscribe",
    "bulletins.displayTimeZone": "CCT",
    "displayTimeZone.disclaimer": "Central Clock Time (UTC/GMT -5 hours)",
    "cancel": "Cancel",
    "capability": "Capability",
    "chartAssumptions": "Assumptions",
    "capabilityHistoricalFlow": "Capability and Historical Flow",
    "changed": "Changed",
    "chart.data": "Chart Data",
    "chart.volumeUnit": "10\u00b3m\u00b3/day",
    "chart.energyUnit": "TJ/day",
    "chart.contractLabel": "Contracts",
    "chart.flowLabel": "Actual Flow",
    "chart.historicalFlowLabel": "Historical Flow",
    "chart.tooltip.localAreaOutages": "Local Area Outages",
    "chart.tooltip.disclaimer": "All actual and historical flows have been rounded to the nearest thousand.",
    "chart.tooltip.historicalDay": "Historical flow date is {historical-date}.",
    "chart.legend.disclaimer1": "Energy conversions for Capabilities and Flow are for illustrative purposes only. For most accurate values, please refer to displayed volume.",
    "chart.legend.disclaimer2" : "Volume and energy quantities represent the best available data at the time and may not reflect actual quantities used for accounting purposes. For the most up to date information please refer to the ",
    "chart.legend.disclaimer.link" : "Volume Energy Composition Report",
    "chip.tspEligible": "TSP Eligible",
    "chip.partialImpact": "Partial Impact",
    "chip.potentialImpact": "Potential Impact",
    "clear": "Clear",
    "collapse": "View Summary",
    "comparedToDateChartLegendLabel": "Compared to",
    "comparedToDateSelectorLabel": "Compared to: ",
    "contactPage.button.submit": "Submit",
    "contactPage.headings.contacts": "Contacts",
    "contactPage.headings.faq": "FAQs",
    "contactPage.faq.outage": "NGTL/FH Daily Operating Plan FAQ",
    "contactPage.faq.bulletin": "Bulletins FAQ",
    "contactPage.faq.currentSystemReport": "Current System Report FAQ",
    "contactPage.faq.gasDaySummaryReport": "Gas Day Summary Report FAQ",
    "contactPage.headings.feedback": "Feedback",
    "contactPage.headings.otherContacts": "Other Contacts",
    "contactPage.headings.pipeLineFree": "Pipe Line (Toll Free)",
    "contactPage.headings.pipeLineLocal": "Pipe Line (Local)",

    "currentSystemReport.deliveryDataGrid.tableHeader.category": "Delivery",
    "currentSystemReport.linepackDataGrid.tableHeader.category": "Linepack",
    "currentSystemReport.receiptDataGrid.tableHeader.category": "Receipts",
    "currentSystemReport.dataGrid.tableHeader.currentValues": "Current Values",
    "currentSystemReport.deliveryDataGrid.category.totalDeliveries": "Total Deliveries",
    "currentSystemReport.deliveryDataGrid.category.intraprovincialDemand": "Intraprovincial Demand",
    "currentSystemReport.deliveryDataGrid.category.empressBorderFlow": "Empress Border Flow",
    "currentSystemReport.deliveryDataGrid.category.mcneillBorderFlow": "McNeill Border Flow",
    "currentSystemReport.deliveryDataGrid.category.AlbertaBCBorderFlow": "Alberta-BC Border Flow",
    "currentSystemReport.deliveryDataGrid.category.willowValleyInterconnect": "Willow Valley Interconnect",
    "currentSystemReport.linepackDataGrid.category.linepackTarget": "Linepack Target",
    "currentSystemReport.linepackDataGrid.category.currentLinepack": "Current Linepack",
    "currentSystemReport.linepackDataGrid.category.linepack4HRRoc": "Linepack 4HR Roc",
    "currentSystemReport.linepackDataGrid.category.flowDifferential": "Flow Differential (Receipts - Deliveries)",
    "currentSystemReport.receiptDataGrid.category.totalReceipts": "Total Receipts",
    "currentSystemReport.receiptDataGrid.category.fieldReceipts": "NGTL-Field Receipts",
    "currentSystemReport.receiptDataGrid.category.groundBirchEastReceipt": "Groundbirch East Receipts",
    "currentSystemReport.receiptDataGrid.category.gordondaleReceipt": "Gordondale Receipts",
    "currentSystemReport.receiptDataGrid.category.netStorageFlow": "Net Storage Flow",
    "currentSystemReport.chart.24hours":"last 24 hours",
    "currentSystemReport.chart.7days":"last 7 days",
    
    "subscribeBanner.headings.subscribeHeader.bulletin": "Stay up to date with the latest Bulletins",
    "subscribeBanner.headings.subscribeHeader.dop": "Stay up to date with the latest Daily Operating Plan",
    "contactPage.inputField.errorMessage.emailAddressError": "*Please enter a valid email address",
    "contactPage.inputField.errorMessage.firstNameError": "*Please enter your first name",
    "contactPage.inputField.errorMessage.lastNameError": "*Please enter your last name",
    "contactPage.inputField.errorMessage.messageError": "*Please enter a message",
    "contactPage.inputField.errorMessage.phoneNumberError": "*Please enter a valid phone number",
    "contactPage.inputField.label.contactPreference": "How would you prefer to be contacted?",
    "contactPage.inputField.label.email": "Email",
    "contactPage.inputField.label.phone": "Phone",
    "contactPage.inputField.placeholder.emailAddress": "Email Address*",
    "contactPage.inputField.placeholder.firstName": "First Name*",
    "contactPage.inputField.placeholder.lastName": "Last Name*",
    "contactPage.inputField.placeholder.message": "Message*",
    "contactPage.inputField.placeholder.phoneNumber": "Phone Number",
    "contactPage.privacyStatement.link": "Privacy Statement",
    "contactPage.privacyStatement.text1": "TC Energy is committed to protecting your privacy and the Personal Information you provide to us. The collection of your Personal Information for this Website requires consent. By completing and choosing to submit this form you are consenting to the collection, use and disclosure of your Personal Information for the purposes of contacting you in accordance with the terms of use as outlined by TC Energy’s",
    "contactPage.privacyStatement.text2": ". Continued use of this Website hereafter is your continued acceptance of TC Energy’s Privacy Statement.",
    "contactPage.privacyStatement.text3": "BY USING THIS WEBSITE, I ACKNOWLEDGE THAT I HAVE READ AND UNDERSTOOD THE TC ENERGY PRIVACY STATEMENT AND AGREE TO THE TERMS AND CONDITIONS IDENTIFIED IN THE PRIVACY STATEMENT.",
    "contactPage.resultMessage.messageSentSuccess": "Your message was successfully sent to TC Energy, thank you for your feedback.",
    "copy": "copy",
    "subscribeBanner.subscribe.button.subscribe": "Subscribe",
    "subscribeBanner.subscribe.manageSubscriptions": "Manage Subscription",
    "subscribeBanner.subscribe.subscribeInfo": "Click the button below to receive notifications on the latest updates and new information.",
    "subscribeBanner.subscribe.subscribeNote": "After sending the message, we will send you a confirmation email that you need to confirm before we'll start sending you notifications. We will only send you notifications about this page. All future emails will contain a link to unsubscribe from future notifications.",
    "cookiesDisclaimer.buttonText": "Close",
    "cookiesDisclaimer.text": "Our site uses cookies and other technologies to tailor your experience and understand how you and other visitors use our site. Click here to read our ",
    "current": "current",
    "currentCapability": "Current Capability",
    "currentGasDay": "Current Gas Day",
    "currentNoOutagesMessage": "There are currently no outages",
    "currentOutages": "Current outages",
    "currentOutagesHeading": "Current Outages",
    "dailyOperatingPlanPublishSuccess": "Publish was successful",
    "dailyOperatingPlanView": "Viewing NGTL/FH Daily Operating Plan",
    "datePulled": "Date Pulled",
    "delete": "Delete",
    "deleteDraft": "Delete Draft",
    "deletePublishedBulletin" : "Delete a Published Bulletin!!",
    "deletePublishedBulletinConfirm" : "Warning! You are about to delete a published bulletin that has already been emailed to customers. It is recommended that a Correction Bulletin is sent to customers with updated information.\nThis cannot be undone. Are you sure?",
    "deletePublicationSuccess" : "Delete Bulletin was successful",
    "deleteDraftConfirm": "Are you sure you want to delete this draft?",
    "deleteDraftConfirm2": "This action will delete the current draft.  Are you sure?",
    "deleteScheduled": "Delete Scheduled!!",
    "deleteScheduledConfirm": "Warning! You are about to delete a scheduled bulletin. Customers will not receive this bulletin in their inboxes.\nThis cannot be undone. Are you sure?",
    "deletePublished": "Delete Published!!",
    "deletePublishedConfirm": "Warning!  You are about to delete data that has already been published.  This can not be undone.  Are you sure?",
    "deletePublishedSuccess": "Delete Published was successful",
    "deleteSuccess": "Delete Draft was successful",
    "deleteDraftSuccess": "Delete draft bulletin was successful",
    "deleteScheduledSuccess": "Delete scheduled bulletin was successful",
    "details": "Details",
    "discard": "Discard",
    "discardChangesBody": "Changes will not be saved.  Are you sure you want to discard and leave this page?",
    "discardChangesHeader": "Discard Changes",
    "dopDraftConfirm": "Are you sure you want to save this draft?",
    "dopPublishConfirm": "Are you sure you want to update the Daily Operating Plan with these changes?",
    "dopPublishConfirmNoRecords": "There are 0 outages detected!  You cannot update the Daily Operating Plan with 0 outages.",
    "download": "download",
    "duration": "Duration",
    "edit": "Edit",
    "email": "Email",
    "emailDailyOperatingPlanDecision": "Would you like to send the Daily Operating Plan email notification?",
    "emailDailyOperatingPlanHeader": "Daily Operation Plan Email Notification",
    "emailDailyOperatingPlanSuccess": "Daily Operating Plan email notification was sent successfully!",
    "end": "End",
    "error.generic": "Oops! Something went wrong on our end, please try again later.",
    "Error.404.whoops": "Whoops...",
    "Error.404.mainMessage": "Something went wrong with your search or the page you are requesting does not exist. Try refreshing the page, check for errors, or return to the main screen.",
    "Error.404.assistance": "For any immediate questions or concerns, please contact the Pipe Line at",
    "expand": "View All",
    "export": "Export",
    "export.start": "Start Date",
    "export.end": "End Date",
    "export.description": "Select a date range to export a CSV containing the NGTL chart data:",
    "export.areaDescription": "Actual Flow, Base Capability, Outage Capability, Impact, Outage Description, Assumed Heat Value",
    "export.areaDescriptionWithContracts": "FT-D, Actual Flow, Base Capability, Outage Capability, Impact, Outage Description, Assumed Heat Value",
    "flow": "Flow",
    "footer.p1": "All system capabilities reported are based on many assumptions related to factors which affect operational capability. These factors and the system capabilities will be reviewed on a daily basis which may result in revision of operational capability and authorized quantities. TC Energy shall not be liable for damages sustained as a result of any use or reliance on information contained.",
    "footer.p2": "Empress/McNeill Border delivery capability is determined assuming zero storage receipt and deliveries. All delivery locations within a defined area of impact are hydraulically equivalent. This includes Group 1 (export), Group 2 (intra), and storage locations.",
    "footer.p3.part1": "Coordinating NGTL System and Foothills maintenance with Customer maintenance can help minimize Customer impact of planned maintenance. Whenever possible, TC Energy will align its maintenance with Customer maintenance activities. In order to maximize coordination opportunities, please complete and return the ",
    "footer.p3.part2": "This form can be used to submit information on either receipt or delivery scheduled facility maintenance.",
    "footer.p4": "For service related matters, please contact your Marketing Representative.",
    "footer.p5.part1": "For further information, please contact The Pipe Line at",
    "footer.p5.part2": "or toll-free",
    "future": "Upcoming",
    "future30NoOutagesMessage": "There are no planned outages in next 30 days",
    "future30OutagesHeading": "Upcoming in next 30 days",
    "future30plusNoOutagesMessage": "There are no planned outages in 30+ days",
    "future30plusOutagesHeading": "Upcoming in 30+ days",
    "futureNoOutagesMessage": "There are no planned outages",
    "futureOutagesHeading": "Upcoming",
    "gasDay": "{gasDay}",
    "genericError": "An error has occurred",
    "genericError2": "An error has occurred. Please refresh the page and try again. If the error persists, please contact Support.",
    "genericSaveSuccess": "Save was successful",
    "heatValue.cancel.header": "Cancel Scheduled Heat Value",
    "heatValue.cancel.body": "Are you sure you want to cancel the scheduled heat value change?",
    "heatValue.update.success": "Heat Values have been updated successfully",
    "heatValue.cancel.success": "Scheduled Heat Values have been cancel successfully",
    "heatValue.units": "GJ/10\u00b3m\u00b3",
    "heatValue.date.header": "Last Updated",
    "heatValue.note": "Enter a heat value between 30 and 45 for each area below. Use the Effective Start Date to schedule a Heat Value change in the future.",
    "heatValue.note2" : " These Heat Value Assumptions are used within OCP to calculate chart data. When customers or administrators export the chart data, these values will be displayed.",
    "heatValue.currentValues.label": "The current Heat Values for the system are:",
    "heatValue.disclaimer": "Heat Value changes will be displayed on the site with the next DOP publication, on or after the scheduled date. Heat Value changes will take immediate effect in chart exports for customers and administrators.",
    "heatValue.note.changes": "To make changes to the Heat Values or the scheduling date, click MODIFY. To Cancel these changes and leave the current Heat Values in place, click CANCEL.",
    "heatValue.label": "Heat Value",
    "heatValue.header.updatedBy": "Last Updated By",
    "heatValue.header.scheduled" : "Scheduled Updates",
    "historicalFlow": "Historical Flow",
    "historicalFlowChart": "Historical Flow Chart",
    "historicalFlowLastUpdated": "Historical Flow Chart Last Updated",
    "impact": "Impact",
    "impactId": "Impact ID",
    "importantLinks": "Other Links",
    "lastUpdatedDate": "Last Updated Date",
    "currentReportUpdatedInfo": "Updated every 30 minutes.",
    "link.adminLoginLabel": "Administrator Login",
    "link.capabilitiesCsv": "Base Capability Table",
    "link.plantTurnaroundCsv": "Plant Turnaround Data",
    "link.chartCsv": "Chart Data",
    "links.currentSystemReport": "Current System Report",
    "links.gasDaySummaryReport": "Gas Day Summary Report",
    "links.ngtlBulletins": "NGTL Bulletins",
    "links.ngtlOperationalAreasMap": "NGTL Systems - Operational Areas Map",
    "links.ngtlSegmentCodes": "NGTL System - Segment Codes & Project Areas Map",
    "links.plantTurnaroundForm": "Plant Turnaround Request Form",
    "links.privacyPolicy": "Privacy Policy",
    "loadOperatingPlan": "Load Today's Daily Operating Plan",
    "loaded": "Loaded",
    "loading": "Loading",
    "localArea": "Local Area",
    "localAreaChartDisclaimer": "Local Area/Location Outages not considered",
    "localAreaDisclaimer": "Local Area/Location Outages not displayed on the map",
    "localAreaCapability": "Local Area Outage Capability",
    "localAreaImpact": "Local Area Impact",
    "localAreaTypicalFlow": "Local Area Typical Flow",
    "localBaseCapability": "Local Base Capability",
    "localOutageCapability": "Local Outage Capability",
    "localTypicalFlow": "Local Typical Flow",
    "AreaOutages": "Local Area Outages",
    "maintenance.sorry": "Sorry!",
    "maintenance.message": " is temporarily down for maintenance and will return shortly.\nThank you for your patience.",
    "modify": "Modify",
    "navigation.menu.bulletins": "Bulletins",
    "navigation.menu.contact": "Contact & FAQ",
    "navigation.menu.dashboard": "Dashboard",
    "navigation.menu.faq": "FAQ",
    "navigation.menu.outages": "NGTL Outages",
    "navigation.menu.subscriptions": "Subscriptions",
    "navigation.outageToggle.chart": "Chart",
    "navigation.outageToggle.list": "List",
    "navigation.outageToggle.map": "Map",
    "nedaDisclaimer": "NEDA outages are not indicated on the charts",
    "new": "New",
    "next30days": "Next 30 days",
    "next30plusDays": "After 30 Days",
    "noAreasSelected": "No Areas Selected",
    "no": "No",
    "yes":"Yes",
    "none": "None",
    "note": "Note",
    "notes": "Notes",
    "ok": "Okay",
    "operationalCap": "Current Operational Capability",
    "outage": "Outage",
    "outageCap": "Outage Capability",
    "outage.card.invalid": "INVALID DATA",
    "outageCapReduction": "Capability Reduction",
    "outageDescription": "Outage Description",
    "outageId": "Outage ID",
    "outages": "Outages",
    "outageCard.tooltip.text1" : "Impact is calculated using the {area-name} Base Operational Capability of",
    "outageCard.tooltip.text2" : "for",
    "outageCard.tooltip.text3" : "{start-date} to {end-date}",
    "outageCard.tooltip.localArea" : "Local Area Impact is calculated using the Area Base Capability of",
    "outageList.header.fromTo": "From {start-date} to {end-date}",
    "outageGasDayChangeRibbon.header": "Gas Day Changes",
    "outageGasDayChangeRibbon.receiptswithoutstorage": "receipts without storage",
    "outageGasDayChangeRibbon.netstorage": "net storage",
    "outageGasDayChangeRibbon.deliverieswithoutstorage": "deliveries without storage",
    "outageGasDayChangeRibbon.linepackvariance": "linepack variance from target",
    "outageGasDayChangeRibbon.sdaccounts": "sd accounts",
    "outageGasDayChangeRibbon.tolerance": "tolerance",
    "page.header.bulletins": "Bulletins",
    "page.header.currentSystemReport": "Current System Report",
    "page.header.gasDaySummaryReport": "Gas Day Summary Report",
    "page.header.contact": "Contact & FAQ",
    "page.header.subscriptions": "Subscriptions",
    "pager.altText.first": "First",
    "pager.altText.last": "Last",
    "pager.altText.next": "Next",
    "pager.altText.previous": "Previous",
    "percentComplete": "% Complete",
    "plantTurnaroundDisclaimer": "Plant Turnaround not displayed on the map",
    "preview": "Preview",
    "previously": "Previously",
    "publicationList": "NGTL/FH Daily Operating Plan List",
    "publish": "Publish",
    "publishDateTime": "Publish Date Time",
    "publishDraftDateTime": "Publish/Draft Date Time",
    "publishedBy": "Publish/Draft User",
    "publishing": "Publishing",
    "pullDateTime": "Data Pull Date Time",
    "removed": "Removed / Completed",
    "removedNoOutagesMessage": "There were no outages removed",
    "removedOutagesHeading": "Removed/Expired Outages",
    "save": "Save",
    "saveCapabilityBody": "Are you sure you want to save the base capabilities?",
    "saveCapabilityHeader": "Save Base Capabilities",
    "saveDraft": "Save as Draft",
    "saveDraftSuccess": "Save as Draft was successful",
    "schedule": "Schedule",
    "search": "SEARCH",
    "search.option.allNoticeTypes": "All Notice Types",
    "search.option.allServiceProviders": "All Service Providers",
    "search.label.noticeType": "Notice Type",
    "search.label.submittedDate": "Submitted Date",
    "search.label.effectiveDate": "Effective Start Date",
    "search.label.critical": "Critical",
    "search.label.nonCritical": "Non-Critical",
    "search.label.all": "All",
    "search.label.searchWords": "Search Words",
    "search.label.subject": "Subject",
    "search.label.text": "Text",
    "search.label.exactPhrase": "Exact Phrase",
    "search.reset": "Reset",
    "search.advanced": "Advanced",
    "serviceAllowable": "Service Allowable",
    "serviceAllowableArea": "Service Allowable Area",
    "serviceAllowableLocation": "Service Allowable Location/Area",
    "showingDateSelectorLabel": "Publication: ",
    "start": "Start",
    "status": "Status",
    "stationList.header.stationList": "Station List",
    "stationList.header.search": "Search",
    "stationList.header.addNewStation": "Add a New Station",
    "stationList.save.body": "Save current changes?",
    "stationList.save.header": "Save Changes",
    "stationList.save.successMessage": "Changes have been saved",
    "stationList.search.placeholderText": "Search station by number",
    "stationList.status.approve": "Approve",
    "stationList.status.exclude": "Exclude",
    "stationList.status.no": "No",
    "stationList.status.yes": "Yes",
    "stationList.option.addStation": "Add Station",
    "stationList.table.header.stationNumber": "Station Number",
    "stationList.table.header.area": "Area",
    "stationList.table.header.isActive": "Is Active",
    "stationList.table.header.approved": "Approved",
    "stationList.table.header.excluded": "Excluded",
    "subscription.manage" : "Manage Subscription",
    "subscription.manage.info": "To manage your other subscriptions, such as NGTL Tolerance Change SMS/Phone notifications, Gas Canada Transactional System notifications, or NGTL Customer Activities, please contact the Call Center at",
    "subscription.email.confirmation.info" :"You will receive a confirmation email shortly after saving your preferences. You must click the link in the email to confirm your subscription.",
    "subscription.email.existingUser.header": "Existing User? Manage your subscriptions.",
    "subscription.email.existingUser.instructions": "Enter your the email address to manage your subscriptions or unsubscribe from my.tccustomerexpress.com.",
    "subscription.email.existingUser.info": "You will receive a Manage Preferences email shortly. You must click the link in the email to modify your subscription.",
    "subscription.email.newUser.instructions": "Enter the email address where you want to receive notifications.",
    "subscription.email.newUser.info": "You will receive a confirmation email shortly after saving your preferences. You must click the link in the email to confirm your subscription.",
    "subscription.email.newUser.header": "New User? Subscribe here.",
    "subscription.notification.info": "Toggle the notification item you would like to stay up to date with.",
    "subscription.notification.all": "All my.tccustomerexpress.com Notifications",
    "subscription.manage.success": "Please check your inbox for your unique subscription management link.",
    "subscription.subscribe": "subscribe",
    "subscription.subscribe.success": "Thanks for your interest! Please check your inbox to confirm your subscription.",
    "subscription.opt.in.header": "Subscription Confirmation",
    "subscription.opt.in.success": "Thank you for confirming your subscription!",
    "subscription.opt.in.error": "Sorry! Your email is not in our system. Please try subscribing again.",
    "subscription.save.success": "Subscriptions have been saved successfully!",
    "subscription.unsubscribe": "Unsubscribe",
    "subscription.unsubscribe.info" : "Click the button below to unsubscribe from all my.tccustomerexpress.com notifications. Subscriptions managed by other systems will continue to notify you.",
    "subscription.unsubscribe.success": "You have been successfully unsubscribed from all notifications and bulletins.",
    "subscription.unsubscribe.anonymous.success": "An unsubscribe email has been sent! Please check your inbox to confirm.",
    "subscription.unsubscribe.sorry": "Sorry to see you go.",
    "subscription.unsubscribe.navigate.home": "Return to home",
    "subscription.unsubscribe.navigate.return": "Back to subscriptions",
    "subscription.welcome": "Welcome",
    "tab.admin": "TC Energy - Administration",
    "tab.bulletin": "TC Energy - Bulletins",
    "tab.currentSystemReport": "TC Energy - Current System Report",
    "tab.gasDaySummaryReport": "TC Energy - Gas Day Summary Report",
    "tab.contact": "TC Energy - Contact & FAQ",
    "tab.dashboard": "TC Energy - Dashboard",
    "tab.outages": "TC Energy - NGTL/FH Daily Operating Plan",
    "tab.subscription": "TC Energy - Subscription Management",
    "timestamp": "Timestamp",
    "typicalFlow": "Typical Flow",
    "unit1": "10\u00b3m\u00b3/day",
    "upcoming": "Upcoming",
    "unauthorized.header": "Session Expired",
    "unauthorized.body": "Your login session has expired. Please refresh the page and try again.",
    "unauthorized.noRefresh": "Don't Refresh",
    "unauthorized.refresh": "Refresh",
    "unsubscribe.disclaimer.note1": "TC Energy is committed to protecting your privacy and the Personal Information you provide to us. By completing and choosing to submit this form you are consenting to the collection, use and disclosure of your Personal Information for the purposes of contacting you in accordance with the terms use as outlined by TC Energy’s",
    "unsubscribe.disclaimer.note2": "Continued use of this Website or submission of Personal Information hereafter is your continued acceptance of TC Energy’s Privacy Statement.",
    "unsubscribe.disclaimer.note3": "BY AGREEING TO USE AND SUBMIT PERSONAL INFORMATION VIA THIS WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD TC ENERGY’S PRIVACY STATEMENT, AND AGREE TO THE TERMS AND CONDITIONS IDENTIFIED IN THE PRIVACY STATEMENT.",
    "validation.area.capabilityAssumptions.requiredMessage": "Capability Assumptions are required.",
    "validation.area.flowChartUpdatedDate.requiredMessage": "Historical Flow Chart Last Updated is required.",
    "validation.area.flowChartUpdatedDate.validationMessage": "Historical Flow Chart Last Updated must be a valid date (MM/DD/YYYY).",
    "validation.areaCapability.capability.minValueMessage": "Capability must be greater than zero.",
    "validation.areaCapability.capability.requiredMessage": "Capability is required.",
    "validation.areaCapability.capability.validationMessage": "Capability must be a number.",
    "validation.areaCapability.endDate.afterStartDateMessage": "End Date must come after the Start Date.",
    "validation.areaCapability.endDate.conflictMessage": "End Date overlaps with other capabilities in this area.",
    "validation.areaCapability.endDate.gapsMessage": "End Date leaves a date gap between this capability and the succeeding capability in this area.",
    "validation.areaCapability.save.validationError": "There are validation errors.  They must be fixed before saving.",
    "validation.areaCapability.startDate.beforeEndDateMessage": "Start Date must come before the End Date.",
    "validation.areaCapability.startDate.conflictMessage": "Start Date overlaps with other capabilities in this area.",
    "validation.areaCapability.startDate.gapsMessage": "Start Date leaves a date gap between this capability and the preceding capability in this area.",
    "validation.bulletin.contactName.invalidFormat": "Enter a valid name or leave blank (maximum 60 characters)",
    "validation.bulletin.noticeType.requiredMessage": "Category is required",
    "validation.bulletin.phoneNumber.invalidNumber": "Enter a valid phone number (eg. 403-123-2211) or leave blank",
    "validation.bulletin.serviceProvider.requiredMessage": "Service Provider is required",
    "validation.bulletin.subject.requiredMessage": "Subject is required",
    "validation.bulletin.subject.tooManyCharacters": "Keep subject under 60 characters",
    "validation.bulletin.scheduleDate.invalidDate": "Scheduled Date must be a valid date and time with the format MMM dd, yyyy HH:mm or left empty",
    "validation.bulletin.scheduleDate.dateIsInPast": "The schedule date needs to be set to a future date",
    "validation.bulletin.startDate.invalidDate": "Start Date must be a valid date and time with the format MMM dd, yyyy HH:mm or left empty",
    "validation.bulletin.startDate.dateLessThanScheduled": "Effective start date must be set equal or greater than the scheduled date or left blank",
    "validation.bulletin.title.requiredMessage": "Title is required",
    "validation.bulletin.body.notExists": "Notice cannot be empty",
    "validation.bulletin.body.tooLong": "Notice cannot exceed 245000 characters in length",
    "validation.export.startDate.invalidDate": "Start Date must be a valid date with the format MMM dd, yyyy",
    "validation.export.endDate.invalidDate": "End Date must be a valid date with the format MMM dd, yyyy",
    "validation.export.startDate.requiredMessage": "Start Date is required",
    "validation.export.endDate.requiredMessage": "End Date is required",
    "validation.export.startDate.beforeEndDateMessage": "Start Date must come before the End Date.",
    "validation.export.endDate.afterStartDateMessage": "End Date must come after the Start Date.",
    "validation.heatValue.outOfRange": "Heat Value must be between 30 and 45",
    "validation.outage.capability.nonNegative": "Capability must be a non-negative number.",
    "validation.outage.capability.numericOrTBDMessage": "Capability must be a number or TBD.",
    "validation.outage.capability.numericOrTbdOrNaMessage": "Capability must be a number, TBD or N/A.",
    "validation.outage.capability.requiredMessage": "Capability is required.",
    "validation.outage.defaultMessage": "Field is invalid.",
    "validation.outage.description.requiredMessage": "Description is required.",
    "validation.outage.draft.validationError": "There are validation errors.  They must be fixed before saving.",
    "validation.outage.endDate.baseCapabilityRequired": "Invalid End Date. Base Capability must be defined for the Area for the date to be valid.",
    "validation.outage.endDate.requiredMessage": "End Date is required.",
    "validation.outage.endDate.validationMessage": "End Date must be a valid date (MM/DD/YYYY) OR TBD.",
    "validation.outage.endDate.mustBeOnOrAfterStartDate": "End Date must come after the Start Date.",
    "validation.outage.impact.requiredMessage": "Impact is required.",
    "validation.outage.outageId.allowedRange": "Outage Id must be 8-10 digits long.",
    "validation.outage.outageId.numericMessage": "Outage Id must be a number.",
    "validation.outage.outageId.requiredMessage": "Outage Id is required.",
    "validation.outage.publish.validationError": "There are validation errors.  They must be fixed before publishing.",
    "validation.outage.startDate.baseCapabilityRequired": "Invalid Start Date. Base Capability must be defined for the Area for the date to be valid.",
    "validation.outage.startDate.requiredMessage": "Start Date is required.",
    "validation.outage.startDate.validationMessage": "Start Date must be a valid date (MM/DD/YYYY) OR TBD.",
    "validation.outage.startDate.mustBeOnOrBeforeEndDate": "Start Date must come before the End Date.",
    "validation.outage.typicalFlow.numericMessage": "Typical Flow must be a number.",
    "validation.outage.typicalFlow.requiredMessage": "Typical Flow is required.",
    "validation.outage.typicalFlow.validationMessage": "Typical Flow must be a number or a range of numbers (example: 1000-2000).",
    "validation.mainlineCapabilityPublication.heatingValue.requiredMessage": "Heating Value is required.",
    "validation.mainlineCapabilityPublication.heatingValue.numericMessage": "Heating Value must be a number.",
    "validation.mainlineCapabilityPublication.heatingValue.allowedRange": "Heating Value must be between 1 and 50.",
    "validation.mainlineCapabilityPublication.capacityPercentage.requiredMessage": "Capacity Factor is required.",
    "validation.mainlineCapabilityPublication.capacityPercentage.numericMessage": "Capacity Factor must be a number.",
    "validation.mainlineCapabilityPublication.capacityPercentage.allowedRange": "Capacity Factor must be between 1 and 100.",
    "validation.mainlineCapabilityPublication.primaryDisclaimer.requiredMessage": "Primary Disclaimer is required.",
    "validation.mainlineCapabilityPublication.secondaryDisclaimer.requiredMessage": "Secondary Disclaimer is required.",
    "validation.mainlineCapabilityPublication.capabilities.invalidRowCount": "The file selected does not contain values for today and the next 10 days.",
    "validation.mainlineCapabilityPublication.capabilities.invalidFileMessage": "The file selected is not valid.",
    "validation.mainlineCapabilityPublication.capabilities.duplicateDays": "The file selected contains duplicate dates.",
    "validation.stationList.numberAlreadyExists": "This station number already exists",
    "validation.stationList.numberTooLarge": "The station number is too large",
    "validation.subscription.email" : "A valid email is required.",
    "validation.subscription.recaptcha" : "reCaptcha has not been verified.",
    "view": "view",
    "viewAll": "View All",
    "widgets.mainline.capability.prairiesLine": "Prairies Line¹",
    "widgets.mainline.capability.emersonExtension": "Emerson Extension²",
    "widgets.mainline.capability.northernOntarioLine": "Northern Ontario Line³",
    "widgets.mainline.capability.easternDeliveryArea": "Eastern Delivery Area⁴",
    "widgets.mainline.capability.downstreamStation148": "Downstream Stn 148⁵",
    "widgets.mainline.capability.downloadLinkText": "Download historical CSV",
    "widgets.mainline.capability.lastUpdatedTime": "Last Updated {last-updated-time}",
    "widgets.mainline.capability.unitMessage": "Units: TJ",
    "widgets.mainline.capability.disclaimer": "Forecast system capabilities converted to energy at {heat-value} MJ/m³",
    "widgets.mainline.tolerance.title": "Tolerance Change",
    "widgets.mainline.tolerance.currently": "Currently",
    "widgets.mainline.tolerance.previously": "Previously",
    "working": "Working",
    "dailyOperatingPlan" : "Daily Operating Plan",
    "admin.Qrg.Title":"Got Questions?",
    "admin.Qrg.Text":"Check out the Quick Reference Guide on how to manage the NGTL/FH Daily Operating Plan or Bulletins & Subscriptions.",
    "admin.button.dop.qrg": "NGTL/FH Daily Operating Plan QRG",
    "admin.button.bulletin.qrg": "Bulletins & Subscriptions QRG",
    "admin.baseCapability.capabilityAssumptions": "Capability Assumptions",
    "CSV": "CSV",
    "plantTurnaroundDownloadCsv": "Download CSV",
    "plantTurnaroundDownloadCsvHeaderLink": "PLANT TURNAROUND CSV",
    "admin.outage.previewChart": "Chart",
    "admin.outage.dailyOperatingPlanChartView": "Preview NGTL/FH Daily Operating Plan Chart"
};

export default en;