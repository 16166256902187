import React from 'react';
import * as S from "../styles";
import closeIcon from "../icons/white_close_x.svg";
import checkIcon from "../icons/check-circle.svg";
import failureIcon from "../icons/failure_x.svg";

interface Props {
    text: string;
    onExit(): any;
    success: boolean;
    optionalQuestionText?: string;
    optionalAction?(): any;
    optionalOkayText?: string;
    optionalCancelText?: string;
}

const DefaultProps = {
    success: true
};

const ResultModal: React.FC<Props> = (props) => {
    return (
        <div>
            <S.ModalOverlay onClick={props.onExit}>
            </S.ModalOverlay>
            <S.ResultModal success={props.success}>
                <S.ModalHeader>
                    <S.Icon src={props.success ? checkIcon : failureIcon} width={24} height={24}/>
                    <S.ResultModalText>
                        {props.text}
                    </S.ResultModalText>
                    <S.ClickableIcon src={closeIcon} width={18} height={20} onClick={props.onExit}/>
                </S.ModalHeader>
                {props.optionalAction &&
                <div>
                    <S.ResultModalDecisionText>
                        {props.optionalQuestionText}
                    </S.ResultModalDecisionText>
                    <S.ConfirmModalFooter>
                        <S.Button onClick={props.onExit}>{props.optionalCancelText}</S.Button>
                        <S.Button onClick={props.optionalAction}>{props.optionalOkayText}</S.Button>
                    </S.ConfirmModalFooter>
                </div>}
            </S.ResultModal>
        </div>
    );
};

ResultModal.defaultProps = DefaultProps;
export default ResultModal;