import {
    CLEAR_OUTAGE_CHART_DATA_LOADED,
    CLEAR_SELECTED_CHART_OUTAGES,
    SET_SHOWING_CHART_KEYS,
    OutageChartActionTypes,
    SET_OUTAGE_CHART_DATA,
    SET_SELECTED_CHART_OUTAGES,
} from "../types/OutageChartActionTypes"
import {IArea, IChartData, IOutage, IShowingChartKeys} from "../types";


export function setOutageChartData(areaChartData: { [key: string]: IChartData[] }, relevantOutages: IOutage[], relevantAreas: IArea[]): OutageChartActionTypes {
    return {
        type: SET_OUTAGE_CHART_DATA,
        areaChartData: areaChartData,
        relevantOutages: relevantOutages,
        relevantAreas: relevantAreas,
    }
}

export function setShowingChartKeys(showingChartKeys: IShowingChartKeys[]): OutageChartActionTypes {
    return {
        type: SET_SHOWING_CHART_KEYS,
        showingChartKeys: showingChartKeys
    }
}

export function clearOutageChartDataLoaded(): OutageChartActionTypes {
    return {
        type: CLEAR_OUTAGE_CHART_DATA_LOADED
    }
}

export function setSelectedChartOutages(selectedOutages: IOutage[], selectedOutageHistory: IOutage[], selectedDate: string): OutageChartActionTypes {
    return {
        type: SET_SELECTED_CHART_OUTAGES,
        selectedChartOutages: selectedOutages,
        selectedChartOutageHistory: selectedOutageHistory,
        selectedChartDate: selectedDate
    }
}

export function clearSelectedChartOutages(): OutageChartActionTypes {
    return {
        type: CLEAR_SELECTED_CHART_OUTAGES,
    }
}