import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router";
import {IBulletin} from "../../types";
import Bulletin from "./Bulletin";
import BackNavigationHeader from "../BackNavigationHeader";
import {locale} from "../../locale";
import * as S from "../../styles";
import {Fetch} from "../../utilities/Fetch";
import {useDispatch} from "react-redux";
import BulletinList from "./BulletinList";

interface Params {
    id: number;
}

const ViewBulletin: React.FC<RouteComponentProps> = (props) => {
    const parameter = props.match.params as Params;
    const history = useHistory();
    const [bulletinToView, setBulletinToView] = useState<IBulletin | undefined>(undefined);
    const [bulletinLoaded, setBulletinLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setBulletinLoaded(false);
        setBulletinToView(undefined);
        Fetch.Build(`/bulletin/${parameter.id}`, dispatch)
            .success(bulletin => {
                setBulletinToView(bulletin)
            })
            .finally(() => {
                setBulletinLoaded(true);
            })
            .get();
    }, [parameter.id, dispatch]);

    return (
        <div>
            {bulletinLoaded && (bulletinToView !== undefined ? <S.PageWithNavigationContainer>
                {/*Goes back to the outgage page if bulletin was opened from an email else go back where it came from*/}
                <BackNavigationHeader handleBackNavigation={() => history.length < 3 ? history.push('/') : history.goBack()}
                                      leftContent={locale('back')}/>
                {bulletinLoaded && bulletinToView !== undefined && <Bulletin data={bulletinToView} isDraft={false}/>}
            </S.PageWithNavigationContainer> : <BulletinList/>)}
        </div>
    )
};

export default ViewBulletin;