export const size = {
  mobile: 700,
  tablet: 1023,
  desktop: 1024,
};

export const device = {
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};