import {
    SET_CURRENT_SYSTEM_REPORT_DATAGRID,
    SET_CSR_DURATION,
    CurrentSystemReportActionTypes} from '../types/CurrentSystemReportTypes';

import {  ICurrentSystemReportData }  from "../types";

export function setSelectedCurrentSystemReport(
        duration: number,
        receiptGrid: ICurrentSystemReportData[],
        deliveryGrid: ICurrentSystemReportData[],
        linepackGrid: ICurrentSystemReportData[]): CurrentSystemReportActionTypes{
            return{
                type: SET_CURRENT_SYSTEM_REPORT_DATAGRID,
                duration: duration,
                receiptGrid: receiptGrid,
                deliveryGrid: deliveryGrid,
                linepackGrid: linepackGrid
            }
        }

export function setCurrentSystemReportDuration(duration: number): CurrentSystemReportActionTypes {
    return {
        type: SET_CSR_DURATION,
        duration: duration
    }
}