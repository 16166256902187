import React, {useCallback, useEffect,useState} from 'react';
import * as S from '../styles';
import {locale} from '../locale';
import {Fetch} from "../utilities/Fetch";
import * as G from '../styles/OutageGasDayChangeRibbonStyles';
import {useDispatch} from "react-redux";
import {clearBusy} from "../actions/appActions";
import {IGasDayChangesDictionary, VolumeUnits} from '../types';
import DateUtilities from "../utilities/DateUtilities";
import useTypedSelector from "../hooks/useTypedSelector";
import {convertVolumeUnit} from '../utilities/UnitConversions';
import changeDown from "../icons/ChangeDown.svg";
import changeUp from "../icons/ChangeUp.svg";
import changeNeutral from "../icons/ChangeNeutral.svg";
import moreDetails from "../icons/moredetails.svg";

const OutageGasChangesRibbon: React.FC = ()=> {
    const unit = useTypedSelector(state => state.app.volumeUnit);
    const [gasDayChangesData, setGasDayChangesData]=useState<IGasDayChangesDictionary | undefined>(undefined);
    const [gasDayChangesDataLoaded, setGasDayChangesDataLoaded]= useState<boolean>(false);
    const [gasDayChangesDate, setGasDayChangesDate]= useState<string>('')
    const dispatch = useDispatch();

    const buildGasDayChangeData = useCallback(() => {
        Fetch
            .Build(`/gdc`, dispatch)
            .success(data => {
                displayGridData(data);
            })
            .get();
        }, [dispatch],
    );

    function displayGridData(gasDayChanges: IGasDayChangesDictionary) {
        setGasDayChangesDataLoaded(true);
        setGasDayChangesDate(gasDayChanges.gasDay.toString());
        setGasDayChangesData(gasDayChanges);
        dispatch(clearBusy());
    }

    useEffect(() => {
        if(!gasDayChangesDataLoaded){
            buildGasDayChangeData();
        }
    }, []);

    function getGasDateDateTime(): string {
        const gasDayDateTime = DateUtilities.UiDateTimeUtility.Reformat(gasDayChangesDate);
        const gasDayUpdateDate = gasDayDateTime.substring(0, gasDayDateTime.length - 5);
        const gasDayUpdateHour = gasDayDateTime.substring(gasDayDateTime.length - 5, gasDayDateTime.length - 3);
        if (parseInt(gasDayUpdateHour) >= 12) {
            return 'For Gas Day ' + gasDayUpdateDate + 'at '+ (gasDayUpdateHour === "12" ? 12 : parseInt(gasDayUpdateHour) - 12).toString() + gasDayDateTime.substring(gasDayDateTime.length - 3, gasDayDateTime.length) + 'PM MST'
        } else {
            return 'For Gas Day ' + gasDayUpdateDate + 'at '+ gasDayUpdateHour.toString() + gasDayDateTime.substring(gasDayDateTime.length - 3, gasDayDateTime.length) + 'AM MST'
        }
    }

    function convert(value: number, unit: VolumeUnits) {
        if (unit === VolumeUnits.MILLION_CUBIC_METERS || unit === VolumeUnits.BILLION_CUBIC_FEET) {
            return (Math.round(convertVolumeUnit(value, unit, false) * 10) / 10).toLocaleString("en-US", {maximumFractionDigits: 1})
        } else {
            return (Math.round(convertVolumeUnit(value, unit))).toLocaleString("en-US", {maximumFractionDigits: 0});
        }
    }

    function displayDifference(data: number, convertNumber: boolean) {
        if (data < 0 && convertNumber) {
            return convert((data * -1), unit);
        } else if (data < 0) {
            return data * -1;
        } else if (convertNumber) {
            return convert(data, unit);
        } else {
            return data;
        }
    }

    const changeIcon = (difference: number) => {
        return difference === 0 ? changeNeutral : (difference > 0 ? changeUp : changeDown);
    };

    return (
        <G.OutageGasDayChangeRibbonContainer>
            <G.GasDayChangeTable>
                <G.GasDayChangeTableHeaderRow key={"gas-day-changes-header-row"} minWidth={G.GasDayChangesColumnWidths.totalWidth}>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.gasChangeTitle}>
                            <G.GasDayChangeTableHeaderText><b>Gas Day Changes: </b></G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.receiptsWithoutStorage}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.receiptswithoutstorage')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.netStorage}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.netstorage')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.deliveriesWithoutStorage}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.deliverieswithoutstorage')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.linePackVariance}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.linepackvariance')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.sdAccounts}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.sdaccounts')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                    <S.TableCellWrapper>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.tolerance}>
                            <G.GasDayChangeTableHeaderText>{locale('outageGasDayChangeRibbon.tolerance')}</G.GasDayChangeTableHeaderText>
                        </S.TableColumn>
                    </S.TableCellWrapper>
                </G.GasDayChangeTableHeaderRow>
                <G.GasDayChangeTableRow key='current-value' minWidth={G.GasDayChangesColumnWidths.totalWidth}>
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.gasChangeTitle}>
                            <div style={{fontSize: "10px"}}>
                                {gasDayChangesDate && getGasDateDateTime()}
                            </div>
                        </S.TableColumn>
                    </S.TableCellWrapperNoGrow>
                    {gasDayChangesData ? Object.entries(gasDayChangesData).map(([key, value]) => {
                        if (key === "gasDay" || key === "toleranceMin" || key === "toleranceMax" || key === "difference" || key === "totalSDAccount") {
                            return;
                        } else {
                            return ( <S.TableCellWrapperNoGrow key={key}>
                                <S.TableColumn width = {G.GasDayChangesColumnWidths.all}>
                                    { convert(value, unit) } { unit }
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow> )
                        }
                    }) : 
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width = {G.GasDayChangesColumnWidths.allWidth}>
                                -
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                    }
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.sdAccounts}>
                            {gasDayChangesData ? Math.round(gasDayChangesData.totalSDAccount * 10) / 10: '-'} (TJ)
                        </S.TableColumn>
                    </S.TableCellWrapperNoGrow>
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.tolerance}>
                            {gasDayChangesData ? gasDayChangesData.toleranceMin + "/" + gasDayChangesData.toleranceMax : '-'} (%)
                        </S.TableColumn>
                    </S.TableCellWrapperNoGrow>
                </G.GasDayChangeTableRow>
                <G.GasDayChangeTableRow key='difference' minWidth={G.GasDayChangesColumnWidths.totalWidth}>
                    <S.TableCellWrapperNoGrow>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.gasChangeTitle}>
                            <div style={{color: "blue"}}>
                                <a href={`/#GasDaySummaryReport?unit=${unit}`}>
                                    More details <S.Icon style={{marginTop: 0, marginBottom: 0}} src={moreDetails}/>
                                </a>
                            </div>
                        </S.TableColumn>
                    </S.TableCellWrapperNoGrow>
                    {gasDayChangesData ? Object.entries(gasDayChangesData.difference).map(([key, value]) => {
                        if (key === "toleranceMin" || key === "toleranceMax") {
                            return;
                        } else if (key === "totalSDAccount") {
                            return ( <S.TableCellWrapperNoGrow>
                                <S.TableColumn width={G.GasDayChangesColumnWidths.sdAccounts}>
                                    <S.Icon style = {{marginTop: 0, marginBottom: 0}} src = {changeIcon(value)} />
                                    {displayDifference(Math.round(value * 10) / 10, false)} (TJ)
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow>);
                        }
                        else {
                            return ( <S.TableCellWrapperNoGrow key={key}>
                                <S.TableColumn width = {G.GasDayChangesColumnWidths.all}>
                                    <S.Icon style = {{marginTop: 0, marginBottom: 0}} src = {changeIcon(value)} />
                                    { displayDifference(value, true) } { unit }
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow>);
                        }
                    }) : 
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width = {G.GasDayChangesColumnWidths.allWidth}>
                                -
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                    }
                    {gasDayChangesData && <S.TableCellWrapperNoGrow>
                        <S.TableColumn width={G.GasDayChangesColumnWidths.tolerance}>
                            <S.Icon style = {{marginTop: 0, marginBottom: 0}} src = {changeIcon(gasDayChangesData.difference.toleranceMin)} />
                            {displayDifference(gasDayChangesData.difference.toleranceMin, false) + "/" + displayDifference(gasDayChangesData.difference.toleranceMax, false)}
                            <S.Icon style = {{marginTop: 0, marginBottom: 0}} src = {changeIcon(gasDayChangesData.difference.toleranceMax)} />
                            (%)
                        </S.TableColumn>
                    </S.TableCellWrapperNoGrow>}
                </G.GasDayChangeTableRow>
            </G.GasDayChangeTable>
        </G.OutageGasDayChangeRibbonContainer>
    )
}

export default OutageGasChangesRibbon