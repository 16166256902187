import React, {useEffect, useState} from 'react';
import * as S from "../styles"
import {locale} from "../locale";
import BackNavigationHeader from "./BackNavigationHeader";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {showConfirm} from "../actions/appActions";
import {DateTime} from "luxon";
import DateUtilities from "../utilities/DateUtilities";
import ResultModal from "./ResultModal";
import {Fetch} from "../utilities/Fetch";
import PageSelector, {PagingInfo} from "./PageSelector";
import useTypedSelector from "../hooks/useTypedSelector";
import { setOutageComparisonLoaded} from "../actions/outageComparisonActions";
import useDailyOperatingPlanCompare from "../hooks/useDailyOperatingPlanCompare";
import {setAreasLoaded} from "../actions/outagesActions";
import {clearOutageChartDataLoaded} from "../actions/outageChartActions";

const AdminPublicationList: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [lastUpdatedDate, setLastUpdatedDate] = useState<string|null>(null);
    const columns = {
        statusWidth: '100',
        publishWidth: '225',
        pullWith: '225',
        userWidth: '250',
        actionWidth: '150',
    };

    interface IOutagePublication {
        id: number;
        pullDateTimeUtc: string;
        publishedDateTimeUtc: string;
        emailSentDateTimeUtc?: string;
        publishedBy: string;
        status: string;
    }

    useDailyOperatingPlanCompare();

    const recentPublishDates = useTypedSelector(x => x.outageComparison.publishDates.recentPublishDates);
    const [lastPublishDate, setLastPublishDate] = useState<string>("");

    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const [publicationsLoaded, setPublicationsLoaded] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<PagingInfo<IOutagePublication>|undefined>(undefined);

    useEffect(() => {
        setLastPublishDate(DateUtilities.ServiceDateUtility.Max(recentPublishDates))
    }, [recentPublishDates]);

    useEffect(() => {
        if (!publicationsLoaded) {
            Fetch.Build("/publications", dispatch)
                .success(x => {
                    setPublicationsLoaded(true);
                    setCurrentPage(new PagingInfo(x??[]));
                })
                .get();
        }
        if (publicationsLoaded && currentPage !== undefined && lastUpdatedDate === null) {
            const latest = currentPage.allItems.find(x => x.status === "Published");
            setLastUpdatedDate(latest !== undefined ? latest.publishedDateTimeUtc : "N/A");
        }
    }, [currentPage, publicationsLoaded, dispatch, lastUpdatedDate]);

    function deleteDraft(finallyAction: () => void = () => setShowResult(true)) {
        setResultSuccess(true);
        setResultText(locale("deleteSuccess"));
        Fetch.Build("/publications/draft", dispatch)
            .error(() => {
                setResultText(locale("genericError"));
                setResultSuccess(false);
            }).finally(finallyAction)
            .delete();
    }

    function deletePublished(id: number, finallyAction: () => void = () => setShowResult(true)) {
        setResultSuccess(true);
        setResultText(locale("deletePublishedSuccess"));
        Fetch.Build(`/publications/published/${id}`, dispatch)
            .error(() => {
                setResultText(locale("genericError"));
                setResultSuccess(false);
            }).finally(() => {
                finallyAction();
                dispatch(setOutageComparisonLoaded(false));
                dispatch(setAreasLoaded(false));
                dispatch(clearOutageChartDataLoaded());
            }).delete();
    }

    function email(id: number) {
        setResultText(locale("emailDailyOperatingPlanSuccess"));
        setResultSuccess(true);
        Fetch.Build(`/publications/email/${id}`, dispatch)
            .error(() => {
                setResultText(locale("genericError"));
                setResultSuccess(false);
            })
            .finally(() => {
                setShowResult(true);
            })
            .post();
    }

    function deleteDraftClick() {
        dispatch(showConfirm({
            headerText: locale('deleteDraft'),
            bodyText: locale('deleteDraftConfirm'),
            buttonCancelText:locale('cancel'),
            buttonConfirmText:locale('delete'),
            onConfirm: deleteDraft
        }));
    }

    function deletePublishedClick(id: number) {
        // alert(id);
        dispatch(showConfirm({
            headerText: locale('deletePublished'),
            bodyText: locale('deletePublishedConfirm'),
            buttonCancelText:locale('cancel'),
            buttonConfirmText:locale('delete'),
            background: "yellow",
            onConfirm: () => deletePublished(id)
        }));
    }

    function emailClick(id: number) {
        dispatch(showConfirm({
            headerText: locale('emailDailyOperatingPlanHeader'),
            bodyText: locale('emailDailyOperatingPlanDecision'),
            buttonCancelText:locale('cancel'),
            buttonConfirmText:locale('email'),
            background: "yellow",
            onConfirm: () => email(id)
        }));
    }

    function navigateToAdminPublicationClick() {
        const finallyAction = () => {history.push("AdminDailyOperatingPlan")};

        setPublicationsLoaded(false);
        Fetch.Build("/publications", dispatch)
                .success(x => {
                    setPublicationsLoaded(true);
                    const page: PagingInfo<IOutagePublication> = new PagingInfo(x??[]);
                    if (page !== undefined && page.allItems.filter(p => p.status === "Draft").length > 0) {
                        dispatch(showConfirm({
                            headerText: locale('deleteDraft'),
                            bodyText: locale('deleteDraftConfirm2'),
                            buttonCancelText:locale('cancel'),
                            buttonConfirmText:locale('delete'),
                            onConfirm: () => {
                                deleteDraft(finallyAction);
                            }
                        }));
                    } else {
                        finallyAction();
                    }
                    setCurrentPage(page);
                })
                .get();
    }

    function navigateToOutagesClick(publication: IOutagePublication) {
        const publishDate = publication.publishedDateTimeUtc;
        history.push(`/ViewDailyOperatingPlan/${publishDate}`);
    }

    function navigateToOutagesChartClick(publication: IOutagePublication) {
        const publishDate = publication.publishedDateTimeUtc;
        history.push(`/ViewDailyOperatingPlanChart/${publishDate}`);
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('publicationList')}/>

            <S.InformationContainer>
                <S.FlexContainerRow>
                    <S.InformationItem>
                        <S.Label>{locale("lastUpdatedDate")}</S.Label>
                        <S.Value>{lastUpdatedDate && DateUtilities.UiDateTimeUtility.ReformatUtcToLocal(lastUpdatedDate)}</S.Value>
                    </S.InformationItem>
                    <S.InformationItem>
                        <S.Label>{locale("currentGasDay")}</S.Label>
                        <S.Value>{DateUtilities.UiDateUtility.Now()}</S.Value>
                    </S.InformationItem>
                </S.FlexContainerRow>

            </S.InformationContainer>

            <S.InformationContainer>
                <S.Button onClick={navigateToAdminPublicationClick}>{locale('loadOperatingPlan')}</S.Button>
            </S.InformationContainer>

            <div style={{height: "700px"}}>
                <S.PublicationListTableHeaderRow key={"publication-header-row"}>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumnNoGrow width={columns.statusWidth}>
                                <S.TableCellHeaderTextLeft>{locale("status")}</S.TableCellHeaderTextLeft>
                            </S.TableColumnNoGrow>
                        </S.TableCellWrapperNoGrow>
                        <S.TableCellWrapper>
                            <S.TableColumn width={columns.publishWidth}>
                                <S.TableCellHeaderTextLeft>{locale("publishDraftDateTime")}</S.TableCellHeaderTextLeft>
                            </S.TableColumn>
                        </S.TableCellWrapper>
                        <S.TableCellWrapper>
                            <S.TableColumn width={columns.pullWith}>
                                <S.TableCellHeaderTextLeft>{locale("pullDateTime")}</S.TableCellHeaderTextLeft>
                            </S.TableColumn>
                        </S.TableCellWrapper>
                        <S.TableCellWrapper>
                            <S.TableColumn width={columns.userWidth}>
                                <S.TableCellHeaderTextLeft>{locale("publishedBy")}</S.TableCellHeaderTextLeft>
                            </S.TableColumn>
                        </S.TableCellWrapper>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumnNoGrow width={columns.actionWidth}>
                                <S.TableCellHeaderText>{locale("action")}</S.TableCellHeaderText>
                            </S.TableColumnNoGrow>
                        </S.TableCellWrapperNoGrow>
                </S.PublicationListTableHeaderRow>

                {currentPage !== undefined && currentPage.items.length !== 0 && currentPage.items.map(publication => {
                    return <S.PublicationListTableRow key={publication.id + "-publication-row"}>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumnNoGrow width={columns.statusWidth}>
                                    <S.PublicationListContent>{publication.status}</S.PublicationListContent>
                                </S.TableColumnNoGrow>
                            </S.TableCellWrapperNoGrow>
                            <S.TableCellWrapper>
                                <S.TableColumn width={columns.publishWidth}>
                                    <S.PublicationListContent>
                                        {DateTime.fromFormat(publication.publishedDateTimeUtc, DateUtilities.serviceDateFormat, {zone: "UTC"}).toLocal().toFormat(DateUtilities.publishDateTimestamp)}
                                    </S.PublicationListContent>
                                </S.TableColumn>
                            </S.TableCellWrapper>
                            <S.TableCellWrapper>
                                <S.TableColumn width={columns.pullWith}>
                                    <S.PublicationListContent>
                                        {DateTime.fromFormat(publication.pullDateTimeUtc, DateUtilities.serviceDateFormat, {zone: "UTC"}).toLocal().toFormat(DateUtilities.publishDateTimestamp)}
                                    </S.PublicationListContent>
                                </S.TableColumn>
                            </S.TableCellWrapper>
                            <S.TableCellWrapper>
                                <S.TableColumn width={columns.userWidth}>
                                    <S.PublicationListContent>{publication.publishedBy}</S.PublicationListContent>
                                </S.TableColumn>
                            </S.TableCellWrapper>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumnNoGrow width={columns.actionWidth}>
                                    {publication.status === "Draft" && <div style={{justifyContent: "space-around", display:"flex"}}>
                                        <S.ActionLink onClick={() => navigateToOutagesChartClick(publication)}>{locale("admin.outage.previewChart")}</S.ActionLink>
                                        <S.ActionLink onClick={() => history.push("/AdminDailyOperatingPlan")}>{locale("edit")}</S.ActionLink>
                                        <S.ActionLink onClick={() => deleteDraftClick()}>{locale("delete")}</S.ActionLink>
                                    </div>}
                                    {publication.status !== "Draft" && <div style={{justifyContent: "space-around", display:"flex"}}>
                                        <S.ActionLink onClick={() => navigateToOutagesClick(publication)}>{locale("view")}</S.ActionLink>
                                        {(DateTime.fromFormat(publication.publishedDateTimeUtc, DateUtilities.serviceDateFormat) > DateTime.utc().minus({days: 1})) && <S.ActionLink onClick={() => deletePublishedClick(publication.id)}>{locale("delete")}</S.ActionLink>}
                                        {!publication.emailSentDateTimeUtc && publication.publishedDateTimeUtc === lastPublishDate &&  <S.ActionLink onClick={() => emailClick(publication.id)}>{locale("email")}</S.ActionLink>}
                                    </div>}
                                </S.TableColumnNoGrow>
                            </S.TableCellWrapperNoGrow>
                    </S.PublicationListTableRow>
                })}
                {showResult && <ResultModal text={resultText} success={resultSuccess} onExit={() => {
                    setShowResult(false);
                    setPublicationsLoaded(false);
                }} />}
            </div>

            {currentPage !== null && <PageSelector saveState={setCurrentPage} pagingInfo={currentPage as PagingInfo<any>}/>}

        </S.PageWithNavigationContainer>
    );
};

export default AdminPublicationList;