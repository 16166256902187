import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import useApiService from "../../hooks/useApiService";
import {IArea, IOutage, AdminDailyOperationPlanColumnWidths} from "../../types";
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {RouteComponentProps, useHistory} from "react-router";
import SortFunctions from "../../utilities/SortFunctions";
import DateUtilities from "../../utilities/DateUtilities";
import HeaderRowOutage from "./HeaderRowOutage";
import OutageChart from "../../components/OutageChart";
interface Params {
    publicationDateUtc: string;
}

const ViewDailyOperatingPlanChart: React.FC<RouteComponentProps> = (props) => {
    const parameter = props.match.params as Params;

    const history = useHistory();

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push("/AdminPublicationList")}
                                  leftContent={locale('admin.outage.dailyOperatingPlanChartView')}/>
            <OutageChart isAdminChart={true} selectPublishDate={parameter.publicationDateUtc}/>
        </S.PageWithNavigationContainer>
    );
};

export default ViewDailyOperatingPlanChart;