import {
	SearchActionTypes,
	SET_SEARCH_OPTIONS,
	SET_SEARCH_OPTIONS_LOADED,
	CLEAR_SEARCH_OPTIONS_LOADED,
	RESET_SEARCH_CRITERIA,
	SET_SEARCH_STATE,
    SET_SEARCH_DRAWER_IS_OPEN
} from '../types/SearchActionTypes';
import { IServiceProvider, Severity, SeverityTypes, SearchKeyTypes, ISubscription } from '../types';

interface ISearchSubscriptionCategories {
    name: string;
    serviceProvider: string;
}

export interface ISearchState {
    serviceProviders: IServiceProvider[];
    subscriptionCategories: ISearchSubscriptionCategories[];
    loaded: boolean;
    searchSubject: boolean;
    searchBody: boolean;
    searchExactPhrase: boolean;
    severity: Severity;
    selectedServiceProvider: string;
    selectedSubscriptionCategory: string;
    submittedStartDate: Date | undefined;
    submittedEndDate: Date | undefined;
    effectiveStartDate: Date | undefined;
    effectiveEndDate: Date | undefined;
    searchText: string;
    isDirty: boolean;
    searchDrawerIsOpen: boolean;
}

export interface ISearchStore {
    [SearchKeyTypes.ADMIN]: ISearchState;
    [SearchKeyTypes.PUBLIC]: ISearchState;
}

export const initialSearchState: ISearchState = {
    serviceProviders: [],
    subscriptionCategories: [],
    loaded: false,
    searchSubject: true,
    searchBody: true,
    searchExactPhrase: false,
    severity: SeverityTypes.ALL,
    selectedServiceProvider: '',
    selectedSubscriptionCategory: '',
    submittedStartDate: undefined,
    submittedEndDate: undefined,
    effectiveStartDate: undefined,
    effectiveEndDate: undefined,
    searchText: '',
    isDirty: false,
    searchDrawerIsOpen: false,
};

export const initialState = {
    public: {...initialSearchState},
    admin: {...initialSearchState}
};

export function searchReducer(state: ISearchStore = initialState, action: SearchActionTypes): ISearchStore {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_SEARCH_OPTIONS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key as SearchKeyTypes],
                    serviceProviders: action.serviceProviders,
                    subscriptionCategories: action.subscriptionCategories.map(
                        (subscription: ISubscription) => ({
                            name: subscription.name,
                            serviceProvider: subscription.serviceProvider.name
                        })
                    ),
                    loaded: true
                }
            };
        case SET_SEARCH_OPTIONS_LOADED:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key as SearchKeyTypes],
                    loaded: action.loaded
                }
            };
        case CLEAR_SEARCH_OPTIONS_LOADED:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key as SearchKeyTypes],
                    loaded: false
                }
            };
        case RESET_SEARCH_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key as SearchKeyTypes],
                    ...initialSearchState,
                    isDirty: false
                }
            };
        case SET_SEARCH_STATE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key as SearchKeyTypes],
                    ...action.state
                }
            };
        case SET_SEARCH_DRAWER_IS_OPEN:
            return {
            	...state,
				[action.key]: {
            		...state[action.key as SearchKeyTypes],
                    searchDrawerIsOpen: action.searchDrawerIsOpen
				}
			};
        default:
            return state;
    }
}
