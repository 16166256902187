import React, {useEffect, useState} from 'react';
import * as S from "../styles"
import {IOutageGroups} from "../types";
import useTypedSelector from "../hooks/useTypedSelector";
import Footer from "./Footer";
import StatusLegend from "./StatusLegend";
import {
    AreaOutagesSummaryCardSectionLine
} from "./AreaOutagesCardLine";
import upcomingIcon30 from "../icons/upcoming_30_blue.svg";
import upcomingIcon30Plus from "../icons/upcoming_30_plus_blue.svg";
import currentIcon from "../icons/outages_current_blue.svg";
import MoreOptions, {MoreOptionsItems} from "./MoreOptions";
import useAreas from "../hooks/useAreas";
import useDailyOperatingPlanCompare from "../hooks/useDailyOperatingPlanCompare";
import SortFunctions from "../utilities/SortFunctions";
import {AreaOutagesSummary} from "../types/AreaOutagesSummary";
import {locale} from "../locale";
import arrow from "../icons/chevron_right.svg";
import {useHistory} from "react-router-dom";
import GetSelectedPublishDateAreaCapability from "../utilities/GetSelectedPublishDateAreaCapability";
import GoogleAnalyticsUtilities from "../utilities/GoogleAnalyticsUtilities";
import BetaBanner from "./BetaBanner";
import {PublishDateUtilities} from "../utilities/PublishDateUtilities";
import OutageControls from './OutageControls';
import { convertVolumeUnit } from '../utilities/UnitConversions';
import {HeatValueUtilities} from "../utilities/HeatValueUtilities";
import useHeatValues from "../hooks/useHeatValues";
import heatValueIcon from "../icons/thermometer.svg";
import {useDispatch} from "react-redux";
import {FileDownloadUtilities} from "../utilities/FileDownloadUtilities";

const AreaSummaryList: React.FC = () => {
    useEffect(() => {
        GoogleAnalyticsUtilities.PageView("AreaSummaryList");
    }, []);
    useAreas();
    useDailyOperatingPlanCompare();

    const history = useHistory();
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)
    const outages = useTypedSelector(state => state.outageComparison.outageHistory.comparison);
    const areas = useTypedSelector(state => state.outages.areas);
    const unit = useTypedSelector(state => state.app.volumeUnit);
    const heatValue = useTypedSelector(x => x.heatValue.heatValues);
    const [groupedOutages, setGroupedOutages] = useState<IOutageGroups>({});
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates);

    useHeatValues();
    const dispatch = useDispatch();

    useEffect(() => {
        const outageDictionary: IOutageGroups = {};

        for (const area of areas) {
            outageDictionary[area.id] = new AreaOutagesSummary(outages.filter(outage => area.id === outage.areaId));
        }
        setGroupedOutages(outageDictionary);
    }, [outages, areas]);

    return (
        <div>
            <BetaBanner/>
            <S.PageWithNavigationContainer hasBanner={showBanner} hasStickyControls={true}>
                {<S.BackNavigationHeader style={{cursor: "default"}} isHiddenMobile={true}>
                    <S.CenteredPageTitle>{locale("outages")}</S.CenteredPageTitle>
                    <MoreOptions isVisible={true} options={[
                        MoreOptionsItems.DownloadOutages,
                        MoreOptionsItems.ViewAllOutages,
                    ]}/>
                </S.BackNavigationHeader>}

                <OutageControls hasBanner={showBanner} showIfTopScroll={true}/>
                <S.OutageAreaCardGrid>
                    {areas.sort(SortFunctions.DefaultAreaSortFunction()).map(area => {
                        return (<S.OutageAreaCard key={area.acronym}>
                            <S.OutageAreaCardHeader color={area.color}>
                                <S.OutageAreaCardHeaderTag>{area.typeName}</S.OutageAreaCardHeaderTag>
                                <S.OutageAreaCardSubHeader>
                                    <S.OutageAreaCardHeaderAreaName>{area.displayName}</S.OutageAreaCardHeaderAreaName>
                                    {!isNaN(HeatValueUtilities.getHeatValue(heatValue, area.acronym, publishDates.selectedPublishDate)) &&
                                    <S.FlexContainerRow style={{alignItems: "baseline"}}>
                                        <img src={heatValueIcon} alt="HV" style={{marginRight: '6px'}}/>
                                        <S.OutageAreaCardTextCapability
                                            style={{color: '#000000'}}>{HeatValueUtilities.getHeatValue(heatValue, area.acronym, publishDates.selectedPublishDate)}
                                        </S.OutageAreaCardTextCapability>
                                        <S.OutageAreaCardTextUnit>{locale('heatValue.units')}</S.OutageAreaCardTextUnit>
                                    </S.FlexContainerRow>}

                                </S.OutageAreaCardSubHeader>

                                {area.capabilities.length > 0 && (area.id !== 4 || showFHBCBaseCapability)?
                                    <S.OutageAreaCardSubHeader>
                                        <S.OutageAreaCardText>{locale('baseCapability')}</S.OutageAreaCardText>
                                        <S.FlexContainerRow style={{alignItems: "baseline"}}>
                                            <S.OutageAreaCardTextCapability>
                                                {convertVolumeUnit(GetSelectedPublishDateAreaCapability(area)?.capability ?? 0, unit).toLocaleString()}{" "}
                                            </S.OutageAreaCardTextCapability>
                                            <S.OutageAreaCardTextUnit>{unit}</S.OutageAreaCardTextUnit>
                                        </S.FlexContainerRow>
                                    </S.OutageAreaCardSubHeader> : <S.OutageAreaCardSubHeader/>
                                }
                            </S.OutageAreaCardHeader>

                            {Boolean(area) && Boolean(groupedOutages[area.id]) &&
                            <S.OutageAreaCardLine onClick={() => history.push(`/OutagesList/${area.acronym}`)}>
                                <S.FlexContainerColumnNoWrap style={{width: "100%", justifyContent: "space-around"}}>
                                    <AreaOutagesSummaryCardSectionLine outages={groupedOutages[area.id]?.current}
                                                                       icon={currentIcon}
                                                                       labelText={locale("gasDay").replace("{gasDay}", PublishDateUtilities.FormatPublishedDate(publishDates.selectedPublishDate, publishDates.recentPublishDates))}/>
                                    <AreaOutagesSummaryCardSectionLine outages={groupedOutages[area.id]?.upcoming30Days}
                                                                       icon={upcomingIcon30}
                                                                       labelText={locale("next30days")}/>
                                    <AreaOutagesSummaryCardSectionLine
                                        outages={groupedOutages[area.id]?.upcomingAfter30Days} icon={upcomingIcon30Plus}
                                        labelText={locale("next30plusDays")}/>
                                </S.FlexContainerColumnNoWrap>
                                <S.FlexContainerColumnNoWrap>
                                    <S.RightIcon src={arrow}/>
                                </S.FlexContainerColumnNoWrap>
                            </S.OutageAreaCardLine>
                            }
                            {(area.centerLat === null) &&
                            <S.OutageAreaCardDisclaimerLine>
                                {area.isPlantTurnAround ?
                                    <span>*{locale('plantTurnaroundDisclaimer')}</span> :
                                    <span>*{locale('localAreaDisclaimer')}</span>}
                            </S.OutageAreaCardDisclaimerLine>
                            }
                            {(area.acronym === "NEDA") &&
                            <S.OutageAreaCardDisclaimerLine>
                                {<span>*{locale("nedaDisclaimer")}</span>}
                            </S.OutageAreaCardDisclaimerLine>
                            }
                            {(area.isPlantTurnAround) &&
                            <S.PlantTurnaroundCsvDownloadLine>
                            <span
                            onClick={() => FileDownloadUtilities.initiateCsvFileDownload(`plantturnaroundactivity/csv/${publishDates.selectedPublishDate}`, dispatch)}>{locale('plantTurnaroundDownloadCsv')}</span>
                            </S.PlantTurnaroundCsvDownloadLine>
                            }
                        </S.OutageAreaCard>);
                    })}
                </S.OutageAreaCardGrid>
            </S.PageWithNavigationContainer>
            <Footer/>
            <S.LegendContainer><StatusLegend/></S.LegendContainer>
            <S.MobileOptions>
                <MoreOptions isVisible={true} options={[
                    MoreOptionsItems.DownloadOutages,
                    MoreOptionsItems.ViewAllOutages
                ]}/>
            </S.MobileOptions>
        </div>
    );
};

export default AreaSummaryList;