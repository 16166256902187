import {
    CLEAR_OUTAGES_LOADED,
    OutageActionTypes,
    SET_AREAS,
    SET_OUTAGE_DISPLAY,
    SET_OUTAGES,
    SET_OUTAGES_LOADED,
} from "../types/OutageActionTypes";
import {IArea, IOutage, OutageDisplay} from "../types";


export interface IOutageState {
    outages: IOutage[];
    outagesLoaded: boolean;
    publishDate: string;
    lastPublishDateTimeUtc: string;
    error: string;
    display: OutageDisplay;
    areas: IArea[];
    areasLoaded: boolean;
    isBusy: boolean;
}

const initialState: IOutageState = {
    outages: [],
    outagesLoaded: false,
    publishDate: "",
    lastPublishDateTimeUtc: "",
    error: "",
    display: OutageDisplay.Map,
    areas: [],
    areasLoaded: false,
    isBusy: false,
};

export function outageReducer(state = initialState, action: OutageActionTypes): IOutageState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_OUTAGE_DISPLAY:
            return {...state, display: action.display};
        case SET_OUTAGES:
            const lastPublishDateTimeUtc = action.outages.length > 0 && action.publishDate === "" ? action.outages[0].publishedDateTimeUtc : state.lastPublishDateTimeUtc;
            return {...state, outages: action.outages, outagesLoaded: true, publishDate: action.publishDate, lastPublishDateTimeUtc: lastPublishDateTimeUtc};
        case SET_AREAS:
            return {...state, areas: action.areas, areasLoaded: true};
        case SET_OUTAGES_LOADED:
            return {...state, areasLoaded: action.loaded, outagesLoaded: action.loaded, lastPublishDateTimeUtc: ""};
        case CLEAR_OUTAGES_LOADED:
            return {...state, outagesLoaded: false};
        default:
            return state
    }
}