import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {locale} from "../../locale";
import styled from 'styled-components';
import * as S from '../../styles';
import {device} from '../../styles/DeviceSizes';
import colors from '../../styles/Colors';
import { ScrollToTop } from "../../utilities/ScrollUtilities"
import {
	setSearchState, resetSearchCriteria, setSearchDrawerIsOpen
} from '../../actions/searchActions';
import useBulletinSearch from '../../hooks/useBulletinSearch';
import DateRangeInput from '../DateRangeInput';
import { Severity, SeverityTypes, SearchKey } from '../../types';
import search from '../../icons/search_white.svg';
import close_x from '../../icons/close_x.svg';
import arrowUp from '../../icons/chevron-up-tc_blue.svg';
import arrowDown from '../../icons/chevron-down-tc_blue.svg';
import { ISearchState } from '../../reducers/searchReducer';

import { setBulletinRowsPerPage, setBulletinPagingReset } from '../../actions/bulletinActions';

const NoticeTypeSelect = styled(S.Select)`
    margin-right: 30px;
`;

const SubmittedDateDateRangeInputContainer = styled.div`
	@media ${device.mobile} {
		width: 100%;
	}
`;

const CenteredAdvancedSearchRow = styled(S.AdvancedSearchRow)`
	justify-content: center;
`;

const AdvancedSearchOptionsRow = styled(S.AdvancedSearchRow)`
	@media ${device.mobile} {
		justify-content: start;
	}
`;

const ServiceProviderSelect = styled(S.Select)`
    max-width: 300px;
    @media ${device.mobile} {
    	max-width: 100%;
    	margin: 0 0 24px 0;
    }
`;

const CenteredSearchBar = styled(S.SearchBar)`
    justify-content: center;
    margin: 12px auto 0 auto;
    @media ${device.mobile} {
    	flex-direction: column;
    	width: auto;
    	height: auto;
    	margin: 12px 16px;
    	div {
    		padding: 6px 0;
    	}
    }
`;

const CloseIcon = styled(S.Icon)`
    position: absolute;
    right: 5px;
    top: 7px;
    cursor: pointer;
`;
const NoticeMissing = styled.div`
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: ${colors.diff_red};
	margin-top: 20px;
`;
const SearchLabel = styled.div`
	font-family: "Maven Pro";
	font-size: 14px;
	padding: 5px 0;
`;

const Label = styled.label`
	font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
`;

const NoticeTypeWrapper = styled.div`
	margin-right: 30px;
	@media ${device.mobile} {
		margin: 0;
		width: 100%;
	}
`;

interface Props {
	noticeMissing?: boolean;
	section: SearchKey;
}

const BulletinSearch: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const { searchState } = useBulletinSearch(props.section);
	const [ localSearchState, setLocalSearchState ] = useState<ISearchState>(searchState);
	const [ noticeTypes, setNoticeTypes ] = useState<string[]>([]);
	const [ serviceProviders, setServiceProviders ] = useState<string[]>([]);

    useEffect(() => {
		if (searchState.searchDrawerIsOpen) {
			ScrollToTop();
		}
	}, [searchState.searchDrawerIsOpen]);

	useEffect(() => setLocalSearchState(searchState), [searchState]);

	const getNoticeTypes = useCallback(() => {
		if(localSearchState.selectedServiceProvider !== '') {
			return searchState.subscriptionCategories.filter(
				sub => sub.serviceProvider === localSearchState.selectedServiceProvider
				).map(sub => sub.name);
		}
		return searchState.subscriptionCategories.map(sub => sub.name).filter((sub, index, array) => array.indexOf(sub) === index);
	}, [localSearchState.selectedServiceProvider, searchState.subscriptionCategories]);

	const getServiceProviders = useCallback(() => {

		if(localSearchState.selectedSubscriptionCategory !== '') {
			return searchState.subscriptionCategories.filter(
				sub => sub.name === localSearchState.selectedSubscriptionCategory
				).map(sub => sub.serviceProvider);
		}
		return searchState.serviceProviders.map(provider => provider.name);
	}, [localSearchState.selectedSubscriptionCategory, searchState.subscriptionCategories, searchState.serviceProviders]);

	useEffect(() => {
		setNoticeTypes(getNoticeTypes());
	}, [localSearchState.selectedServiceProvider, searchState, getNoticeTypes]);

	useEffect(() => {
		setServiceProviders(getServiceProviders());
	}, [localSearchState.selectedSubscriptionCategory, searchState, getServiceProviders]);

	return (
		<React.Fragment>
			{searchState.searchDrawerIsOpen && (
				<S.AdvancedSearchDrawerWrapper>
					<CenteredAdvancedSearchRow>
						<NoticeTypeWrapper>
							<SearchLabel>{locale('search.label.noticeType')}</SearchLabel>
    						<NoticeTypeSelect
    							onChange={(e) => setLocalSearchState({...localSearchState, selectedSubscriptionCategory: e.target.value, isDirty: true})}
    							value={localSearchState.selectedSubscriptionCategory}
    						>
    							<option value="">Notice Type</option>
    							{noticeTypes.map((subscription) => (
    								<option key={subscription} value={subscription}>
    									{subscription}
    								</option>
    							))}
						    </NoticeTypeSelect>
						</NoticeTypeWrapper>
						<NoticeTypeWrapper>
							<SearchLabel>{locale('search.label.submittedDate')}</SearchLabel>
							<SubmittedDateDateRangeInputContainer>
								<DateRangeInput
									handleChange={(e) => setLocalSearchState({...localSearchState, submittedStartDate: e?.[0], submittedEndDate: e?.[1], isDirty: true})}
    								start={localSearchState.submittedStartDate}
    								end={localSearchState.submittedEndDate}
									placeholder={''}
								/>
							</SubmittedDateDateRangeInputContainer>
						</NoticeTypeWrapper>
						<NoticeTypeWrapper>
							<SubmittedDateDateRangeInputContainer>
							<SearchLabel>{locale('search.label.effectiveDate')}</SearchLabel>
							<DateRangeInput
								handleChange={(e) => setLocalSearchState({...localSearchState, effectiveStartDate: e?.[0], effectiveEndDate: e?.[1], isDirty: true})}
    							start={localSearchState.effectiveStartDate}
    							end={localSearchState.effectiveEndDate}
								placeholder={''}
							/>
							</SubmittedDateDateRangeInputContainer>
						</NoticeTypeWrapper>
					</CenteredAdvancedSearchRow>
					<AdvancedSearchOptionsRow>
						<S.AdvancedSearchOptions>
							<span>{locale('search.label.critical')}:</span>
							<S.RadioInput>
								<input
									id={'nonCritical'}
									name="severity"
									onChange={(e) => setLocalSearchState({...localSearchState, severity: e.target.value as Severity, isDirty: true})}
									value={SeverityTypes.NON_CRITICAL}
									checked={localSearchState.severity === SeverityTypes.NON_CRITICAL}
									type="radio"
								/>
								<Label htmlFor={"nonCritical"}>{locale('search.label.nonCritical')}</Label>
								<input
									id={'critical'}
									name="severity"
									onChange={(e) => setLocalSearchState({...localSearchState, severity: e.target.value as Severity, isDirty: true})}
									value={SeverityTypes.CRITICAL}
									checked={localSearchState.severity === SeverityTypes.CRITICAL}
									type="radio"
								/>
								<Label htmlFor={'critical'}>{locale('search.label.critical')}</Label>
								<input
									id={'severityAll'}
									name="severity"
									onChange={(e) => setLocalSearchState({...localSearchState, severity: e.target.value as Severity, isDirty: true})}
									value={SeverityTypes.ALL}
									checked={localSearchState.severity === SeverityTypes.ALL}
									type="radio"
								/>
								<Label htmlFor={'severityAll'}>{locale('search.label.all')}</Label>
							</S.RadioInput>
						</S.AdvancedSearchOptions>
						<S.AdvancedSearchOptions>
							<span>{locale('search.label.searchWords')}:</span>
							<S.RadioInput>
								<input
									onChange={() => setLocalSearchState({...localSearchState, searchSubject: !localSearchState.searchSubject, isDirty: true})}
									checked={localSearchState.searchSubject}
									type="checkbox"
								/>
								<Label htmlFor={'searchSubject'}>{locale('search.label.subject')}</Label>
								<input
									id={'searchBody'}
									onChange={() => setLocalSearchState({...localSearchState, searchBody: !localSearchState.searchBody, isDirty: true})}
									checked={localSearchState.searchBody}
									type="checkbox"
								/>
								<Label htmlFor={'searchBody'}>{locale('search.label.text')}</Label>
								<input
									id={'searchExact'}
									onChange={() => setLocalSearchState({...localSearchState, searchExactPhrase: !localSearchState.searchExactPhrase, isDirty: true})}
									checked={localSearchState.searchExactPhrase}
									type="checkbox"
								/>
								<Label htmlFor={'searchExact'}>{locale('search.label.exactPhrase')}</Label>
							</S.RadioInput>
						</S.AdvancedSearchOptions>
					</AdvancedSearchOptionsRow>
				</S.AdvancedSearchDrawerWrapper>
			)}
			<S.SearchWrapper>
				{props.noticeMissing && <NoticeMissing>{locale('bulletin.preview.notAvailable')}</NoticeMissing>}
				<CenteredSearchBar onSubmit={ e => {
					e.preventDefault();
					dispatch(setSearchState(props.section, localSearchState));
					dispatch(setBulletinPagingReset(true));
				}}>
						<ServiceProviderSelect
							onChange={(e) => setLocalSearchState({...localSearchState, selectedServiceProvider: e.target.value, isDirty: true})}
							value={localSearchState.selectedServiceProvider}
						>
							<option value="">{locale('search.option.allServiceProviders')}</option>
							{serviceProviders.map((provider) => (
								<option key={provider} value={provider}>
									{provider}
								</option>
							))}
						</ServiceProviderSelect>
						<S.SearchBarInputContainer>
							<S.SearchBarInput
								placeholder={locale('bulletins.searchPlaceholder')}
								value={localSearchState.searchText}
								onChange={(e) => setLocalSearchState({...localSearchState, searchText: e.target.value, isDirty: true})}
							/>
							{localSearchState.searchText.length > 0 && (
								<CloseIcon
									src={close_x}
									width={16}
									height={16}
									onClick={() => setLocalSearchState({...localSearchState, searchText: ''})}
								/>
							)}
						</S.SearchBarInputContainer>
					<S.BlueSearchButton type={"submit"}>
						<S.Icon src={search} style={{ height: '24px', width: '24px' }} />
						{locale('search')}
					</S.BlueSearchButton>
					<S.SearchResetButton
						onClick={() => {dispatch(setBulletinPagingReset(true));dispatch(resetSearchCriteria(props.section));dispatch(setBulletinRowsPerPage(50));}}
					>{locale('search.reset')}</S.SearchResetButton>
				</CenteredSearchBar>
				<S.AdvancedSearchButton>
					<div onClick={() => dispatch(setSearchDrawerIsOpen(props.section, !searchState.searchDrawerIsOpen))}>
						<span>{locale('search.advanced')}</span>
						<S.Icon
							src={searchState.searchDrawerIsOpen ? arrowUp : arrowDown}
							style={{ height: '16px', width: '16px' }}
						/>
					</div>
				</S.AdvancedSearchButton>
			</S.SearchWrapper>
		</React.Fragment>
	);
};

export default BulletinSearch;
