import {IProgress} from "../reducers/appReducer";
import { AppStatus, VolumeUnits, EnergyUnits } from ".";

export const SET_IS_AUTH_INITIALIZED = "SET_IS_AUTH_INITIALIZED";
export const SET_IS_AUTHORIZED = "SET_AUTHORIZED";
export const SET_ERROR = "SET_ERROR";
export const SET_IS_BUSY = "SET_IS_BUSY";
export const SET_PROGRESS = "SET_PROGRESS";
export const CLEAR_IS_BUSY = "CLEAR_IS_BUSY";
export const SET_IS_DIRTY = "SET_IS_DIRTY";
export const CLEAR_IS_DIRTY = "CLEAR_IS_DIRTY";
export const SHOW_CONFIRM = "SHOW_CONFIRM";
export const HIDE_CONFIRM = "HIDE_CONFIRM";
export const SHOW_DISCARD_CONFIRM = "SHOW_DISCARD_CONFIRM";
export const TOGGLE_SHOW_DISCLAIMER = "TOGGLE_SHOW_DISCLAIMER";
export const SET_APP_STATUS = "SET_APP_STATUS";
export const SET_VOLUME_UNIT = "SET_VOLUME_UNIT";
export const SET_CSRVOLUME_UNIT = "SET_CSRVOLUME_UNIT";
export const SET_ENERGY_UNIT = "SET_ENERGY_UNIT";

export interface IConfirm {
    headerText: string;
    bodyText: string;
    buttonCancelText: string;
    buttonConfirmText?: string;
    background?: string;
    border?: string;
    onConfirm(): any;
}



interface ISetError {
    type: typeof SET_ERROR;
    error: string;
}

interface ISetIsBusy {
    type: typeof SET_IS_BUSY;
}

interface ISetProgress {
    type: typeof SET_PROGRESS;
    progress: IProgress;
}

interface IClearIsBusy {
    type: typeof CLEAR_IS_BUSY;
    hardClear: boolean;
}

interface ISetIsDirty {
    type: typeof SET_IS_DIRTY;
}

interface IClearIsDirty {
    type: typeof CLEAR_IS_DIRTY;
}

interface IShowConfirm {
    type: typeof SHOW_CONFIRM;
    confirm: IConfirm;
}

interface IHideConfirm {
    type: typeof HIDE_CONFIRM;
}

interface IShowDiscardConfirm {
    type: typeof SHOW_DISCARD_CONFIRM;
    onConfirm(): any;
}

interface IToggleShowDisclaimer {
    type: typeof TOGGLE_SHOW_DISCLAIMER;
}

interface ISetIsAuthInitialized {
    type: typeof SET_IS_AUTH_INITIALIZED;
    isAuthenticationInitialized: boolean;
}

interface ISetIsUnauthorized {
    type: typeof SET_IS_AUTHORIZED;
    isUserAuthorized: boolean;
}

interface ISetAppStatus {
    type: typeof SET_APP_STATUS;
    status: AppStatus;
}

interface ISetAppDopUnits {
    type: typeof SET_VOLUME_UNIT;
    unit: VolumeUnits;
}

interface ISetAppEnergyUnits {
    type: typeof SET_ENERGY_UNIT;
    unit: EnergyUnits;
}

export type AppActionTypes = ISetError
    | ISetIsBusy
    | IClearIsBusy
    | ISetProgress
    | ISetIsDirty
    | IClearIsDirty
    | IShowConfirm
    | IHideConfirm
    | IShowDiscardConfirm
    | IToggleShowDisclaimer
    | ISetIsAuthInitialized
    | ISetIsUnauthorized
    | ISetAppStatus
    | ISetAppDopUnits
    | ISetAppEnergyUnits;