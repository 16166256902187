import React from "react";
import { Route, RouteComponentProps } from 'react-router-dom';
import { AdminRouteProps } from "./interfaces";
import { Auth } from "./";
import Unauthorized from "./Unauthorized";

const AdminRoute: React.FC<AdminRouteProps> = ({ component, render, groups = [], ...props }) => {
     const Component = component;
    return <React.Fragment>
        <Unauthorized/>
        <Route {...props} render={(props: RouteComponentProps) => {
            return <Auth groups={groups} >
                {Component ? <Component {...props}/> : render && render(props) }
            </Auth>
        }}/>
    </React.Fragment>
};

export default AdminRoute;