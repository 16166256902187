// eslint-disable-next-line
import Worker from "worker-loader!./Worker"; // using worker-loader to load the worker.
import {
    BUILD_DOP_SAVE_DATA,
    IBuildDopDataWorkerResponse,
    IFailureResponse,
    IParseChartDataWorkerResponse,
    IParseMainlineMaintenanceDataResponse,
    IResponse,
    PARSE_CHART_DATA,
    PARSE_MAINLINE_MAINTENANCE_DATA,
    WORKER_FAILURE,
    WORKER_PROGRESS,
    WORKER_SUCCESS,
    WorkerActionTypes
} from "./index";
import {IArea, IAreaForEdit, IOutage, IOutageForEdit, IDailyChartData} from "../types";
import {DateTime} from "luxon";
import DateUtilities from "../utilities/DateUtilities";

export class SendToWorker<T> {
        private constructor(data: WorkerActionTypes) {
            this.data = data;
            this.successAction = null;
            this.errorAction = null;
            this.progressAction = null;
        }

        data: WorkerActionTypes;
        private successAction: ((data: IResponse<T>) => void) | null;
        private errorAction: ((data: IFailureResponse) => void) | null;
        private progressAction: ((data: IResponse<any>) => void) | null;
        private worker = new Worker();

        static buildDopSaveData(outages: IOutageForEdit[],  previousOutages: IOutage[], outageHistory: IOutage[], areas: IAreaForEdit[], dailyData: IDailyChartData[], includeDailyCapabilities: boolean): SendToWorker<IBuildDopDataWorkerResponse> {
            return new SendToWorker<IBuildDopDataWorkerResponse>({
                type: BUILD_DOP_SAVE_DATA,
                areas: areas,
                includeChartData: includeDailyCapabilities,
                outages: outages,
                previousOutages: previousOutages,
                historicalOutages: outageHistory,
                dailyData: dailyData
            });
        }

        static parseChartData(f: File, areas: IArea[]): SendToWorker<IParseChartDataWorkerResponse> {
            return new SendToWorker<IParseChartDataWorkerResponse>({
                type: PARSE_CHART_DATA,
                file: f,
                areas: areas
            });
        }

        static parseMainlineMaintenanceData(f: File, startDate: DateTime, endDate: DateTime): SendToWorker<IParseMainlineMaintenanceDataResponse> {
            return new SendToWorker<IParseMainlineMaintenanceDataResponse>({
                type: PARSE_MAINLINE_MAINTENANCE_DATA,
                file: f,
                startDate: DateUtilities.ServiceDateOnlyUtility.Format(startDate),
                endDate: DateUtilities.ServiceDateOnlyUtility.Format(endDate)
            });
        }

        success(action: (data: IResponse<T>) => void): SendToWorker<T> {
            this.successAction = action;
            return this;
        }

        progress(action: (data: any) => void): SendToWorker<T> {
            this.progressAction = action;
            return this;
        }

        error(action: (response: IFailureResponse) => void): SendToWorker<T> {
            this.errorAction = action;
            return this;
        }

        private messageHandler() {
            const func = (event: any) => {
                const response: IResponse<T> = event.data;
                console.log("main thread received message back from worker");
                console.log(response);

                if (event.data.responseType === WORKER_SUCCESS && this.successAction) {
                    this.successAction(event.data);
                }
                if (event.data.responseType === WORKER_FAILURE && this.errorAction) {
                    this.errorAction(event.data);
                }
                if (event.data.responseType === WORKER_PROGRESS && this.progressAction) {
                    this.progressAction(event.data);
                }
                if (response.responseType === WORKER_SUCCESS || response.responseType === WORKER_FAILURE) {
                    // complete.  remove listener.
                    this.worker.removeEventListener('message', func);
                    this.worker.terminate();
                }
            };
            return func;
        }

        execute() {
            this.worker.addEventListener('message', this.messageHandler());
            this.worker.postMessage(this.data);
        }

}