import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {RouteComponentProps, useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {Fetch} from "../../utilities/Fetch";
import ResultModal from "../ResultModal";
import useTypedSelector from "../../hooks/useTypedSelector";
import BetaBanner from "../BetaBanner";

interface Params {
    uniqueId: string;
}

const Verify: React.FC<RouteComponentProps> = (props) => {
    const p = props.match.params as Params;

    const history = useHistory();
    const dispatch = useDispatch();
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);

    useEffect(() => {
        if (p.uniqueId) {
            Fetch.Build("/subscriber/verify", dispatch)
                .withPayload([
                    {
                        email: "",
                        uniqueId: p.uniqueId
                    }])
                .success(() => {
                    setResultText(locale("subscription.opt.in.success"));
                    setResultSuccess(true);
                })
                .error(() => {
                    setResultText(locale("subscription.opt.in.error"));
                    setResultSuccess(false);
                })
                .finally(() => {
                    setShowResult(true);
                })
                .post();
        }
    }, [p.uniqueId, dispatch]);

    return (
        <div>
            <BetaBanner/>
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <BackNavigationHeader handleBackNavigation={() => history.goBack()}
                                      leftContent={locale('subscription.opt.in.header')}/>


                {showResult && <ResultModal text={resultText} onExit={() => {
                    setShowResult(false);
                    if (resultSuccess) {
                        history.push(`/Subscription/${p.uniqueId}`);
                    }
                }} success={resultSuccess}/>}

            </S.PageWithNavigationContainer>
        </div>
    );
};

export default Verify;