import styled from "styled-components";

import colors from "./Colors";
import {device} from "./DeviceSizes";

export const PaddedContainer = styled.div`
  padding-top: 24px;
  padding-left: 40px;
`;

export const SettingsHeading = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.black_text};
  margin-bottom: 20px;
  margin-top: 0;
`;

interface SettingsButtonProps {
  isVisble?: boolean;
}
export const SettingsButton = styled.button<SettingsButtonProps>`
  display: ${props => props?.isVisble === false  ? "none" : ""};
  transition: all 200ms ease;
  background: ${colors.primary};
  border-radius: 4px;
  border-color: transparent;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  height: 32px;
  margin: 0 8px;
  cursor: pointer;
  margin-right: 24px;
  margin-left: 0;
  margin-bottom: 24px;
  padding: 16px;
  min-height: 48px;
  min-width: 300px;
  &:hover {
    background-color: ${colors.hover_primary};
  }
`;

export const SettingsLabel = styled.label`
  display: block;
  font-family: Maven Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.charcoal};
  margin-bottom: 7px;
  padding: 0;
`;

export const SettingsValue = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;

  color: ${colors.black_text};
`;
interface SettingsFooterProps {
  notFixed?: boolean;
}

export const SettingsFooter = styled.div<SettingsFooterProps>`
position: ${props => props?.notFixed ? "inherit" : "fixed"};
bottom: 0;
width: 100%;
background-color: ${colors.light_blue};
text-align: center;
color: ${colors.black_text};
@media ${device.mobile}{
  position: unset;
}
@media ${device.tablet}{
  position: unset;
}
`;

export const SettingsFooterHeading = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 12px;
  color: ${colors.charcoal_text};
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 30px;
`;

export const SettingsFooterLabel = styled.label`
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${colors.charcoal_text};
  margin-bottom: 0;
  padding: 0;
`;

export const SettingsFooterButtonRow = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobile}{
    padding-left: 20px;
  }
`;

export const SettingsFooterFooter = styled.label`
  display: flex;
  justify-content: center;
  font-style: italic;
  font-size: 10px;
  line-height: 12px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
  color: ${colors.charcoal_text};
  padding: 0;
  padding-bottom: 30px;
  b {
    font-weight: bold;
  }
`;
