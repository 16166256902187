import React from 'react';
import * as S from '../styles/';
import {useHistory} from "react-router-dom";
import colors from "../styles/Colors";
import {useLocation} from 'react-router';
import useTypedSelector from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {clearIsDirty, showDiscardConfirm} from "../actions/appActions";
import NavigationUtilities from "../utilities/NavigationUtilities";

interface Props {
    icon: string;
    iconSelected: string;
    link: string;
    name: string;
}

const NavIcon: React.FC<Props> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isDirty = useTypedSelector(state => state.app.isDirty);

    const isSelected = () => {
        const name = NavigationUtilities.getPage(location.pathname);
        return name.name === props.name;
    };

    // TODO consolidate this navigate in these components BackNavigationHeader, Menu, NavIcon
    function onNavigate() {
        if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                history.push(props.link);
            }));

            return;
        }

        history.push(props.link)
    }

    return (
        <S.NavIcon>
            <div onClick={onNavigate} style={{textDecoration: "none"}}>
                <img src={isSelected() ? props.iconSelected : props.icon} alt={props.name}/>
                <p style={{color: isSelected() ? colors.selected : colors.primary}}>{props.name}</p>
            </div>
        </S.NavIcon>
    );
};

export default NavIcon;
