import {
    CLEAR_OUTAGES_LOADED,
    OutageActionTypes,
    SET_AREAS,
    SET_OUTAGE_DISPLAY,
    SET_OUTAGES,
    SET_OUTAGES_LOADED,
} from "../types/OutageActionTypes"
import {IArea, IOutage, OutageDisplay,} from "../types";

export function setOutages(outages: IOutage[], publishDate: string): OutageActionTypes {
    return {
        type: SET_OUTAGES,
        outages: outages,
        publishDate: publishDate
    }
}

export function clearOutagesLoaded(): OutageActionTypes {
    return {type: CLEAR_OUTAGES_LOADED}
}

export function setAreas(areas: IArea[]): OutageActionTypes {
    return {
        type: SET_AREAS,
        areas: areas
    }
}

export function setAreasLoaded(loaded: boolean): OutageActionTypes {
    return {
        type: SET_OUTAGES_LOADED,
        loaded: loaded
    }
}

export function setOutageDisplay(display: OutageDisplay): OutageActionTypes {
    return {
        type: SET_OUTAGE_DISPLAY,
        display: display
    }
}
