import {useEffect, useState} from 'react';
import {Payload} from '../types'
import {locale} from "../locale";
import {Fetch} from "../utilities/Fetch";
import {useDispatch} from "react-redux";

function useApiService<T>(defaultValue: T, path: string): Payload<T> {
    const dispatch = useDispatch();
    const [result, setResult] = useState<Payload<T>>({
        message: locale('loading'),
        data: defaultValue,
        error: false,
        loading: true
    });
    useEffect(() => {
        console.info(`Value change: ${defaultValue}`)
        Fetch.Build(`/${path}`, dispatch).success(response => setResult({
            message: locale('loaded'),
            data: response,
            loading: false
        }))
        .error(error => setResult({message: error, data: defaultValue, error: true, loading: false}))
        .get();
    }, [dispatch, path]);

    return result;
}

export default useApiService;