import {IHeatValue} from "../types";
import {
    CLEAR_HEAT_VALUE,
    SET_HEAT_VALUE,
    HeatValueActionTypes,
} from "../types/HeatValueActionTypes";

export function setHeatValue(heatValue: IHeatValue[]): HeatValueActionTypes {
    return {
        type: SET_HEAT_VALUE,
        heatValue: heatValue
    }
}

export function clearHeatValue(): HeatValueActionTypes {
    return {
        type: CLEAR_HEAT_VALUE
    }
}