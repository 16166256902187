import {
    SubscriptionActionTypes,
    CLEAR_SUBSCRIPTIONS_LOADED,
    SET_SUBSCRIPTIONS,
    SET_SUBSCRIPTIONS_LOADED,
} from "../types/SubscriptionActionTypes";
import {ISubscription} from "../types";


export interface ISubscriptionState {
    subscriptions: ISubscription[];
    subscriptionsLoaded: boolean;
    error: string;
}

export const initialState: ISubscriptionState = {
    subscriptions: [],
    subscriptionsLoaded: false,
    error: ""
};

export function subscriptionReducer(state: ISubscriptionState = initialState, action: SubscriptionActionTypes): ISubscriptionState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_SUBSCRIPTIONS:
            return {...state, subscriptions: action.subscriptions, subscriptionsLoaded: true};
        case SET_SUBSCRIPTIONS_LOADED:
            return {...state, subscriptionsLoaded: action.loaded};
        case CLEAR_SUBSCRIPTIONS_LOADED:
            return {...state, subscriptionsLoaded: false};
        default:
            return state;
    }
}