import {
    BulletinActionTypes,
    CLEAR_BULLETINS_LOADED,
    SET_BULLETINS,
    SET_BULLETINS_LOADED,
    SET_SENT_BULLETINS,
    SET_BULLETIN_LIST_SORT,
    SET_BULLETIN_ADMIN_SORT,
    SET_BULLETIN_ROWS_PER_PAGE,
    SET_BULLETIN_CURRENT_PAGE,
    SET_BULLETIN_PAGING_RESET
} from "../types/BulletinActionTypes";
import {IBulletin, ILookupValue, ColumnSort, SORT_ORDER} from "../types";
import { PagingInfo } from "../components/PageSelector";


export interface IBulletinState {
    bulletins: IBulletin[];
    sentBulletins: IBulletin[];
    bulletinsLoaded: boolean;
    sentBulletinsLoaded: boolean;
    serviceProviders: ILookupValue[];
    serviceProvidersLoaded: boolean;
    categories: ILookupValue[];
    categoriesLoaded: boolean;
    error: string;
    bulletinListSort: ColumnSort;
    bulletinAdminSort: ColumnSort;
    rowsPerPage: number;
    currentPage: PagingInfo<IBulletin>;
    resetPaging: boolean;
}

export const initialState: IBulletinState = {
    bulletins: [],
    sentBulletins: [],
    bulletinsLoaded: false,
    sentBulletinsLoaded: false,
    serviceProviders: [],
    serviceProvidersLoaded: false,
    categories: [],
    categoriesLoaded: false,
    error: '',
    bulletinListSort: { columnName: "publishedDateTimeUtc", sortOrder: SORT_ORDER.DECENDING},
    bulletinAdminSort: { columnName: "Date", sortOrder: SORT_ORDER.DECENDING},
    rowsPerPage: 50,
    currentPage: new PagingInfo([]),
    resetPaging: true,
};

export function bulletinReducer(state: IBulletinState = initialState, action: BulletinActionTypes): IBulletinState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_BULLETINS:
            return {...state, bulletins: action.bulletins, bulletinsLoaded: true};
        case SET_BULLETINS_LOADED:
            return {...state, bulletinsLoaded: action.loaded};
        case CLEAR_BULLETINS_LOADED:
            return {...state, bulletinsLoaded: false, sentBulletinsLoaded: false};
        case SET_SENT_BULLETINS:
            return {...state, sentBulletins: action.sentBulletins, sentBulletinsLoaded: true};
        case SET_BULLETIN_LIST_SORT:
            return {...state, bulletinListSort: action.bulletinListSort};
        case SET_BULLETIN_ADMIN_SORT:
            return {...state, bulletinAdminSort: action.bulletinAdminSort};
        case SET_BULLETIN_ROWS_PER_PAGE:
            return {...state, rowsPerPage: action.rowsPerPage};
        case SET_BULLETIN_CURRENT_PAGE:
            return {...state, currentPage: action.currentPage};
        case SET_BULLETIN_PAGING_RESET:
            return {...state, resetPaging: action.resetPaging};
        default:
            return state;
    }
}