import React, {useEffect, useState} from 'react';
import useTypedSelector from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {selectDailyOperatingPlanCompareDate, setPublishDate} from "../actions/outageComparisonActions";
import * as S from "../styles"
import {locale} from "../locale";
import {clearOutageChartDataLoaded} from "../actions/outageChartActions";
import {PublishDateUtilities} from "../utilities/PublishDateUtilities";

interface PublishDatesSelectorProps {
    noCompareTo?: boolean;
    noPublishedList?: boolean;
    selectPublishDate: string;
}

const PublishDatesSelector: React.FC<PublishDatesSelectorProps> = (props) => {
    const dispatch = useDispatch();

    const selectedPublishDate = props.noPublishedList ? props.selectPublishDate : useTypedSelector(x => x.outageComparison.publishDates.selectedPublishDate);
    const selectedCompareToDate = useTypedSelector(x => x.outageComparison.outageHistory.publishDate);
    const publishDates = useTypedSelector(x => x.outageComparison.publishDates.recentPublishDates);

    const lastPublishDateTimeUtc = useTypedSelector(x => x.outages.lastPublishDateTimeUtc);

    const [newCompareToDate, newCompareToDates] = getCompareDates(selectedPublishDate, ["", ...publishDates], selectedCompareToDate);

    const [componentState, setComponentState] = useState(
        {
            publishDate: selectedPublishDate,
            compareToDate: newCompareToDate,
            publishDates: [...publishDates],
            compareToDates: newCompareToDates
        });

    const [currentDate, setCurrentDate] = useState(locale("current"));

    useEffect(() => {
        setCurrentDate(lastPublishDateTimeUtc);
    }, [lastPublishDateTimeUtc]);

    useEffect(() => {
        if (componentState.publishDate !== selectedPublishDate) {
            dispatch(setPublishDate(componentState.publishDate));
        }
        if (componentState.compareToDate !== selectedCompareToDate) {
            dispatch(selectDailyOperatingPlanCompareDate(componentState.compareToDate));
        }

    }, [componentState.publishDate, componentState.compareToDate, dispatch, selectedCompareToDate, selectedPublishDate]);

    function getCompareDates(publishDate: string, publishDates: string[], compareToDate: string): [string, string[]] {
        const newCompareToDates = publishDates.slice(publishDates.indexOf(publishDate) + 1);
        if (newCompareToDates.length === 0) {
            newCompareToDates.push(publishDate);
        }
        const newCompareToDate = newCompareToDates.includes(compareToDate) ? compareToDate : newCompareToDates[0];
        return [newCompareToDate, newCompareToDates];
    }

    function publishDateChanged(newPublishDate: string) {
        const [newCompareToDate, newCompareToDates] = getCompareDates(newPublishDate, componentState.publishDates, componentState.compareToDate);
        setComponentState({
            ...componentState,
            publishDate: newPublishDate,
            compareToDate: newCompareToDate,
            compareToDates: newCompareToDates
        });
        dispatch(clearOutageChartDataLoaded());
    }

    function compareToDateChanged(newCompareToDate: string) {
        setComponentState({...componentState, compareToDate: newCompareToDate});
        dispatch(clearOutageChartDataLoaded());
    }

    return (
        <S.PublishDateForm>
            {currentDate !== "current" &&
            <S.FlexContainerRow>
                <S.FlexContainerColumnNoWrapPrintAsRow style={{marginRight: 5}}>
                    <S.PublishDatesSelectorLabel>{locale("showingDateSelectorLabel")}</S.PublishDatesSelectorLabel>
                    {props?.noPublishedList !== true &&
                    <S.Select value={componentState.publishDate}
                              onChange={(e: any) => publishDateChanged(e.target.value)}>
                        {componentState.publishDates.map(value => <option key={`from-option-${value}`}
                                                                          value={value}>{PublishDateUtilities.FormatPublishedDate(value, componentState.publishDates)}</option>)}
                    </S.Select>
                    }
                    {props?.noPublishedList && <S.PublishDatesSelectorLabel>{selectedPublishDate}</S.PublishDatesSelectorLabel>}
                </S.FlexContainerColumnNoWrapPrintAsRow>
                {props?.noCompareTo !== true &&
                    <S.FlexContainerColumnNoWrapPrintAsRow style={{marginRight: 5}}>
                        <S.PublishDatesSelectorLabel>{locale("comparedToDateSelectorLabel")}</S.PublishDatesSelectorLabel>
                        <S.Select value={componentState.compareToDate}
                                  onChange={(e: any) => compareToDateChanged(e.target.value)}>
                            {componentState.compareToDates.map(value => <option key={`to-option-${value}`}
                                                                                value={value}>{PublishDateUtilities.FormatPublishedDate(value, componentState.publishDates)}</option>)}
                        </S.Select>
                    </S.FlexContainerColumnNoWrapPrintAsRow>
                }
            </S.FlexContainerRow>
            }
        </S.PublishDateForm>
    );
};

export default PublishDatesSelector;