import {useDispatch} from "react-redux";
import useTypedSelector from "./useTypedSelector";
import {useEffect} from "react";
import fetchHelper from "../utilities/fetchHelper";
import {setHeatValue} from "../actions/heatValueAction";

function useHeatValues(): void {
    const dispatch = useDispatch();
    const heatValueLoaded = useTypedSelector(x => x.heatValue.heatValueLoaded);

    useEffect(() => {
        if (!heatValueLoaded) {
            fetchHelper('heatValues/all', x => dispatch(setHeatValue(x)), dispatch);
        }
    }, [heatValueLoaded, dispatch])
}

export default useHeatValues;