import styled from 'styled-components';
import {ErrorBorderStyle} from "./index";
import {TableHeaderRow} from "./AdminStyles";
import colors from "./Colors";
import {device} from "./DeviceSizes";
import selectDownArrowImage from "../icons/select_down_arrow_disabled.svg";

interface IGDSRControlsContainerProps {
  isOpen: boolean;
}

export const GDSRWrapper = styled.div`
  background: #ffffff;
  display: flex;
 
`;

export const GDSRBannerContainer = styled.div`
  height: 1%;
  background: #555759;
  @media ${device.mobile} {
    display:none;
   
  }
   
`;

export const GDSRBannerInner = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  p {
    margin-top: unset;
    margin-bottom: unset;
    color: white;
  }
  a {
    color: white;
  }
  @media ${device.mobile} {
    display:none;
    
    
  }

`;

export const GDSRControlsInner = styled.div<IGDSRControlsContainerProps>`
    position: relative;
    background-color: white;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between;
    -webkit-animation: ${props => props.isOpen ? "slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" : "slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"};
    animation: ${props => props.isOpen ? "slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" : "slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"};
    
    @media ${device.mobile} {
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 10px;
        animation: unset;
        font-size: 11px;
        
    }
    @media ${device.tablet} {
      padding-right: 5px;
      padding-left: 5px;
      padding-bottom: 10px;
      animation: unset;
  }
    
`;

export const DatePickerContainer = styled.div`
  display: flex;
  @media ${device.mobile} {
    font-size: 11px;
    width: 55%;
}
`;

export const DateInformationContainer = styled.div`
  margin-left: 10px;
  p {
    font-family: Maven Pro;
  }
`;

interface GDSRHeaderProps {
  minWidth?: string;
}

export const GDSRTableHeaderRow = styled(TableHeaderRow)<GDSRHeaderProps>`
  min-width: ${props => props.minWidth}px;
  border-bottom: solid #E3E3E3 1px;
  @media print {
    .vendorListHeading th {
      background-color: #555759 !important;
      color: white !important;
    }
  /*  @-moz-document url-prefix(){
      border-bottom: solid #E3E3E3 1px;
    }*/
  }
 
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-align: center;
`;

export const GDSRDateDisplayUpper = styled.p`
  margin-top: 0em;
  margin-bottom: 0em;
`;

export const GDSRDateDisplayLower = styled.p`
  margin-top: 0em;
`;

export const GDSRGasDayDisplay = styled.b`
  margin-bottom: 1em;

`;

export const GDSRTableContainer = styled.div`
  height: calc(95vh - 160px);
  z-index: -1;
  .center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    }
   @media print {
    overflow: unset !important;
    display: table-cell !important;
    width:50% !important;
    height: auto;
    @-moz-document url-prefix(){
      display: inline-table !important;
    }
    -moz-transform: scale(1);
    -moz-transform-origin: top left;
   }
  @media ${device.mobile} {
    display:inline-table;
    overflow:unset;
    height: 500px;
    width:100%;
    
	}
  @media ${device.tablet} {
    height:680px;
  }
`;
export const GDSRBigTableContainer = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  height:auto;
  @media ${device.tablet} {
    height:700px;
  }
  @media print {
    /*overflow: unset !important;*/
    display: table !important; 
    height: 500px;
    @-moz-document url-prefix(){
      display: flex !important;
    }
    /*width:100%;
    -moz-transform: scale(1);
    -moz-transform-origin: top left;
  */ }
  @media ${device.mobile} {
    display: inherit;
    flex-direction: column;
    height: 650px;
    overflow:scroll;
    width:100%;
	}
`;

export const GDSRDataTable = styled.div`
display:inline-table;
@media print {
  zoom: 0.6 !important;
  width:85% !important;
  }
  border-collapse: collapse;
  width: 100%;
 /* min-width: 775px;
  min-height: 716px;*/
  p {
    
    font-size: 14px;
    font-weight: 300;
    position: relative;
    float: left;
    margin-top: 0em;
    margin-left: 5px;
  }
 
`;

export const GDSRDataTableSpacer = styled.div`
  display: flex;
  width: 10px;
  height: 25px;
 
  @media print{
    width: 25px;
    height: 10px;
  }
  @media ${device.mobile}{
    width: 100%;
   
  }
`;

interface GSDRBorderProps {
  noBorderLeft?: boolean;
  noBorderRight?: boolean;
  noBorderBottom?: boolean;
  noBorderTop?: boolean;
  cellBackgroundColor?: string;
  fontStyle?: string;
  
}

export const GDSRDataRow = styled.div<GSDRBorderProps>`
  display:table-row;
  border-bottom: solid #E3E3E3 ${props => props.noBorderBottom ? 0 : 1}px;
  border-collapse: separate; 
 
  @media print {
    color-adjust: exact;
      -webkit-print-color-adjust: exact;
    @-moz-document url-prefix(){
      border-bottom: solid #E3E3E3 ${props => props.noBorderBottom ? 0 : 3}px;
      display: inline-block !important;
    }
  }
  background:${props => props.cellBackgroundColor};
  
  p {
   text-align:left;
   color: #555759;
   font-family: Roboto;
   font-size: 14px;
   }
  
`;

export const GDSRDataHeader = styled.div<GSDRBorderProps>`
  display:table-row;
  border-bottom: solid #E5E5E5 ${props => props.noBorderBottom ? 0 : 1}px;
    @media print {
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
      @-moz-document url-prefix(){
        border-bottom: solid #E5E5E5 ${props => props.noBorderBottom ? 0 : 3}px;
        display: inline-block !important;
      }
    }
  p {
    color: #ffffff;
  }
  z-index: 1;
  background: rgb(14,50,110);
  margin-bottom: 15px;
  margin-top: 15px;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */

`;
interface FFProps {
  minWidthFFPrint?: number;
}

export const GDSRDataTitleContainer = styled.div<GSDRBorderProps & FFProps>`
  display:table-cell;
  border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 1}px;
  border-top: solid #E5E5E5 ${props => props.noBorderTop? 0 : 1}px;
  border-left: solid #E5E5E5 ${props => props.noBorderLeft ? 0 : 1}px;
  @media print {
    @-moz-document url-prefix(){
        border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 3}px;
        min-width: ${props => props.minWidthFFPrint ? props.minWidthFFPrint : 0}px;
    }
  }
  p {
    font-weight: 500;
    font-family: Roboto;
    font-size: 17px;
    margin-top: 0.5em;
  }
`;
export const GDSRDataLeftTitleContent = styled.div`
  text-align: left;
  color: white;
  font-weight: normal;
  font-family: Roboto;
  font-size: 14px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  
  margin-right: 0.3em;
  margin-left: 0.3em;
  }
`;
export const GDSRDataCenterTitleContent = styled.div`
  text-align: center;
  color: white;
  font-weight: normal;
  font-family: Roboto;
  font-size: 14px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
   
  }
`;

export const GDSRNameContainerWithIcon = styled.div<GSDRBorderProps>`
border-left: solid #E5E5E5 ${props => props.noBorderLeft ? 0 : 1}px;  
display: inline-block;
  margin-top: 0px;
  img {
    height: fit-content;
    
  }
`;

export const GDSRDataNameContainer = styled.div<GSDRBorderProps>`
  display:table-cell;
  padding-top: 10px;
  border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 1}px;
  border-left: solid #E5E5E5 ${props => props.noBorderLeft ? 0 : 1}px;
  
  @media print {
   /* @-moz-document url-prefix(){
      border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 3}px;
    }*/
  }
  p {
    color: #555759;
    font-weight: normal;
    font-family: Roboto;
    font-size: 14px;
    
  }
  p.subColumnName {
    font-size: 11px;
  }
  font-style:${props => props.fontStyle};
 
`;

export const GDSRDataNameContainerDiv = styled.div<GSDRBorderProps>`
  border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 1}px;
  display: inline;
  @media print {
    @-moz-document url-prefix(){
      border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 3}px;
    }
  }
  p {
    font-weight: normal;
    font-family: Roboto - Italic;
    font-size: 14px;
    
  }
  p.subColumnName {
    font-size: 14px;
    margin-top: 0.5em;
  }
  background:${props => props.cellBackgroundColor};
  font-style:${props => props.fontStyle};
 
`;

export const GDSRDataCell = styled.div<GSDRBorderProps>`
  border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 1}px;
  border-left: solid #E5E5E5 1px;
  display:table-cell;
  vertical-align:middle;
  @media print {
   /* @-moz-document url-prefix(){
      border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 3}px;
      border-left: solid #E5E5E5 2px;
    }*/
  }
  padding-left: 15px;
  padding-right: 15px;
  p {
    font-weight: normal;
  }
  @media ${device.mobile} {
    text-align: -webkit-right;
    line-height:18px;
    
  }
`;
export const GDSRFooterCell = styled.div<GSDRBorderProps>`
  border-right: solid #E5E5E5 ${props => props.noBorderRight ? 0 : 1}px;
  border-left: solid #E5E5E5 1px;
  display:none;
  vertical-align:middle;
  @media print {
    display: none !important;
    @-moz-document url-prefix(){
      display: none !important;
    } 
  }
  p {
    font-weight: normal;
  }
  @media ${device.mobile} {
    text-align: -webkit-center;
    display:table-caption;
    width:320%;
    background: #555759;
    @-moz-document url-prefix(){
    width:120%;
  }
}
`;


export const GDSRValueContainer = styled.div`
display: inline-block !important;
width:60px;
  p {
    width:100%;
    text-align: -webkit-right;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 700;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: auto;
    margin-right: 0px;
 
  }
  div.tolerance {
    display: flex !important;
  }
  @media ${device.tablet} {
    display:flex !important;
    width:100%;
    p {
      
      text-align: -webkit-right;
      font-family: Roboto;
      font-size: 17px;
      font-weight: 700;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 0px;
      margin-right: 0px;
   
    }
  }
  @media ${device.mobile} {
    display:flex !important;
    p {
      width:100%;
      text-align: -webkit-right;
      font-family: Roboto;
      font-size: 17px;
      font-weight: 700;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 0px;
      margin-right: 0px;
   
    }
    div.tolerance {
      display: flex !important;
      width:100%;
    }
  }
`

export const GDSRDiffContainer = styled.div`
  display: inline-block !important;
 
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0px;
    margin-right: 0px;
    
    float:right;
  }

  p.tolerance {
    margin-right: 0px;
  }

  div.tolerance {
    display: flex !important;
  }

  img.leftSideIcon {
    margin:unset;
    margin-left: 12px;
    margin-right: 8px
  }
  img.rightSideIcon {
    margin:unset;
    margin-left: 2px;
    margin-right: -10px
  }
  @media ${device.tablet} {
    display: inline-flex !important;
    justify-content:right;
    width:60%;
  
  }
  @media ${device.mobile} {
    display: inline-block !important;
    width:55%;

    img.leftSideIcon {
      margin:unset;
      margin-left: 12px;
      margin-right: 8px
    }
    img.rightSideIcon {
      margin:unset;
      margin-left: 2px;
      margin-right: -10px
    }
  }
 
  
`;

export const GDSRIconContainer = styled.div`
display: inline-block !important;
img {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
}
@media ${device.tablet} {
  display: inline-flex !important;
  img {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    
  }
}
@media ${device.mobile} {
  display: inline-block !important;
  img {
   padding-right:10px;
  }
}

`;

export const GDSRFooterContainer = styled.div`
  display: none;
  height: 1%;
  background: #555759;
  @media ${device.mobile} {
   display: flex;
    height: 10%;
    
  }
  @media print{
    display: none !important;
  }
`;

export const GDSRFooterInner = styled.div`
  @media ${device.mobile} {
    background: #555759;
    color: white;
    display:unset;
    text-align: -webkit-left;
    font-size: 13px;
    p {
      margin-top: unset;
      margin-bottom: unset;
     
    }
    a {
      color: white;
      font-size: 13px;
    }
  }

  @media print{
    display: none !important;
  }
`;