import React from 'react';
import * as S from "../styles";
import {locale} from "../locale";

interface CompareDetailBoxProps {
    label: string;
    data: string;
    previous: string | undefined;
    hasChanged: boolean;
    showPrevious: boolean;
    breakLineOn?: string;
    unit?: string;
}

const CompareDetailBox: React.FC<CompareDetailBoxProps> = (props) => {

    return (
        <div>
            <S.TextSmall style={{verticalAlign: 'bottom'}}>
                {props.label}
            </S.TextSmall>
            <S.ComparedField hasChanged={props.hasChanged} >
                <div>
                    {props?.breakLineOn ? props.data.split(props.breakLineOn).map(x => <React.Fragment key={x}>{x} <br/></React.Fragment>) : props.data}
                    {props.unit && <S.ComparedFieldUnit>{" "}{props.unit}</S.ComparedFieldUnit>}
                </div>
                {props.showPrevious && props.hasChanged && <div>
                    <S.PreviousValueLabel>{locale("previously")}:</S.PreviousValueLabel>
                    <S.PreviousValue>{props.previous}{" "}{props.unit && <React.Fragment>{props.unit}</React.Fragment>}</S.PreviousValue>

                </div>}
            </S.ComparedField>
        </div>
    );
};

export default CompareDetailBox;