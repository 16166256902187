import React from 'react';
import TCLogo from '../../icons/menu_TC_logo.svg';
import {IBulletin} from "../../types";
import * as S from '../../styles';
import {locale} from "../../locale";
import DateUtilities from "../../utilities/DateUtilities";
import {DateTime} from "luxon";

interface Props {
    data: IBulletin;
    isDraft: boolean;
}

const Bulletin: React.FC<Props> = (props) => {
    const publishDate = props.isDraft ? locale('bulletin.preview.notPublishedTimeMessage') : DateTime.fromFormat(props.data.publishedDateTimeUtc ?? '', DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.publishDateTimestamp);
    const startDate = props.isDraft ? props.data.startDateTimeUtc : props.data.startDateTimeUtc !== null ? DateTime.fromFormat(props.data.startDateTimeUtc, DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.publishDateTimestamp) : publishDate;

    return (
        <S.BulletinWrapper>
            <div style={{maxWidth: '1440px'}}>
                <S.BulletinSubWrapper pos={1}>
                    <S.BulletinHeader>
                        <img src={TCLogo} alt="TC Energy" style={{height: '40px'}}/>
                        <span>{`${props.data.subscription?.serviceProvider.name ?? props.data.serviceProvider?.name} ${props.data.isCritical ? locale('bulletin.preview.criticalNotice') : locale('bulletin.preview.nonCriticalNotice')}`}</span>
                    </S.BulletinHeader>
                    <S.BulletinInfoRow>
                        <div>
                            <p>
                                <span>{`${locale('bulletin.preview.noticeType')}:`}</span> {props.data.subscription?.name ?? props.data.category?.name}
                            </p>
                        </div>
                        <div title={locale('displayTimeZone.disclaimer')}>
                            <p>
                                <span>{`${locale('bulletin.preview.effectiveStartDate')}:`}</span> {startDate ?? publishDate} {locale("bulletins.displayTimeZone")}
                            </p>

                        </div>
                        <div>
                            <p><span>{`${locale('bulletin.preview.subject')}:`}</span> {props.data.subject}</p>
                        </div>
                    </S.BulletinInfoRow>
                </S.BulletinSubWrapper>
                <S.BulletinSubWrapper pos={2} color={'#FCFCFC'}>
                    <S.BulletinBody>
                        <div dangerouslySetInnerHTML={{__html: props.data.body}}/>
                    </S.BulletinBody>
                </S.BulletinSubWrapper>
                <S.BulletinSubWrapper pos={3}>
                    <S.BulletinSummary>
                        {props.data.contactName &&
                        <div>
                            <p><span>{`${locale('bulletin.preview.contact')}:`}</span> {props.data.contactName}</p>
                        </div>
                        }
                        {props.data.contactPhone &&
                        <div>
                            <p><span>{`${locale('bulletin.preview.phone')}:`}</span> {props.data.contactPhone}</p>
                        </div>
                        }
                        <div title={locale('displayTimeZone.disclaimer')}>
                            <p>
                                <span>{`${locale('bulletin.preview.postingTime')}:`}</span> {publishDate ? publishDate + " " + locale("bulletins.displayTimeZone") : locale('bulletin.preview.notPublishedTimeMessage')}
                            </p>
                        </div>
                        <div>
                            <p><span>{`${locale('bulletin.preview.noticeNumber')}:`}</span> {props.data?.id || '0'}</p>
                        </div>
                    </S.BulletinSummary>
                </S.BulletinSubWrapper>
            </div>
        </S.BulletinWrapper>
    )
};

export default Bulletin;