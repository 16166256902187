import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";
import { FontSize } from "./FontStyles";

export const OutageAreaCard = styled.div`
    background-color: #F3F3F3;
    display: flex;
    @media ${device.tablet} {
        border: 1px solid #CFCDCE;
        margin: 16px;
        border-radius: 4px;
        width: 350px;
        display: inline-block;
    }

    @media ${device.desktop} {
        border: 1px solid #CFCDCE;
        margin: 16px;
        border-radius: 4px;
        width: 500px;
        display: inline-block;
   }
    
    @media ${device.mobile} {
        border: none;
        border-bottom: 2px solid #8C878A;
        margin: 0;
        border-radius: 0;
        width: 100%;
    }
`;

export const OutageAreaCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OutageAreaCardHeaderTag = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 12px;
  vertical-align: Top;
  color: #000000;
`;

export interface IOutageAreaCardLineProps {
    isVisible?: boolean;
}

export const OutageAreaCardLineIconContainer = styled.div`
    width: 45px;
`;

export const AreaOutageSummaryLineContent = styled.span`
  font-family: Roboto, sans-serif; 
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #1A72C8;
  margin: auto 5px;
`;

export const OutageAreaCardLine = styled.div<IOutageAreaCardLineProps>`
  border-bottom: 1px solid #CFCDCE;
  padding: 10px;
  color: ${colors.primary};
  background-color: #F3F3F3; 
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  vertical-align: Top;
  display: flex;
  align-items: center;
  cursor: ${props => props.isVisible === false ? "normal" : "pointer"};
  > * {
    visibility: ${props => props.isVisible === false ? "hidden" : "visible"}
  }
`;

export const OutageAreaCardDisclaimerLine = styled.div`
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 53px;
  color: ${colors.selected};
`;

interface IColorProp {
    color?: string;
}
export const OutageAreaCardHeader = styled(OutageAreaCardLine)<IColorProp>`
  font-family: Maven Pro;
  border-bottom: 1px solid #CFCDCE;
  padding: 10px;
  background-color: ${props => props.color ? props.color : "#E5E5E5"};  
  display: block;
  cursor: default;
  min-height: 33px;
`;

export const OutageAreaCardHeaderAreaName = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #000000;
`;

export const OutageAreaCardTextCapability = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px; 
  color: #000000;
  margin-right: 5px;
`;

export const OutageAreaCardTextUnit = styled.div`
  ${FontSize("10px")};
  color: #555759;
`;

export const OutageAreaCardTextBlue = styled(OutageAreaCardTextCapability)`
  color: #1A72C8;
`;

export const AreaSummaryListPublishDatesSelectorContainer = styled.div`
  display: flex;
  position: fixed;
  top: 105px;
  width: calc(100vw - 115px);
  background-color: white;
  @media ${device.mobile}{
    display: block;
    width: 100%;
  }
`;

export const OutageAreaCardSubHeader = styled.div`
   height: 16px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;   
   padding-top: 7px;
   padding-bottom: 0;  
`;

export const OutageAreaCardText = styled.div`    
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px; 
  color: #555759;
`;

export const PlantTurnaroundCsvDownloadLine = styled.div`
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 53px;
  color: ${colors.selected};
  
  a, span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: ${colors.primary};
    margin: 0 0 16px 0;
    text-decoration: none;
    cursor: pointer;
  }
`;