import React, { useState, useEffect, ReactElement } from "react";
import { useDispatch} from "react-redux";
import * as S from "../../styles";
import {IBulletin, ColumnSort, SearchKey} from "../../types";
import { locale } from "../../locale";
import useBulletinSearch from "../../hooks/useBulletinSearch";
import ResultsPerPagePicker from "./ResultsPerPagePicker";
import {BulletinUtilities} from "../../utilities/BulletinUtilities";
import PageSelector, {PagingInfo} from "../PageSelector";
import {Fetch} from "../../utilities/Fetch";
import BulletinSearch from "./BulletinSearch";
import useTypedSelector from "../../hooks/useTypedSelector";
import { setBulletinRowsPerPage, setBulletinCurrentPage, setBulletinPagingReset } from '../../actions/bulletinActions';

interface Props {
    endpoint: string;
    renderHeader: () => ReactElement;
    children: (item: IBulletin, fetchBulletins: () => void, lastSearch: string) => ReactElement;
    section: SearchKey;
    noticeMissing?: boolean;
    columnSortOrder: ColumnSort;
}

const BulletinTable: React.FC<Props> = ({ renderHeader, endpoint, children, section, noticeMissing, columnSortOrder}) => {
    const [lastSearch, setLastSearch] = useState<string>("");

    const dispatch = useDispatch();

    const { searchState } = useBulletinSearch(section);
    const [searching, setSearching] = useState<boolean>();
    const rowsPerPage = useTypedSelector(state => state.bulletins.rowsPerPage);
    const currentPage = useTypedSelector(state => state.bulletins.currentPage);
    const resetPaging = useTypedSelector(state => state.bulletins.resetPaging);

    const fetchBulletins = (url: string) => {
        setLastSearch(searchState.searchText);
        setSearching(true);
        Fetch.Build(url, dispatch)
            .success(response => {
                const arr = new Array(response.totalRecords);
                arr.fill({});
                for (let i = (response.currentPage - 1) * response.pageSize; i < response.currentPage * response.pageSize; i++) {
                    if (i > arr.length - 1)
                        break;
                    arr[i] = response.data[i - (response.currentPage - 1) * response.pageSize];
                }
                dispatch(setBulletinCurrentPage(new PagingInfo(arr, rowsPerPage, response.currentPage, 3, 3)));
                setSearching(false);
            })
            .get();
    };

    useEffect(() => {
        if(resetPaging){
            dispatch(setBulletinPagingReset(false));
            fetchBulletins(BulletinUtilities.buildQuery(endpoint, 1, rowsPerPage, columnSortOrder.columnName, columnSortOrder.sortOrder, searchState));
        }else{
            fetchBulletins(BulletinUtilities.buildQuery(endpoint, currentPage?.currentPage, rowsPerPage, columnSortOrder.columnName, columnSortOrder.sortOrder, searchState));
        }
    }, [endpoint, rowsPerPage, searchState, dispatch]);

    useEffect(() => {
        fetchBulletins(BulletinUtilities.buildQuery(endpoint, currentPage?.currentPage, rowsPerPage, columnSortOrder.columnName, columnSortOrder.sortOrder, searchState));
    }, [columnSortOrder]);

    function reloadBulletins() {
        fetchBulletins(BulletinUtilities.buildQuery(endpoint, 1, rowsPerPage, columnSortOrder.columnName, columnSortOrder.sortOrder, searchState));
    }

    function onPageSelected(pagingInfo: PagingInfo<any>) {
        fetchBulletins(BulletinUtilities.buildQuery(endpoint, pagingInfo.currentPage, rowsPerPage, columnSortOrder.columnName, columnSortOrder.sortOrder, searchState))
    }

    return <React.Fragment>
    <BulletinSearch noticeMissing={noticeMissing} section={section}/>
    <S.ResultPerPageWrapper>
        <span style={{alignSelf: 'center'}}>{`Showing ${currentPage?.items.length.toLocaleString() ?? 0} of ${currentPage?.allItems.length.toLocaleString() ?? 0} results`}</span>
         <span>{locale('bulletin.list.resultPerPage')} <ResultsPerPagePicker rowsPerPage={rowsPerPage} selectedValue={rowsPerPage.toString()}
         action={val => {
             dispatch(setBulletinRowsPerPage(parseInt(val.target.value)));
             dispatch(setBulletinPagingReset(true));
         }
         }/>
         </span>

    </S.ResultPerPageWrapper>
    <S.ListTableWrapper>
        {renderHeader()}
        {!searching && (currentPage?.allItems === undefined || currentPage?.allItems.length === 0) &&
            <S.NoResultMessage><span>We couldn&apos;t find any matches{searchState.searchText && <span> for &quot;<b>{searchState.searchText}</b>&quot;</span>}. Please try another search.</span></S.NoResultMessage>}
        {currentPage !== undefined && currentPage.items.length !== 0 && currentPage.items.map((bulletin) => { return children(bulletin, reloadBulletins, lastSearch ) })}
        {currentPage !== undefined && currentPage?.totalPages > 1 &&
                <PageSelector saveState={onPageSelected} pagingInfo={currentPage as PagingInfo<any>}/>}
    </S.ListTableWrapper>
    </React.Fragment>
};

export default BulletinTable;