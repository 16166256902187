import {clearBusy, setBusy} from "../actions/appActions";
import {Fetch} from "../utilities/Fetch";

export class FileDownloadUtilities {
    static initiateCsvFileDownload(url: string, dispatch: any) {
        const headers = new Headers();
        if (Fetch.token) {
            headers.set('authorization', `Bearer ${Fetch.token}`);
        }
        dispatch(setBusy());
        fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
            headers: headers
        })
            .then(r => {
                r.text().then(csv => {

                    console.log(console.log(r.headers));
                    const file_header = r.headers.get("content-disposition");

                    const file_name = (file_header?.match(/(".+")/) as RegExpMatchArray)[0].slice(1, -1);
                    FileDownloadUtilities.initiateFileDownload(file_name, csv, "text/csv;charset=utf-8;");
                })
            }).finally(() => dispatch(clearBusy()));
    }

    static initiateFileDownload(filename: string, fileContent: string, mimeTypeString: string): void {
        const blob = new Blob([fileContent], { type: mimeTypeString });
        if (navigator.msSaveBlob) { // IE has it's own special way (in some ways better) of saving a blow
            navigator.msSaveBlob(blob, filename);
        } else {
            // the only way (on most browsers) to save a file with a filename is to click on a blob url
            const link = document.createElement('a');
            // modern browsers support download filename
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            if (link.download !== undefined) {
                link.setAttribute('download', filename);
            }
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}