import styled from 'styled-components';
import colors from "./Colors";

export const MaintenanceContainer = styled.div`
  width: 100%;
`;

export const MaintenanceContent = styled.div`
  text-align: center;
  max-width: 1080px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    font-family: "Maven Pro";
    font-size: 40px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    white-space: pre-wrap;
  }
  a {
    color: ${colors.primary};
    text-decoration: none;
    cursor: pointer;
  }
  a:visited {
    color: ${colors.primary};
  }
  a:hover {
    color: ${colors.selected};
  }
`;

export const TcLogo = styled.img `
  width: 180px;
  margin-top: 40px;
`;

export const MaintenanceImg = styled.img `
  width: 50%;
  object-fit: contain;
  min-width: 300px;
`;