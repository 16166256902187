import ValidationFunctions from "./ValidationFunctions";
import Validation, {IValidationResult} from "./Validation";
import {IMainlineDailyCapability} from "../../types";
import {locale} from "../../locale";


export interface IMainlineCapabilityPublicationCapabilitiesValidation {
    validate(capabilities: IMainlineDailyCapability[], hadParserError: boolean): IValidationResult;
}

class MainlineCapabilityPublicationCapabilitiesValidation implements IMainlineCapabilityPublicationCapabilitiesValidation {
    validate(capabilities: IMainlineDailyCapability[], hadParserError: boolean): IValidationResult {
        const validationResults: IValidationResult[] = [];
        if (hadParserError) {
            validationResults.push({isValid:false, message: locale("validation.mainlineCapabilityPublication.capabilities.invalidFileMessage")});
        } else {
            const uniqueDays = capabilities.filter(y => capabilities.find(z => z.gasDay === y.gasDay) === y);

            if (uniqueDays.length !== 11) {
                validationResults.push({isValid:false, message: locale("validation.mainlineCapabilityPublication.capabilities.invalidRowCount")});

            }
            if (capabilities.length !== uniqueDays.length) {
                validationResults.push({isValid:false, message: locale("validation.mainlineCapabilityPublication.capabilities.duplicateDays")});
            }
        }
        return {isValid: validationResults.length === 0, message: validationResults.map(x => x.message).join("\n")};
    }
}

export default class MainlineCapabilityPublicationValidation {
    private static HeatingValueValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.heatingValue.requiredMessage"},
        {validationFunction: ValidationFunctions.FloatValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.heatingValue.numericMessage"},
        {validationFunction: ValidationFunctions.MaxNumericValueValidationFunction(50), validationMessageKey: "validation.mainlineCapabilityPublication.heatingValue.allowedRange"},
        {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(1), validationMessageKey: "validation.mainlineCapabilityPublication.heatingValue.allowedRange"},
    ]);

    private static CapacityPercentageValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.capacityPercentage.requiredMessage"},
        {validationFunction: ValidationFunctions.FloatValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.capacityPercentage.numericMessage"},
        {validationFunction: ValidationFunctions.MaxNumericValueValidationFunction(100), validationMessageKey: "validation.mainlineCapabilityPublication.capacityPercentage.allowedRange"},
        {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(1), validationMessageKey: "validation.mainlineCapabilityPublication.capacityPercentage.allowedRange"},
    ]);

    private static PrimaryDisclaimerValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.primaryDisclaimer.requiredMessage"},
    ]);

    private static SecondaryDisclaimerValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.mainlineCapabilityPublication.secondaryDisclaimer.requiredMessage"},
    ]);

    public static CapabilitiesValidation: IMainlineCapabilityPublicationCapabilitiesValidation = new MainlineCapabilityPublicationCapabilitiesValidation();

    public static AllValidations: {[key: string]: Validation} = {
        heatingValue: MainlineCapabilityPublicationValidation.HeatingValueValidation,
        capacityPercentage: MainlineCapabilityPublicationValidation.CapacityPercentageValidation,
        primaryDisclaimer: MainlineCapabilityPublicationValidation.PrimaryDisclaimerValidation,
        secondaryDisclaimer: MainlineCapabilityPublicationValidation.SecondaryDisclaimerValidation
    }
}