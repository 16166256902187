import styled, {css} from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

export const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${device.tablet}{
    flex-direction: column;
    align-content: center;
  }
  @media ${device.mobile} {
    padding: 0 16px 0 16px;
  }
  justify-content: center;
`;

export const SmallHeading = styled.p`
  font-family: Maven Pro;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.charcoal};
  margin: 0;
`;

interface PhoneContainerProps {
  webLink?: boolean;
}

export const PhoneNumberContainer = styled.div<PhoneContainerProps>`
  flex: 1 1 auto;
  a {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 24px;
    color: ${props => props.webLink? colors.primary : '#000000'};
    text-decoration: none;
    @media ${device.mobile} {
      font-size: 20px;
    }
  }
  
  a:hover {
    text-decoration: underline;
  }
  @media ${device.mobile}{
    margin: 16px 0 16px 0;
  }
`;

interface HeaderProps {
  hiddenOnMobile?: boolean;
}

export const ContactPageHeader = styled.div<HeaderProps>`
  flex: 1 1 auto;
  Font-family: Maven Pro;
  font-size: 32px;
  color: ${colors.selected};
  @media ${device.tablet} {
    display: ${props => props.hiddenOnMobile ? 'none' : 'flex'}
  }
`;

export const AllNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 20px 0 40px 0;
  padding: 16px 40px;
  @media ${device.mobile}{
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const FormContainer = styled.div`
  margin: 36px 0 0 36px;
  form {
    display: flex;
    flex-direction: row;
    @media ${device.mobile}{
      flex-direction: column;
    }
  }
  @media ${device.mobile}{
    margin: 1px;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: ${colors.charcoal};
  }
  small {
    color: #ff0000;
    font-size: 10px;
    font-style: italic;
    min-height: 13px;
  }
  @media ${device.mobile} {
    margin-right: 0;
  }
`;

export const SmallSpan = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: ${colors.charcoal};
`;

export const RadioInput = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin-bottom: 8px;
  input {
    width: 24px;
    height: 24px;
  }
  p {
    margin: 0 16px 0 8px;
  }
`;

export const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SharedStyles = css`
  border: 1px solid #CFCDCE;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 312px;
  height: 48px;
  margin: 16px 0 0 0;
  padding: 9px;
  ::placeholder {
    color: ${colors.charcoal};
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-size: 100%;
    margin: 9px 16px;
  }
`;

export const TextInput = styled.input`
  flex: 1;
  min-height: 48px;
  font-size: 100%;
  ${SharedStyles};
`;

export const TextArea = styled.textarea`
  ${SharedStyles};
  flex: 2;
  resize: none;
  min-height: 112px;
  font-family: Roboto, sans-serif;
  font-size: 100%;
`;

export const SubmitButton = styled.button`
  flex: 1;
  min-height: 48px;
  background: ${colors.primary};
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Roboto, sans-serif;  
  margin-bottom: 13px;
  margin-top: 8px;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background-color: ${colors.charcoal};
  }
  @media ${device.mobile}{
    margin-top: 12px;
  }
`;

export const PrivacyStatementWrapper = styled.div`
  font-size: 10px;
  max-width: 656px;
  margin: 0 32px 25px 0;
  a {
    font-size: 10px;
  }
  p {
    margin: 0 0 12px 0;
  }
  @media ${device.mobile} {
    margin: 0;
  }
  @media ${device.tablet} {
  align-self: flex-end;
  }
`;