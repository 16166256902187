import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

export const SubscribeWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  background: rgba(26,114,200, 0.1);
  justify-content: center;
`;

export const SubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  padding: 30px 0;
  a {
    text-decoration: none;
  }
  @media ${device.mobile}{
    margin:0 16px;
  }
  .subscriptionButton {
    @media ${device.mobile} {
      width: 100%;
    }
  }
`;

export const SubscribeHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 18px;
  color: ${colors.charcoal_text};
  text-align: center;
`;

export const SubscribeSubHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${colors.charcoal_text};
`;

export const SubscribeInfo = styled.div`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: ${colors.charcoal_text};
  max-width: 450px;
`;

export const SubscribeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  background: ${colors.primary};
  border-radius: 4px;
  border-color: transparent;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;

  width: 328px;
  height: 48px;
  @media ${device.mobile}{
    width: 100%;
  }
`;