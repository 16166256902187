import React from 'react';
import * as S from "../../styles";
import {locale} from "../../locale";
import {Link} from 'react-router-dom'

interface Props {
    header: string;
    link: string;
}

const SubscribeBanner: React.FC<Props> = (props) => {
    return (
        <S.SubscribeWrapper>
            <S.SubscribeContainer>
                <S.SubscribeHeader>{props.header}</S.SubscribeHeader>
                <S.SubscribeSubHeader>{locale("subscribeBanner.subscribe.subscribeInfo")}</S.SubscribeSubHeader>
                <Link className='subscriptionButton' to={props.link}><S.SubscribeButton>{locale("subscribeBanner.subscribe.button.subscribe")}</S.SubscribeButton></Link>
                <S.Links style={{padding: 0}}><Link to={props.link}>{locale("subscribeBanner.subscribe.manageSubscriptions")}</Link></S.Links>
                <S.SubscribeInfo><b style={{textTransform: "uppercase"}}>{locale('note')}:</b> {locale('subscribeBanner.subscribe.subscribeNote')}
                </S.SubscribeInfo>
            </S.SubscribeContainer>
        </S.SubscribeWrapper>
    )
};

export default SubscribeBanner;