import {DateTime, Interval} from "luxon";

class DateUtilities {
    static serviceDateFormat = "yyyy-MM-dd HH:mm:ss";
    static serviceDateOnlyFormat = "yyyy-MM-dd";
    static uiDateFormat = "MMM dd, yyyy";
    static csrChartFormat = "MMM, yy";
    static editDateFormat = "MM/dd/yyyy";
    static editDateTimeFormat = "MM/dd/yyyy HH:mm";
    static publishDateTimestamp = "MMM dd, yyyy HH:mm";
    static uiDateTimeFormat = DateUtilities.publishDateTimestamp;
    static uiDateTimeWithTimezone = "MMM dd, yyyy HH:mm ZZZZ";
    static mountainTimeZone = "America/Boise";
    static cctTimeZone = "America/Winnipeg";
    static universalTimeZone = "UTC";

    static readonly ServiceDateUtility = DateUtilities.WithFormat(DateUtilities.serviceDateFormat);
    static readonly ServiceDateOnlyUtility = DateUtilities.WithFormat("yyyy-MM-dd");
    static readonly UiDateUtility = DateUtilities.WithFormat(DateUtilities.uiDateFormat);
    static readonly UiDateTimeUtility = DateUtilities.WithFormat(DateUtilities.publishDateTimestamp);
    static readonly CsrChartUtility = DateUtilities.WithFormat(DateUtilities.csrChartFormat);
    static readonly EditDateUtility = DateUtilities.WithFormat(DateUtilities.editDateFormat);
    static readonly EditDateTimeUtility = DateUtilities.WithFormat(DateUtilities.editDateTimeFormat);
    static readonly ExcelDateUtility = DateUtilities.WithFormat("d-MMM-yy");
    static readonly KeyDateUtility = DateUtilities.WithFormat("yyyy-MM-dd");

    static readonly AllSupportedFormats: IDateUtility[] = [
        DateUtilities.ServiceDateUtility,
        DateUtilities.EditDateUtility,
        DateUtilities.UiDateUtility,
        DateUtilities.KeyDateUtility,
        DateUtilities.ServiceDateOnlyUtility,
        DateUtilities.WithFormat("MMM dd/yy"),
        DateUtilities.WithFormat("MM-dd-yy")
    ];

    static Parse(date: string): DateTime {
        for (const handler of this.AllSupportedFormats) {
            const parsed = handler.ParseDateTime(date);
            if (parsed.isValid) {
                return parsed;
            }
        }
        return DateTime.fromJSDate(new Date(date));
    }

    static ParseDate(date: string): DateTime {
        return this.Parse(date).startOf("day");
    }

    static formatDate = (date: Date, removeTime = true): string => {
        const truncation = removeTime ? 5 : 0;
        const formatedDate = DateUtilities.UiDateTimeUtility.Reformat(date.toString());
        return formatedDate.substring(0, formatedDate.length-truncation);
    }

    static formatDateTime = (date: DateTime, removeTime = true): string => {
        const formatedDate = DateUtilities.UiDateTimeUtility.Reformat(date.toString());
        return formatedDate;
    }

    static TruncateTime(date: string, defaultValue = ""): string {
        for (const handler of this.AllSupportedFormats) {
            const parsed = handler.ParseDate(date);
            if (parsed.isValid) {
                return handler.Format(parsed);
            }
        }

        const parsed = DateTime.fromJSDate(new Date(date));
        if (parsed.isValid) {
            return parsed.startOf("day").toJSDate().toString();
        }

        return defaultValue;
    }

    static Now(): DateTime {
        return DateTime.local();
    }

    static UtcNow(): DateTime {
        return DateTime.utc();
    }

    static Today(): DateTime {
        return DateTime.local().startOf("day");
    }
    static FromJSDate(date: Date | undefined): DateTime| undefined {
        if (!date) {
            return date;
        }
        return DateTime.fromJSDate(date);
    }
    static WithFormat(format: string): IDateUtility {
        const parseDateTime = (date: string) => DateTime.fromFormat(date, format);

        function dateComparer(dateA: DateTime, dateB: DateTime) {
            if (dateA.isValid && dateB.isValid) {
                return dateA.toMillis() - dateB.toMillis();
            }
            if (!dateA.isValid && !dateB.isValid) {
                return 0;
            }
            return dateA.isValid ? -1 : 1;
        }

        return {
            ParseDateTime: parseDateTime,
            ParseDate: (date: string) => parseDateTime(date).startOf("day"),
            ParseDateTimeInterval: (date1: string, date2: string) => Interval.fromDateTimes(parseDateTime(date1), parseDateTime(date2)),
            ParseDateInterval: (date1: string, date2: string) => Interval.fromDateTimes(parseDateTime(date1).startOf("day"), parseDateTime(date2).startOf("day")),
            Format: (date: DateTime) => date.toFormat(format),
            DateComparer: (a: string, b: string) => dateComparer(parseDateTime(a).startOf("day"), parseDateTime(b).startOf("day")),
            DateTimeComparer: (a: string, b: string) => dateComparer(parseDateTime(a), parseDateTime(b)),
            Now: () => DateTime.local().toFormat(format),
            Reformat: (date: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.toFormat(format) : date;
            },
            ReformatUtcToLocal: (date: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.setZone("UTC", {keepLocalTime: true}).toLocal().toFormat(format) : date;
            },
            ReformatLocalToUtc: (date: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.toUTC().toFormat(format) : date;
            },
            ReformatUtcToTz: (date: string, tz: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.setZone("UTC", { keepLocalTime: true }).setZone(tz).toFormat(format) : date;
            },
            ReformatTzToUtc: (date: string, tz: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.setZone(tz, { keepLocalTime: true }).toUTC().toFormat(format) : date;
            },
            UtcToLocalDateTime: (date: string) => {
                const parsed = DateUtilities.Parse(date);
                return parsed.isValid ? parsed.setZone("UTC", {keepLocalTime: true}).toLocal() : parseDateTime(date);
            },
            Today: () => DateTime.local().startOf("day").toFormat(format),
            Max: (dates: string[]) => {
                const dateTimes = dates.map(date => parseDateTime(date));
                return DateTime.max(...dateTimes.filter(d => d.isValid))?.toFormat(format) ?? "";
            },
            Min: (dates: string[]) => {
                const dateTimes = dates.map(date => parseDateTime(date));
                return DateTime.min(...dateTimes.filter(d => d.isValid))?.toFormat(format) ?? "";
            },
        }
    }

    static getGasDay = (date: Date): Date => {
        if (date.getHours() >= 8){
            date.setHours(8);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        } else{
            date.setDate(date.getDate() - 1);
            date.setHours(8);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        return date;
    }

    static getPreviousGasDay = (date: Date, adjustGasDayCutoffHour = 0): Date => {
        const offsetHours = (date.getTimezoneOffset() / 60);
        const MSTOffset = -7;
        const offsetFromMST = MSTOffset + offsetHours;
        if (date.getHours() + offsetFromMST >= (8 + adjustGasDayCutoffHour)){
            date.setDate(date.getDate() - 1);
            date.setHours(8);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        } else{
            date.setDate(date.getDate() - 2);
            date.setHours(8);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        return date;
    }

    static MSTTime = (date: Date): Date => {
        const offsetHours = (date.getTimezoneOffset() / 60);
        const MSTOffset = -7;
        const offsetFromMST = MSTOffset + offsetHours;
        date.setHours(date.getHours() + offsetFromMST)
        return date;
    }


}

export interface IDateUtility {
    ParseDateTime: (date: string) => DateTime;
    ParseDate: (date: string) => DateTime;
    ParseDateTimeInterval: (date1: string, date2: string) => Interval;
    ParseDateInterval: (date1: string, date2: string) => Interval;
    Format: (date: DateTime) => string;
    DateComparer: (a: string, b: string) => number;
    DateTimeComparer: (a: string, b: string) => number;
    Now: () => string;
    Reformat: (date: string) => string;
    ReformatUtcToLocal: (date: string) => string;
    ReformatLocalToUtc: (date: string) => string;
    ReformatUtcToTz: (date: string, tz: string) => string;
    ReformatTzToUtc: (date: string, tz: string) => string;
    UtcToLocalDateTime: (date: string) => DateTime;
    Today: () => string;
    Max: (dates: string[]) => string;
    Min: (dates: string[]) => string;
}

export default DateUtilities;
