import React from 'react';
import * as S from "../../styles";

import {ICurrentSystemReportActive, ICurrentSystemReportData} from "../../types";
import {locale} from "../../locale";
import {DataGridDetail} from './DataGridDetail';


interface Props {
    active: ICurrentSystemReportActive;
    setActiveChartDataMultiple: any;
    setActiveChartData: any;
    receiptDataGridContents: ICurrentSystemReportData[];
    linepackDataGridContents: ICurrentSystemReportData[];
    deliveryDataGridContents: ICurrentSystemReportData[];
}

const CurrentSystemReportDataGrid: React.FC<Props> = (Props)=>{
    return (
        <S.DatagridTableWrapper>
            <DataGridDetail active={Props.active} setActiveChartDataMultiple={Props.setActiveChartDataMultiple} setActiveChartData={Props.setActiveChartData} dataGridContents={Props.linepackDataGridContents} dataGridName={locale('currentSystemReport.linepackDataGrid.tableHeader.category')}/>
            <DataGridDetail active={Props.active} setActiveChartDataMultiple={Props.setActiveChartDataMultiple} setActiveChartData={Props.setActiveChartData} dataGridContents={Props.receiptDataGridContents} dataGridName={locale('currentSystemReport.receiptDataGrid.tableHeader.category')}/>
            <DataGridDetail active={Props.active} setActiveChartDataMultiple={Props.setActiveChartDataMultiple} setActiveChartData={Props.setActiveChartData} dataGridContents={Props.deliveryDataGridContents} dataGridName={locale('currentSystemReport.deliveryDataGrid.tableHeader.category')}/>
        </S.DatagridTableWrapper>
    );
};
export default CurrentSystemReportDataGrid;