import React from 'react';
import colors from '../styles/Colors';
import * as S from '../styles';
import {locale} from '../locale';
import {Status} from "../types";

interface Props {
    status?: Status;
}

const StatusBadge: React.FC<Props> = (props) => {
    let textColor: string;
    let backgroundColor: string;
    let status = props.status;

    if (!status) {
        status = Status.UNCHANGED;
    }

    switch (status) {
        case Status.NEW:
            textColor = '#ffffff';
            backgroundColor = colors.new;
            break;
        case Status.CHANGED:
            textColor = '#ffffff';
            backgroundColor = colors.changed;
            break;
        case Status.REMOVED:
            textColor = '#000000';
            backgroundColor = colors.removed;
            break;
        default:
            textColor = '#ffffff';
            backgroundColor = '#000000';
    }

    if (status === Status.UNCHANGED) {
        return null;
    }

    return (
            <S.Badge textColor={textColor} backgroundColor={backgroundColor}><p>{locale(status).toUpperCase()}</p></S.Badge>
    )
};

export default StatusBadge;