import React, {ChangeEvent} from 'react';
import * as S from "../../styles";

interface Props {
    rowsPerPage: number;
    action: (e: ChangeEvent<HTMLSelectElement>) => void;
    selectedValue: string;
}

const ResultsPerPagePicker: React.FC<Props> = (props) => {
    return (
        <S.PageNumberSelect onChange={props.action} value={props.selectedValue}>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
        </S.PageNumberSelect>
    )
};

export default ResultsPerPagePicker;