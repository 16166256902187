class NumberUtilities {
    static ParseFlow(flow: string): number  {
        try {
            return Math.trunc(parseFloat(flow.replace(/,/g, '')));
        } catch (e) {
            return NaN;
        }
    }

    static FormatFlow(flow: string): string {
        const parsedFlow = this.ParseFlow(flow);
        return parsedFlow.toString() === NaN.toString() ? flow : parsedFlow.toLocaleString();
    }

    static FormatFlowForCsv(flow: string) {
        const parsedFlow = this.ParseFlow(flow);
        return parsedFlow.toString() === NaN.toString() ? flow : parsedFlow.toString();
    }

    static RoundUpToNearestTenThousand(value: number) {
        return Math.round(Math.ceil(value/10000)) * 10000;
    }

    static RoundDownToNearestTenThousand(value: number) {
        return Math.round(Math.floor(value/10000)) * 10000;
    }

    static RoundUpToNearestThousand(value: number) {
        return Math.round(Math.ceil(value/1000)) * 1000;
    }

    static RoundDownToNearestThousand(value: number) {
        return Math.round(Math.floor(value/1000)) * 1000;
    }

    static RoundUpToNearestHundred(value: number) {
        return Math.round(Math.ceil(value/10)) * 10;
    }

    static RoundDownToNearestHundred(value: number) {
        return Math.round(Math.floor(value/10)) * 10;
    }

    static RoundUpToNearestTen(value: number) {
        return Math.round(Math.ceil(value/10)) * 10;
    }

    static RoundDownToNearestTen(value: number) {
        return Math.round(Math.floor(value/10)) * 10;
    }

    static RoundUpToNearestOne(value: number) {
        return Math.round(Math.ceil(value));
    }

    static RoundDownToNearestOne(value: number) {
        return Math.round(Math.floor(value));
    }

    static GetRandomNumber(min: number, max: number): number {
        return Math.round(Math.random() * (max-min)) + min;
    }
}

export default NumberUtilities;