import {
    IOutage,
    IOutageComparison
} from "../types";

import {
    OutageComparisonActionTypes,
    SET_DAILY_OPERATING_PLAN_COMPARE_DATE,
    SET_DAILY_OPERATING_PLAN_COMPARISONS,
    SET_OUTAGE_HISTORY,
    SET_PUBLISH_DATES,
    SET_PUBLISH_DATE,
    SET_OUTAGE_COMPARISON_LOADED
} from "../types/OutageComparisonActionTypes";

export function setPublishDates(publishDates: string[]): OutageComparisonActionTypes {
    return {
        type: SET_PUBLISH_DATES,
        publishDates: publishDates
    };
}

export function setPublishDate(publishDate: string): OutageComparisonActionTypes {
    return {
        type: SET_PUBLISH_DATE,
        publishDate: publishDate
    };
}

export function setOutageComparisonLoaded(loaded: boolean): OutageComparisonActionTypes {
    return {
        type: SET_OUTAGE_COMPARISON_LOADED,
        loaded: loaded
    };
}

export function setOutageHistory(outages: IOutage[], publishDate: string): OutageComparisonActionTypes {
    return {
        type: SET_OUTAGE_HISTORY,
        outages: outages,
        publishDate: publishDate,
    };
}

export function setDailyOperatingPlanComparisons(comparisons: IOutageComparison[]): OutageComparisonActionTypes {
    return {
        type: SET_DAILY_OPERATING_PLAN_COMPARISONS,
        comparison: comparisons
    };
}

export function selectDailyOperatingPlanCompareDate(date: string): OutageComparisonActionTypes {
    return {
        type: SET_DAILY_OPERATING_PLAN_COMPARE_DATE,
        compareDate: date,
    };
}

