import {
    AppActionTypes,
    CLEAR_IS_BUSY,
    CLEAR_IS_DIRTY,
    HIDE_CONFIRM,
    IConfirm,
    SET_ERROR,
    SET_IS_AUTH_INITIALIZED,
    SET_IS_BUSY,
    SET_IS_DIRTY,
    SET_IS_AUTHORIZED,
    SET_PROGRESS,
    SHOW_CONFIRM,
    SHOW_DISCARD_CONFIRM,
    TOGGLE_SHOW_DISCLAIMER,
    SET_APP_STATUS,
    SET_VOLUME_UNIT,
    SET_ENERGY_UNIT,
} from "../types/AppActionTypes";
import {IProgress} from "../reducers/appReducer";
import { AppStatus, VolumeUnits,  EnergyUnits } from "../types";

export function setError(error: string): AppActionTypes {
    return {
        type: SET_ERROR,
        error: error
    }
}

export function setBusy():  AppActionTypes {
    return {
        type: SET_IS_BUSY,
    }
}

export function clearBusy(hardClear = false):  AppActionTypes {
    return {
        type: CLEAR_IS_BUSY,
        hardClear: hardClear
    }
}

export function setProgress(progress: IProgress):  AppActionTypes {
    return {
        type: SET_PROGRESS,
        progress: progress,
    }
}

export function setIsDirty():  AppActionTypes {
    return {
        type: SET_IS_DIRTY,
    }
}

export function clearIsDirty():  AppActionTypes {
    return {
        type: CLEAR_IS_DIRTY,
    }
}


export function showConfirm(confirm: IConfirm):  AppActionTypes {
    return {
        type: SHOW_CONFIRM,
        confirm: confirm
    }
}

export function hideConfirm():  AppActionTypes {
    return {
        type: HIDE_CONFIRM,
    }
}

export function showDiscardConfirm(onConfirm: any):  AppActionTypes {
    return {
        type: SHOW_DISCARD_CONFIRM,
        onConfirm: onConfirm
    }
}

export function toggleShowDisclaimer(): AppActionTypes {
    return {
        type: TOGGLE_SHOW_DISCLAIMER,
    }
}

export function setIsAuthenticationInitialized(isAuthenticationInitialized: boolean): AppActionTypes {
    return {
        type: SET_IS_AUTH_INITIALIZED,
        isAuthenticationInitialized: isAuthenticationInitialized
    };
}

export function setIsUserAuthorized(isUserAuthorized: boolean): AppActionTypes {
    return {
        type: SET_IS_AUTHORIZED,
        isUserAuthorized: isUserAuthorized
    };
}

export function setAppStatus(status: AppStatus): AppActionTypes {
    return {
        type: SET_APP_STATUS,
        status: status
    };
}

export function setVolumeUnit(unit: VolumeUnits): AppActionTypes {
    return {
        type: SET_VOLUME_UNIT,
        unit: unit
    }
}

export function setAppEnergyUnit(unit: EnergyUnits): AppActionTypes {
    return {
        type: SET_ENERGY_UNIT,
        unit: unit
    }
}
