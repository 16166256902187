import styled from "styled-components";
import {device} from '../styles/DeviceSizes';
import colors from '../styles/Colors';

export const DisclaimerBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  min-height: 96px;
  background: rgba(14, 60, 110, 0.85);
  font-family: Roboto, sans-serif;
      
  @media ${device.tablet} {
    bottom: 74px;
  }
  @media ${device.mobile} {
    bottom: 52px;
  }
  
  @media print {
  display: none;
  }
`;

export const DisclaimerInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: column;
    margin: 0 12px;
  }
`;

export const DisclaimerText = styled.div`
  font-size: 14px;
  color: #ffffff;
  p {
    max-width: 700px;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #82A6DD;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const DisclaimerButton = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${colors.primary};
  background-color: rgba(255,255,255,1);
  border: 1px solid #82A6DD;
  border-radius: 4px;
  min-width: 92px;
  max-height: 32px;
  align-content: center;
  flex: 1 1 auto;
  display: flex;
  padding: 9px 0;
  cursor: pointer;
  margin-left: 75px;
  span {
    align-self: center;
    margin: 0 auto;
  }
  @media ${device.tablet} {
    margin-bottom: 12px;
    margin-left: 0;
  }
`;