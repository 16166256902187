import {
    IArea, ICapability,
    IChartData,
    IDailyCapability,
    IDailyChartData,
    IOutage,
    IOutageForChart,
    IOutageForSave,
    VolumeUnits,
    EnergyUnits
} from "../types";
import SortFunctions from "./SortFunctions";
import NumberUtilities from "./NumberUtilities";
import {AdminUtilities} from "./AdminUtilities";
import DateUtilities, {IDateUtility} from "./DateUtilities";
import {DateTime} from "luxon";
import ArrayUtilities from "./ArrayUtilities";
import { convertVolumeUnit, convertEnergyUnit } from "./UnitConversions";

interface INumberDictionary {
    [key: string]: number;
}

export const AlbertaBCAcronym = "AB/BC";
export const WestGateAcronym = "WGAT";
export const EastGateAcronym = "EGAT";
export const FoothillsBCAcronym = "FHBC";
export const FoothillsSKAcronym = "FHSK";
export const LocalAreaDelivery = "LCLD";
export const LocalAreaReceipt = "LCLR";
export const UsjrAcronym = "USJR";
export const HeatValueDefault = 38.5;

export class ChartBuilder {

    private static readonly outageDateHandler: IDateUtility = DateUtilities.ServiceDateUtility;
    private static readonly keyDateHandler: IDateUtility = DateUtilities.WithFormat("yyyy-MM-dd");

    // 38.5 TJ/day to 1 10^6m^3/day => 0.0385 TJ/day to 1 10^3m^3/day
    static HeatValueConversionFactor(volumeUnit: VolumeUnits, energyUnit: EnergyUnits, heatValue: number): number {
        return ((heatValue/1000) / (convertVolumeUnit(1, volumeUnit, false))) * convertEnergyUnit(1, energyUnit, false)
    }

    static GetChartDates(outages: IOutageForChart[], dailyData: IDailyChartData[], areas: IArea[]): { start: DateTime; end: DateTime } {
        const xStart = DateTime.min(...outages.map(x => this.outageDateHandler.ParseDate(x.startDateTime)), DateUtilities.Now(), ...dailyData.map(d => this.keyDateHandler.ParseDate(d.gasDay)));
        let xEnd = DateTime.max(...outages.map(x => this.outageDateHandler.ParseDate(x.endDateTime)), ...dailyData.map(d => this.keyDateHandler.ParseDate(d.gasDay)));

        // do not go further into future than base capability end date.
        const areaCapabilityEndDates: DateTime[] = [];
        areas.filter(a => a.capabilities.length > 0).forEach(a => areaCapabilityEndDates.push(DateTime.max(...a.capabilities.map(x => this.outageDateHandler.ParseDate(x.endDate)))));
        const areaCapabilityMaxEndDate = DateTime.min(...areaCapabilityEndDates);
        if (areaCapabilityMaxEndDate < xEnd) {
            xEnd = areaCapabilityMaxEndDate;
        }

        return {start: xStart, end: xEnd};
    }

    static GetChartDatesAndHistoricalOutagesForSave(outages: IOutageForSave[], previousOutages: IOutage[], historicalOutages: IOutage[]): { start: DateTime; end: DateTime; outagesToInclude: IOutage[] } {
        const historicalOutagesToInclude: IOutage[] = [];
        const startDates: DateTime[] = [];
        const oldOutages: IOutage[] = [];

        outages.forEach(o => {
            previousOutages.forEach(p => {
                if (o.outageId === p.outageId && o.impactId === p.impactId) {
                    const previousStart = this.outageDateHandler.ParseDate(p.startDateTime);
                    if (p.startDateTime < o.startDateTime && previousStart < DateUtilities.Now()) {
                        startDates.push(previousStart);

                        // get historical outages that overlap this time frame but are not the same outage
                        const outagesInclude = historicalOutages.filter(h => !(h.outageId === o.outageId && h.impactId === o.impactId)
                            && (this.outageDateHandler.ParseDate(h.startDateTime) >= this.outageDateHandler.ParseDate(p.startDateTime)
                            || this.outageDateHandler.ParseDate(h.endDateTime) <= this.outageDateHandler.ParseDate(p.endDateTime)));

                        // get most recent publication for these overlapping outages
                        outagesInclude.forEach(o => {
                            const duplicateOutages = outagesInclude.filter(i => i.impactId === o.impactId && i.outageId === o.outageId);
                            if(duplicateOutages.length > 0) {
                                const maxPublishedDateTimeUtc = DateTime.max(...duplicateOutages.map(x => this.outageDateHandler.ParseDate(x.publishedDateTimeUtc)));
                                const latestOutage = duplicateOutages.filter(max => this.outageDateHandler.ParseDate(max.publishedDateTimeUtc).equals(maxPublishedDateTimeUtc));
                                if (latestOutage.length === 1 && historicalOutagesToInclude.filter(h => h.impactId === latestOutage[0].impactId && h.outageId === latestOutage[0].outageId).length === 0){
                                    historicalOutagesToInclude.push(latestOutage[0])
                                }
                            }
                        });
                    }
                // Get overlapping outages
                } else if (
                    ((this.outageDateHandler.ParseDate(p.startDateTime) >= this.outageDateHandler.ParseDate(o.startDateTime))
                    && (this.outageDateHandler.ParseDate(p.startDateTime) <= this.outageDateHandler.ParseDate(o.endDateTime)))
                    || ((this.outageDateHandler.ParseDate(p.endDateTime) >= this.outageDateHandler.ParseDate(o.startDateTime))
                    && (this.outageDateHandler.ParseDate(p.endDateTime) <= this.outageDateHandler.ParseDate(o.endDateTime)))
                ) {
                    oldOutages.push(p);
                }
            });
        });

        // get most recent publication for these overlapping outages
        oldOutages.forEach(o => {
            const duplicateOutages = oldOutages.filter(i => i.impactId === o.impactId && i.outageId === o.outageId);
            if(duplicateOutages.length > 0) {
                const maxPublishedDateTimeUtc = DateTime.max(...duplicateOutages.map(x => this.outageDateHandler.ParseDate(x.publishedDateTimeUtc)));
                const latestOutage = duplicateOutages.filter(max => this.outageDateHandler.ParseDate(max.publishedDateTimeUtc).equals(maxPublishedDateTimeUtc));
                if (latestOutage.length === 1 && historicalOutagesToInclude.filter(h => h.impactId === latestOutage[0].impactId && h.outageId === latestOutage[0].outageId).length === 0){
                    historicalOutagesToInclude.push(latestOutage[0]);
                }
            } else if (historicalOutagesToInclude.filter(h => h.impactId === o.impactId && h.outageId === o.outageId).length === 0){
                historicalOutagesToInclude.push(o);
            }
        });

        const xStart = DateTime.min(...outages.map(x => this.outageDateHandler.ParseDate(x.startDateTime)), ...startDates, DateUtilities.Now().startOf('day'));
        const xEnd = DateTime.max(...outages.map(x => this.outageDateHandler.ParseDate(x.endDateTime)), DateUtilities.Now().plus({ days: 15 }).startOf('day'));
        return {start: xStart, end: xEnd, outagesToInclude: historicalOutagesToInclude};
    }

    static BuildCapabilityChartDataDictionary(outages: IOutageForChart[], comparedToOutages: IOutageForChart[], areas: IArea[], dailyData: IDailyChartData[], xStart: DateTime, xEnd: DateTime): { [key: string]: IChartData[] } {
        const areaData: { [key: string]: IChartData[] } = {};

        areas.sort(SortFunctions.DefaultAreaSortFunction()).filter(area => area.capabilities.length > 0).forEach(area => {
            const outagesInArea = outages.filter(o => o.area.id === area.id);
            const comparedToOutagesInArea = comparedToOutages.filter(o => o.area.id === area.id);

            let areaAcronym = area.acronym;

            if (area.acronym === WestGateAcronym) {
                areaAcronym = AlbertaBCAcronym;
            }

            areaData[areaAcronym] = ChartBuilder.BuildCapabilityChartData(outagesInArea, comparedToOutagesInArea, area, areaAcronym, xStart, xEnd, dailyData.filter(x => x.areaId === area.id));
        });

        return areaData;
    }

    static BuildCapabilityChartData(outages: IOutageForChart[], comparedToOutages: IOutageForChart[], area: IArea, acronym: string, xStart: DateTime, xEnd: DateTime, dailyData: IDailyChartData[]): IChartData[] {
        const result: IChartData[] = [];
        const values = ChartBuilder.TransformOutageCapabilityToValueDictionary(outages, xStart, xEnd, area, dailyData);
        const previousValues = ChartBuilder.TransformOutageCapabilityToValueDictionary(comparedToOutages, xStart, xEnd, area, dailyData);
        const outageStart = DateTime.min(...[...outages.map(x => this.outageDateHandler.ParseDate(x.startDateTime)), DateUtilities.Now()]);
        
        for (const key in values) {
            const dayValues = dailyData.length > 0 && dailyData[0].gasDay === key ? dailyData.shift() : null;
            const value = values[key];
            const keyDateTime = this.keyDateHandler.ParseDate(key);
            result.push({
                day: key,
                historicalDay: dayValues?.historicalGasDay,
                value: (value ? value : dayValues?.capabilityVolume) ?? value,
                previousValue: keyDateTime < outageStart ? undefined : previousValues[key],
                area: acronym,
                historicalFlow: dayValues?.historicalFlow,
                flow: dayValues?.flow,
                contract: dayValues?.firmContractEnergy,
            });
        }
        return result;
    }

    static BuildCapabilityChartDataForSave(outages: IOutageForSave[], previousOutages: IOutage[], historicalOutages: IOutage[], areas: IArea[], dailyData: IDailyChartData[]): IDailyCapability[] {
        const areaMap: { [key: string]: IArea } = {};
        areas.forEach(x => areaMap[x.id.toString()] = x);
        areas.forEach(x => areaMap[x.acronym] = x);
        areaMap[AlbertaBCAcronym] = areaMap[WestGateAcronym];

        const outagesForChart = outages.map(x => {
            return {...x, area: areaMap[x.areaId.toString()]}
        });

        const chartDataByArea: { [key: string]: IChartData[] } = {};
        areas.forEach(area => {
            const saveDates = ChartBuilder.GetChartDatesAndHistoricalOutagesForSave(outages.filter(o => o.areaId === area.id), previousOutages.filter(o => o.areaId === area.id), historicalOutages);
            const outageIncludingHistory: IOutageForChart[] = [...outagesForChart, ...saveDates.outagesToInclude];
            if(dailyData){
                const result = this.BuildCapabilityChartDataDictionary(outageIncludingHistory, outagesForChart, [area], dailyData, saveDates.start, saveDates.end);

                if (result && Object.keys(result).length > 0) {
                    const key = Object.keys(result)[0];
                    chartDataByArea[key] = result[key].filter(r => this.keyDateHandler.ParseDate(r.day) >= saveDates.start);
                }
            }
        });

        return ArrayUtilities
            .Flatten(Object.keys(chartDataByArea).map(x => chartDataByArea[x]))
            .map(x => {
                return {
                    capabilityVolume: x.value * 1000,
                    gasDay: DateUtilities.ServiceDateUtility.Reformat(x.day),
                    areaId: areaMap[x.area].id
                };
            });
    }

    static ValidChartOutage(outage: IOutageForChart) {
        const startDateTime = DateTime.fromFormat(outage.startDateTime, DateUtilities.serviceDateFormat);
        const endDateTime = DateTime.fromFormat(outage.endDateTime, DateUtilities.serviceDateFormat);
        if (!endDateTime.isValid || !startDateTime.isValid) {
            return false;
        }
        if (isNaN(parseInt(outage.flowCapability))) {
            return false;
        }
        return true;
    }

    static TransformOutageCapabilityToValueDictionary(outages: IOutageForChart[], start: DateTime, end: DateTime, area: IArea, dailyData: IDailyChartData[]): INumberDictionary {
        const values: INumberDictionary = {};
        const minDopStart = DateUtilities.Now();

        // builds base capability for the time range
        for (let i = start; i <= end; i = i.plus({days: 1})) {
            const key = this.keyDateHandler.Format(i);
            values[key] = ChartBuilder.ChooseHistoricalOrBaseCapabilityValue(key, i, minDopStart, area.capabilities, dailyData);
        }
        // applies outage capability for time range
        outages.sort(SortFunctions.CompositeSortFunction([SortFunctions.OutageStartDateSortFunction(), SortFunctions.OutageEndDateSortFunction()])).forEach(outage => {
            if (!ChartBuilder.ValidChartOutage(outage)) {
                return;
            }
            const interval = this.outageDateHandler.ParseDateInterval(outage.startDateTime, outage.endDateTime);
            for (let i = interval.start; i <= interval.end; i = i.plus({days: 1})) {
                const key = this.keyDateHandler.Format(i);
                if (values[key]) {
                    const capability = NumberUtilities.ParseFlow(outage.flowCapability);
                    values[key] = capability < values[key] ? capability : values[key];
                } else {
                    values[key] = NumberUtilities.ParseFlow(outage.flowCapability);
                }
            }
        });
        return values;
    }

    static ChooseHistoricalOrBaseCapabilityValue(gasDayKey: string, gasDay: DateTime, minDopStart: DateTime, capabilities: ICapability[], dailyData: IDailyChartData[]): number {
        let value = AdminUtilities.GetAreaCapabilityByDate(gasDay, capabilities);
        if (this.keyDateHandler.DateComparer(this.keyDateHandler.Format(gasDay), this.keyDateHandler.Format(minDopStart)) < 0 && dailyData.length > 0) {
            try {
                const historicalValue = dailyData.filter(d => d.gasDay === gasDayKey)[0]?.capabilityVolume;
                if (historicalValue !== undefined) {
                    value = historicalValue;
                }
            } catch (e) {
                console.log(e);
            }
        }
        return value;
    }

    static GetMinimumYValue(chartData: IChartData[], volumeUnit: VolumeUnits, energyUnit: EnergyUnits, heatValue: number): number {
        const minimum = Math.min(...ArrayUtilities.Flatten(chartData.map(d => [
            d.value,
            d.historicalFlow,
            d.flow,
            d.previousValue,
            d.contract ? d.contract / this.HeatValueConversionFactor(volumeUnit, energyUnit, heatValue) : null // convert energy to volume
        ].filter(x => x) as number[])));
        return minimum * 0.9
    }

    static GetMaximumYValue(chartData: IChartData[], volumeUnit: VolumeUnits, energyUnit: EnergyUnits, heatValue: number): number {
        const maximum = Math.max(...ArrayUtilities.Flatten(chartData.map(d => [
            d.value,
            d.historicalFlow,
            d.flow,
            d.previousValue,
            d.contract ? d.contract / this.HeatValueConversionFactor(volumeUnit, energyUnit, heatValue) : null // convert energy to volume
        ].filter(x => x) as number[])));
        return maximum * 1.1;
    }

    // Part of FHBC part of WGAT Chart combination logic
    static GetAreaByChartDataKey(key: string, areas: IArea[]): IArea {
        const keyToUse = key === AlbertaBCAcronym ? WestGateAcronym : key;
        const foundAreas = areas.filter(a => a.acronym === keyToUse);
        return foundAreas[0];
    }

    // Part of FHBC part of WGAT Chart combination logic
    static ConvertChartKeyToAreaAcronyms(chartKey: string): string[] {
        return chartKey === AlbertaBCAcronym ? [WestGateAcronym, FoothillsBCAcronym] : [chartKey];
    }

    static GetOutagesByChartDataKey(key: string, outages: IOutage[]): IOutage[] {
        return outages.filter(o => this.ConvertChartKeyToAreaAcronyms(key).indexOf(o.area.acronym) !== -1);
    }
}