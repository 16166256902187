import ValidationFunctions from "./ValidationFunctions";
import {IArea, IOutageForEdit} from "../../types";
import Validation from "./Validation";

export default class OutageValidation {

    private static OutageIdRegularValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.outageId.requiredMessage"},
        {validationFunction: ValidationFunctions.IntegerValidationFunction, validationMessageKey: "validation.outage.outageId.numericMessage"},
        {validationFunction: ValidationFunctions.MaxNumericValueValidationFunction(9999999999), validationMessageKey: "validation.outage.outageId.allowedRange"},
        {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(10000000), validationMessageKey: "validation.outage.outageId.allowedRange"},
    ]);

    static OutageIdValidation: (area: IArea) => Validation = (area) => {
        if (area.isPlantTurnAround)
            return Validation.EmptyValidation;
        return OutageValidation.OutageIdRegularValidation;
    };

    static StartDateValidation: (outage: IOutageForEdit) => Validation = (outage: IOutageForEdit) => new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.startDate.requiredMessage"},
        {validationFunction: ValidationFunctions.DateOrTBDValidationFunction, validationMessageKey: "validation.outage.startDate.validationMessage"},
        {validationFunction: ValidationFunctions.DateHasCapabilityValidationFunction(outage.area), validationMessageKey: "validation.outage.startDate.baseCapabilityRequired"},
        {validationFunction: ValidationFunctions.MaxDateValueValidationFunction(outage.endDateTime), validationMessageKey: "validation.outage.startDate.mustBeOnOrBeforeEndDate"},
    ]);

    static EndDateValidation: (outage: IOutageForEdit) => Validation = (outage: IOutageForEdit) => new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.endDate.requiredMessage"},
        {validationFunction: ValidationFunctions.DateOrTBDOrHourDurationValidationFunction, validationMessageKey: "validation.outage.endDate.validationMessage"},
        {validationFunction: ValidationFunctions.DateHasCapabilityValidationFunction(outage.area), validationMessageKey: "validation.outage.endDate.baseCapabilityRequired"},
        {validationFunction: ValidationFunctions.MinDateIntegerValueValidationFunction(outage.startDateTime), validationMessageKey: "validation.outage.endDate.mustBeOnOrAfterStartDate"},
    ]);

    static CapabilityRegularValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.capability.requiredMessage"},
        {validationFunction: ValidationFunctions.IntegerOrTBDValidationFunction, validationMessageKey: "validation.outage.capability.numericOrTBDMessage"},
        {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(0), validationMessageKey: "validation.outage.capability.nonNegative"},
    ]);

    static CapabilityValidation: (area: IArea) => Validation = (area) => {
        if (area.isPlantTurnAround)
            return Validation.EmptyValidation;
        return OutageValidation.CapabilityRegularValidation;
    };

    static LocalAreaCapabilityValidation: (area: IArea) => Validation = (area) => {
        if (area.isPlantTurnAround)
            return Validation.EmptyValidation;
        return new Validation([
            {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.capability.requiredMessage"},
            {validationFunction: ValidationFunctions.IntegerOrTbdOrNaValidationFunction, validationMessageKey: "validation.outage.capability.numericOrTbdOrNaMessage"},
            {validationFunction: ValidationFunctions.MinValueNumericValueValidationFunction(0), validationMessageKey: "validation.outage.capability.nonNegative"},
        ]);
    };

    private static ImpactRegularValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.impact.requiredMessage"},
    ]);

    static ImpactValidation: (area: IArea) => Validation = (area) => {
        if (area.isPlantTurnAround)
            return Validation.EmptyValidation;
        return OutageValidation.ImpactRegularValidation;
    };

    static DescriptionRegularValidation: Validation = new Validation([
        {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.description.requiredMessage"},
    ]);

    static DescriptionValidation: (area: IArea) => Validation = (area) => {
        if (area.isPlantTurnAround)
            return Validation.EmptyValidation;
        return OutageValidation.DescriptionRegularValidation;
    };

    static TypicalFlowValidation: (area: IArea) => Validation = (area: IArea) => {
        if (!area.hasTypicalFlow) {
            return new Validation([]);
        }

        if (area.isPlantTurnAround) {
            return new Validation([
                {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.typicalFlow.requiredMessage"},
                {validationFunction: ValidationFunctions.IntegerValidationFunction, validationMessageKey: "validation.outage.typicalFlow.numericMessage"}
            ]);
        }

        return new Validation([
            {validationFunction: ValidationFunctions.RequiredValidationFunction, validationMessageKey: "validation.outage.typicalFlow.requiredMessage"},
            {validationFunction: ValidationFunctions.IntegerOrRangeOfIntegersValidationFunction, validationMessageKey: "validation.outage.typicalFlow.validationMessage"}
        ])};

}