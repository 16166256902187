import {
    CLEAR_OUTAGE_CHART_DATA_LOADED,
    CLEAR_SELECTED_CHART_OUTAGES,
    OutageChartActionTypes,
    SET_OUTAGE_CHART_DATA,
    SET_SELECTED_CHART_OUTAGES,
    SET_SHOWING_CHART_KEYS,
} from "../types/OutageChartActionTypes";
import {IArea, IChartData, IOutage, IShowingChartKeys} from "../types";

export interface IOutageChartState {
    areaChartData: { [key: string]: IChartData[] };
    relevantOutages: IOutage[];
    relevantAreas: IArea[];
    selectedChartOutages: IOutage[];
    selectedChartOutageHistory: IOutage[];
    selectedChartDate: string;
    chartDataLoaded: boolean;
    showingChartKeys: IShowingChartKeys[];
}

export const initialChartState: IOutageChartState = {
    areaChartData: {},
    relevantOutages: [],
    relevantAreas: [],
    selectedChartOutages: [],
    selectedChartOutageHistory: [],
    selectedChartDate: "",
    chartDataLoaded: false,
    showingChartKeys: [],
};

export function outageChartReducer(state = initialChartState, action: OutageChartActionTypes): IOutageChartState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_OUTAGE_CHART_DATA:
            return {
                ...state,
                areaChartData: action.areaChartData,
                relevantOutages: action.relevantOutages,
                relevantAreas: action.relevantAreas,
                chartDataLoaded: true
            };
        case CLEAR_OUTAGE_CHART_DATA_LOADED:
            return {...state, chartDataLoaded: false};
        case SET_SELECTED_CHART_OUTAGES:
            return {
                ...state,
                selectedChartOutages: action.selectedChartOutages,
                selectedChartOutageHistory: action.selectedChartOutageHistory,
                selectedChartDate: action.selectedChartDate,
            };
        case CLEAR_SELECTED_CHART_OUTAGES:
            return {...state, selectedChartOutages: [], selectedChartOutageHistory: [], selectedChartDate: ""};
        case SET_SHOWING_CHART_KEYS:
            return {...state, showingChartKeys: action.showingChartKeys};
        default:
            return state
    }
}