import styled from "styled-components";

export const TooltipOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    z-index: 999;
`;

export const TooltipTop = styled.div`
  width: 160px;
  min-height: 50px;
  text-align: center;
  padding: 4px 4px 10px 10px;
  position: absolute;
  z-index: 1000;
  transform:translateY(-100%);
  margin-top: -30px;
  margin-left: -3px;
  background: #FFFFFF;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
  -webkit-filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
  border-radius: 4px;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 9px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

export const TooltipHeader = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: end;
   align-items: center;
`;

export const TooltipText = styled.div`
    padding-right: 20px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    vertical-align: top;
    margin: auto;
    text-align: left;
`;