import styled from "styled-components";
import colors from "./Colors";

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
  h1 {
    font-family: Maven Pro;
    font-weight: 500;
    font-size: 18px;
    color: ${colors.selected};
    margin: 0 0 12px 0;
  }
  a, span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: ${colors.primary};
    margin: 0 0 16px 0;
    text-decoration: none;
    cursor: pointer;
  }
  a:visited {
    color: ${colors.primary};
  }
  a:hover, span:hover {
    color: ${colors.selected};
  }
   
  @media print {
    display: none;
  }
`;