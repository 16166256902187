import React, {useEffect, useState} from 'react';
import * as S from "../../styles"
import {locale} from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import {RouteComponentProps, useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {Fetch} from "../../utilities/Fetch";
import ResultModal from "../ResultModal";
import SubscriptionDisclaimer from "./SubscriptionDisclaimer";
import useTypedSelector from "../../hooks/useTypedSelector";
import BetaBanner from "../BetaBanner";

interface Params {
    uniqueId: string;
}

const Unsubscribe: React.FC<RouteComponentProps> = (props) => {
    const p = props.match.params as Params;

    const history = useHistory();
    const dispatch = useDispatch();
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);

    useEffect(() => {
        if (p.uniqueId) {
            Fetch.Build("/subscriber/unsubscribe", dispatch)
                .withPayload([
                    {
                        email: "",
                        uniqueId: p.uniqueId
                    }])
                .success(() => {
                    setResultText(locale("subscription.unsubscribe.success"));
                    setResultSuccess(true);
                })
                .error((e) => {
                    setResultText(e.message);
                    setResultSuccess(false);
                })
                .finally(() => {
                    setShowResult(true);
                })
                .post();
        }
    }, [p.uniqueId, dispatch]);

    return (
        <div>
            <BetaBanner/>
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <BackNavigationHeader handleBackNavigation={() => history.goBack()}
                                      leftContent={locale('subscription.unsubscribe')}/>

                {showResult && resultSuccess &&
                <S.UnsubscribeContainer>
                    <S.UnsubscribeHeader>{locale("subscription.unsubscribe.sorry")}</S.UnsubscribeHeader>
                    <S.InfoLabel>{locale("subscription.unsubscribe.success")}</S.InfoLabel>
                    <S.Button style={{marginTop: "16px"}} onClick={() => {
                        history.push("/")
                    }}>{locale("subscription.unsubscribe.navigate.home")}</S.Button>
                    <S.LinkButton style={{marginTop: "16px", marginBottom: "56px"}}>
                        <div onClick={() => {
                            history.push("/Subscription")
                        }}>{locale('subscription.unsubscribe.navigate.return')}</div>
                    </S.LinkButton>
                    <SubscriptionDisclaimer/>
                </S.UnsubscribeContainer>
                }

                {showResult && !resultSuccess && <ResultModal text={resultText} onExit={() => {
                    setShowResult(false);
                }} success={resultSuccess}/>}

            </S.PageWithNavigationContainer>
        </div>
    );
};

export default Unsubscribe;