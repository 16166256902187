import styled from "styled-components";
import {device} from "./DeviceSizes";

export const ToggleRow = styled.div`
    position: fixed;     
    top: 0;           
    display: flex;   
    flex-direction: row;     
    padding: 10px;
    z-index: 998;
    @media ${device.mobile}{
      width: 100vw;
      padding: 0;
    }
    @media print {
        display: none !important;
    }
`;

export const ToggleOptions = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1 1 auto;
  border-radius: 4px;
  padding: 16px 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  @media ${device.mobile}{
    border-radius: 0;
    justify-content: space-between;
    padding: 16px 30px;
    z-index: 6;
  }
`;

export const DisplayOptionIcon = styled.div`
  display: flex;
  img {
    margin-right: 6px;
  }
`;

export const ToggleRowItem = styled.div`      
    padding: 0 10px;
    cursor: pointer;
`;

export const HeaderOutageSummaryCountLabel = styled.span`
  padding-top: 10px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 21px;
`;

export const HeaderOutageSummaryCount = styled.span`
  padding-top: 9px;
  font-weight: bolder;
  margin-left: 30px;
  font-size: 18px;
  line-height: 21px;
`;

export const HeaderAreaName = styled.div`
  font-family: Maven Pro;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  @media ${device.mobile}{
    font-size: 18px;
    line-height: 21px;
    margin: 0 20px;
  }
`;

export const HeaderCountDesktop = styled.div`
  @media ${device.mobile}{
    display: none !important;
  }
`;

export const HeaderCountMobile = styled.div`
  display: none !important;
  @media ${device.mobile}{
    display: flex !important;
  }
`;
