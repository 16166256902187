import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import * as S from "../../styles";
import BackNavigationHeader from "../BackNavigationHeader";
import {locale} from "../../locale";
import {useHistory} from "react-router";
import {Link} from 'react-router-dom';
import {Fetch} from "../../utilities/Fetch";
import DateUtilities from "../../utilities/DateUtilities";
import {DateTime} from "luxon";
import {showConfirm} from "../../actions/appActions";
import {ColumnSort, IBulletin, PublicationStatus, SearchKeyTypes} from "../../types";
import ResultModal from '../ResultModal';
import {BulletinUtilities} from "../../utilities/BulletinUtilities";
import TableColumnSortHeader from './TableColumnSortHeader';
import { setBulletinAdminSort } from '../../actions/bulletinActions';
import useTypedSelector from '../../hooks/useTypedSelector';
import BulletinTable from "./BulletinTable";
import {clearSearchOptionsLoaded} from "../../actions/searchActions";


const BulletinManagement: React.FC = () => {

    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const columnSortOrder = useTypedSelector(state => state.bulletins.bulletinAdminSort);
    const history = useHistory();
    const dispatch = useDispatch();
    const columns = {
        statusWidth: '75',
        noticeNumberWidth: '75',
        dateWidth: '100',
        serviceProviderWidth: '85',
        categoryWidth: '85',
        subjectWidth: '200',
        usernameWidth: '250',
        actionWidth: '150',
    };

    function setColumnSortOrder(columnSortOrder: ColumnSort){
        dispatch(setBulletinAdminSort(columnSortOrder))
    }

    const editDraftClick = (bulletin: IBulletin) => {
        if (!bulletin.isDraft) {
            dispatch(showConfirm({
                headerText: locale('bulletin.managementPage.editScheduled.header'),
                bodyText: locale('bulletin.managementPage.editScheduled.bodyText'),
                buttonCancelText: locale('cancel'),
                buttonConfirmText: locale('ok'),
                onConfirm: () => pushEditBulletin(bulletin.id || 0)
            }));
        } else {
            pushEditBulletin(bulletin.id || 0);
        }
    };

    const pushEditBulletin = (id: number) => {
        history.push(`/BulletinEdit/${id}`);
    };

    const copyClick = (id: number) => {
        history.push(`/BulletinEdit/Copy/${id}`);
    };


    const deletePublishedClick = (fetchBulletins: () => void ) => (id: number) => {
        dispatch(showConfirm({
            headerText: locale('deletePublishedBulletin'),
            bodyText: locale('deletePublishedBulletinConfirm'),
            buttonCancelText: locale('cancel'),
            buttonConfirmText: locale('delete'),
            background: "yellow",
            onConfirm: () => deleteDraft(fetchBulletins)(id, "deletePublicationSuccess")
        }));
    };

    const deleteDraftClick = (fetchBulletins: () => void ) => (id: number) => {
        dispatch(showConfirm({
            headerText: locale('deleteDraft'),
            bodyText: locale('deleteDraftConfirm'),
            buttonCancelText: locale('cancel'),
            buttonConfirmText: locale('delete'),
            onConfirm: () => deleteDraft(fetchBulletins)(id, "deleteDraftSuccess")
        }));
    };

    const deleteScheduledClick = (fetchBulletins: () => void ) => (id: number) => {
        dispatch(showConfirm({
            headerText: locale('deleteScheduled'),
            bodyText: locale('deleteScheduledConfirm'),
            buttonCancelText: locale('cancel'),
            buttonConfirmText: locale('delete'),
            background: "yellow",
            onConfirm: () => deleteDraft(fetchBulletins)(id, "deleteScheduledSuccess")
        }));
    };

    const deleteDraft = (fetchBulletins: () => void ) => (id: number, message: string) => {
        Fetch.Build(`/bulletin/${id}`, dispatch)
            .success(serviceSuccess(message, () => {
            }))
            .error(serviceError())
            .finally(() => {
                fetchBulletins();
                dispatch(clearSearchOptionsLoaded(SearchKeyTypes.ADMIN));
                dispatch(clearSearchOptionsLoaded(SearchKeyTypes.PUBLIC));
            })
            .delete();
    };

    function serviceSuccess(successMessageKey: string, successAction: () => void) {
        return () => {
            successAction();
            setResultText(locale(successMessageKey));
            setResultSuccess(true);
            setShowResult(true);
        };
    }

    function serviceError() {
        return () => {
            setResultText(locale("genericError2"));
            setResultSuccess(false);
            setShowResult(true);
        };
    }

    const displayDate = (bulletin: IBulletin) => {
        const myDate = bulletin.isDraft ? bulletin.lastSavedDateTimeUtc : bulletin.publishedDateTimeUtc ?? bulletin.scheduleDateTimeUtc ?? bulletin.lastSavedDateTimeUtc;
        const note = DateUtilities.ServiceDateUtility.ParseDateTime(bulletin.scheduleDateTimeUtc ?? '') > DateUtilities.Now() && !bulletin.isDraft ? "Scheduled for " : "";
        return ` ${note} ${DateTime.fromFormat(myDate, DateUtilities.serviceDateFormat, {zone: 'utc'}).setZone(DateUtilities.cctTimeZone).toFormat(DateUtilities.publishDateTimestamp)}`;
    };

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('admin.options.bulletinManagement')}/>

            <div style={{padding: '12px 24px'}}>
                <S.InformationContainer>
                    <S.SettingsButton
                        onClick={() => history.push("BulletinEdit/")}>{locale("admin.options.createBulletin")}
                    </S.SettingsButton>
                </S.InformationContainer>
                <BulletinTable endpoint={"bulletin"}
                section={SearchKeyTypes.ADMIN}
                columnSortOrder={columnSortOrder}
                    renderHeader={() => {
                        return (<S.PublicationListTableHeaderRow key={"publication-header-row"}>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.statusWidth}
                            columnName={"isDraft"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.status')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.noticeNumberWidth}
                            columnName={"id"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.noticeNumber')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.dateWidth}
                            columnName={"Date"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.date')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.serviceProviderWidth}
                            columnName={"subscription.serviceProvider.name"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.serviceProvider')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.categoryWidth}
                            columnName={"subscription.name"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.category')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.subjectWidth}
                            columnName={"subject"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.subject')}
                        </TableColumnSortHeader>
                        <TableColumnSortHeader isAdmin={true}
                            width={columns.usernameWidth}
                            columnName={"publishedBy"}
                            sortOrder={columnSortOrder}
                            setSort={setColumnSortOrder}>
                            {locale('bulletin.managementPage.tableHeader.username')}
                        </TableColumnSortHeader>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumnNoGrow width={columns.actionWidth}>
                                <S.TableCellHeaderText>{locale('bulletin.managementPage.tableHeader.action')}</S.TableCellHeaderText>
                            </S.TableColumnNoGrow>
                        </S.TableCellWrapperNoGrow>
                    </S.PublicationListTableHeaderRow>)
                    }}
                >
                    {(bulletin, fetchBulletins, lastSearched) => {
                        const isSchedule: boolean = !bulletin.isDraft && bulletin.scheduleDateTimeUtc !== null && bulletin.publishedDateTimeUtc === null;
                        return (
                            <S.PublicationListTableRow key={bulletin.id + "-publication-row"}>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.statusWidth}>
                                        <S.PublicationListContent>
                                            {bulletin.isDraft ? <S.StatusSpan
                                                status={PublicationStatus.DRAFT}>{PublicationStatus.DRAFT}</S.StatusSpan> : isSchedule ?
                                                <S.StatusSpan
                                                    status={PublicationStatus.SCHEDULED}>{PublicationStatus.SCHEDULED}</S.StatusSpan> :
                                                <S.StatusSpan
                                                    status={PublicationStatus.SENT}>{PublicationStatus.SENT}</S.StatusSpan>}
                                        </S.PublicationListContent>
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.noticeNumberWidth}>
                                        <S.PublicationListContent italic={isSchedule}
                                                                  dangerouslySetInnerHTML={{__html: bulletin.id !== null ? BulletinUtilities.BoldText(bulletin.id.toString(), lastSearched) : ''}} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.dateWidth} title={locale('displayTimeZone.disclaimer')}>
                                        <S.PublicationListContent
                                            italic={isSchedule}
                                            dangerouslySetInnerHTML={{ __html: BulletinUtilities.BoldText(
                                                displayDate(bulletin) + " " + locale("bulletins.displayTimeZone"),
                                                lastSearched) }}
                                            />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.serviceProviderWidth}>
                                        <S.PublicationListContent italic={isSchedule}
                                                                  dangerouslySetInnerHTML={{__html: BulletinUtilities.BoldText(bulletin.subscription?.serviceProvider.name ?? '', lastSearched)}} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.categoryWidth}>
                                        <S.PublicationListContent italic={isSchedule}
                                                                  dangerouslySetInnerHTML={{__html: BulletinUtilities.BoldText(bulletin.subscription?.name ?? '', lastSearched)}} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.subjectWidth}>
                                        <S.PublicationListContent italic={isSchedule}
                                                                  dangerouslySetInnerHTML={{__html: BulletinUtilities.BoldText(bulletin.subject, lastSearched)}} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapper>
                                    <S.TableColumn width={columns.usernameWidth}>
                                        <S.PublicationListContent italic={isSchedule}
                                                                  dangerouslySetInnerHTML={{__html: BulletinUtilities.BoldText(bulletin.publishedBy, lastSearched)}} />
                                    </S.TableColumn>
                                </S.TableCellWrapper>
                                <S.TableCellWrapperNoGrow>
                                    <S.TableColumnNoGrow width={columns.actionWidth}>
                                        {bulletin.publishedDateTimeUtc !== null &&
                                        <div style={{justifyContent: "space-around", display: "flex"}}>
                                            <S.ActionLink><Link
                                                to={`/bulletin/${bulletin.id || -1}`}>{locale("view")}</Link></S.ActionLink>
                                            <S.ActionLink
                                                onClick={() => deletePublishedClick(fetchBulletins)(bulletin.id || -1)}>
                                                {locale("delete")}
                                            </S.ActionLink>
                                            <S.ActionLink
                                                onClick={() => copyClick(bulletin.id || -1)}>{locale("copy")}</S.ActionLink>
                                        </div>}
                                        {bulletin.publishedDateTimeUtc === null &&
                                        <div style={{justifyContent: "space-around", display: "flex"}}>
                                            <S.ActionLink
                                                onClick={() => editDraftClick(bulletin)}>{locale("edit")}</S.ActionLink>
                                            <S.ActionLink
                                                onClick={() => !bulletin.isDraft ?
                                                    deleteScheduledClick(fetchBulletins)(bulletin.id || -1) :
                                                    deleteDraftClick(fetchBulletins)(bulletin.id || -1)}>
                                                {locale("delete")}
                                            </S.ActionLink>
                                            <S.ActionLink
                                                onClick={() => copyClick(bulletin.id || -1)}>{locale("copy")}</S.ActionLink>
                                        </div>}
                                    </S.TableColumnNoGrow>
                                </S.TableCellWrapperNoGrow>
                            </S.PublicationListTableRow>
                        )
                    }}
                </BulletinTable>
            </div>

            {showResult && <ResultModal text={resultText} onExit={() => {
                setShowResult(false);
            }} success={resultSuccess}/>}
        </S.PageWithNavigationContainer>
    )
};

export default BulletinManagement;