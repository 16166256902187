import {IHeatValue} from "../types";
import {
    CLEAR_HEAT_VALUE,
    HeatValueActionTypes,
    SET_HEAT_VALUE,
} from "../types/HeatValueActionTypes";

export interface IHeatValueState {
    heatValues: IHeatValue[];
    heatValueLoaded: boolean;
}

export const initialState: IHeatValueState = {
    heatValues: [],
    heatValueLoaded: false,
};

export function heatValueReducer(state: IHeatValueState = initialState, action: HeatValueActionTypes): IHeatValueState {
    if (state === undefined) {
        return state;
    }
    switch (action.type) {
        case SET_HEAT_VALUE:
            return {...state, heatValues: action.heatValue, heatValueLoaded: true};
        case CLEAR_HEAT_VALUE:
            return{...state, heatValues: [], heatValueLoaded: false};
        default:
            return state;
    }
}