import styled from "styled-components"
import { device } from "./DeviceSizes";
import {FontSize} from "./FontStyles";
import {TableColumnNoGrow, TableHeaderRow} from "./AdminStyles";

export const GasDayChangesColumnWidths = {
    gasChangeTitle: '200',
    receiptsWithoutStorage: '180',
    netStorage: '180',
    deliveriesWithoutStorage: '180',
    linePackVariance: '180',
    sdAccounts: '180',
    tolerance: '180',
    all: '180',
    allWidth: '900',
    totalWidth: '1280'
};

export const OutageGasDayChangeRibbonContainer = styled.div`
    position: fixed;
    align-self: flex-start;
    width: calc((100vw - (100vw - 100%)));
    bottom: 0px
    z-index: 996;
    border-top: solid grey 2px;
    padding-right: 0;
    padding-left: 0;
    background: white;
    height: 80px;
    @media ${device.tablet}{
      border-bottom: solid grey 1.5px;
  }   
`;

export const GasDayChangeTable = styled.div`\
  justify-content: center;
  display: table-row-group;
  vertical-align: middle;
`;

interface GasDayChangeTableHeaderProps {
  minWidth: string;
}

export const GasDayChangeTableHeaderRow = styled(TableHeaderRow)<GasDayChangeTableHeaderProps>`
  min-width: ${props => props.minWidth}px;
  margin-bottom: 15px;
  padding-bottom: 0px;
  height: 18px;
  text-align: center;
`;

export const GasDayChangeTableHeaderText = styled.div`\
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 9px;
    line-height: 14px;    
    text-transform: uppercase;
    font-size: 12px;
`;

export const GasDayChangeTableRow = styled(TableHeaderRow)<GasDayChangeTableHeaderProps>`
  min-width: ${props => props.minWidth}px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 1px;
  margin-top: 0px;
  height: 20px;
  font-size: 14px;
  text-align: center
`;