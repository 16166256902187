import {IArea, IChartData, IOutage, IShowingChartKeys} from "./index";

export const SET_OUTAGE_CHART_DATA = "SET_OUTAGE_CHART_DATA";
export const SET_SHOWING_CHART_KEYS = "SET_SHOWING_CHART_KEYS";
export const CLEAR_OUTAGE_CHART_DATA_LOADED = "CLEAR_OUTAGE_CHART_DATA_LOADED";
export const SET_SELECTED_CHART_OUTAGES = "SET_SELECTED_CHART_OUTAGES";
export const CLEAR_SELECTED_CHART_OUTAGES = "CLEAR_SELECTED_CHART_OUTAGES";

interface ISetChartData {
    type: typeof SET_OUTAGE_CHART_DATA;
    areaChartData: { [key: string]: IChartData[] };
    relevantOutages: IOutage[];
    relevantAreas: IArea[];
}

interface ISetShowingChartKeys {
    type: typeof SET_SHOWING_CHART_KEYS;
    showingChartKeys: IShowingChartKeys[];
}

interface IClearChartDataLoaded {
    type: typeof CLEAR_OUTAGE_CHART_DATA_LOADED;
}

interface ISetSelectedChartOutages {
    type: typeof SET_SELECTED_CHART_OUTAGES;
    selectedChartOutages: IOutage[];
    selectedChartOutageHistory: IOutage[];
    selectedChartDate: string;
}

interface IClearSelectedChartOutages {
    type: typeof CLEAR_SELECTED_CHART_OUTAGES;
}

export type OutageChartActionTypes =
    ISetChartData
    | IClearChartDataLoaded
    | ISetSelectedChartOutages
    | IClearSelectedChartOutages
    | ISetShowingChartKeys