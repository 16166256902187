import React, {useEffect, useState, useCallback} from 'react';
import * as S from '../../styles';
import {RouteComponentProps, useHistory} from "react-router";
import useTypedSelector from "../../hooks/useTypedSelector";
import useSubscriptions from "../../hooks/useSubscriptions";
import {IServiceProvider, ISubscriberPayload, ISubscription, ISubscriptionCategory} from "../../types";
import {useDispatch} from "react-redux";
import BetaBanner from "../BetaBanner";
import BackNavigationHeader from "../BackNavigationHeader";
import {locale} from "../../locale";
import fetchHelper from "../../utilities/fetchHelper";
import chevronDown from "../../icons/chevron-down-charcoal.svg";
import chevronUp from "../../icons/chevron-up-charcoal.svg";
import SubscriptionDisclaimer from "./SubscriptionDisclaimer";
import ResultModal from "../ResultModal";
import {Fetch} from "../../utilities/Fetch";
import {clearIsDirty, setIsDirty, showDiscardConfirm} from "../../actions/appActions";

interface Params {
    uniqueId: string;
}

const SubscriptionManagement: React.FC<RouteComponentProps> = (props) => {
    const p = props.match.params as Params;
    const history = useHistory();
    const dispatch = useDispatch();
    const isDirty = useTypedSelector(state => state.app.isDirty);
    const allSubscriptions = useTypedSelector(x => x.subscriptions.subscriptions);
    const subscriptionsLoaded = useTypedSelector(x => x.subscriptions.subscriptionsLoaded);
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const [changedSubscriptions, setChangedSubscriptions] = useState<number[]>([]);
    const [serviceProviders, setServiceProviders] = useState<IServiceProvider[]>([]);
    const [subscriptionCategories, setSubscriptionCategories] = useState<ISubscriptionCategory[]>([]);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [uniqueId, setUniqueId] = useState<string>("");
    const navigateAwayRoute = "/Subscription";

    useSubscriptions();

    const updateSubscriberInformation = useCallback( (payload: ISubscriberPayload, subscriptions: ISubscription[]) => {
        const subscriptionsUpdate: ISubscription[] = subscriptions.slice();

        if (!payload.subscriber) {
            history.push("/Subscription");
            return;
        }
        payload.subscriber_subscriptions.forEach(subscription => {
            if(subscriptionsUpdate.filter(u => u.id === subscription.subscriptionId).length !== 0){
                subscriptionsUpdate.filter(u => u.id === subscription.subscriptionId)[0].editChecked = true;
            }
        });

        setEmail(payload.subscriber.email);
        setSubscriptions(subscriptionsUpdate);
        setUniqueId(p.uniqueId);
    }, [p.uniqueId, history]);

    useEffect(() => {
        if (subscriptionsLoaded) {
            const serviceProviders: IServiceProvider[] = [];
            const subscriptionCategories: ISubscriptionCategory[] = [];
            allSubscriptions.forEach(subscription => {
                if (serviceProviders.filter(sp => sp.id === subscription.serviceProvider.id).length === 0) {
                    serviceProviders.push(subscription.serviceProvider);
                }
                if (subscriptionCategories.filter(sc => sc.id === subscription.subscriptionCategory.id).length === 0) {
                    subscriptionCategories.push(subscription.subscriptionCategory);
                }
                subscription.editChecked = false;
            });
            serviceProviders.sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
            setSubscriptions(allSubscriptions);
            setServiceProviders(serviceProviders);
            setSubscriptionCategories(subscriptionCategories);
        }
    }, [subscriptionsLoaded, allSubscriptions]);

    useEffect(() => {
        if(changedSubscriptions.length === 0) {
            dispatch(clearIsDirty());
        }
        else {
            dispatch(setIsDirty())
        }
    }, [changedSubscriptions, dispatch]);

    useEffect(() => {
        if (p.uniqueId && subscriptionsLoaded) {
            fetchHelper(`subscriptions/subscriber/${p.uniqueId}`, payload => updateSubscriberInformation(payload, allSubscriptions), dispatch);
            dispatch(setIsDirty());
        }
    }, [p.uniqueId, subscriptionsLoaded, allSubscriptions, dispatch, updateSubscriberInformation]);

    useEffect(() => {
        if (!isDirty) {
            return;
        }
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [isDirty]);

    function managedChangedSubscriptions(id: number) {
        if (changedSubscriptions.includes(id)) {
            setChangedSubscriptions((changedSubscriptions) => changedSubscriptions.filter(x => x !== id));
        } else {
            setChangedSubscriptions((changedSubscriptions) => changedSubscriptions.concat([id]));
        }
    }

    function save(successMessage: string) {
        Fetch.Build("/subscriber/subscriptions", dispatch)
            .withPayload([
                ...subscriptions.filter(s => s.editChecked),
                {
                    email: email.trim(),
                    uniqueId: uniqueId
                }])
            .success(() => {
                setResultText(successMessage);
                setResultSuccess(true);
                dispatch(clearIsDirty());
                setChangedSubscriptions([]);
            })
            .error(() => {
                setResultText(locale("error.generic"));
                setResultSuccess(false);
            })
            .finally(() => {
                setShowResult(true);
            })
            .post();
    }

    function actionItems() {
        if (subscriptions.length === 0) {
            return
        }
        return <S.ActionButtonContainer>
            <S.SecondaryAction>
                <div onClick={() => {
                    navigateAway();
                }}>{locale('cancel')}</div>
            </S.SecondaryAction>
            <S.SaveButton disabled={!isDirty} onClick={() => save(locale("subscription.save.success"))}>{locale('save')}</S.SaveButton>
        </S.ActionButtonContainer>
    }

    function subscriptionClick(id: number) {
        const changeSubscriptions = subscriptions.slice();
        changeSubscriptions.filter(cs => cs.id === id).forEach(cs => {
            cs.editChecked = !cs.editChecked;
            managedChangedSubscriptions(cs.id);
        });
        setSubscriptions(changeSubscriptions);
    }

    function subscriptionCategoryClick(serviceProviderId: number, subscriptionCategoryId: number) {
        const checked = isSubscriptionCategoryChecked(serviceProviderId, subscriptionCategoryId);
        const changeSubscriptions = subscriptions.slice();
        changeSubscriptions.filter(cs => cs.subscriptionCategoryId === subscriptionCategoryId && cs.serviceProviderId === serviceProviderId && cs.editChecked === checked).forEach(cs => {
            cs.editChecked = !checked;
            managedChangedSubscriptions(cs.id);
        });
        setSubscriptions(changeSubscriptions);
    }

    function serviceProviderClick(id: number) {
        const checked = isServiceProviderChecked(id);
        const changeSubscriptions = subscriptions.slice();
        changeSubscriptions.filter(cs => cs.serviceProviderId === id && cs.editChecked === checked).forEach(cs => {
            cs.editChecked = !checked;
            managedChangedSubscriptions(cs.id);
        });
        setSubscriptions(changeSubscriptions);
    }

    function allNotificationsClick() {
        const checked = areAllSubscriptionsChecked();
        const changeSubscriptions = subscriptions.slice();
        changeSubscriptions.forEach(cs => {
            cs.editChecked = !checked;
            managedChangedSubscriptions(cs.id);
        });
        setSubscriptions(changeSubscriptions);
    }

    function isServiceProviderChecked(serviceProviderId: number) {
        return subscriptions.filter(s => s.serviceProviderId === serviceProviderId).length === subscriptions.filter(s => s.serviceProviderId === serviceProviderId && s.editChecked).length;
    }

    function isSubscriptionCategoryChecked(serviceProviderId: number, subscriptionCategoryId: number) {
        return subscriptions.filter(s => s.serviceProviderId === serviceProviderId && s.subscriptionCategoryId === subscriptionCategoryId).length === subscriptions.filter(s => s.serviceProviderId === serviceProviderId && s.subscriptionCategoryId === subscriptionCategoryId && s.editChecked).length;
    }

    function areAllSubscriptionsChecked() {
        return subscriptions.length === subscriptions.filter(s => s.editChecked).length;
    }

    function serviceProviderExpanderClick(serviceProvider: IServiceProvider) {
        const serviceProvidersUpdate = serviceProviders.slice();
        serviceProvidersUpdate.filter(s => s.id === serviceProvider.id)[0].isCollapsed = !serviceProvider.isCollapsed;
        setServiceProviders(serviceProvidersUpdate);
    }

    function navigateAway() {
        if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                history.push(navigateAwayRoute);
            }));
            return;
        }
        history.push(navigateAwayRoute);
    }

    return (
        <div>
            <BetaBanner/>
            <S.PageWithNavigationContainer hasBanner={showBanner}>
                <BackNavigationHeader handleBackNavigation={navigateAway}
                                      leftContent={locale('subscription.manage')}/>

                {p.uniqueId && subscriptionsLoaded &&
                <div>
                    <S.FlexContainerRow>
                        <S.InformationItem>
                            <S.WelcomeLabel>{locale('subscription.welcome')} {email}</S.WelcomeLabel>
                        </S.InformationItem>
                    </S.FlexContainerRow>
                    <S.FlexContainerRow>

                        <S.InformationItem>
                            <S.FlexContainerColumn>
                                <S.InfoLabel>{locale('subscription.notification.info')}</S.InfoLabel>
                                <S.FlexContainerRow>
                                    <input type={"checkbox"} checked={areAllSubscriptionsChecked()}
                                           style={{marginTop: "0px", marginRight: "11px"}}
                                           onChange={() => allNotificationsClick()}/>
                                    <S.InfoLabel>{locale('subscription.notification.all')}</S.InfoLabel>
                                </S.FlexContainerRow>
                                <S.InfoLabel
                                    style={{margin: '16px 0'}}>{locale('subscription.unsubscribe.info')}</S.InfoLabel>
                            </S.FlexContainerColumn>
                            <S.Button onClick={() => history.push(`/Unsubscribe/${uniqueId}`)}>{locale('subscription.unsubscribe')}</S.Button>
                            <S.InfoLabel style={{margin: '16px 0'}}>{locale('subscription.manage.info')} <S.A
                                style={{fontSize: '14px'}} href="tel:4039207473">(403)
                                920-7473</S.A> {locale('banner.beta.or')}
                                <S.A style={{fontSize: '14px'}} href="tel:18779207473"> 1 (877)
                                    920-7473</S.A> {locale('banner.beta.or')} <S.A style={{fontSize: '14px'}}
                                                                                   href="mailto: nominations@tcenergy.com">nominations@tcenergy.com</S.A></S.InfoLabel>
                        </S.InformationItem>
                    </S.FlexContainerRow>

                    {serviceProviders && serviceProviders.map(sp => {
                        return <S.InformationItem key={sp.id}>
                            <S.SeparatorLine/>
                            <S.FlexContainerRow style={{textAlign: "center"}}>
                                <input type={"checkbox"} style={{marginTop: "0px", marginRight: "11px"}}
                                       checked={isServiceProviderChecked(sp.id)}
                                       onChange={() => {
                                           serviceProviderClick(sp.id)
                                       }}/>
                                <S.InfoLabel>{sp.name}</S.InfoLabel>
                                <S.Icon
                                    style={{
                                        marginBottom: "auto",
                                        marginTop: "3px",
                                        marginLeft: "11px",
                                        cursor: "pointer"
                                    }}
                                    src={sp.isCollapsed ? chevronDown : chevronUp} onClick={() => {
                                    serviceProviderExpanderClick(sp)
                                }}/>
                            </S.FlexContainerRow>
                            <S.FlexContainerRowCollapsible collapse={sp.isCollapsed}>
                                {subscriptionCategories.map(sc => {
                                    return <div key={sp.id + "-" + sc.id}>
                                        {subscriptions.filter(s => s.serviceProviderId === sp.id && s.subscriptionCategoryId === sc.id).length > 0 &&
                                        <S.FlexContainerRow>
                                            <input style={{marginLeft: "33px", marginTop: "0px", marginRight: "11px"}}
                                                   type={"checkbox"}
                                                   checked={isSubscriptionCategoryChecked(sp.id, sc.id)}
                                                   onChange={() => {
                                                       subscriptionCategoryClick(sp.id, sc.id)
                                                   }}/>
                                            <S.InfoLabel>{sc.name}</S.InfoLabel>
                                        </S.FlexContainerRow>}
                                        {subscriptions.filter(s => s.serviceProviderId === sp.id && s.subscriptionCategoryId === sc.id).map(s => {
                                            return <S.FlexContainerRow key={s.id}>
                                                <input
                                                    style={{marginLeft: "66px", marginTop: "0px", marginRight: "11px"}}
                                                    type={"checkbox"}
                                                    checked={s.editChecked}
                                                    onChange={() => {
                                                        subscriptionClick(s.id)
                                                    }}/>
                                                <S.InfoLabel>{s.name}</S.InfoLabel>
                                            </S.FlexContainerRow>
                                        })}
                                    </div>
                                })}
                            </S.FlexContainerRowCollapsible>
                            <br/>
                        </S.InformationItem>
                    })}
                    <S.InformationItem>
                        {actionItems()}
                    </S.InformationItem>
                <SubscriptionDisclaimer/>
                </div>}

                {showResult && <ResultModal text={resultText} onExit={() => {
                    setShowResult(false);
                }} success={resultSuccess}/>}

            </S.PageWithNavigationContainer>
        </div>
    )
};

export default SubscriptionManagement;