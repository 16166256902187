import React, {useEffect, useState} from "react";
import {locale} from "../../../locale";
import * as S from "../../../styles"
import BackNavigationHeader from "../../BackNavigationHeader";
import {useDispatch} from "react-redux";
import {Fetch} from "../../../utilities/Fetch";
import {useHistory} from "react-router-dom";
import DateUtilities from "../../../utilities/DateUtilities";
import {IHasValidationResults, IMainlineDailyCapability, IMainlineDailyCapabilityPublication} from "../../../types";
import {convertUnit, Unit} from "../../../utilities/UnitConversions";
import {SendToWorker} from "../../../workers/SendToWorker";
import {clearBusy, setBusy, showConfirm} from "../../../actions/appActions";
import {RawMainlineMaintenanceWidget} from "../../mainline/widgets/MainlineMaintenanceWidget";
import styled from "styled-components";
import {FontSize} from "../../../styles/FontStyles";
import colors from "../../../styles/Colors";
import ResultModal from "../../ResultModal";
import MainlineCapabilityPublicationValidation from "../../../utilities/validation/MainlineCapabilityPublicationValidation";
import {IValidationResult} from "../../../utilities/validation/Validation";

export function CalculateMainlineCapabilities(capabilities: IMainlineDailyCapability[], heatValue: number, percentage: number): IMainlineDailyCapability[] {
    function calculateSingleCapability(rawCapability: number, heatValueMJPerM3: number, percentage: number) {
        return convertUnit(Unit.TJ, Unit.J, Math.round(rawCapability * percentage * heatValueMJPerM3 / 5) * 5)
    }
    const heatValueMJPerM3 = convertUnit(Unit.J, Unit.MJ, heatValue);
    return capabilities.map(x => ({
        gasDay: x.gasDay,
        heatingValue: heatValue,
        lastUpdatedDateTimeUtc: DateUtilities.ServiceDateUtility.ReformatLocalToUtc(DateUtilities.ServiceDateUtility.Now()),
        prairiesLineCapability: calculateSingleCapability(x.prairiesLineCapability, percentage, heatValueMJPerM3),
        emersonExtensionCapability: calculateSingleCapability(x.emersonExtensionCapability, percentage, heatValueMJPerM3),
        northernOntarioLineCapability: calculateSingleCapability(x.northernOntarioLineCapability, percentage, heatValueMJPerM3),
        easternDeliveryAreaCapability: calculateSingleCapability(x.easternDeliveryAreaCapability, percentage, heatValueMJPerM3),
        downstreamStation148Capability: calculateSingleCapability(x.downstreamStation148Capability, percentage, heatValueMJPerM3),
    }));
}

const AdminLabel = styled.label`
    ${FontSize("10px")}
    vertical-align: bottom;
    color: ${colors.charcoal}
`;

const TextAreaWithError = styled(S.AdminTextArea)`
    height: 200px;
`;

const TextInputWithError = styled(S.AdminTextInput)`
    width: auto;
`;

enum LoadingState {
    Loaded,
    Loading,
    NotLoading,
    Error,
}

interface FileUploadWithErrorProperties {
    hasError?: boolean;
}

export const FileUploadWithError = styled.div<FileUploadWithErrorProperties>` 
  ${props => props.hasError ? `
    border: ${S.ErrorBorderStyle} !important;
    background: ${colors.error_background} !important;
    color: black;
  ` : ""};
  border-radius: 4px;
  display: inline;
  box-sizing: border-box;
  padding: 7px;
`;

interface IMainlineDailyCapabilityPublicationForEdit extends IHasValidationResults
{
    heatingValue: string;
    capacityPercentage: string;
    primaryDisclaimer: string;
    secondaryDisclaimer: string;
}

const AdminMainlineMaintenance: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string>("");
    const [rawCapabilities, setRawCapabilities] = useState<IMainlineDailyCapability[]>([]);
    const [capabilities, setCapabilities] = useState<IMainlineDailyCapability[]>([]);
    const [loaded, setLoaded] = useState<LoadingState>(LoadingState.NotLoading);
    const [saved, setSaved] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const defaultPublicationForEdit = {heatingValue: "", capacityPercentage: "", primaryDisclaimer: "", secondaryDisclaimer: "", validationResults: {}};
    const [publicationForEdit, setPublicationForEdit] = useState<IMainlineDailyCapabilityPublicationForEdit>(defaultPublicationForEdit);

    useEffect(() => {
        if (loaded === LoadingState.NotLoading) {
            Fetch
                .Build("/mainline/capabilities/latest_publication", dispatch, false)
                .success(data => {
                    setPublicationForEdit({
                        heatingValue: convertUnit(Unit.J, Unit.MJ, data.heatingValue).toString(),
                        capacityPercentage: (data.capacityPercentage*100).toString(),
                        primaryDisclaimer: data.primaryDisclaimer,
                        secondaryDisclaimer: data.secondaryDisclaimer,
                        validationResults: {}
                    });
                    setLoaded(LoadingState.Loaded);
                })
                .error(ex => {
                    setLoaded(LoadingState.Error);
                    setError(ex);
                })
                .get();
            setLoaded(LoadingState.Loading);
        }

    }, [loaded]);

    useEffect(() => {
        setIsValid(isEverythingValid());
    }, [publicationForEdit]);

    function handleFile(e: any) {
        if (!e.target.value) {
            return;
        }
        const files: FileList = e.target.files, f = files[0];
        e.target.value = null;
        setFile(f);

        dispatch(setBusy());
        setFileValidation({isValid: true, message: ""});
        SendToWorker
            .parseMainlineMaintenanceData(f, DateUtilities.Today(), DateUtilities.Today().plus({days: 10}))
            .success(x => {
                setRawCapabilities(x.data.capabilities);
                setFileValidation(MainlineCapabilityPublicationValidation.CapabilitiesValidation.validate(x.data.capabilities, false));
                dispatch(clearBusy());
            })
            .error(() => {
                setRawCapabilities([]);
                setFileValidation(MainlineCapabilityPublicationValidation.CapabilitiesValidation.validate([], false));
                dispatch(clearBusy());
            })
            .execute();
    }

    function setFileValidation(validation: IValidationResult) {
        setPublicationForEdit(previousValue => {
            return {
                ...previousValue,
                validationResults: {
                    ...previousValue.validationResults,
                    ["capabilities"]: validation
                }
            }
        });
    }

    function prepareForSave(): IMainlineDailyCapabilityPublication {
        return {
            capacityPercentage: parseFloat(publicationForEdit.capacityPercentage)/100,
            heatingValue: convertUnit(Unit.MJ, Unit.J, parseFloat(publicationForEdit.heatingValue)),
            primaryDisclaimer: publicationForEdit.primaryDisclaimer,
            secondaryDisclaimer: publicationForEdit.secondaryDisclaimer
        };
    }

    useEffect(() => {
        const publication = prepareForSave();
        setCapabilities(CalculateMainlineCapabilities(rawCapabilities, publication.heatingValue, publication.capacityPercentage));
    }, [rawCapabilities, publicationForEdit]);


    function save() {
        const isValid = isEverythingValid();

        dispatch(showConfirm({
            buttonConfirmText: isValid ? locale("ok") : undefined,
            buttonCancelText: locale("cancel"),
            headerText: locale("admin.mainlineDailyCapabilities.uploadConfirmModal.header"),
            bodyText: isValid ? locale("admin.mainlineDailyCapabilities.uploadConfirmModal.message") : locale("admin.mainlineDailyCapabilities.validationErrorModal.message"),
            onConfirm: () => {
                Fetch
                    .Build("/mainline/capabilities", dispatch)
                    .withPayload([...capabilities,  prepareForSave()])
                    .success(() => setSaved(true))
                    .error(x => setError(x))
                    .post();
            }
        }));
    }


    function isEverythingValid() {
        return Object.keys(publicationForEdit.validationResults).every(field => publicationForEdit.validationResults[field].isValid) && capabilities.length === 11;
    }

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : "";
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    function updateValue(newValue: string, fieldName: string) {
        const newPublicationForEdit = {...publicationForEdit, [fieldName]: newValue, validationResults: {...publicationForEdit.validationResults, [fieldName]: MainlineCapabilityPublicationValidation.AllValidations[fieldName].validate(newValue)}};
        setPublicationForEdit(newPublicationForEdit);
    }

    return <S.PageWithNavigationContainer>
        <BackNavigationHeader handleBackNavigation={() => history.goBack()}
                              leftContent={locale('admin.options.updateMainlineMaintenanceValues')}/>

            <S.FlexContainerRow style={{padding: "15px", alignContent: "space-after", width: "calc(100% - 97px)"}}>
                <S.FlexContainerColumn style={{width: "40%"}}>
                    {loaded === LoadingState.Loaded && <>
                        <div>
                            <S.FileUploadLabel htmlFor="file-upload" className="custom-file-upload">
                                {locale("admin.dailyValues.fileUploader")}
                            </S.FileUploadLabel>
                            {file && <FileUploadWithError
                                title={errorMessage(publicationForEdit, "capabilities")}
                                hasError={hasError(publicationForEdit, "capabilities")}>
                                {file.name}
                            </FileUploadWithError>}
                            <input id="file-upload" type="file" style={{display: "none"}} onChange={handleFile}/>
                        </div>
                        <div>
                            <div>
                                <AdminLabel htmlFor="percentage">{locale("admin.mainlineDailyCapabilities.capacityPercentage")}</AdminLabel>
                            </div>
                            <div>
                                <TextInputWithError
                                    title={errorMessage(publicationForEdit, "capacityPercentage")}
                                    hasError={hasError(publicationForEdit, "capacityPercentage")}
                                    id="percentage"
                                    type="number"
                                    value={publicationForEdit.capacityPercentage}
                                    onChange={e => updateValue(e.target.value, "capacityPercentage")}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <AdminLabel htmlFor="heatValue">{locale("admin.mainlineDailyCapabilities.heatValue")}</AdminLabel>
                            </div>
                            <div>
                                <TextInputWithError
                                    step="0.01"
                                    title={errorMessage(publicationForEdit, "heatingValue")}
                                    hasError={hasError(publicationForEdit, "heatingValue")}
                                    id="heatValue"
                                    type="number"
                                    value={publicationForEdit.heatingValue}
                                    onChange={e => updateValue(e.target.value, "heatingValue")}
                                    onInput={e => {
                                        // Match to 2 decimal places without rounding
                                        const match = e.currentTarget.value.match(/^-?\d+(?:\.\d{0,2})?/);
                                        if(match !== null) {
                                            e.currentTarget.value = match[0]
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <AdminLabel htmlFor="primaryDisclaimer">{locale("admin.mainlineDailyCapabilities.primaryDisclaimer")}</AdminLabel>
                            </div>
                            <div>
                                <TextAreaWithError
                                    title={errorMessage(publicationForEdit, "primaryDisclaimer")}
                                    hasError={hasError(publicationForEdit, "primaryDisclaimer")}
                                    id="primaryDisclaimer"
                                    value={publicationForEdit.primaryDisclaimer}
                                    onChange={e => updateValue(e.target.value, "primaryDisclaimer")}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <AdminLabel htmlFor="secondaryDisclaimer">{locale("admin.mainlineDailyCapabilities.secondaryDisclaimer")}</AdminLabel>
                            </div>
                            <div>
                                <TextAreaWithError
                                    title={errorMessage(publicationForEdit, "secondaryDisclaimer")}
                                    hasError={hasError(publicationForEdit, "secondaryDisclaimer")}
                                    id="primaryDisclaimer"
                                    value={publicationForEdit.secondaryDisclaimer}
                                    onChange={e => updateValue(e.target.value, "secondaryDisclaimer")}
                                />
                            </div>
                        </div>
                        {isValid && <div>
                            <S.Button onClick={() => save()}>{locale("admin.mainlineDailyCapabilities.submit")}</S.Button>
                        </div>}
                    </>}
                </S.FlexContainerColumn>
                <S.FlexContainerColumn style={{width: "60%"}}>
                    {capabilities.length > 0  && <>
                        <RawMainlineMaintenanceWidget capabilities={capabilities} primaryDisclaimer={publicationForEdit.primaryDisclaimer} secondaryDisclaimer={publicationForEdit.secondaryDisclaimer} />
                    </>}
                </S.FlexContainerColumn>

                {(error !== "" || saved) && <ResultModal text={error === "" ? locale("admin.mainlineDailyCapabilities.uploadConfirmModal.success") : error}
                                success={error === ""}
                                onExit={() => {
                                    if (error === "") {
                                        setRawCapabilities([]);
                                        setCapabilities([]);
                                        setFile(null);
                                        setLoaded(LoadingState.NotLoading);
                                        setSaved(false);
                                        setPublicationForEdit(defaultPublicationForEdit);
                                    }
                                    setError("");
                                }}
                />}
            </S.FlexContainerRow>
    </S.PageWithNavigationContainer>

};

export default AdminMainlineMaintenance;