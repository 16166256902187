import {IOutageComparison, Status} from "./index";
import OutagePredicates from "../utilities/OutagePredicates";
import SortFunctions from "../utilities/SortFunctions";

export class AreaOutagesSummaryItem {
    constructor(outages: IOutageComparison[]) {
        this.outages = outages.sort(SortFunctions.DefaultOutageSortFunction());
    }

    get totalCount(): number {
        return this.outages.filter(x => x.changeStatus !== Status.REMOVED).length;
    }

    get removedCount(): number {
        return this.outages.filter(x => x.changeStatus === Status.REMOVED).length;
    }

    get changedCount(): number {
        return this.outages.filter(x => x.changeStatus === Status.CHANGED).length;
    }

    get newCount(): number {
        return this.outages.filter(x => x.changeStatus === Status.NEW).length;
    }

    getOutages(includeRemoved: boolean): IOutageComparison[] {
        return includeRemoved ? this.outages : this.outages.filter(x => x.changeStatus !== Status.REMOVED);
    }

    outages: IOutageComparison[];
}

export interface IAreaOutagesSummary {
    removed: AreaOutagesSummaryItem;
    current: AreaOutagesSummaryItem;
    upcoming: AreaOutagesSummaryItem;
    upcoming30Days: AreaOutagesSummaryItem;
    upcomingAfter30Days: AreaOutagesSummaryItem;
}

export class AreaOutagesSummary implements IAreaOutagesSummary {
    constructor(outages: IOutageComparison[]) {
        this.removed = new AreaOutagesSummaryItem(outages.filter(OutagePredicates.IsRemoved));
        this.current = new AreaOutagesSummaryItem(outages.filter(OutagePredicates.IsCurrentOutage));
        this.upcoming = new AreaOutagesSummaryItem(outages.filter(OutagePredicates.IsUpcomingOutage));
        this.upcoming30Days = new AreaOutagesSummaryItem(outages.filter(OutagePredicates.IsUpcomingOutage30Days));
        this.upcomingAfter30Days = new AreaOutagesSummaryItem(outages.filter(OutagePredicates.IsUpcomingOutageAfter30Days));
    }

    current: AreaOutagesSummaryItem;
    removed: AreaOutagesSummaryItem;
    upcoming: AreaOutagesSummaryItem;
    upcoming30Days: AreaOutagesSummaryItem;
    upcomingAfter30Days: AreaOutagesSummaryItem;
}
