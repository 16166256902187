import styled from "styled-components";
import DashboardImage from "../assets/home_background.jpg";
import colors from "./Colors";
import {Link} from "react-router-dom";
import {FontSize} from "./FontStyles";
import {device} from "./DeviceSizes";
import {IBannerProps} from "../types";

export const DashboardGridContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
`;

type DashboardGridItemProps = {
    size: number;
}

export const DashboardGridItem = styled.div<DashboardGridItemProps>`
    flex-basis: ${({size}) => size*33}%;
    &:not(:last-child) {
        padding-right: 30px;
    } 
`;

export const HeaderContainer = styled.div<IBannerProps>`
    box-sizing: border-box;
    width: 100%;
    height: ${props => props.hasBanner ? "109px;": "76px;"};
    @media ${device.mobile}{
      height: ${props => props.hasBanner ? "76px;": "56px;"};  
    }
    padding-top: 20px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
`;

export const HeroImage = styled.div`
    background: url(${DashboardImage}) no-repeat;
    background-size: cover;
    background-position: center top -245px;
    height: 325px;
    width: 100%;
`;

export const TabNavigationContainer = styled.div`
    display: flex;
    height: 85px;
    width: 100%;
    background-color: ${colors.tc_blue};
    margin-bottom: 39px;
`;

type TabNavigationItemProps = {
    active?: boolean;
};

export const TabNavigationItem = styled(Link)<TabNavigationItemProps>`
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    
    text-decoration: none;
    ${FontSize("32px")}
    ${({active}) => active ? `
        color: ${colors.charcoal_text};
        background-color: white;
        position: relative;
        top: -4px;
        height: calc(100% + 4px);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    ` : "color: white;"}
`;