import React, {useEffect, useState} from "react";
import * as S from '../../styles';
import {IArea, IDailyContract, IDailyFlow} from '../../types';
import * as appActions from '../../actions/appActions'
import {clearBusy, setBusy, showConfirm} from '../../actions/appActions'
import DateUtilities from "../../utilities/DateUtilities";
import {Fetch} from "../../utilities/Fetch";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import useAreas from "../../hooks/useAreas";
import useTypedSelector from "../../hooks/useTypedSelector";
import {locale} from "../../locale";
import ResultModal from "../ResultModal";
import {defaultProgress, IProgress} from "../../reducers/appReducer";
import BackNavigationHeader from "../BackNavigationHeader";
import SortFunctions from "../../utilities/SortFunctions";
import {DateTime} from "luxon";
import {SendToWorker} from "../../workers/SendToWorker";
import {clearOutageChartDataLoaded} from "../../actions/outageChartActions";

interface IAreaSummary {
    area: IArea;
    items: IAreaSummaryItem[];
}

interface IAreaSummaryItem {
    type: string;
    startDate: string;
    endDate: string;
    count: number;
    latestValue: number;
    unit: string;
}

interface IAreaSummaryItemFromService {
    type: string;
    areaId: number;
    startDate: string;
    endDate: string;
    count: number;
    latestValue: number;
}

interface IAreas {
    usjr: IArea;
    wgat: IArea;
    egat: IArea;
    osda: IArea;
}

const contractConversionFactor = 1000000000000;
const flowConversionFactor = 1000000;
const capabilityConversionFactor = 1000000;

const AdminChartData: React.FC = () => {
    const updateChunkSize = 500;
    const flowUnit = "10⁶m³/d";
    const capabilityUnit = "10⁶m³/d";
    const contractUnit = "TJ/d";
    const monthsBackToUpdate = -3;

    enum UpdateMode {
        AllContractsAndFlows,
        RecentContractsAndFlowsOnly,
        RecentContractsOnly
    }

    const dispatch = useDispatch();
    const history = useHistory();
    useAreas();

    const [dailyValues, setDailyValues] = useState<{flows: IDailyFlow[]; contracts: IDailyContract[]}>({contracts: [], flows: []});
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [progress, setProgress] = useState<IProgress>(defaultProgress);
    const [error, setError] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [areas, setAreas] = useState<IAreas|null>(null);
    const allAreas = useTypedSelector(x => x.outages.areas);
    const [fileSummary, setFileSummary] = useState<IAreaSummary[]>([]);
    const [serverSummary, setServerSummary] = useState<IAreaSummary[]>([]);
    const [mode, setMode] = useState<UpdateMode>(UpdateMode.RecentContractsOnly);
    const [dates, setDates] = useState<{min: string; max: string; bounds: string}>({bounds: "", max: "", min: ""});


    function buildFileSummary(area: IArea): IAreaSummary {
        const flows = dailyValues.flows.filter(x => x.areaId === area.id).sort(SortFunctions.DateSortFunction<IDailyFlow>(x => x.gasDay, DateUtilities.ServiceDateUtility, true));
        const contracts = dailyValues.contracts.filter(x => x.areaId === area.id).sort(SortFunctions.DateSortFunction<IDailyContract>(x => x.gasDay, DateUtilities.ServiceDateUtility, true));

        const newVar: (IAreaSummaryItem|null)[] = [
            contracts.length > 0 ?
                {
                    count: contracts.length,
                    type: locale("admin.dailyValues.contract"),
                    startDate: DateUtilities.UiDateUtility.Reformat(contracts[0].gasDay),
                    endDate: DateUtilities.UiDateUtility.Reformat(contracts[contracts.length-1]?.gasDay),
                    latestValue: contracts[contracts.length-1].firmContractEnergy / contractConversionFactor,
                    unit: contractUnit,
                } : null,
            flows.length > 0 ?
                {
                    count: flows.length,
                    type: locale("admin.dailyValues.flow"),
                    startDate: DateUtilities.UiDateUtility.Reformat(flows[0]?.gasDay),
                    endDate: DateUtilities.UiDateUtility.Reformat(flows[flows.length-1]?.gasDay),
                    latestValue: flows[flows.length-1]?.flowVolume / flowConversionFactor,
                    unit: flowUnit,
                } : null,
        ];
        return {
            area: area,
            items: (newVar).filter(x => x !== null) as IAreaSummaryItem[],
        };
    }

    useEffect(() => {
        setMode(UpdateMode.RecentContractsOnly);
    }, [initialLoad, UpdateMode.RecentContractsOnly]);

    useEffect(() => {
        if (areas && dailyValues.contracts.length > 0 && dailyValues.flows.length > 0) {
            setFileSummary([
                buildFileSummary(areas.usjr),
                buildFileSummary(areas.wgat),
                buildFileSummary(areas.egat),
                buildFileSummary(areas.osda),
            ]);
        }
    }, [dailyValues]);

    useEffect(() => {
        if (fileSummary.length > 0) {
            const min = DateUtilities.UiDateUtility.Min(fileSummary.map(x => x.items.filter(x => x.type !== "Capability").map(y => y.startDate)).flat());
            const max = DateUtilities.UiDateUtility.Max(fileSummary.map(x => x.items.filter(x => x.type !== "Capability").map(y => y.endDate)).flat());
            const recentBound = DateUtilities.UiDateUtility.Max([min, DateUtilities.UiDateUtility.Format(DateUtilities.Today().plus({months: monthsBackToUpdate}))]);
            setDates({max: max, min: min, bounds: recentBound});
        }
    }, [fileSummary]);

    function loadServerSummaries(areas: { usjr: IArea; wgat: IArea; osda: IArea; egat: IArea }) {
        Fetch
            .Build("/chart/summary", dispatch)
            .success(x => buildCurrentSummary(x, areas))
            .get();
    }

    useEffect(() => {
        if (allAreas && allAreas.length > 0) {

            const areas = {
                usjr: allAreas.filter(x => x.acronym === "USJR")[0],
                wgat: allAreas.filter(x => x.acronym === "WGAT")[0],
                egat: allAreas.filter(x => x.acronym === "EGAT")[0],
                osda: allAreas.filter(x => x.acronym === "OSDA")[0],
            };

            setAreas(areas);
            loadServerSummaries(areas);
        }
    }, [allAreas]);

    function buildCurrentSummary(data: IAreaSummaryItemFromService[], areas: IAreas) {
        const convert = (x: IAreaSummaryItemFromService): IAreaSummaryItem => {
            const returnValue = {...x, unit: contractUnit, latestValue: x.latestValue / contractConversionFactor,
                startDate: DateUtilities.UiDateUtility.Reformat(x.startDate), endDate: DateUtilities.UiDateUtility.Reformat(x.endDate)};

            if (x.type === "Flow") {
                returnValue.unit = flowUnit;
                returnValue.latestValue = x.latestValue / flowConversionFactor;
            }
            if (x.type === "Capability") {
                returnValue.unit = capabilityUnit;
                returnValue.latestValue = x.latestValue / capabilityConversionFactor;
            }

            return returnValue;
        };

        const summaries = Object.values(areas).map(x => x as IArea).map((area: IArea) => {
            return {area: area, items: data ? data.filter(x => x.areaId === area.id).map(convert) : []};
        });

        setServerSummary(summaries);
        setInitialLoad(!data || DateUtilities.ParseDate(summaries[0].items[0].startDate) > DateTime.local(2018, 1, 1));
    }


    function handleFile(e: any) {
        if (!e.target.value) {
            return;
        }
        const files: FileList = e.target.files, f = files[0];
        e.target.value = null;
        setFileName(f.name);

        dispatch(setBusy());

        SendToWorker
            .parseChartData(f, allAreas)
            .success(x => {
                setDailyValues(x.data);
                dispatch(clearBusy());
            })
            .error(() => {
                setError(locale("admin.dailyValues.invalidFile"));
                dispatch(clearBusy());
            })
            .execute();
    }

    function resetProgress(progress: IProgress) {
        setProgress(() => {return {...progress}});
        dispatch(appActions.setProgress({...progress}));
    }

    function addProgress(progressIncrement: number) {
        setProgress((oldProgress) => {
            dispatch(appActions.setProgress({completed: oldProgress.completed + progressIncrement, total: oldProgress.total}));
            return {completed: oldProgress.completed + progressIncrement, total: oldProgress.total};
        });
    }

    function buildSummaryUi(summaries: IAreaSummary[]) {
        return  <div>
            {summaries.map(x => <div key={`file-summary-${x.area.acronym}`} style={{margin: "10px"}}>
                <div style={{fontWeight: "bolder"}}>{x.area.acronym}</div>
                {x.items.map(item => <div key={`file-summary-${x.area.acronym}-${item.type}`}>
                    <span style={{fontWeight: "bolder"}}>{item.count.toLocaleString()}</span>
                    <span> {item.type} </span>
                    <span> {locale("admin.dailyValues.daysFrom")} </span>
                    <span style={{fontWeight: "bolder"}}>{item.startDate}</span>
                    <span> {locale("admin.dailyValues.daysTo")} </span>
                    <span style={{fontWeight: "bolder"}}>{item.endDate}</span>
                    <span>. </span>
                    <span> {locale("admin.dailyValues.lastValue")} </span>
                    <span style={{fontWeight: "bolder"}}>{item.latestValue.toLocaleString([], {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                    <span> {item.unit}</span>
                    <span>.</span>
                </div>)}
            </div>)}
        </div>
    }

    function save() {
        let allToSave: (IDailyContract|IDailyFlow)[] = [...dailyValues.flows, ...dailyValues.contracts];
        let header = "admin.dailyValues.submit.allContractsAndFlows";
        const recentBounds = DateUtilities.Parse(dates.bounds);
        if (mode === UpdateMode.RecentContractsAndFlowsOnly) {
            header = "admin.dailyValues.submit.recentContractsAndFlows";
            allToSave = allToSave.filter(x => DateUtilities.ServiceDateUtility.ParseDate(x.gasDay) > recentBounds);
        }

        if (mode === UpdateMode.RecentContractsOnly) {
            header = "admin.dailyValues.submit.recentContractsOnly";
            allToSave = [...dailyValues.contracts].filter(x => DateUtilities.ServiceDateUtility.ParseDate(x.gasDay) > recentBounds);
        }

        dispatch(showConfirm({
            buttonConfirmText: locale("ok"),
            buttonCancelText: locale("cancel"),
            headerText: `${locale(header)}?`,
            bodyText: locale("admin.dailyValues.uploadConfirmModal.message"),
            onConfirm(): any {
                resetProgress({total: allToSave.length, completed: 0});
                for (let i=0,j=allToSave.length; i<j; i+=updateChunkSize) {
                    const chunk = allToSave.slice(i,i+updateChunkSize);
                    Fetch
                        .Build("/chart", dispatch, false)
                        .withPayload(chunk)
                        .success(() => {
                            addProgress(chunk.length);
                        })
                        .error(x => setError("error: " + x))
                        .post();
                }
            },
        }))
    }

    function updateMode(e: any) {
        const mode = parseInt(e.target.value) as UpdateMode;
        setMode(mode);
    }

    return <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push("/Admin")}
                                  leftContent={locale('admin.options.updateDailyValues')}/>

          <div>
              <S.FlexContainerRow style={{padding: "15px"}}>
                  <div style={{margin: "15px"}}>
                      {(serverSummary.length > 0) && <div>
                          <S.Label>{locale("admin.dailyValues.serverSummaryHeading")}</S.Label>
                          {buildSummaryUi(serverSummary)}
                      </div>}
                  </div>
                  <div style={{margin: "15px"}}>
                      <div>
                          {areas && <S.Label>{locale("admin.dailyValues.excelSummaryHeading")}</S.Label>}
                          {(fileSummary.length > 0) && buildSummaryUi(fileSummary)}

                          {areas && <div>
                              <S.FileUploadLabel htmlFor="file-upload" className="custom-file-upload">
                                  {locale("admin.dailyValues.fileUploader")}
                              </S.FileUploadLabel>
                              <span>{fileName}</span>
                              <input id="file-upload" type="file" style={{display: "none"}} onChange={handleFile}/>
                          </div>}


                          {fileSummary.length > 0 && <div style={{margin: "5px", fontWeight: "bolder"}}>
                              <input type="radio" id="a" checked={mode === UpdateMode.RecentContractsOnly} onChange={updateMode} value={UpdateMode.RecentContractsOnly}/>
                              <label htmlFor="a">{`${locale("admin.dailyValues.submit.recentContractsOnly")} (${dates.bounds} - ${dates.max})`}</label>
                          </div>}
                          {fileSummary.length > 0 && <div style={{margin: "5px", fontWeight: "bolder"}}>
                              <input type="radio" id="a" checked={mode === UpdateMode.RecentContractsAndFlowsOnly} onChange={updateMode} value={UpdateMode.RecentContractsAndFlowsOnly}/>
                              <label htmlFor="a">{`${locale("admin.dailyValues.submit.recentContractsAndFlows")} (${dates.bounds} - ${dates.max})`}</label>
                          </div>}
                          {fileSummary.length > 0 && <div style={{margin: "5px", fontWeight: "bolder"}}>
                              <input type="radio" id="b" checked={mode === UpdateMode.AllContractsAndFlows} onChange={updateMode} value={UpdateMode.AllContractsAndFlows}/>
                              <label htmlFor="b">{`${locale("admin.dailyValues.submit.allContractsAndFlows")} (${dates.min} - ${dates.max})`}</label>
                          </div>}
                          {fileSummary.length > 0 && <div>
                              <S.Button onClick={() => save()}>{locale("admin.dailyValues.submit")}</S.Button>
                          </div>}
                      </div>
                  </div>


                  {((progress.total !== 0 && progress.completed === progress.total) || error !== "")
                  && <ResultModal text={error === "" ? locale("admin.dailyValues.uploadConfirmModal.success") : error}
                                  success={error === ""}
                                  onExit={() => {
                                      resetProgress({total: 0, completed: 0});
                                      setError("");
                                      if (error === "") {
                                          if (areas) {
                                              loadServerSummaries(areas);
                                          }
                                          setFileSummary([]);
                                          setFileName("");
                                          setDailyValues({contracts: [], flows: []});
                                          dispatch(clearOutageChartDataLoaded());
                                      }
                                  }}
                  />}
              </S.FlexContainerRow>
          </div>
        </S.PageWithNavigationContainer>




};

export default AdminChartData;