export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_HAS_ENDED_INTERACTING = "SET_HAS_ENDED_INTERACTING";
export const SET_SCROLL_END_REF = "SET_SCROLL_END_REF";
export const SET_HIDE_REF = "SET_HIDE_REF";

export interface ISetIsOpenAction {
    type: typeof SET_IS_OPEN;
    isOpen: boolean;
}

export interface ISetHasEndedInteractingAction {
    type: typeof SET_HAS_ENDED_INTERACTING;
    hasEndedInteracting: boolean;
}

export interface ISetScrollEndRefAction {
    type: typeof SET_SCROLL_END_REF;
    ref: number;
}

export interface ISetHideRefAction {
    type: typeof SET_HIDE_REF;
    ref: number;
}

export type OutageControlsActionTypes =
    ISetIsOpenAction |
    ISetHasEndedInteractingAction |
    ISetScrollEndRefAction |
    ISetHideRefAction;
