import ReactGA from 'react-ga';

class GoogleAnalyticsUtilities {
    static gtag: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG;
    static initialized = false;

    static Initialize() {
        if (this.gtag && !this.initialized) {
            this.initialized = true;
            ReactGA.initialize(this.gtag);
        }
    }

    static PageView(page: string) {
        const fullPage = `${process.env.PUBLIC_URL}#${page}`;
        if (this.gtag && this.initialized) {
            ReactGA.pageview(fullPage);
        } else {
            console.log(`Viewing page: ${fullPage}`)
        }
    }
}

export default GoogleAnalyticsUtilities;