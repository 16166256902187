import DateUtilities from '../../utilities/DateUtilities';

export const getDGSRPrintHeader = (unit: string, date: string): string => {
    return (
`
<h3 style="margin-top: 0px; margin-bottom: 0px; text-align: center;">NOVA Gas Transmission Ltd.</h1>
<h3 style="margin-top: 0px; margin-bottom: 0px; text-align: center;">Gas Day Summary Report (${unit})</h1>
<p style="text-align: center;">Gas Day: ${date} as of ${DateUtilities.formatDate(new Date(), false)}</p>
`
    )
}

export const getPrintUnsupportedFF = (): string => {
    return (
`
<h3 style="margin-top: 0px; margin-bottom: 0px; text-align: center;">Print preview is not supported for Mozilla Firefox.</h1>
`
    )
}

export const getGDSRPrintFooter = (): string => {
    return (
`
<div style="font-size: 12px; margin-top: -500px; @-moz-document url-prefix(){} margin-top: 0px; }">
    <p>Other Borders include: Alberta Montana, Alliance Clairmont, Alliance Edson, Alliance Shell, Cold Lake and Unity</p>
    <p>Net Storage includes: Aeco, Aitken, Carbon, Chancellor, Crossfield, January Creek, Big Eddy, Salt Cavern, Severn Creek and Warwick Southeast - negative value is withdrawal</p>
    <p>NGTL Field Receipts = Total Receipts - Storage Receipts - Border Receipts</p>
    <p>Comments:</p>
    <p>Should you have any questions regarding this information, please contact your Customer Account Representative.</p>
    <p>Notes:</p>
    <p>1. This report is based on measurement numbers extrapolated forward to the end of the gas day. As a result, the actual end of day numbers may be different than reported above.</p>
    <p>2. Due to the timing of this report the 08:00 Intraprovincial and Storage nomination may be incomplete.  As a result, the actual 08:00 Intraprovincial and Storage nomination may be higher than reported above.</p>
    <p>3.  Effective January 1, 2012 the conversion factor for converting volumes from E6M3 to MMcf will be based on the National Energy Board (NEB) conversion factor of 14.73 psi.  Reports prior to January 1, 2012 were converted using the AEUB standard of 14.65 psi.</p>
</div>
`
    );
}