import {DateTime, Interval} from "luxon";
import {
    IArea,
    IAreaForEdit,
    IAreaHistory, IAreaHistoryForSave,
    ICapability,
    ICapabilityForEdit,
    IDopOutage,
    IOutage,
    IOutageForEdit, IOutageForSave
} from "../types";
import DateUtilities from "./DateUtilities";
import SortFunctions from "./SortFunctions";
import OutageValidation from "./validation/OutageValidation";
import AreaValidation from "./validation/AreaValidation";
import NumberUtilities from "./NumberUtilities";
import AreaCapabilityValidation from "./validation/AreaCapabilityValidation";
import { UsjrAcronym } from "./ChartBuilder";

interface IPlantTurnAroundValueDictionary {
    [key: string]: number;
}

export class AdminUtilities {
    static ReceiptTypeName = "receipt";
    static DeliveryTypeName = "delivery";
    static temporaryIdSequence = 0;

    static PreparePlantTurnAroundOutageForEdit(area: IArea, id: number, start: string, end: string, value: string): IOutageForEdit {
        return this.SetValidationResultsForOutageForEdit({
            id: id,
            impactId: 1,
            outageId: id.toString(),
            area: area,
            impact: "",
            typicalFlow: value,
            flowCapability: "",
            localAreaBaseCapability: "",
            localAreaOutageCapability: "",
            startDateTime: DateUtilities.EditDateUtility.Reformat(start),
            endDateTime: DateUtilities.EditDateUtility.Reformat(end),
            description: "",
            hasFieldDirty: {},
            temporaryId:  this.temporaryIdSequence++,
            validationResults: {},
        });
    }

    static PrepareNewOutageForEdit(area: IArea, id: number): IOutageForEdit {
        return this.SetValidationResultsForOutageForEdit({
            id: id,
            impactId: 1,
            outageId: "",
            area: area,
            impact: "",
            typicalFlow: "",
            flowCapability: "",
            localAreaBaseCapability: "",
            localAreaOutageCapability: "",
            startDateTime: "",
            endDateTime: "",
            description: "",
            hasFieldDirty: {"outageId": true, "impact": true, "typicalFlow": true, "flowCapability": true, "startDateTime": true, "endDateTime": true, "description": true, "localAreaBaseCapability": true, "localAreaOutageCapability": true},
            validationResults: {},
            temporaryId:  this.temporaryIdSequence++
        });
    }

    static PrepareEndDateForSave(endDateTime: string, startDateTime: string) {
        let endDate = endDateTime;
        if (/^\d\d?H$/g.test(endDate)) {
            // 8H was used for the end date.
            startDateTime = DateUtilities.ServiceDateUtility.Reformat(startDateTime);
            const hours = parseInt(endDate.replace(/\D/g, ''));
            const startDate = DateUtilities.ServiceDateUtility.ParseDate(startDateTime).startOf('day');
            endDate = startDate.plus({ hours: hours}).toJSDate().toString();
        }
        return endDate;
    }

    static PrepareOutageForSave(outage: IOutageForEdit): IOutageForSave {
        const endDate = AdminUtilities.PrepareEndDateForSave(outage.endDateTime, outage.startDateTime);
        return {
            impactId: outage.impactId,
            outageId: outage.area.isPlantTurnAround ? AdminUtilities.GenerateUniqueId(outage.startDateTime, outage.endDateTime, outage.area.id) : parseInt(outage.outageId),
            areaId: outage.area.id,
            impact: outage.impact,
            typicalFlow: outage.typicalFlow,
            flowCapability: outage.flowCapability,
            localAreaBaseCapability: outage.localAreaBaseCapability,
            localAreaOutageCapability: outage.localAreaOutageCapability,
            startDateTime: DateUtilities.ServiceDateUtility.Reformat(outage.startDateTime),
            endDateTime: DateUtilities.ServiceDateUtility.Reformat(endDate),
            description: outage.description,
        };
    }

    static PrepareAreaHistoryForSave(area: IAreaForEdit): IAreaHistoryForSave {
        return {
            areaId: area.id,
            notes: area.notes,
        };
    }

    static PrepareDraftOutagesForEdit(outages: IOutage[]): IOutageForEdit[] {
        return outages.map(o => {
            return this.SetValidationResultsForOutageForEdit({
                id: o.id,
                impactId: o.impactId,
                outageId: o.outageId.toString(),
                area: o.area,
                impact: o.impact,
                typicalFlow: o.typicalFlow,
                flowCapability: o.flowCapability,
                localAreaOutageCapability: o.localAreaOutageCapability,
                localAreaBaseCapability: o.localAreaBaseCapability,
                startDateTime: DateUtilities.EditDateUtility.Reformat(o.startDateTime),
                endDateTime: DateUtilities.EditDateUtility.Reformat(o.endDateTime),
                description: o.description,
                hasFieldDirty: {},
                validationResults: {},
                temporaryId:  this.temporaryIdSequence++
            })
        });
    }

    static PrepareDopOutagesForEdit(dopOutages: IDopOutage[], areas: IArea[]): IOutageForEdit[] {
        const outages: IOutageForEdit[] = [];
        for (const dopOutage of dopOutages) {
            const filteredAreas = areas.filter(a => a.dopAcronym === dopOutage.userstatus);
            if (filteredAreas.length === 0) {
                continue;
            }

            const mappedArea = filteredAreas[0];
            outages.push(this.PrepareDopOutageForEdit(dopOutage, mappedArea));
        }

        return outages;
    }

    static PrepareDopPlantTurnAroundActivitiesForEdit(turnAroundActivities: IDopOutage[], areas: IArea[]): IOutageForEdit[] {
        const parsedOutages = turnAroundActivities.filter(x => x.flowcapability !== null && x.typicalflow !== null).map(t => this.ParsePlantTurnAroundActivitiesIntoOutages(t, areas));
        const sortedParsedOutages = parsedOutages.sort(SortFunctions.CompositeSortFunction([SortFunctions.OutageStartDateSortFunction(), SortFunctions.OutageEndDateSortFunction()]));

        let outagesForEdit: IOutageForEdit[] = [];
        outagesForEdit = outagesForEdit.concat(AdminUtilities.TransformPlantActivitiesToOutagesForEdit(sortedParsedOutages.filter(a => a.area.typeName.toLowerCase() === AdminUtilities.ReceiptTypeName)));
        outagesForEdit = outagesForEdit.concat(AdminUtilities.TransformPlantActivitiesToOutagesForEdit(sortedParsedOutages.filter(a => a.area.typeName.toLowerCase() === AdminUtilities.DeliveryTypeName)));

        const maxDate = DateUtilities.Now().setZone(DateUtilities.mountainTimeZone).plus({months: 6}).startOf("day");
        outagesForEdit = outagesForEdit.filter(x => {
            return DateUtilities.EditDateUtility.ParseDate(x.startDateTime) <= maxDate;
        });

        outagesForEdit.forEach(o => {
            if (DateTime.fromFormat(o.endDateTime, DateUtilities.editDateFormat) > maxDate) {
                o.endDateTime = maxDate.toFormat(DateUtilities.editDateFormat);
            }
        });

        return outagesForEdit;
    }

    static TransformPlantActivitiesToOutagesForEdit(parsedSortedOutages: IOutage[]) {
        if (parsedSortedOutages.length <= 0) {
            return [];
        }

        const area = parsedSortedOutages[0].area;
        const ptaValues = AdminUtilities.TransformPlantTurnAroundActivitiesToValueDictionary(parsedSortedOutages);
        return AdminUtilities.BuildPlantTurnAroundActivitiesForEdit(ptaValues, area);
    }

    static TransformPlantTurnAroundActivitiesToValueDictionary(sortedOutages: IOutage[]): IPlantTurnAroundValueDictionary {
        const keyFormat = "MM-dd-yyyy";
        const ptaValues: IPlantTurnAroundValueDictionary = {};
        sortedOutages.forEach(p => {
            const start = DateUtilities.ServiceDateUtility.ParseDate(p.startDateTime);
            const end = DateUtilities.ServiceDateUtility.ParseDate(p.endDateTime);
            for (let date = start; date <= end; date = date.plus({days: 1})) {
                const key = date.toFormat(keyFormat);
                if (ptaValues[key]) {
                    ptaValues[key] = ptaValues[key] + parseInt(p.typicalFlow);
                } else {
                    ptaValues[key] = parseInt(p.typicalFlow);
                }
            }
        });

        return ptaValues;
    }

    static BuildPlantTurnAroundActivitiesForEdit(ptaDeliveryValues: IPlantTurnAroundValueDictionary, area: IArea): IOutageForEdit[] {
        const keyFormat = "MM-dd-yyyy";
        const result: IOutageForEdit[] = [];
        let value = -1;
        let start = "-1";
        let previous = "-1";
        let count = 0;
        const length = Object.keys(ptaDeliveryValues).length;
        for (const key in ptaDeliveryValues) {
            let addedThisIteration = false;
            count++;

            const day = DateTime.fromFormat(key, keyFormat);
            if(day < DateUtilities.Today()){
                continue;
            }

            if (value === -1) {
                value = ptaDeliveryValues[key];
                start = key;
                previous = key;
            }

            if (value !== ptaDeliveryValues[key] || day.diff(DateTime.fromFormat(previous, keyFormat)).days > 1) {
                result.push(AdminUtilities.PreparePlantTurnAroundOutageForEdit(area, AdminUtilities.GenerateUniqueId(start, previous, area.id), start, previous, value.toString()));

                value = ptaDeliveryValues[key];
                start = key;
                previous = key;
                addedThisIteration = true;
            }

            const isLastRecord = count === length;
            if (isLastRecord) {
                const startValue = addedThisIteration ? key : start;
                result.push(AdminUtilities.PreparePlantTurnAroundOutageForEdit(area, AdminUtilities.GenerateUniqueId(startValue, key, area.id), startValue, key, value.toString()));
            }

            previous = key;
        }

        return result;
    }

    static GenerateUniqueId(start: string, end: string, areaId: number): number {
        return ((DateTime.fromJSDate(new Date(start)).valueOf() + (DateTime.fromJSDate(new Date(end)).valueOf() - DateTime.fromJSDate(new Date(start)).valueOf()))/1000 + areaId) * -1;
    }

    static ParsePlantTurnAroundActivitiesIntoOutages(dopOutage: IDopOutage, areas: IArea[]): IOutage {
        let plantTurnAroundValue = parseInt(dopOutage.typicalflow) - parseInt(dopOutage.flowcapability);
        let area: IArea;
        if (plantTurnAroundValue >= 0) {
            area = areas.filter(a => a.isPlantTurnAround && a.typeName.toLowerCase() === this.ReceiptTypeName)[0];
        } else {
            area = areas.filter(a => a.isPlantTurnAround && a.typeName.toLowerCase() === this.DeliveryTypeName)[0];
            plantTurnAroundValue = plantTurnAroundValue * -1;
        }

        return {
            id: dopOutage.outageid,
            impactId: dopOutage.impactid,
            outageId: dopOutage.outageid,
            areaId: area.id,
            area: area,
            impact: "",
            typicalFlow: plantTurnAroundValue.toString(),
            flowCapability: "",
            localAreaBaseCapability: "",
            localAreaOutageCapability: "",
            startDateTime: dopOutage.impactstartdatetime,
            endDateTime: dopOutage.impactenddatetime,
            description: "",
            publishedDateTimeUtc: DateTime.utc().toFormat(DateUtilities.serviceDateFormat)
        };
    }

    static PrepareAreaForEdit(area: IArea, areaHistory: IAreaHistory | null): IAreaForEdit {
        return {
            id: area.id,
            acronym: area.acronym,
            dopAcronym: area.dopAcronym,
            displayName: area.displayName,
            typeName: area.typeName,
            centerLat: area.centerLat,
            centerLng: area.centerLng,
            offsetLat: area.offsetLat,
            offsetLng: area.offsetLng,
            locations: area.locations,
            color: area.color,
            notes: areaHistory?.notes ?? area.notes,
            sortOrder: area.sortOrder,
            hasTypicalFlow: area.hasTypicalFlow,
            hasLocalOutages: area.hasLocalOutages,
            isPlantTurnAround: area.isPlantTurnAround,
            capabilities: area.capabilities,
            capabilityAssumptions: area.capabilityAssumptions,
            capabilityAssumptionsHeader: area.capabilityAssumptionsHeader,
            hasFieldDirty: {},
            validationResults: {
                capabilityAssumptions: AreaValidation.CapabilityAssumptionsValidation(area).validate(area.capabilityAssumptions ?? "")
            },
        }
    }

    static SetValidationResultsForOutageForEdit(outage: IOutageForEdit): IOutageForEdit {
        outage.validationResults = {
            flowCapability: OutageValidation.CapabilityValidation(outage.area).validate(outage.flowCapability),
            description: OutageValidation.DescriptionValidation(outage.area).validate(outage.description),
            impact: OutageValidation.ImpactValidation(outage.area).validate(outage.impact),
            outageId: OutageValidation.OutageIdValidation(outage.area).validate(outage.outageId),
            startDateTime: OutageValidation.StartDateValidation(outage).validate(outage.startDateTime),
            endDateTime: OutageValidation.EndDateValidation(outage).validate(outage.endDateTime),
            typicalFlow: OutageValidation.TypicalFlowValidation(outage.area).validate(outage.typicalFlow),
        };
        if(outage.area.acronym === UsjrAcronym){
            outage.validationResults = {
                ...outage.validationResults,
                localAreaOutageCapability: OutageValidation.LocalAreaCapabilityValidation(outage.area).validate(outage.localAreaOutageCapability),
                localAreaBaseCapability: OutageValidation.LocalAreaCapabilityValidation(outage.area).validate(outage.localAreaBaseCapability)
            };
        }
        return outage;
    }

    static PrepareDopOutageForEdit(dopOutage: IDopOutage, area: IArea): IOutageForEdit {
        return this.SetValidationResultsForOutageForEdit({
            id: dopOutage.outageid,
            impactId: dopOutage.impactid,
            outageId: dopOutage.outageid.toString(),
            area: area,
            impact: dopOutage.areaofimpact,
            typicalFlow: dopOutage.typicalflow?.replace(/,/g, "") ?? null,
            flowCapability: dopOutage.flowcapability,
            localAreaBaseCapability: dopOutage.areabasecapability,
            localAreaOutageCapability: dopOutage.areaoutagecapability,
            startDateTime: DateUtilities.EditDateUtility.Reformat(dopOutage.impactstartdatetime),
            endDateTime: DateUtilities.EditDateUtility.Reformat(dopOutage.impactenddatetime),
            description: dopOutage.outagedescription,
            temporaryId: this.temporaryIdSequence++,
            validationResults: {},
            hasFieldDirty: {}
        });
    }

    static PrepareCapabilitiesForEdit(areas: IArea[]): ICapabilityForEdit[] {
        return (([] as ICapability[]).concat(...areas.map(x => x.capabilities)).filter(x => DateUtilities.ParseDate(x.startDate) >= DateUtilities.Now().startOf('month').minus({months: 6})).sort(SortFunctions.CapabilitySortFunction())).map(x => this.PrepareCapabilityForEdit(x));
    }

    static PrepareCapabilityForEdit(capability: ICapability): ICapabilityForEdit {
        const start = DateUtilities.ParseDate(capability.startDate);
        const allowDeleteDate = DateUtilities.Now().plus({months: 5});
        return {
            ...capability,
            capability: capability.capability.toString(),
            startDate: DateUtilities.UiDateUtility.Reformat(capability.startDate),
            endDate: DateUtilities.UiDateUtility.Reformat(capability.endDate),
            allowDelete: allowDeleteDate <= start,
            validationResults: {

            },
            hasFieldDirty: {

            }
        }
    }

    static PrepareNewCapabilityForEdit(latestExistingCapabilityEndDate: DateTime, area: IArea): ICapabilityForEdit {
        const uniqueId = parseInt(DateTime.local().toMillis().toString() + NumberUtilities.GetRandomNumber(10,99).toString() + area.id.toString()) * -1;
        const start = latestExistingCapabilityEndDate.plus({days: 1});
        const end = start.plus({months: 1}).plus({days: -1}).endOf("month");
        return {
            startDate: start.toFormat(DateUtilities.uiDateFormat),
            endDate: end.toFormat(DateUtilities.uiDateFormat),
            capability: "",
            areaId: area.id,
            id: uniqueId,
            hasFieldDirty: {"startDate": true, "endDate": true, "capability": true},
            allowDelete: true,
            validationResults: {
                capability: AreaCapabilityValidation.CapabilityValidation.validate("")
            }
        };
    }

    static PrepareCapabilitiesForSave(capabilities: ICapabilityForEdit[]): ICapability[] {
        return capabilities.map(x => this.PrepareCapabilityForSave(x));
    }

    private static PrepareCapabilityForSave(capability: ICapabilityForEdit): ICapability {
        return {
            id: capability.id,
            areaId: capability.areaId,
            startDate: DateUtilities.ServiceDateUtility.Reformat(capability.startDate),
            endDate: DateUtilities.ServiceDateUtility.Reformat(capability.endDate),
            capability: parseInt(capability.capability),
        }
    }

    static GetAreaCapabilityByDate(date: DateTime, capabilities: ICapability[]): number {
        const matchedCapabilities = capabilities.filter(c => {
            const normalizedDate = date.startOf("day");
            return DateUtilities.Parse(c.startDate).startOf("day") <= normalizedDate && DateUtilities.Parse(c.endDate).startOf("day") >= normalizedDate;
        });
        return matchedCapabilities.length === 0 ? -1 : matchedCapabilities[0].capability;
    }

    static GetAreaCapabilityAndRangeByDate(date: DateTime, capabilities: ICapability[]): ICapability|undefined {
        const matchedCapabilities = capabilities.filter(c => {
            const normalizedDate = date.startOf("day");
            return DateUtilities.Parse(c.startDate).startOf("day") <= normalizedDate && DateUtilities.Parse(c.endDate).startOf("day") >= normalizedDate;
        });
        return matchedCapabilities.length === 0 ? undefined : matchedCapabilities[0];
    }

    static GetAreaCapabilityByOutage(outage: IOutage, capabilities: ICapability[]): number {
        interface ConvertedCapability {
            capability: ICapability;
            intersection: Interval | null;
        }

        const getInclusiveInterval = (start: string, end: string) => {
            return Interval.fromDateTimes(DateUtilities.Parse(start).startOf("day"), DateUtilities.Parse(end).startOf("day").plus({days: 1}));
        };
        const startDateTime = DateTime.fromFormat(outage.startDateTime, DateUtilities.serviceDateFormat);
        const endDateTime = DateTime.fromFormat(outage.endDateTime, DateUtilities.serviceDateFormat);
        if (!startDateTime.isValid || !endDateTime.isValid) {
            return -1;
        }
        const outageInterval = getInclusiveInterval(outage.startDateTime, outage.endDateTime);

        const matchedCapabilities = capabilities
            .map(c => {return {capability: c, intersection: getInclusiveInterval(c.startDate, c.endDate).intersection(outageInterval)};})
            .filter(x => x.intersection)
            .sort(SortFunctions.CompositeSortFunction([
                SortFunctions.IntegerSortFunction<ConvertedCapability>(x => x.intersection?.toDuration()?.milliseconds ?? 0, false),
                SortFunctions.IntegerSortFunction<ConvertedCapability>(x => x.capability.capability, true),
            ]));

        if (matchedCapabilities.length === 0) {
            return -1;
        }
        return matchedCapabilities[0].capability.capability;
    }

    static GetAreaCapabilityObjectByOutage(outage: IOutage, capabilities: ICapability[]): ICapability|undefined {
        interface ConvertedCapability {
            capability: ICapability;
            intersection: Interval | null;
        }

        const getInclusiveInterval = (start: string, end: string) => {
            return Interval.fromDateTimes(DateUtilities.Parse(start).startOf("day"), DateUtilities.Parse(end).startOf("day").plus({days: 1}));
        };

        const startDateTime = DateTime.fromFormat(outage.startDateTime, DateUtilities.serviceDateFormat);
        const endDateTime = DateTime.fromFormat(outage.endDateTime, DateUtilities.serviceDateFormat);
        if (!startDateTime.isValid || !endDateTime.isValid) {
            return undefined;
        }

        const outageInterval = getInclusiveInterval(outage.startDateTime, outage.endDateTime);

        const matchedCapabilities = capabilities
            .map(c => {return {capability: c, intersection: getInclusiveInterval(c.startDate, c.endDate).intersection(outageInterval)};})
            .filter(x => x.intersection)
            .sort(SortFunctions.CompositeSortFunction([
                SortFunctions.IntegerSortFunction<ConvertedCapability>(x => x.intersection?.toDuration()?.milliseconds ?? 0, false),
                SortFunctions.IntegerSortFunction<ConvertedCapability>(x => x.capability.capability, true),
            ]));

        if (matchedCapabilities.length === 0) {
            return undefined;
        }
        return matchedCapabilities[0].capability;
    }

    static ChangeAlphaFromRGBA(rgba: string, newAlpha: string){
        const lastComma = rgba.lastIndexOf(',');
        return `${rgba.substr(0, lastComma)}, ${newAlpha})`;
    }
}