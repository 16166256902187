import React, {useState} from 'react';
import * as S from "../styles";
import NavIcon from "./NavIcon";
import outages from '../icons/menu_outages.svg';
import bulletins from '../icons/menu_mail.svg';
import currentsystemreport from '../icons/menu_currentsystemreport.svg';
import gasdaysummaryreport from '../icons/menu_gasdaysummaryreport.svg';
import contact from '../icons/menu_contact.svg';
import dashboard from "../icons/menu_dashboard.svg";
import subscriptions from '../icons/menu_bell.svg';
import dashboardSelected from '../icons/menu_dashboard_selected.svg';
import bulletinsSelected from '../icons/menu_mail_selected.svg';
import currentsystemreportSelected from '../icons/menu_currentsystemreport_selected.svg';
import gasdaysummaryreportSelected from '../icons/menu_gasdaysummaryreport_selected.svg';
import outagesSelected from '../icons/menu_outages_selected.svg';
import contactSelected from '../icons/menu_contact_selected.svg';
import subscriptionsSelected from '../icons/menu_bell_selected.svg';
import TCLogo from '../icons/menu_TC_logo.svg';
import hamburger from '../icons/menu_hamburger.svg';
import close from '../icons/menu_x.svg';
import styled, {css} from "styled-components";
import colors from "../styles/Colors";
import {clearIsDirty, showDiscardConfirm} from "../actions/appActions";
import useTypedSelector from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {locale} from "../locale";
import {device} from "../styles/DeviceSizes";


const A = styled.a`
  text-decoration: none;
  color: ${colors.primary};
`;

const MobileMenuWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 997;
  background: #FFFFFF;
  @media ${device.mobile} {
    display: block;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  box-shadow:  0 0 6px rgba(0,0,0,0.35);
`;

interface MenuWrapper {
    isOpen: boolean;
}

const ExpandedMenuWrapper = styled.div<MenuWrapper>`
  height: 0;
  transition: height .5s ease-in-out;
  ${props => props.isOpen && css`
    height: 200px;
  `};
`;

const homeUrl = "http://www.tccustomerexpress.com/ngtl.html";

const Menu: React.FC = () => {
    const isDirty = useTypedSelector(state => state.app.isDirty);
    const showDashboard = useTypedSelector(state => state.toggles.showDashboard);

    // Mobile menu specific variables
    const [expanded, setExpanded] = useState<boolean>(false);
    const dispatch = useDispatch();

    // TODO consolidate this navigate in these components BackNavigationHeader, Menu, NavIcon
    function onNavigate(e: any, url: string): boolean {
        if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                window.location.href = url;
            }));

            e.preventDefault();
            return false;
        }

        return true;
    }

    function renderMenu() {
        return (
            <S.Menu>
                <S.NavLogo>
                    <A href={homeUrl} onClick={(e) => onNavigate(e, homeUrl)}
                       style={{textDecoration: 'none', cursor: 'pointer'}}>
                        <S.Logo>
                            <img src={TCLogo} alt="TC Energy"/>
                        </S.Logo>
                    </A>
                </S.NavLogo>
                {showDashboard && <NavIcon icon={dashboard} iconSelected={dashboardSelected} link={'/Dashboard/Ngtl'}
                                           name={locale('navigation.menu.dashboard')}/>}
                <NavIcon icon={outages} iconSelected={outagesSelected} link={'/'}
                         name={locale('navigation.menu.outages')}/>
                <NavIcon icon={bulletins} iconSelected={bulletinsSelected} link={'/Bulletins'}
                         name={locale('page.header.bulletins')}/>
                <NavIcon icon={currentsystemreport} iconSelected={currentsystemreportSelected} link={'/CurrentSystemReport'}
                         name={locale('page.header.currentSystemReport')}/>
                <NavIcon icon={gasdaysummaryreport} iconSelected={gasdaysummaryreportSelected} link={'/GasDaySummaryReport'}
                         name={locale('page.header.gasDaySummaryReport')}/>
                <NavIcon icon={contact} iconSelected={contactSelected} link={'/ContactUs'}
                         name={locale('navigation.menu.contact')}/>
                <NavIcon icon={subscriptions} iconSelected={subscriptionsSelected} link={'/Subscription'}
                         name={locale('navigation.menu.subscriptions')}/>
            </S.Menu>
        )
    }

    function renderMobileMenu() {
        return (
            <MobileMenuWrapper>
                <MenuHeader onClick={() => setExpanded(!expanded)}>
                    <A href={homeUrl} onClick={(e) => onNavigate(e, homeUrl)}
                       style={{textDecoration: 'none', cursor: 'pointer'}}>
                        <img src={TCLogo} alt="TC Energy" width={108}/>
                    </A>
                    <div>
                        <img src={expanded ? close : hamburger} alt="="/>
                    </div>
                </MenuHeader>
                <ExpandedMenuWrapper isOpen={expanded}>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={outages} iconSelected={outagesSelected}
                                 link={'/'}
                                 name={locale('navigation.menu.outages')}/>
                    </div>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={bulletins} iconSelected={bulletinsSelected}
                                 link={'/Bulletins'}
                                 name={locale('page.header.bulletins')}/>
                    </div>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={currentsystemreport} iconSelected={currentsystemreportSelected}
                                 link={'/CurrentSystemReport'}
                                 name={locale('page.header.currentSystemReport')}/>
                    </div>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={gasdaysummaryreport} iconSelected={gasdaysummaryreportSelected}
                                 link={'/GasDaySummaryReport'}
                                 name={locale('page.header.gasDaySummaryReport')}/>
                    </div>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={contact} iconSelected={contactSelected}
                                 link={'/ContactUs'}
                                 name={locale('navigation.menu.contact')}/>
                    </div>
                    <div onClick={() => setExpanded(false)}>
                        <NavIcon icon={subscriptions}
                                 iconSelected={subscriptionsSelected}
                                 link={'/Subscription'}
                                 name={locale('navigation.menu.subscriptions')}/>
                    </div>
                </ExpandedMenuWrapper>
            </MobileMenuWrapper>
        )
    }

    return (
        <div>
            {renderMenu()}
            {renderMobileMenu()}
        </div>
    );
};

export default Menu;