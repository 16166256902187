import React from 'react';
import {useDispatch} from "react-redux";
import {locale} from '../locale';
import {setOutageDisplay} from "../actions/outagesActions";
import * as S from '../styles';
import {OutageDisplay} from "../types";
import useTypedSelector from "../hooks/useTypedSelector";

interface Props {
    localAreaCount: number;
}

const LocalAreaDisclaimer: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    return (
        <S.LocalAreaDisclaimerMap hasBanner={showBanner} onClick={() => dispatch(setOutageDisplay(OutageDisplay.List))}>
            <span>{props.localAreaCount} {locale('localAreaDisclaimer')}</span>
        </S.LocalAreaDisclaimerMap>
    )
};

export default LocalAreaDisclaimer;