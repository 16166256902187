import fetchHelper from "../utilities/fetchHelper";
import {setToggles} from "../actions/toggleActions";
import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

function useToggles(): void {
    const dispatch = useDispatch();
    const togglesLoaded = useTypedSelector(x => x.toggles.togglesLoaded);
    const error = useTypedSelector(state => state.toggles.error);

    useEffect(() => {
        if (!togglesLoaded && !error) {
            fetchHelper("toggle", x => dispatch(setToggles(x)), dispatch);
        }
    }, [togglesLoaded, error, dispatch]);

}

export default useToggles;