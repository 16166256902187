import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import * as S from "../styles"
import {  VolumeUnits } from '../types';
import { setVolumeUnit } from '../actions/appActions';


interface Props {
    units: string[];
    defaultValue?: string;
    onChange?: ((newValue: string) => void);
}
const DisplayValuesSelector: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    //default value
    const [stateUnit, setStateUnit] = useState<string>(props.defaultValue ? props.defaultValue : VolumeUnits.THOUSAND_CUBIC_METERS);
    function displayValueChanged(newValue: string) {
        setStateUnit(newValue);
        dispatch(setVolumeUnit(newValue as VolumeUnits));
    }

    return (
        <S.DisplayUnitForm>
            <S.FlexContainerRow>
                <S.FlexContainerColumnNoWrapPrintAsRow>
                    <S.PublishDatesSelectorLabel>{"Displayed Values:"}</S.PublishDatesSelectorLabel>
                    <S.Select value={stateUnit}
                              onChange={(e: any) => {
                                  props.onChange && props.onChange(e.target.value);
                                  displayValueChanged(e.target.value)
                              }}>
                        { (props.units as string[]).map(value => <option key={`from-option-${value}`}
                                                                          value={value}>{value}</option>)}
                    </S.Select>
                </S.FlexContainerColumnNoWrapPrintAsRow>
            </S.FlexContainerRow>
        </S.DisplayUnitForm>
    );
};

export default DisplayValuesSelector;