import {
    OutageControlsActionTypes,
    SET_HAS_ENDED_INTERACTING,
    SET_HIDE_REF,
    SET_IS_OPEN,
    SET_SCROLL_END_REF
} from "../types/OutageControlsActionTypes";

export interface IOutagesControlsState {
    isOpen: boolean;
    hasEndedInteracting: boolean;
    scrollEndRef: number;
    hideRef: number;
}
export const initialState: IOutagesControlsState = {
    isOpen: true,
    hasEndedInteracting: true,
    scrollEndRef: 0,
    hideRef: 0
}

export function outageControlsReducer(state: IOutagesControlsState = initialState, action: OutageControlsActionTypes): IOutagesControlsState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_IS_OPEN:
            return {
                ...state,
                isOpen: action.isOpen
            }
        case SET_HIDE_REF:
            return {
                ...state,
                hideRef: action.ref
            }
        case SET_HAS_ENDED_INTERACTING:
            return {
                ...state,
                hasEndedInteracting: action.hasEndedInteracting
            }
        case SET_SCROLL_END_REF:
            return {
                ...state,
                scrollEndRef: action.ref
            }
        default:
            return state
    }
}