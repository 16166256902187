import React, {useEffect, useState, useCallback} from 'react';
import {Area, CartesianGrid, ComposedChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import { isMobile } from "react-device-detect";
import useAreas from "../hooks/useAreas";
import useDailyOperatingPlanCompare from "../hooks/useDailyOperatingPlanCompare";
import useTypedSelector from "../hooks/useTypedSelector";
import SortFunctions from "../utilities/SortFunctions";
import {
    ChartBuilder,
    FoothillsBCAcronym,
    LocalAreaDelivery,
    LocalAreaReceipt,
    AlbertaBCAcronym,
    WestGateAcronym,
    HeatValueDefault
} from "../utilities/ChartBuilder";

import * as S from "../styles"
import {
    IChartData,
    IChartTooltip,
    IOutage,
    IShowingChartKeys,
    VolumeUnits,
    IArea,
    EnergyUnits
} from "../types";
import DateUtilities from "../utilities/DateUtilities";
import {useDispatch} from "react-redux";
import {
    setOutageChartData,
    setSelectedChartOutages,
    setShowingChartKeys,
} from "../actions/outageChartActions";
import NumberUtilities from "../utilities/NumberUtilities";
import {locale} from "../locale";
import colors from "../styles/Colors";
import MoreOptions, {MoreOptionsItems} from "./MoreOptions";
import {clearBusy, setBusy, setVolumeUnit, setAppEnergyUnit} from "../actions/appActions";
import ChartDisclaimerModal from "./ChartDisclaimerModal";
import {AdminUtilities} from "../utilities/AdminUtilities";
import GoogleAnalyticsUtilities from "../utilities/GoogleAnalyticsUtilities";
import {DateTime, Interval} from "luxon";
import {Fetch} from "../utilities/Fetch";
import "../styles/rechart-custom.css";
import {IBuildChartDataResponse} from "../workers";
import BetaBanner from "./BetaBanner";
import {convertVolumeUnit, convertEnergyUnit} from '../utilities/UnitConversions';
import useHeatValues from "../hooks/useHeatValues";
import {HeatValueUtilities} from "../utilities/HeatValueUtilities";
import OutageControls from "./OutageControls";

interface OutageChartProps {
    isAdminChart: boolean;
    selectPublishDate: string;
}

const OutageChart: React.FC<OutageChartProps> = (props) => {
    useEffect(() => {
        GoogleAnalyticsUtilities.PageView("OutageChart");
    }, []);

    const dispatch = useDispatch();
    const allAreas = useTypedSelector(state => state.outages.areas);
    const allOutages = useTypedSelector(state => state.outages.outages);
    const outageHistory = useTypedSelector(state => state.outageComparison.outageHistory);
    const areasLoaded = useTypedSelector(state => state.outages.areasLoaded);
    const outagesLoaded = useTypedSelector(state => state.outages.outagesLoaded);
    const outageHistoryLoaded = useTypedSelector(state => state.outageComparison.outageHistory.loaded);
    const areaDataDictionary = useTypedSelector(state => state.outageChart.areaChartData);
    const showingChartKeys = useTypedSelector(state => state.outageChart.showingChartKeys);
    const relevantOutages = useTypedSelector(state => state.outageChart.relevantOutages);
    const relevantAreas = useTypedSelector(state => state.outageChart.relevantAreas);
    const chartDataLoaded = useTypedSelector(state => state.outageChart.chartDataLoaded);
    const volUnit = useTypedSelector(state => state.app.volumeUnit);
    const energyUnit = useTypedSelector(state => state.app.energyUnit);
    const selectedPublishDate = props.isAdminChart? props.selectPublishDate : useTypedSelector(x => x.outageComparison.publishDates.selectedPublishDate);
    const selectedCompareToDate = useTypedSelector(x => x.outageComparison.outageHistory.publishDate);
    const heatValues = useTypedSelector(x => x.heatValue.heatValues);
    const heatValueLoaded = useTypedSelector(x => x.heatValue.heatValueLoaded);
    const [tooltipAreaId, setTooltipAreaId] = useState<number>(0);
    const [showInformationModal, setShowInformationModal] = useState<boolean>(false);
    const [disabledChartLines, setDisabledChartLines] = useState<{ [key: string]: boolean }>({});
    const showBanner = useTypedSelector(state => state.toggles.showBanner);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)
    const [maxDate, setMaxDate] = useState<DateTime>(DateUtilities.Today());
    const [convertedChartData, setConvertedChartData] = useState<{ [key: string]: IChartData[] }>(areaDataDictionary);
    const [localEnergy, setLocalEnergy] = useState<EnergyUnits>(energyUnit);
    const [localVolume, setLocalVolume] = useState<VolumeUnits>(volUnit);

    useAreas();
    useDailyOperatingPlanCompare();
    useHeatValues();

    const buildChartData = useCallback( () => {
        const minDate = DateUtilities.Today().plus({month: -6});
        //const lastOutageDate = DateUtilities.ServiceDateUtility.ParseDate(DateUtilities.ServiceDateUtility.Max(allOutages.map(x => x.endDateTime)));
        const areaCapabilityEndDates = allAreas.filter(x => x.capabilities.length > 0).map(x => x.capabilities).flat().map(x => x.endDate);
        const lastAreaCapabilityEndDate = DateUtilities.ServiceDateUtility.ParseDate(DateUtilities.ServiceDateUtility.Max(areaCapabilityEndDates));
        const maxChartDate = DateTime.max(lastAreaCapabilityEndDate, DateUtilities.Today().plus({month: 6}));
        setMaxDate(maxChartDate);
        const format = DateUtilities.ServiceDateOnlyUtility.Format;
        const areas = allAreas.filter(x => x.capabilities.length > 0).map(x => x.id).join(",");
        const requestUrl = props.isAdminChart ? `/chart/full?start=${format(minDate)}&end=${format(maxChartDate)}&areaIds=${areas}&publicationDate=${selectedPublishDate}&comparePublicationDate=${selectedCompareToDate}&isAdmin=true` :
        `/chart/full?start=${format(minDate)}&end=${format(maxChartDate)}&areaIds=${areas}&publicationDate=${selectedPublishDate}&comparePublicationDate=${selectedCompareToDate}`;
        Fetch
            .Build(requestUrl)
            .success(x => {
                displayChartData(x);
            })
            .get();
    }, []);

    useEffect(() => {
        if (outagesLoaded && areasLoaded && outageHistoryLoaded && heatValueLoaded) {
            dispatch(setBusy());
            buildChartData();
        }
    }, [outagesLoaded, areasLoaded, outageHistoryLoaded, heatValueLoaded, dispatch, buildChartData]);

    useEffect(() => {
        if (chartDataLoaded)
            convertChartData();
    }, [volUnit, energyUnit, chartDataLoaded]);

    function getAreaHeatValue(area: IArea): number {
        if (area.acronym === AlbertaBCAcronym) {
            const WgatVal = HeatValueUtilities.getHeatValue(heatValues, WestGateAcronym, selectedPublishDate);
            const FhbcVal = HeatValueUtilities.getHeatValue(heatValues, FoothillsBCAcronym, selectedPublishDate);
            return WgatVal && FhbcVal ? (WgatVal + FhbcVal) / 2 : HeatValueDefault;
        } else {
            return HeatValueUtilities.getHeatValue(heatValues, area.acronym, selectedPublishDate);
        }
    }

    function convertChartData() {
        const chartData: { [key: string]: IChartData[] } = {};
        for (const key of Object.keys(areaDataDictionary)) {
            chartData[key] = areaDataDictionary[key].map(values => ({
                ...values,
                value: convertVolumeUnit(values.value, volUnit),
                previousValue: values.previousValue ? convertVolumeUnit(values.previousValue, volUnit) : values.previousValue,
                historicalFlow: values.historicalFlow ? convertVolumeUnit(values.historicalFlow, volUnit) : values.historicalFlow,
                flow: values.flow ? convertVolumeUnit(values.flow, volUnit) : values.flow,
                contract: values.contract ? convertEnergyUnit(values.contract, energyUnit) : values.contract
            }));
        }
        setConvertedChartData(chartData);
    }

    function volumeValueChanged(newValue: string) {
        setLocalVolume(newValue as VolumeUnits);
        dispatch(setVolumeUnit(newValue as VolumeUnits));
    }

    function energyValueChanged(newValue: string) {
        setLocalEnergy(newValue as EnergyUnits);
        dispatch(setAppEnergyUnit(newValue as EnergyUnits));
    }

    function displayChartData(chartData: IBuildChartDataResponse) {
        const relevantOutages: IOutage[] = allOutages.filter(o => o.area.capabilities.length > 0 || o.area.acronym === FoothillsBCAcronym);
        const chartKeys: IShowingChartKeys[] = [];
        for (const key of Object.keys(chartData)) {
            const area = ChartBuilder.GetAreaByChartDataKey(key, allAreas);
            chartKeys.push({key: key, order: area.sortOrder});
        }

        dispatch(setOutageChartData(chartData,
            relevantOutages.sort(SortFunctions.CompositeSortFunction([SortFunctions.OutageStartDateSortFunction(), SortFunctions.OutageEndDateSortFunction()])),
            allAreas.filter(a => a.capabilities.length > 0).sort(SortFunctions.DefaultAreaSortFunction())));
        dispatch(setShowingChartKeys(chartKeys));
        dispatch(clearBusy());
    }

    function onChartClick(e: any) {
        if (!e) {
            return;
        }

        let acronymsToInclude: string[] = [LocalAreaReceipt, LocalAreaDelivery];
        acronymsToInclude = acronymsToInclude.concat(...(showingChartKeys.map(chartKey => ChartBuilder.ConvertChartKeyToAreaAcronyms(chartKey.key))));

        const selected = getRelevantOutagesByDay(e.activeLabel).filter(x => acronymsToInclude.indexOf(x.area.acronym) !== -1);
        const selectedOutageHistory = getRelevantOutageHistory(selected, outageHistory.history);

        dispatch(setSelectedChartOutages(selected, selectedOutageHistory, DateUtilities.UiDateUtility.Reformat(e.activeLabel)));
    }

    function getRelevantOutagesByDay(dateSelectedString: string) {
        const dateSelected = DateUtilities.Parse(dateSelectedString).startOf("day");
        const parseInclusiveInterval = (start: string, end: string): Interval => {
            let compareEnd = DateUtilities.Parse(end);
            if (!compareEnd.isValid) {
                // Outage does not have a valid date, use the max chart date as a filter for the outage.
                compareEnd = maxDate;
            }
            return Interval.fromDateTimes(DateUtilities.Parse(start).startOf("day"), compareEnd.startOf("day").plus({days: 1}));
        };
        return relevantOutages.filter(o => parseInclusiveInterval(o.startDateTime, o.endDateTime).contains(dateSelected))
            .sort(SortFunctions.DefaultOutageSortFunction());
    }

    function getRelevantOutageHistory(selectedOutages: IOutage[], allOutageHistory: IOutage[]) {
        return allOutageHistory.filter(historical => selectedOutages.some(selected => selected.impactId === historical.impactId && selected.outageId === historical.outageId));
    }

    const getToolTipsHeatValues = (area: string, date: string) => {
        if (area === AlbertaBCAcronym) {
            const result = parseFloat((HeatValueUtilities.getHeatValue(heatValues, WestGateAcronym, selectedPublishDate, date) + HeatValueUtilities.getHeatValue(heatValues, FoothillsBCAcronym, selectedPublishDate, date)).toFixed(4)) / 2; // Using parseFloat to avoid rounding errors of floats
            return result.toFixed((result * 10) % 10 === 0 ? 0 : 1); // Always show 1 decimal unless the decimal is 0
        }
        return HeatValueUtilities.getHeatValue(heatValues, area, selectedPublishDate, date);
    };

    const CustomSingleChartTooltipForAllAreas = (props: IChartTooltip) => {
        // props.label is coming back as undefined sometimes, not sure why, but is causing chart to fail on tooltip creation
        if (!props.label) {
            return null;
        }

        const chartDataForSelectedDay: IChartData[] = [];
        showingChartKeys.forEach(chartKey => {
            chartDataForSelectedDay.push(convertedChartData[chartKey.key].filter(a => a.day === props.label)[0]);
        });

        const historicalDay = chartDataForSelectedDay.filter(x => x.historicalDay)[0]?.historicalDay;

        if (props.active) {
            const outages = getRelevantOutagesByDay(props.label);
            return <S.ChartTooltip>
                <S.ChartTooltipItem>
                    <S.ChartTooltipHeader>{DateUtilities.UiDateUtility.Reformat(props.label)}</S.ChartTooltipHeader>
                </S.ChartTooltipItem>
                {chartDataForSelectedDay.map(item => {
                    const difference = item && DateUtilities.ParseDate(item.day) >= DateUtilities.Now() && item.previousValue ? Math.abs(item.value - item.previousValue) : 0;
                    const color = AdminUtilities.ChangeAlphaFromRGBA(ChartBuilder.GetAreaByChartDataKey(item.area, relevantAreas).color, "1.0");
                    const relevantOutages = ChartBuilder.GetOutagesByChartDataKey(item.area, outages);
                    return <S.ChartTooltipItem key={item.area + item.day + item.value + item.previousValue + volUnit}>
                        <S.FlexContainerRow>
                            <S.ChartTooltipLabel color={color}>{item.area + ":"}</S.ChartTooltipLabel>
                            <S.ChartTooltipLabel color={color}>{item.value.toLocaleString()}</S.ChartTooltipLabel>
                            <S.ChartTooltipLabel
                                color={colors.changed}>{difference > 0 && item.previousValue !== undefined ? item.value > item.previousValue ? `+ ${difference.toLocaleString()}` : `- ${difference.toLocaleString()}` : ""}</S.ChartTooltipLabel>
                            {relevantOutages.length > 0 && <S.ChartTooltipLabel
                                color={color}>{`(${relevantOutages.length} ${relevantOutages.length === 1 ? locale('outage') : locale('outages')})`}</S.ChartTooltipLabel>}
                        </S.FlexContainerRow>
                        <S.ChartTooltipAreaSection>
                            {(item.flow || item.flow === 0) && <S.FlexContainerRow>
                                <S.ChartTooltipLabelMini
                                    color={color}>{locale("chart.flowLabel")}:</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{item.flow.toLocaleString([], {maximumFractionDigits: 2})}</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{volUnit}</S.ChartTooltipLabelMini>
                            </S.FlexContainerRow>}
                            {(item.historicalFlow || item.historicalFlow === 0) && <S.FlexContainerRow>
                                <S.ChartTooltipLabelMini
                                    color={color}>{locale("chart.historicalFlowLabel")}:</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{item.historicalFlow.toLocaleString([], {maximumFractionDigits: 2})}</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{volUnit}</S.ChartTooltipLabelMini>
                            </S.FlexContainerRow>}
                            {(item.contract || item.contract) && <S.FlexContainerRow>
                                <S.ChartTooltipLabelMini
                                    color={color}>{locale("chart.contractLabel")}:</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{item.contract.toLocaleString([], {maximumFractionDigits: 2})}</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{energyUnit}</S.ChartTooltipLabelMini>
                            </S.FlexContainerRow>}
                            {heatValueLoaded && <S.FlexContainerRow>
                                <S.ChartTooltipLabelMini
                                    color={color}>{locale("heatValue.label")}:</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{getToolTipsHeatValues(item.area, props.label)}</S.ChartTooltipLabelMini>
                                <S.ChartTooltipLabelMini
                                    color={color}>{locale('heatValue.units')}</S.ChartTooltipLabelMini>
                            </S.FlexContainerRow>}
                        </S.ChartTooltipAreaSection>
                    </S.ChartTooltipItem>;
                })}
                <S.ChartTooltipItem>
                    <S.ChartTooltipLabelMini style={{maxWidth: "200px"}}
                                             color={colors.charcoal_text}>{locale("chart.tooltip.disclaimer")}</S.ChartTooltipLabelMini>
                    {historicalDay && <S.ChartTooltipLabelMini style={{maxWidth: "200px", marginTop: "5px"}}
                                                               color={colors.charcoal_text}>{locale("chart.tooltip.historicalDay").replace("{historical-date}", DateUtilities.UiDateUtility.Reformat(historicalDay))}</S.ChartTooltipLabelMini>}
                </S.ChartTooltipItem>
            </S.ChartTooltip>
        }

        return null;
    };

    // rotate doesn't worth with axis labels, have to custom solution them for X below
    const CustomizedXAxisTick = (props: any) => {
        const {x, y, payload} = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <S.RotatedText degree={-35} x={0} y={0} dy={16} textAnchor="end"
                               fill="#666">{DateUtilities.UiDateUtility.Reformat(payload.value)}</S.RotatedText>
            </g>
        );
    };

    function customYAxisTickFormatter(label: any) {
        return NumberUtilities.FormatFlow(label.toString());
    }

    function onChartMouseMove(areaId: number) {
        if (areaId !== tooltipAreaId) {
            setTooltipAreaId(areaId);
        }
    }

    function onLegendClick(chartKey: string, order: number) {
        const chartKeys = showingChartKeys.slice();
        const foundChartKeys = chartKeys.filter(k => k.key === chartKey);

        if (foundChartKeys.length > 0) {
            const index = chartKeys.indexOf(foundChartKeys[0]);
            chartKeys.splice(index, 1);
        } else {
            chartKeys.push({key: chartKey, order: order});
        }

        dispatch(setShowingChartKeys(chartKeys.sort(SortFunctions.ChartKeySortFunction())));
    }

    function toggleDisableLine(key: string) {
        setDisabledChartLines(x => {
            return {...x, [key]: !x[key]}
        });
    }

    const chartLegend = () => (
        chartDataLoaded && <S.ChartLegend>
            <S.BlockContainer>
                <S.ChartLegendRow key={"legendRowOne"}>
                    {Object.keys(convertedChartData).map(key => {
                        const area = ChartBuilder.GetAreaByChartDataKey(key, relevantAreas);
                        const color = AdminUtilities.ChangeAlphaFromRGBA(area.color, "1.0");
                        const showChartKey = showingChartKeys.filter(showingChartKey => showingChartKey.key === key);
                        return <S.ChartLegendItem key={key + "Legend"}
                                                  onClick={() => onLegendClick(key, area.sortOrder)}>
                            {showChartKey.length === 0 &&
                            <S.ChartLegendBoxBorder color={area.color} borderColor={color}/>}
                            {showChartKey.length > 0 &&
                            <S.ChartLegendBox color={area.color} borderColor={color}/>}
                            <S.ChartLegendLabel
                                style={{color: color}}>{key}</S.ChartLegendLabel>
                        </S.ChartLegendItem>
                    })}

                    <S.ChartLegendItem key={"historicalLegend"}
                                       onClick={() => toggleDisableLine("previousValue")}>
                        <S.ChartLegendDashedLine color={colors.changed}/>
                        <S.ChartLegendDashedLine color={colors.changed}/>
                        <S.ChartLegendLabelCanDisable
                            isDisabled={disabledChartLines["previousValue"]}
                            style={{color: colors.changed}}>{locale("comparedToDateChartLegendLabel")}</S.ChartLegendLabelCanDisable>
                    </S.ChartLegendItem>
                </S.ChartLegendRow>
                <S.ChartLegendRow key={"legendRowTwo"}>
                    <S.ChartLegendItem key={"flowLegend"}
                                       onClick={() => toggleDisableLine("flow")}>
                        <S.ChartLegendLine color={colors.current_flow}/>
                        <S.ChartLegendLabelCanDisable isDisabled={disabledChartLines["flow"]}
                                                      style={{color: colors.current_flow}}>{locale("chart.flowLabel")}</S.ChartLegendLabelCanDisable>
                    </S.ChartLegendItem>

                    <S.ChartLegendItem key={"historicalFlowLegend"}
                                       onClick={() => toggleDisableLine("historicalFlow")}>
                        <S.ChartLegendLine color={colors.historical_flow}/>
                        <S.ChartLegendLabelCanDisable
                            isDisabled={disabledChartLines["historicalFlow"]}
                            style={{color: colors.historical_flow}}>{locale("chart.historicalFlowLabel")}</S.ChartLegendLabelCanDisable>
                    </S.ChartLegendItem>
                    <S.ChartLegendItem key={"contractLegend"} onClick={() => toggleDisableLine("contract")}>
                        <S.ChartLegendDashedLine color={colors.contract}/>
                        <S.ChartLegendDashedLine color={colors.contract}/>
                        <S.ChartLegendLabelCanDisable isDisabled={disabledChartLines["contract"]}
                                                    style={{color: colors.contract}}>{locale("chart.contractLabel")}</S.ChartLegendLabelCanDisable>
                    </S.ChartLegendItem>
                </S.ChartLegendRow>
            </S.BlockContainer>
        </S.ChartLegend>);

    const capabilityButton = () => <S.ChartDisclaimer>
            <S.AssumptionsButton
                onClick={() => setShowInformationModal(true)}>*{locale('chartAssumptions')}</S.AssumptionsButton>
        </S.ChartDisclaimer>;

    return (
        <S.ChartWrapper>
            {!props.isAdminChart &&
            <S.BackNavigationHeader style={{cursor: "default"}} isHiddenMobile={true}>
                <S.CenteredPageTitle>{locale("outages")}</S.CenteredPageTitle>
                <MoreOptions isVisible={true} options={[
                    MoreOptionsItems.DownloadChartData,
                    MoreOptionsItems.DownloadOutages
                ]}/>
            </S.BackNavigationHeader>}
            {!props.isAdminChart && <BetaBanner/>}
            <S.PageWithNavigationContainer hasBanner={showBanner} hasStickyControls={true}>
                <S.ChartHeaderContainer>
                    <OutageControls
                        hasBanner={showBanner && !props.isAdminChart}
                        showDisplayValues={false}
                        showIfTopScroll={true}
                        noPublishedList={props.isAdminChart}
                        selectPublishDate={props.isAdminChart ? props.selectPublishDate : ''}
                        renderers={{ center: () => !isMobile && chartLegend(), right: () => capabilityButton() }} />
                </S.ChartHeaderContainer>
                {isMobile && chartLegend()}
                {chartDataLoaded && <S.FlexContainerRow style={{justifyContent: "space-between", ...{paddingTop: !isMobile ? 10 : 0}}}>
                    <S.ChartYAxisLabel style={{padding: "1px 10px 5px 10px"}}>
                        <S.Select value={localVolume}
                                  onChange={(e: any) => volumeValueChanged(e.target.value)}>
                            {Object.values(VolumeUnits).map(value => <option key={`from-option-${value}`}
                                                                             value={value}>{value}</option>)}
                        </S.Select>
                    </S.ChartYAxisLabel>
                    <S.ChartYAxisLabel>{
                        <S.Select value={localEnergy}
                                  onChange={(e: any) => energyValueChanged(e.target.value)}>
                            {Object.values(EnergyUnits).map(value => <option key={`from-option-${value}`}
                                                                             value={value}>{value}</option>)}
                        </S.Select>}
                    </S.ChartYAxisLabel>
                </S.FlexContainerRow>}

                {chartDataLoaded && heatValues && showingChartKeys.map(chartKey => {
                    const area = ChartBuilder.GetAreaByChartDataKey(chartKey.key, relevantAreas);
                    const isLastChart = showingChartKeys.lastIndexOf(chartKey) === showingChartKeys.length - 1;
                    const areaHeatValue = getAreaHeatValue(area);
                    const margin = energyUnit === EnergyUnits.TERAJOULE ? 5 : 35;
                    return <S.FlexContainerRow key={chartKey.key + "separatedChart"}>
                        <S.OutageChartSingle key={chartKey.key + "separatedChart"} hasBanner={showBanner}
                                             showingCount={showingChartKeys.length} heightAdd={isLastChart ? 45 : 0}>
                            <ResponsiveContainer>
                                <ComposedChart
                                    style={{cursor: "pointer"}}
                                    data={convertedChartData[chartKey.key]}
                                    onClick={onChartClick}
                                    syncId={'area'}
                                    margin={{top: 0, right: margin, left: 10, bottom: isLastChart ? 35 : 0}}
                                    onMouseMove={() => onChartMouseMove(area.id)}
                                >
                                    <XAxis
                                        dataKey="day"
                                        tick={<CustomizedXAxisTick/>}
                                        hide={!isLastChart}
                                    />
                                    <YAxis
                                        type="number"
                                        yAxisId={1}
                                        orientation={"left"}
                                        domain={[
                                            ChartBuilder.GetMinimumYValue(convertedChartData[chartKey.key], volUnit, energyUnit, areaHeatValue),
                                            ChartBuilder.GetMaximumYValue(convertedChartData[chartKey.key], volUnit, energyUnit, areaHeatValue)
                                        ]}
                                        tickFormatter={customYAxisTickFormatter}
                                    />
                                    <YAxis
                                        type="number"
                                        yAxisId={2}
                                        orientation={"right"}
                                        domain={[
                                            ChartBuilder.GetMinimumYValue(convertedChartData[chartKey.key], volUnit, energyUnit, areaHeatValue) * ChartBuilder.HeatValueConversionFactor(volUnit, energyUnit, areaHeatValue), // convert volume to energy
                                            ChartBuilder.GetMaximumYValue(convertedChartData[chartKey.key], volUnit, energyUnit, areaHeatValue) * ChartBuilder.HeatValueConversionFactor(volUnit, energyUnit, areaHeatValue)  // convert volume to energy
                                        ]}
                                        tickFormatter={customYAxisTickFormatter}
                                    />
                                    <CartesianGrid strokeDasharray="3 3"/>

                                    <Tooltip
                                        content={tooltipAreaId === area.id ? CustomSingleChartTooltipForAllAreas : () => null}
                                        cursor={{strokeWidth: 2, stroke: colors.primary}}
                                        isAnimationActive={false}
                                    />

                                    {!disabledChartLines["previousValue"] && <Area
                                        yAxisId={1}
                                        type="monotone"
                                        dataKey="previousValue"
                                        stroke={colors.changed}
                                        strokeWidth={2}
                                        fillOpacity={1}
                                        strokeDasharray={"5 5"}
                                        fill={"transparent"}
                                        isAnimationActive={false}
                                    />}

                                    <Area
                                        yAxisId={1}
                                        name={chartKey.key}
                                        type="monotone"
                                        dataKey="value"
                                        stroke={area.color}
                                        fillOpacity={1}
                                        fill={area.color}
                                        isAnimationActive={false}
                                    />

                                    {!disabledChartLines["historicalFlow"] && <Area
                                        yAxisId={1}
                                        type="monotone"
                                        dataKey="historicalFlow"
                                        stroke={colors.historical_flow}
                                        strokeWidth={2}
                                        fill={"transparent"}
                                        isAnimationActive={false}
                                    />}

                                    {!disabledChartLines["flow"] && <Area
                                        yAxisId={1}
                                        type="monotone"
                                        dataKey="flow"
                                        stroke={colors.current_flow}
                                        strokeWidth={2}
                                        fill={"transparent"}
                                        isAnimationActive={false}
                                    />}

                                    {!disabledChartLines["contract"] && <Area
                                        yAxisId={2}
                                        type="monotone"
                                        dataKey="contract"
                                        stroke={colors.contract}
                                        strokeWidth={2}
                                        fill={"transparent"}
                                        isAnimationActive={false}
                                        strokeDasharray={"5 5"}
                                    />}

                                    <ReferenceLine
                                        yAxisId={1}
                                        strokeDasharray={"7 7"}
                                        x={DateUtilities.WithFormat("yyyy-MM-dd").Now()}
                                        stroke={colors.new}
                                        strokeWidth={3}/>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </S.OutageChartSingle>
                    </S.FlexContainerRow>
                })}

                {chartDataLoaded && showingChartKeys.length === 0 &&
                <S.CenterText>{locale("noAreasSelected")}</S.CenterText>}
                
            </S.PageWithNavigationContainer>
            
            {showInformationModal && <ChartDisclaimerModal
                areas={relevantAreas}
                onExit={() => setShowInformationModal(false)}
            />}

            <S.MobileOptions>
                <MoreOptions isVisible={true} options={[
                    MoreOptionsItems.DownloadOutages
                ]}/>
            </S.MobileOptions>
            
        </S.ChartWrapper>
    );
};

export default OutageChart;