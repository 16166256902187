import React from 'react';
import {useDispatch} from "react-redux";
import OutageMap from "./OutageMap";
import {setOutageDisplay} from "../actions/outagesActions";
import AreaSummaryList from "./AreaSummaryList";
import useTypedSelector from "../hooks/useTypedSelector";
import useAreas from "../hooks/useAreas";
import useDailyOperatingPlanCompare from "../hooks/useDailyOperatingPlanCompare";
import {OutageDisplay} from "../types";
import OutageChart from "./OutageChart";
import OutageToggleRow from "./OutageToggleRow";
import {ScrollToTop} from "../utilities/ScrollUtilities";
import OutageGasDayChangesRibbon from './OutageGasDayChangesRibbon'

const Outages: React.FC = () => {
    useAreas();
    useDailyOperatingPlanCompare();

    const outagesLoaded = useTypedSelector(state => state.outages.outagesLoaded);
    const areasLoaded = useTypedSelector(state => state.outages.areasLoaded);
    const historyLoaded = useTypedSelector(state => state.outageComparison.outageHistory.loaded);
    const display = useTypedSelector(state => state.outages.display);
    const dispatch = useDispatch();

    function determineDisplay(display: OutageDisplay) {
        switch (display) {
            case OutageDisplay.Map: {
                return <OutageMap/>;
            }
            case OutageDisplay.List: {
                return <AreaSummaryList/>;
            }
            case OutageDisplay.Graph: {
                return <OutageChart isAdminChart={false} selectPublishDate={''}/>;
            }
            default: {
                return <OutageMap/>;
            }
        }
    }

    ScrollToTop();
    return (
        <div>
            {(outagesLoaded && areasLoaded && historyLoaded) && determineDisplay(display)}
            <OutageToggleRow
                onMapClick={() => {dispatch(setOutageDisplay(OutageDisplay.Map));}}
                onChartClick={() => {dispatch(setOutageDisplay(OutageDisplay.Graph));}}
                onListClick={() => {dispatch(setOutageDisplay(OutageDisplay.List));}}
                display={display}/>
            <OutageGasDayChangesRibbon/>
        </div>
    );
};

export default Outages;