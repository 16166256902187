import React from 'react';
import * as S from '../styles';
import {locale} from '../locale';
import {useDispatch} from "react-redux";
import {toggleShowDisclaimer} from "../actions/appActions";
import linksStore from "../utilities/linksStore";

const CookiesDisclaimer: React.FC = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(toggleShowDisclaimer());
        localStorage.setItem('cookiesAccepted', 'true');
    };

    return (
        <S.DisclaimerBody>
            <S.DisclaimerInnerWrapper>
                <S.DisclaimerText>
                    <p>
                        {locale('cookiesDisclaimer.text')}
                        <a href={linksStore.privacyPolicy}>
                            {locale('links.privacyPolicy')}
                        </a>
                    </p>
                </S.DisclaimerText>
                <S.DisclaimerButton onClick={() => handleClick()}>
                    <span>{locale('cookiesDisclaimer.buttonText')}</span>
                </S.DisclaimerButton>
            </S.DisclaimerInnerWrapper>
        </S.DisclaimerBody>
    )
};

export default CookiesDisclaimer;