import {IBulletin, ColumnSort} from "./index";
import {PagingInfo} from "../components/PageSelector";
import { bool } from "aws-sdk/clients/signer";

export const SET_BULLETINS = 'SET_BULLETINS';
export const SET_BULLETINS_LOADED = 'SET_BULLETINS_LOADED';
export const CLEAR_BULLETINS_LOADED = 'CLEAR_BULLETINS_LOADED';
export const SET_SENT_BULLETINS = 'SET_SENT_BULLETINS';
export const SET_BULLETIN_LIST_SORT = 'SET_BULLETIN_LIST_SORT';
export const SET_BULLETIN_ADMIN_SORT = 'SET_BULLETIN_ADMIN_SORT';
export const SET_BULLETIN_ROWS_PER_PAGE ='SET_BULLETIN_ROWS_PER_PAGE';
export const SET_BULLETIN_CURRENT_PAGE = 'SET_BULLETIN_CURRENT_PAGE';
export const SET_BULLETIN_PAGING_RESET = 'SET_BULLETIN_PAGING_RESET';

interface ISetBulletinsAction {
    type: typeof SET_BULLETINS;
    bulletins: IBulletin[];
}

interface ISetBulletinsLoaded {
    type: typeof SET_BULLETINS_LOADED;
    loaded: boolean;
}

interface IClearBulletinsLoaded {
    type: typeof CLEAR_BULLETINS_LOADED;
}

interface ISetSentBulletinsAction {
    type: typeof SET_SENT_BULLETINS;
    sentBulletins: IBulletin[];
}

interface ISetBulletinListSortOrder {
    type: typeof SET_BULLETIN_LIST_SORT;
    bulletinListSort: ColumnSort;
}

interface ISetBulletinAdminSortOrder {
    type: typeof SET_BULLETIN_ADMIN_SORT;
    bulletinAdminSort: ColumnSort;
}

interface ISetBulletinRowsPerPage {
    type: typeof SET_BULLETIN_ROWS_PER_PAGE;
    rowsPerPage: number;
}

interface ISetBulletinCurrentPage{
    type: typeof SET_BULLETIN_CURRENT_PAGE;
    currentPage: PagingInfo<IBulletin>;
}

interface ISetBulletinPaginReset{
    type: typeof SET_BULLETIN_PAGING_RESET;
    resetPaging: bool;
}

export type BulletinActionTypes =
    ISetBulletinsAction
    | ISetBulletinsLoaded
    | IClearBulletinsLoaded
    | ISetSentBulletinsAction
    | ISetBulletinListSortOrder
    | ISetBulletinAdminSortOrder
    | ISetBulletinRowsPerPage
    | ISetBulletinCurrentPage
    | ISetBulletinPaginReset;
