import React from 'react';
import * as S from '../styles';
import {locale} from "../locale";
import {FileDownloadUtilities} from "../utilities/FileDownloadUtilities";
import {useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import linksStore from '../utilities/linksStore';

const ImportantLink: React.FC = () => {
    const dispatch = useDispatch();
    return (
        <S.Links>
            <h1>{locale('importantLinks')}</h1>
            <a href={linksStore.gasDaySummaryReport} target="_blank"
               rel="noopener noreferrer">{locale("links.gasDaySummaryReport")}</a>
            <a href={linksStore.currentSystemReport} target="_blank"
               rel="noopener noreferrer">{locale('links.currentSystemReport')}</a>
            <a href={linksStore.ngtlSegmentCodes}
               target="_blank"
               rel="noopener noreferrer">{locale("links.ngtlSegmentCodes")}</a>
            <a href={linksStore.ngtlOperationalAreasMap}
               target="_blank"
               rel="noopener noreferrer">{locale("links.ngtlOperationalAreasMap")}</a>
            <a href={linksStore.plantTurnaroundForm}
               rel="noopener noreferrer">{locale("links.plantTurnaroundForm")}</a>
            <span
                onClick={() => FileDownloadUtilities.initiateCsvFileDownload("plantturnaroundactivity/csv", dispatch)}>{locale('link.plantTurnaroundCsv')}</span>
            <Link to={"/Admin"}>{locale("link.adminLoginLabel")}</Link>
            <span
                onClick={() => FileDownloadUtilities.initiateCsvFileDownload("csv/capabilities", dispatch)}>{locale('link.capabilitiesCsv')}</span>
            <a href={linksStore.privacyPolicy}
               target="_blank"
               rel="noopener noreferrer">{locale("links.privacyPolicy")}</a>
        </S.Links>
    );
};

export default ImportantLink;