import {
    AppActionTypes,
    CLEAR_IS_BUSY,
    CLEAR_IS_DIRTY,
    HIDE_CONFIRM,
    IConfirm,
    SET_ERROR,
    SET_IS_AUTH_INITIALIZED,
    SET_IS_BUSY,
    SET_IS_DIRTY,
    SET_IS_AUTHORIZED,
    SET_PROGRESS,
    SHOW_CONFIRM,
    SHOW_DISCARD_CONFIRM,
    TOGGLE_SHOW_DISCLAIMER,
    SET_APP_STATUS,
    SET_VOLUME_UNIT,
    SET_ENERGY_UNIT,
} from "../types/AppActionTypes";
import {locale} from "../locale";
import { AppStatus, VolumeUnits, EnergyUnits } from "../types";

interface IAppState {
    isAuthenticationInitialized: boolean;
    isUserAuthorized: boolean;
    isBusy: boolean;
    busyCount: number;
    error: string;
    isDirty: boolean;
    showConfirm: boolean;
    confirm: IConfirm;
    showDisclaimer: boolean;
    progress: IProgress;
    status: AppStatus;
    volumeUnit: VolumeUnits;
    energyUnit: EnergyUnits;
    gdsrDefaultVolumeUnit: VolumeUnits;
    csrDefaultVolumeUnit: VolumeUnits;
}

export interface IProgress {
    completed: number;
    total: number;
}

export const defaultConfirm: IConfirm = {
    headerText: "Confirmation",
    bodyText: "Are you sure?",
    buttonCancelText: "Cancel",
    buttonConfirmText: "Yes",
    onConfirm: () => {
    },
};

export const defaultProgress = {
    completed: 0,
    total: 0,
};

export const defaultAppState: IAppState = {
    isAuthenticationInitialized: false,
    isUserAuthorized: false,
    isBusy: false,
    busyCount: 0,
    error: "",
    isDirty: false,
    showConfirm: false,
    confirm: defaultConfirm,
    showDisclaimer: true,
    progress: defaultProgress,
    status: AppStatus.RUNNING,
    volumeUnit: VolumeUnits.THOUSAND_CUBIC_METERS,
    energyUnit: EnergyUnits.TERAJOULE,
    gdsrDefaultVolumeUnit: VolumeUnits.MILLION_CUBIC_FEET,
    csrDefaultVolumeUnit:VolumeUnits.MILLION_CUBIC_FEET,
};

export function appReducer(state = defaultAppState, action: AppActionTypes): IAppState {
    switch (action.type) {
        case SET_IS_AUTH_INITIALIZED:
            return {...state, isAuthenticationInitialized: action.isAuthenticationInitialized};
        case SET_IS_AUTHORIZED:
            return {...state, isUserAuthorized: action.isUserAuthorized};
        case SET_ERROR:
            return {...state, error: action.error};
        case SET_PROGRESS:
            return {...state, progress: {...action.progress}}
        case SET_IS_BUSY:
            return {...state, isBusy: true, busyCount: state.busyCount + 1};
        case CLEAR_IS_BUSY:
            let busyCount = action.hardClear ? 0 : state.busyCount - 1;
            busyCount = busyCount < 0 ? 0 : busyCount;
            return {...state, isBusy: busyCount !== 0, busyCount: busyCount, progress: busyCount === 0 ? defaultProgress : state.progress};
        case SET_IS_DIRTY:
            return {...state, isDirty: true};
        case CLEAR_IS_DIRTY:
            return {...state, isDirty: false};
        case SHOW_CONFIRM:
            return {...state, confirm: action.confirm, showConfirm: true};
        case HIDE_CONFIRM:
            return {...state, showConfirm: false};
        case SHOW_DISCARD_CONFIRM:
            return {
                ...state, confirm: {
                    headerText: locale('discardChangesHeader'),
                    bodyText: locale('discardChangesBody'),
                    buttonCancelText: locale('cancel'),
                    buttonConfirmText: locale('discard'),
                    onConfirm: () => {
                        action.onConfirm();
                    }
                },
                showConfirm: true
            };
        case TOGGLE_SHOW_DISCLAIMER:
            return {...state, showDisclaimer: false};
        case SET_APP_STATUS:
            return {...state, status: action.status};
        case SET_VOLUME_UNIT:
            return {...state, volumeUnit: action.unit};
        case SET_ENERGY_UNIT:
            return {...state, energyUnit: action.unit};
        default:
            return state
    }
}