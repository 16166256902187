import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {showConfirm} from "../../actions/appActions";
import {locale} from "../../locale";
import useTypedSelector from "../../hooks/useTypedSelector";
import { useLocation, useHistory } from "react-router";

const Unauthorized: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuthInitialized = useTypedSelector(x => x.app.isAuthenticationInitialized);
    const isAuthorized = useTypedSelector(x => x.app.isUserAuthorized);

    useEffect(() => {
        if (isAuthInitialized && !isAuthorized) {
            dispatch(showConfirm({
                headerText: locale("unauthorized.header"),
                bodyText: locale("unauthorized.body"),
                buttonCancelText: locale("unauthorized.noRefresh"),
                buttonConfirmText: locale("unauthorized.refresh"),
                onConfirm: () => {
                    if(location.pathname === "/AdminDailyOperatingPlan"){
                        history.push("AdminPublicationList")
                    }
                    window.location.reload();
                }
            }))
        }
    }, [isAuthInitialized, isAuthorized, dispatch, history, location.pathname]);

    return <></>;
};

export default Unauthorized;