import React from 'react';
import * as S from "../styles"
import arrowRight from '../icons/chevrons_right.svg';
import useTypedSelector from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {OutageCard} from "./OutageCard";
import {OutageComparisonBuilder} from "../types/OutageComparisonBuilder";
import {clearSelectedChartOutages} from "../actions/outageChartActions";

const OutageChartDetails: React.FC = () => {
    const outages = useTypedSelector(x => x.outageChart.selectedChartOutages);
    const outageHistory = useTypedSelector(x => x.outageChart.selectedChartOutageHistory);
    const selectedDate = useTypedSelector(x => x.outageChart.selectedChartDate);
    const dispatch = useDispatch();

    function hide() {
        dispatch(clearSelectedChartOutages());
    }

    // no current usage, so don't show it
    if (outages.length === 0) {
        return null;
    }

    return (
        <div>
            <S.ModalOverlay onClick={() => hide()}/>
            <S.OutageChartDetail>
                <S.PanelHeader>
                    <img style={{cursor: 'pointer'}} src={arrowRight} alt="close" onClick={() => hide()}/>
                    <S.Text>Selected Date: {selectedDate}</S.Text>
                </S.PanelHeader>
                {OutageComparisonBuilder.Build(outages, outageHistory).map(outage => {
                    return <OutageCard key={outage.id} outage={outage}/>
                })}
            </S.OutageChartDetail>
        </div>);
};

export default OutageChartDetails;