import React from 'react';
import * as S from '../../styles';
import {SORT_ORDER, ColumnSort} from '../../types';
import sortIconDown from "../../icons/sort-icon-down.svg";
import sortIconUp from "../../icons/sort-icon-up.svg";


interface Props {
    setSort(columnSort: ColumnSort): any;

    width: string;
    columnName: string;
    sortOrder: ColumnSort;
    inverted?: boolean;
    isAdmin?: boolean;
}


const TableColumnSortHeader: React.FC<Props> = (props) => {

    const changeSort = () => {
        let sort = SORT_ORDER.DECENDING;
        if (props.sortOrder.columnName === props.columnName) {
            if (props.sortOrder.sortOrder === SORT_ORDER.DECENDING) {
                sort = SORT_ORDER.ACCENDING;
            } else if (props.sortOrder.sortOrder === SORT_ORDER.ACCENDING) {
                sort = SORT_ORDER.DECENDING;
            }
        }
        props.setSort({columnName: props.columnName, sortOrder: sort});
    };

    const sortIcon = () => {
        if (props.sortOrder.columnName === props.columnName) {
            return props.sortOrder.sortOrder === SORT_ORDER.ACCENDING ? sortIconUp : sortIconDown;
        }
        return sortIconDown;
    };

    return (
        <S.TableCellWrapper onClick={changeSort} sortable={true}>
            <S.TableColumn width={props.width}>
                {props.isAdmin ?
                    <S.TableCellHeaderTextLeft isSorted={props.sortOrder.columnName === props.columnName}>
                        {props.children}
                        <S.Icon style={{marginTop: 0, marginBottom: 0}} src={sortIcon()}/>
                    </S.TableCellHeaderTextLeft> :
                    <S.GenericTableCellHeaderText isSorted={props.sortOrder.columnName === props.columnName}>
                        {props.children}
                        <S.Icon style={{marginTop: 0, marginBottom: 0}} src={sortIcon()}/>
                    </S.GenericTableCellHeaderText>}
            </S.TableColumn>
        </S.TableCellWrapper>
    )
};

export default TableColumnSortHeader;