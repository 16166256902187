import React from 'react';
import * as S from '../styles';
import ImportantLink from "./ImportantLinks";
import {locale} from "../locale";
import linksStore from "../utilities/linksStore";

const Footer: React.FC = () => {
    return (
        <div>
            <S.Footer>
                <p>{locale('footer.p1')}</p>
                <p>{locale('footer.p2')}</p>
                <p>
                    {locale('footer.p3.part1')}
                    <a href={linksStore.plantTurnaroundForm}>
                        {locale('links.plantTurnaroundForm')}
                    </a>
                    {locale('footer.p3.part2')}
                </p>
                <p>{locale('footer.p4')}</p>
                <p>
                    {locale('footer.p5.part1')}
                    <a href='tel:403-920-7473'> 403-920-7473 </a>
                    {locale('footer.p5.part2')}
                    <a href='tel:1-877-920-7473'> 1-877-920-7473</a>.
                </p>
            </S.Footer>
            <ImportantLink/>
        </div>
    )
};

export default Footer;
