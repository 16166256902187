import {clearBusy, setBusy, setError, setIsUserAuthorized} from "../actions/appActions";
import {Dispatch} from "redux";
import Log from "./Log";

/**
 * VSTS #155944
   The cancelled error ocurrs on iOS when an api call is occuring and the user navigates
   away from the page to a new origin. It causes an issue where some api calls that
   may be happening while the user is being redirect to the login page.
   This error is okay to swallow because if the error ocurrs, the user is on their way
   to another page.
 */
const CANCELLED_ERROR = "cancelled";

export class Fetch {
    constructor(url: string, method: string, payload: any, dispatch: any, dispatchAppError: boolean) {
        this.url = url;
        this.method = method;
        this.payload = payload;
        this.dispatch = dispatch;
        this.successAction = null;
        this.errorAction = null;
        this.finallyAction = null;
        this.dispatchAppError = dispatchAppError;
        this.headers = new Headers();

        this.headers.set('Content-Type', 'application/json');
        this.headers.set('authorization', `Bearer ${Fetch.token}`);
    }

    url: string;
    method: string;
    payload: any;
    successAction: ((data: any) => void) | null;
    errorAction: ((reason: any) => void) | null;
    finallyAction: (() => void) | null;
    dispatch: Dispatch<any> | null;
    dispatchAppError: boolean;
    headers: Headers;

    static token: string;
    static invalidating: boolean;
    static invalidatedEndpoints: string[];

    success(action: (data: any) => void): Fetch {
        this.successAction = action;
        return this;
    }

    error(action: (ex: any | null) => void): Fetch {
        this.errorAction = action;
        return this;
    }

    finally(action: () => void): Fetch {
        this.finallyAction = action;
        return this;
    }

    withPayload(p: any): Fetch {
        this.payload = p;
        return this;
    }

    post(): void {
        this.method = "post";
        Fetch.InvalidateApiGatewayCache();
        this.execute();
    }

    delete(): void {
        this.method = "delete";
        Fetch.InvalidateApiGatewayCache();
        this.execute();
    }

    get(): void {
        this.method = "get";
        if (Fetch.invalidating && !Fetch.invalidatedEndpoints.includes(this.url)) {
            console.log("INVALIDATING CACHE FOR " + this.url);
            this.headers.set('Cache-Control', 'max-age=0');
            Fetch.invalidatedEndpoints.push(this.url);
        }
        this.execute();
    }
    shouldSwallowError(err: string): boolean {
        return !!(err && err.trim() === CANCELLED_ERROR);

    }
    execute(): void {
        if (this.dispatch) {
            this.dispatch(setBusy());
        }

        fetch(`${process.env.REACT_APP_API_URL}${this.url}`, {
            method: this.method,
            headers: this.headers,
            body: this.payload === null ? undefined : JSON.stringify(this.payload)
        })
            .then(response => {
                if (response.status === 401 && this.dispatch)
                {
                    this.dispatch(setIsUserAuthorized(false));
                }

                if (!response.ok) {
                    if (this.errorAction !== null) {
                        this.errorAction(response.statusText);
                    }
                    return null;
                }

                return response.json()
                    .then(response => {
                        if (this.successAction !== null) {
                            try {
                                this.successAction(response.data);

                            } catch (e) {
                                console.log(e);
                                throw e;
                            }
                        }
                    })
            })
            .catch((reason) => {
                Log.debug(`fetch ${this.url} failed.`);
                Log.debug(`reason - ${JSON.stringify(reason)}.`);
                if (!this.shouldSwallowError(reason.toString())) {
                    if (this.dispatch && this.dispatchAppError) {
                        this.dispatch(setError(reason.toString()));
                    }
                    if (this.errorAction !== null) {
                        this.errorAction(reason);
                    }
                }

            })
            .finally(() => {
                if (this.dispatch) {
                    this.dispatch(clearBusy());
                }
                if (this.finallyAction !== null) {
                    this.finallyAction();
                }
            });

    }

    static Build(url: string, dispatch: Dispatch<any> | null = null, dispatchAppError = true): Fetch {
        return new Fetch(url, "get", null, dispatch, dispatchAppError);
    }

    static SetAccessToken(token: string) {
        Fetch.token = token;
    }

    static InvalidateApiGatewayCache() {
        console.log("START INVALIDATING CACHE");
        this.invalidating = true;
        this.invalidatedEndpoints = [];
    }
}