import React, { FC, createContext, useContext, useState, useEffect, useCallback } from 'react';
import jwtDecode from 'jwt-decode';

import { AuthPropTypes, DecodedToken, AuthProviderPropTypes, AuthContextInterface } from "./interfaces";
import { TC_ACCESS_TOKEN, TC_SESSION_KEY, JWT_CUSTOM_GROUPS, SKIP_AUTHENTICATION } from "./constants";
import {
	getAccessTokenStatus,
	getTcAccessToken,
	extractTokenIfRequired as extractTokenIfRequiredCreator,
	getUser,
} from "./util";
import useTypedSelector from "../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {setIsAuthenticationInitialized, setIsUserAuthorized} from "../../actions/appActions";
import Log from "../../utilities/Log";

export const AuthContext = createContext<AuthContextInterface>({
	initialize: () => {},
	isAuthenticated: () => false,
	isAuthorized: () => false,
	renderUnauthorized: () => 'Unauthorized',
	getUser: () => ({
		'custom:groups': "",
		exp: 0,
		given_name: "",
		name: "",
		email: ""
	}),
	failedLoginErrorMessage: undefined
});

export const useAuth = () => useContext(AuthContext);

/**
 *
 * There are 2 pieces to the Authentication
 * 1. AuthProvider component
 * 2. Auth component
 *
 * The general flow consists:
 * 1. Auth Provider is mounted at the root.
 * 2. If there is a valid access token, propagate it to the onAccessTokenChange
 * 3. Set up all the functions required for authentication, but don't authenticate yet.
 * 4. <Auth /> component is mounted anywhere in the application.
 * 5. If Initialize has not been called yet, call it.
 * 5a. Attempt to parse the token from the query string
 * 5b. Redirect to ping federate url if parsing failed, return back to current page and run steps 1 through 5.
 * 6. Scheduled job is set up that every x minutes will check if the token is within the threshold (5 minutes by default)
 * 7. If token is within threshold, attempt to refresh it.
 * 8. If checkRefresh runs  and fails, then fail the authentication
 *
 * @param props
 */
export const AuthProvider: FC<AuthProviderPropTypes> = (props: AuthProviderPropTypes) => {
	Log.debug(`AuthProvider`);
	Log.debug(`OAUTH URL = ${props.oauthUrl}`);
	Log.debug(`token refresh URL = ${props.tokenRefreshUrl}`);
	Log.debug(`refresh check time = ${props.refreshCheckTime}`);
	Log.debug(`refresh threshold = ${props.refreshThreshold}`);

	const dispatch = useDispatch();
	const isInitialized = useTypedSelector(x => x.app.isAuthenticationInitialized);
	const setIsInitialized = (x: boolean) => {
		dispatch(setIsAuthenticationInitialized(x));
	};
	const [ refreshIntervalId, setRefreshInterval ] = useState<number|null>(null);
	const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(getAccessTokenStatus(props.refreshThreshold).isValid);
	const [ errorMsg, setErrorMsg ] = useState<string>("");

	const extractTokenIfRequired = extractTokenIfRequiredCreator(props);

	// Sets the token again on render in case any updates happened
	if (getAccessTokenStatus(props.refreshThreshold).isValid) {
		if (props.onAccessTokenChange) {
			props.onAccessTokenChange(getTcAccessToken() ?? "")
		}
	}

	// Runs every amount of seconds specified by refreshThreshold
	const checkForRefresh = useCallback(() => {
		Log.debug(`checkForRefresh`);
		if (getAccessTokenStatus(props.refreshThreshold).requiresRefresh) {
			Log.debug(`Refreshing Token`);
			fetch(props.tokenRefreshUrl, {
				method: 'POST',
				body: JSON.stringify({
					token: getTcAccessToken() || "",
					sessionkey: localStorage.getItem(TC_SESSION_KEY) || '',
				})
			})
				.then((r) => {
					if (r.status !== 200) {
						Log.debug(`Refresh Token Failed`);
						setIsAuthenticated(false);
						dispatch(setIsUserAuthorized(false));
					}
					return r.json();
				})
				.then((response) => {
					Log.debug(`Refresh Response = ${response.data}`);
					localStorage.setItem(TC_ACCESS_TOKEN, response.data.id_token);
					if (props.onAccessTokenChange) props.onAccessTokenChange(response.data.id_token);
				})
				.catch(e => {
					Log.debug(`Refresh Token Failed - ${e}`);
					setIsAuthenticated(false);
					dispatch(setIsUserAuthorized(false));
				});
		}
	}, [props, dispatch]);


	// refreshIntervalId holds the current instance running of the job running on the timer configured
	// by refreshCheckTime
	useEffect(
		() => {
			if (isAuthenticated && !refreshIntervalId) {
				Log.debug(`setting refresh interval`);
				checkForRefresh();
				setRefreshInterval(window.setInterval(checkForRefresh, props.refreshCheckTime));
			}
			return () => {
				if (refreshIntervalId) {
					Log.debug(`clearing refresh interval`);
					window.clearInterval(refreshIntervalId);
					setRefreshInterval(null);
				}
			};
		},
		[checkForRefresh, isAuthenticated, props.refreshCheckTime, refreshIntervalId]
	);

	// Called on the first component mount in Auth
	const initialize = () => {
		if (!isInitialized) {
			Log.debug(`Initialize`);
			const resultErrorMsg = extractTokenIfRequired();
			if (resultErrorMsg) {
				setErrorMsg(resultErrorMsg);
				return;
			} else if (!getAccessTokenStatus(props.refreshThreshold).isValid) {
				const url = encodeURIComponent(window.location.href);
				return window.location.assign(`${props.oauthUrl}&state=${url}`);
			}
			dispatch(setIsUserAuthorized(true));
			setIsAuthenticated(true);
			setIsInitialized(true);
		}
	};

	const isAuthorized = (groups: string[] = []) => {
		const func = () => {
			if (!isAuthenticated) return false;
			if (SKIP_AUTHENTICATION) return true;
			if (groups.length < 1) return true;
			const token = jwtDecode<DecodedToken>(getTcAccessToken() || "");
			if (!token[JWT_CUSTOM_GROUPS]) return false;

			for (let i = 0; i < groups.length; i++) {
				if(groups[i] !== ""){
					if (token[JWT_CUSTOM_GROUPS].indexOf(groups[i]) > -1) {
						return true;
					}
				}
			}
			return false;
		};
		const result = func();
		Log.debug(`isAuthorized = ${result}`);
		dispatch(setIsUserAuthorized(result));
		return result;
	};

	return (
		<AuthContext.Provider
			value={{
				initialize,
				isAuthenticated: () => isAuthenticated,
                isAuthorized,
				renderUnauthorized: () => "",
				getUser,
				failedLoginErrorMessage: errorMsg
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

AuthProvider.defaultProps = {
	tokenRefreshUrl: process.env.REACT_APP_TOKEN_REFRESH_URL,
	oauthUrl: process.env.REACT_APP_OAUTH_URL,
	refreshCheckTime: 300000,
	refreshThreshold: 300
};

export const Auth: FC<AuthPropTypes> = (props: AuthPropTypes) => {
	const { initialize, isAuthenticated, isAuthorized, renderUnauthorized, failedLoginErrorMessage } = useAuth();
	const isAppInitialized = useTypedSelector(x => x.app.isAuthenticationInitialized);
	useEffect(initialize,[isAppInitialized]);
	return <React.Fragment>
        {Boolean(failedLoginErrorMessage) ? failedLoginErrorMessage : isAuthenticated() && isAuthorized(props.groups) ? props.children : renderUnauthorized()}
    </React.Fragment>
};

Auth.defaultProps = {
    groups: [],
    children: ""
};