import React from 'react';
import * as S from "../styles";
import TCLogo from '../assets/TCLogo.png';
import MaintenanceImg from "../assets/maintenance.png"
import {locale} from "../locale";

const UnderConstructionPage: React.FC = () => {

    return (
        <S.MaintenanceContainer>
            <S.MaintenanceContent>
                <S.TcLogo src={TCLogo} alt="TC Energy"/>
                <h1>{locale('maintenance.sorry')}</h1>
                <S.MaintenanceImg src={MaintenanceImg} alt="TC Energy"/>
                <p><a href="my.tccustomerexpress.com">my.tccustomerexpress.com</a> {locale('maintenance.message')}</p>
                <br/>
                <p>{locale('Error.404.assistance')} <a href="tel:4039207473">(403) 920-7473.</a></p>
            </S.MaintenanceContent>
        </S.MaintenanceContainer>
    )
};

export default UnderConstructionPage;