import React, { ChangeEvent, useEffect, useState } from 'react';
import * as S from "../../styles";
import { locale } from "../../locale";
import BackNavigationHeader from "../BackNavigationHeader";
import { RouteComponentProps, useHistory } from "react-router";
import { useRouteMatch, useParams } from "react-router-dom";
import Bulletin from "./Bulletin";
import {
    IBulletin,
    IBulletinForEdit,
    IHasValidationResults,
    ILookupValueForEdit, IServiceProvider, ISubscriptionCategory, SearchKeyTypes
} from "../../types";
import TextEditor from "./TextEditor";
import { EditorState, Modifier } from "draft-js";
import { Fetch } from "../../utilities/Fetch";
import { useDispatch } from "react-redux";
import { clearIsDirty, setIsDirty, showConfirm } from "../../actions/appActions";
import colors from "../../styles/Colors";
import Validation from "../../utilities/validation/Validation";
import BulletinValidation from "../../utilities/validation/BulletinValidation";
import ResultModal from "../ResultModal";
import { EditorUtilities } from "../../utilities/EditorUtilities";
import useTypedSelector from "../../hooks/useTypedSelector";
import { stateFromHTML } from "draft-js-import-html";
import useSubscriptions from "../../hooks/useSubscriptions";
import DateInput from "../DateInput";
import { BulletinUtilities } from '../../utilities/BulletinUtilities';
import DateUtilities from "../../utilities/DateUtilities";
import { DateTime } from "luxon";
import { clearSearchOptionsLoaded } from "../../actions/searchActions";
import BulletinFileUpload from "./BulletinFileUpload"

interface IInputRadio {
    name: string;
    checked: boolean;
    action: () => void;
    option1: string;
    option2: string;
    label?: string;
}

const InputRadio: React.FC<IInputRadio> = (props) => {
    return (
        <div>
            {props.label != null && <S.BulletinInputLabel>{props.label}</S.BulletinInputLabel>}
            <S.RadioInput style={{ marginTop: '16px' }}>
                <input type="radio" name={props.name}
                    checked={!props.checked}
                    onChange={props.action} />
                <p>{props.option1}</p>
                <input type="radio" name={props.name}
                    checked={props.checked}
                    onChange={props.action} />
                <p>{props.option2}</p>
            </S.RadioInput>
        </div>
    )
};

interface IInputSelect {
    value: ILookupValueForEdit;
    options: ILookupValueForEdit[];
    label: string;
    action: (e: ChangeEvent<HTMLSelectElement>) => void;
    hasError?: boolean;
}

const InputSelect: React.FC<IInputSelect> = (props) => {
    return (
        <S.BulletinInputWrapper>
            <S.Select value={props.value.id} onChange={props.action} style={{ minHeight: '38px', maxWidth: '200px' }}
                hasError={props.hasError} isPlaceholder={props.value.id.toString() === ""} defaultValue={""}>
                <option value="" disabled>{props.label}</option>
                {props.options.map((value) => <option key={`to-option-${value.id}`}
                    value={value.id}>{value.name}</option>)}
            </S.Select>
        </S.BulletinInputWrapper>
    )
};

const BulletinForm: React.FC<RouteComponentProps> = () => {
    const defaultLookupValue: ILookupValueForEdit = { id: "", name: "" };
    const initialState: IBulletinForEdit = {
        id: null,
        serviceProvider: defaultLookupValue,
        isCritical: false,
        category: defaultLookupValue,
        subject: '',
        contactName: '',
        contactPhone: '',
        startDateTimeUtc: '',
        body: '',
        editorState: '',
        editor: {
            editorState: EditorState.createEmpty(),
            isHtml: false,
        },
        publishedDateTimeUtc: '',
        hasFieldDirty: {},
        validationResults: {},
        publishedBy: '',
        subscriptionId: -1,
        subscription: null,
        scheduleDateTimeUtc: '',
        lastSavedDateTimeUtc: '',
        isDraft: false,
    };
    const [serviceProvider, setServiceProvider] = useState<ILookupValueForEdit[]>([]);
    const [bulletinCategory, setBulletinCategory] = useState<ILookupValueForEdit[]>([]);
    const [bulletinToEdit, setBulletinToEdit] = useState<IBulletinForEdit>(initialState);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [publishSuccess, setPublishSuccess] = useState<boolean>(false);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>('');
    const [inputAsHTML, setInputAsHTML] = useState<boolean>(false);
    const [editorState, setEditorState] = useState(initialState.editor.editorState);
    const [changeToDraft, setChangeToDraft] = useState(false);
    const isDirty = useTypedSelector(state => state.app.isDirty);
    const isBusy = useTypedSelector(x => x.app.isBusy);
    const subscriptionsLoaded = useTypedSelector(x => x.subscriptions.subscriptionsLoaded);
    const subscriptions = useTypedSelector(x => x.subscriptions.subscriptions);
    const allowBulletinScheduling = useTypedSelector(state => state.toggles.allowBulletinScheduling);
    const history = useHistory();
    const { id } = useParams();
    const isCopyBulletin = useRouteMatch('/BulletinEdit/Copy/:id');
    const dispatch = useDispatch();
    useSubscriptions();

    useEffect(() => {
        if (id) {
            const isCopy = isCopyBulletin?.isExact || false;
            Fetch.Build(`/bulletin/${id}`, dispatch)
                .success(bulletin => {
                    prepareForEdit(bulletin, isCopy);
                    if (!bulletin.publishedDatetimeUtc && !isCopy)
                        setChangeToDraft(true);
                })
                .get()
        }
    }, [id, isCopyBulletin?.isExact, dispatch]);

    useEffect(() => {
        if (changeToDraft) {
            Fetch.Build(`/bulletin/setDraft/${id}`)
                .post();
        }
    }, [changeToDraft, id]);

    useEffect(() => {
        if (subscriptionsLoaded) {
            const sp: IServiceProvider[] = [];
            subscriptions.filter(sc => sc.subscriptionCategoryId === 3).forEach(s => {
                if (sp.filter(sp => sp.id === s.serviceProviderId).length === 0) {
                    sp.push(s.serviceProvider);
                }
            });
            sp.sort((a, b) => (a.name > b.name) ? 1 : -1);
            setServiceProvider(sp.map(x => ({ name: x.name, id: x.id.toString() })));
        }
    }, [subscriptionsLoaded, subscriptions]);

    useEffect(() => {
        if (subscriptionsLoaded) {
            const sc: ISubscriptionCategory[] = [];
            subscriptions.filter(sc => sc.subscriptionCategoryId === 3 && sc.serviceProviderId === parseInt(bulletinToEdit.serviceProvider.id)).forEach(s => {
                sc.push(s)
            });
            const nt = sc.map(x => ({ name: x.name, id: x.id.toString() }));
            setBulletinCategory(nt);
            if (nt.filter(x => x.id === bulletinToEdit.subscriptionId.toString()).length === 0 && bulletinToEdit.subscriptionId !== -1) {
                setBulletinToEdit({
                    ...bulletinToEdit,
                    category: { id: "", name: "" },
                })
            }
        }
    }, [bulletinToEdit.serviceProvider]);


    useEffect(() => {
        if (!isDirty) {
            return;
        }
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [isDirty]);

    const draftClick = () => {
        const isValid = validateInputs();
        dispatch(showConfirm({
            headerText: locale('save'),
            bodyText: isValid ? locale('bulletin.confirmation.saveDraft') : locale('bulletin.confirmation.invalidInputs'),
            buttonCancelText: isValid ? locale('cancel') : locale('ok'),
            buttonConfirmText: isValid ? locale('save') : null,
            background: isValid ? undefined : colors.error_background,
            border: isValid ? undefined : S.ErrorBorderStyle,
            onConfirm: saveDraft
        }));
    };

    const previewClick = () => {
        setShowPreview(true);
    };

    const publishClick = () => {
        const isValid = validateInputs();
        const isScheduled = bulletinToEdit.scheduleDateTimeUtc !== '';
        dispatch(showConfirm({
            headerText: isScheduled ? 'Schedule the Notice' : locale('publish'),
            bodyText: isValid ? (isScheduled ? `Schedule the current notice? All subscribers to this notice type will be emailed at the scheduled time. (${bulletinToEdit.scheduleDateTimeUtc})` : locale('bulletin.confirmation.publish')) : locale('bulletin.confirmation.invalidInputs'),
            buttonCancelText: isValid ? locale('cancel') : locale('ok'),
            buttonConfirmText: isValid ? (isScheduled ? 'Schedule' : locale('publish')) : null,
            background: isValid ? undefined : colors.error_background,
            border: isValid ? undefined : S.ErrorBorderStyle,
            onConfirm: publish
        }));
    };

    const prepareForEdit = (bulletin: IBulletin, isCopy: boolean) => {
        const e = EditorUtilities.prepareForEdit(bulletin.editorState, bulletin);
        const editBulletin = BulletinUtilities.prepareBulletinForEdit(bulletin, isCopy, e);
        if (isCopy)
            dispatch(setIsDirty());
        setBulletinToEdit(editBulletin);
        setEditorState(e.editorState);
        setInputAsHTML(e.isHtml);
    };

    const publish = () => {
        save(
            BulletinUtilities.prepareBulletin(bulletinToEdit, false),
            bulletinToEdit.scheduleDateTimeUtc !== '' ?
                locale('bulletin.schedule.success') : locale('bulletin.publish.success'));
    };

    const saveDraft = () => {
        save(BulletinUtilities.prepareBulletin(bulletinToEdit, true), locale('bulletin.saveDraft.success'))
    };

    const save = (payload: IBulletin, successMessage: string) => {
        Fetch.Build('/bulletin/publish', dispatch)
            .withPayload(payload)
            .error(e => {
                setResultText(`${locale('bulletin.publish.error')} ${e}`);
                setPublishSuccess(false);
            })
            .success(() => {
                setResultText(successMessage);
                setPublishSuccess(true);
                dispatch(clearSearchOptionsLoaded(SearchKeyTypes.ADMIN));
                dispatch(clearSearchOptionsLoaded(SearchKeyTypes.PUBLIC));
            })
            .finally(() => {
                setShowResult(true);
            })
            .post();
    };

    const validateInputs = () => {

        const bulletin = bulletinToEdit;
        bulletin.validationResults['serviceProvider'] = BulletinValidation.ServiceProviderValidation().validate(bulletinToEdit.serviceProvider.id.toString());
        bulletin.validationResults['category'] = BulletinValidation.NoticeTypeValidation().validate(bulletinToEdit.category.id.toString());
        bulletin.validationResults['subject'] = BulletinValidation.SubjectValidation(bulletinToEdit.subject).validate(bulletinToEdit.subject);
        bulletin.validationResults['startDateTimeUtc'] = BulletinValidation.StartDateValidation(bulletinToEdit.startDateTimeUtc ?? '', bulletinToEdit.scheduleDateTimeUtc ?? '').validate(bulletinToEdit.startDateTimeUtc ?? '');
        bulletin.validationResults['scheduleDateTimeUtc'] = BulletinValidation.ScheduledDateValidation(bulletinToEdit.scheduleDateTimeUtc || '').validate(bulletinToEdit.scheduleDateTimeUtc || '');
        bulletin.validationResults['contactName'] = BulletinValidation.ContactNameValidation(bulletinToEdit.contactName).validate(bulletinToEdit.contactName);
        bulletin.validationResults['contactPhone'] = BulletinValidation.PhoneNumberValidation(bulletinToEdit.contactPhone).validate(bulletinToEdit.contactPhone);
        bulletin.validationResults['body'] = BulletinValidation.BodyValidation(bulletin.editor.editorState.getCurrentContent().getPlainText()).validate(bulletin.editor.editorState.getCurrentContent().getPlainText());
        setBulletinToEdit(bulletin);
        return Object.keys(bulletinToEdit.validationResults).every(field => bulletinToEdit.validationResults[field].isValid);
    };

    const reValidateStartDate = (newDate: string) => {
        const bulletin = bulletinToEdit;
        bulletin.validationResults['startDateTimeUtc'] = BulletinValidation.StartDateValidation(bulletinToEdit.startDateTimeUtc ?? '', newDate ?? '').validate(bulletinToEdit.startDateTimeUtc ?? '');
        setBulletinToEdit(bulletin);
    };

    const updateInputs = (selectedBulletin: IBulletinForEdit, fieldName: string, newValue: string, validation: Validation | null = null, capitalize = false) => {
        const isDirty = selectedBulletin.hasFieldDirty ? selectedBulletin.hasFieldDirty : {};
        isDirty[fieldName] = true;

        if (capitalize && fieldName !== 'body') {
            newValue = newValue.toUpperCase();
        }

        validateInput(selectedBulletin, fieldName, newValue, validation);

        const getNewValue = () => {
            if (fieldName === 'serviceProvider') {
                return serviceProvider.find(x => x.id === newValue) ?? defaultLookupValue
            }

            if (fieldName === 'category') {
                return bulletinCategory.find(x => x.id === newValue) ?? defaultLookupValue
            }

            if (fieldName === 'scheduleDateTimeUtc') {
                reValidateStartDate(newValue);
            }

            return newValue;
        };

        setBulletinToEdit({
            ...bulletinToEdit,
            [fieldName]: getNewValue(),
            subscriptionId: fieldName === 'category' ? parseInt(newValue) : selectedBulletin.subscriptionId,
            hasFieldDirty: isDirty
        });

        dispatch(setIsDirty());
    };

    const updateEditor = (selectedBulletin: IBulletinForEdit, newValue: EditorState) => {
        const isDirty = selectedBulletin.hasFieldDirty ? selectedBulletin.hasFieldDirty : {};
        const newEditorState = selectedBulletin.editor;
        newEditorState.editorState = newValue;
        if (editorState.getCurrentContent() !== newValue.getCurrentContent()) {
            validateEditor(selectedBulletin, newValue);
            isDirty['body'] = true;
            dispatch(setIsDirty());
        }
        setEditorState(newValue);
        setBulletinToEdit({
            ...bulletinToEdit,
            body: EditorUtilities.getRawHTML(newEditorState),
            editor: newEditorState,
            hasFieldDirty: isDirty
        });
    };

    const handlePastedText = (selectedBulletin: IBulletinForEdit, text: string, html: string) => {
        const es = bulletinToEdit.editor;
        if (es.isHtml && !html) {
            return false; // handle paste as per default editor behavior
        }
        console.log(html);
        const isDirty = selectedBulletin.hasFieldDirty ? selectedBulletin.hasFieldDirty : {};
        const newState = Modifier.replaceWithFragment(
            bulletinToEdit.editor.editorState.getCurrentContent(),
            bulletinToEdit.editor.editorState.getSelection(),
            stateFromHTML(html || text).getBlockMap());
        const newEditor = EditorState.push(es.editorState, newState, 'insert-fragment');
        validateEditor(selectedBulletin, newEditor);
        es.editorState = newEditor;

        setEditorState(newEditor);
        setBulletinToEdit({
            ...bulletinToEdit,
            body: EditorUtilities.getRawHTML(es),
            editor: es,
            hasFieldDirty: isDirty,
        });
        return true;
    };

    const updateContentType = (isHtml: boolean) => {
        setInputAsHTML(isHtml);
        const newEditorState = bulletinToEdit.editor;
        newEditorState.isHtml = isHtml;
        setBulletinToEdit({
            ...bulletinToEdit,
            body: EditorUtilities.getRawHTML(newEditorState),
            editor: newEditorState,
        })
    };

    const validateEditor = (selectedBulletin: IBulletinForEdit, newValue: EditorState) => {
        selectedBulletin.validationResults['body'] = {
            isValid: newValue.getCurrentContent().hasText(),
            message: newValue.getCurrentContent().hasText() ? "" : "Notice cannot be empty"
        };
    };

    const validateInput = (selectedBulletin: IBulletinForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        const validationResults = selectedBulletin.validationResults;
        if (validation) {
            validationResults[fieldName] = validation.validate(newValue);
        }
    };

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : "";
    }

    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/BulletinManagement')}
                leftContent={locale('bulletin.form.pageTitle')} />
            <S.BulletinFormWrapper>
                <S.BulletinFormRow>
                    <S.BulletinFormColumn>
                        <InputSelect value={bulletinToEdit.serviceProvider} options={serviceProvider}
                            label={`${locale('bulletin.form.serviceProvider')}*`}
                            action={option => updateInputs(bulletinToEdit, 'serviceProvider', option.target.value, BulletinValidation.ServiceProviderValidation())}
                            hasError={hasError(bulletinToEdit, "serviceProvider")} />
                        <InputSelect value={bulletinToEdit.category} options={bulletinCategory}
                            label={`${locale('bulletin.form.category')}*`}
                            action={option => updateInputs(bulletinToEdit, "category", option.target.value, BulletinValidation.NoticeTypeValidation())}
                            hasError={hasError(bulletinToEdit, 'category')} />
                        <S.BulletinInputWrapper>
                            <S.BulletinInput
                                isDirty={bulletinToEdit?.hasFieldDirty ? bulletinToEdit.hasFieldDirty["subject"] : false}
                                title={errorMessage(bulletinToEdit, "subject")}
                                hasError={hasError(bulletinToEdit, "subject")}
                                type="text"
                                placeholder={`${locale('bulletin.form.subject')}*`}
                                onChange={text => updateInputs(bulletinToEdit, "subject", text.target.value, BulletinValidation.SubjectValidation(text.target.value))}
                                value={bulletinToEdit.subject} />
                        </S.BulletinInputWrapper>
                        <InputRadio name={'isCritical'} checked={bulletinToEdit['isCritical']}
                            label={locale('bulletin.form.critical')}
                            action={() => setBulletinToEdit({
                                ...bulletinToEdit,
                                'isCritical': !bulletinToEdit['isCritical']
                            })}
                            option1={locale('bulletin.form.nonCritical')}
                            option2={locale('bulletin.form.critical')} />
                    </S.BulletinFormColumn>
                    <S.BulletinFormColumn>
                        <S.BulletinInputWrapper>
                            <S.BulletinInput
                                isDirty={bulletinToEdit?.hasFieldDirty ? bulletinToEdit.hasFieldDirty["contactName"] : false}
                                title={errorMessage(bulletinToEdit, "contactName")}
                                hasError={hasError(bulletinToEdit, "contactName")}
                                type="text"
                                placeholder={locale('bulletin.form.contactName')}
                                onChange={text => updateInputs(bulletinToEdit, "contactName", text.target.value, BulletinValidation.ContactNameValidation(text.target.value))}
                                value={bulletinToEdit.contactName} />
                        </S.BulletinInputWrapper>
                        <S.BulletinInputWrapper>
                            <S.BulletinInput
                                isDirty={bulletinToEdit?.hasFieldDirty ? bulletinToEdit.hasFieldDirty["contactPhone"] : false}
                                title={errorMessage(bulletinToEdit, "contactPhone")}
                                hasError={hasError(bulletinToEdit, "contactPhone")}
                                type="text"
                                placeholder={locale('bulletin.form.contactPhone')}
                                onChange={text => updateInputs(bulletinToEdit, "contactPhone", text.target.value, BulletinValidation.PhoneNumberValidation(text.target.value))}
                                value={bulletinToEdit.contactPhone} />
                        </S.BulletinInputWrapper>
                        <S.BulletinInputWrapper>
                            <DateInput
                                date={bulletinToEdit.startDateTimeUtc ? new Date(bulletinToEdit.startDateTimeUtc) : null}
                                placeholderText={locale('bulletin.form.effectiveDate')}
                                handleChange={(date: Date) => updateInputs(bulletinToEdit, 'startDateTimeUtc', date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.publishDateTimestamp) : '', BulletinValidation.StartDateValidation(date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.publishDateTimestamp) : '', bulletinToEdit.scheduleDateTimeUtc ?? ''))}
                                isDirty={bulletinToEdit?.hasFieldDirty ? bulletinToEdit.hasFieldDirty["startDateTimeUtc"] : false}
                                hasError={hasError(bulletinToEdit, "startDateTimeUtc")}
                                errorMessage={errorMessage(bulletinToEdit, "startDateTimeUtc")}
                                customClassName={'bulletin-dateInput'}
                                showTimeSelect={true}
                                minDate={new Date()}
                                isClearable={true} /> <S.TimezoneIndicator title={locale('displayTimeZone.disclaimer')}>{locale("bulletins.displayTimeZone")}</S.TimezoneIndicator>
                        </S.BulletinInputWrapper>
                    </S.BulletinFormColumn>
                </S.BulletinFormRow>
                <hr />
                <div>
                    <S.BulletinEditorWrapper>
                        <TextEditor
                            editorState={editorState}
                            placeholder={`${locale('bulletin.form.textEditorPlaceholder')}*`}
                            action={(editorState: EditorState) => updateEditor(bulletinToEdit, editorState)}
                            pasteAction={(text: string, html: string) => handlePastedText(bulletinToEdit, text, html)}
                            hasError={hasError(bulletinToEdit, 'body')}
                            toggle={() => {
                                updateContentType(!inputAsHTML);
                            }}
                            isEnabled={inputAsHTML} />
                            {hasError(bulletinToEdit, 'body') && <div style={{ color: 'red' }}>{bulletinToEdit.validationResults.body.message}</div>}
                    </S.BulletinEditorWrapper>
                    <div style={{ fontSize: '10px', textAlign: 'end', marginTop: '8px' }}>
                        <span>*Note: Cannot paste tables in Rich Text mode, use HTML mode and paste in html content</span><br />
                        <span>{inputAsHTML ? 'Editor in HTML mode' : 'Editor in Rich Text mode'}</span>
                    </div>
                </div>
                
                <div style={{ margin: '24px 0' }}>
                    {allowBulletinScheduling && <div style={{ marginBottom: '24px' }}>
                        <S.BulletinInputLabel>{locale('Bulletin.form.schedule')}</S.BulletinInputLabel>
                        <S.BulletinInputWrapper>
                            <DateInput
                                date={bulletinToEdit.scheduleDateTimeUtc ? new Date(bulletinToEdit.scheduleDateTimeUtc) : null}
                                placeholderText={locale('bulletin.form.schedulePlaceholder')}
                                handleChange={(date: Date) => updateInputs(bulletinToEdit, 'scheduleDateTimeUtc', date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.publishDateTimestamp) : '', BulletinValidation.ScheduledDateValidation(date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.publishDateTimestamp) : ''))}
                                isDirty={bulletinToEdit?.hasFieldDirty ? bulletinToEdit.hasFieldDirty["scheduleDateTimeUtc"] : false}
                                hasError={hasError(bulletinToEdit, "scheduleDateTimeUtc")}
                                errorMessage={errorMessage(bulletinToEdit, "scheduleDateTimeUtc")}
                                customClassName={'bulletin-dateInput'}
                                showTimeSelect={true}
                                minDate={new Date()}
                                isClearable={true} />
                        </S.BulletinInputWrapper>
                    </div>}
                    <S.Button style={{ marginLeft: '0' }} onClick={draftClick}>{locale('saveDraft')}</S.Button>
                    <S.Button onClick={previewClick}>{locale('preview')}</S.Button>
                    <S.Button
                        onClick={publishClick}>{bulletinToEdit.scheduleDateTimeUtc !== '' ? locale('schedule') : locale('publish')}</S.Button>       
                </div>

                { 
                    (id && !(isCopyBulletin?.isExact || false)) 
                        ? (<BulletinFileUpload bulletinId = {parseInt(id)} 
                                            pipelineProvider= {bulletinToEdit.serviceProvider.name}>
                            </BulletinFileUpload>) 
                        :('')
                }        
                
            </S.BulletinFormWrapper>

            {showPreview &&
                <S.PreviewWrapper onClick={() => setShowPreview(false)}>
                    <S.ModalOverlay />
                    <S.BulletinPreview>
                        <Bulletin data={bulletinToEdit} isDraft={true} />
                    </S.BulletinPreview>
                </S.PreviewWrapper>
            }
            {showResult && !isBusy &&
                <ResultModal text={resultText}
                    success={publishSuccess}
                    onExit={() => {
                        setShowResult(false);
                        if (publishSuccess) {
                            dispatch(clearIsDirty());
                            history.push('/BulletinManagement');
                        }
                    }}
                />}
        </S.PageWithNavigationContainer>
    )
};

export default BulletinForm;