import {IHeatValue} from "../types";
import DateUtilities from "./DateUtilities";

export class HeatValueUtilities {
    static getHeatValue(heatValues: IHeatValue[], area: string, publishedDate: string, toolTipDate = '') {
        const result = heatValues.filter(x => {
            return x.acronym === area && DateUtilities.ParseDate(x.startDateTimeUtc) <= (publishedDate === '' ? DateUtilities.Now().startOf("day") : DateUtilities.ParseDate(publishedDate)) && DateUtilities.ParseDate(x.startDateTimeUtc) <= DateUtilities.ParseDate(toolTipDate === '' ? x.startDateTimeUtc : toolTipDate)
        }).sort((a, b) => (a.startDateTimeUtc <= b.startDateTimeUtc) ? 1 : -1);

        if (result.length >= 1) {
            return result[0].value;
        }
        return NaN;
    }
}