import React, {useEffect, useState} from 'react';
import * as S from "../styles";
import {locale} from "../locale";
import {useDispatch} from "react-redux";
import useTypedSelector from "../hooks/useTypedSelector";
import SortFunctions from "../utilities/SortFunctions";
import useAreas from "../hooks/useAreas";
import trashIcon from "../icons/trash-2.svg";
import {IArea, IAreaForEdit, ICapabilityForEdit, IHasValidationResults} from "../types";
import {clearBusy, clearIsDirty, setBusy, setIsDirty, showConfirm, showDiscardConfirm} from "../actions/appActions";
import addIcon from "../icons/plus-circle.svg";
import BackNavigationHeader from "./BackNavigationHeader";
import {useHistory} from "react-router";
import {Fetch} from "../utilities/Fetch";
import {setAreasLoaded, clearOutagesLoaded} from "../actions/outagesActions";
import ResultModal from "./ResultModal";
import DateInput from "./DateInput";
import {AdminUtilities} from "../utilities/AdminUtilities";
import {clearOutageChartDataLoaded} from "../actions/outageChartActions";
import AreaCapabilityValidation from "../utilities/validation/AreaCapabilityValidation";
import Validation from "../utilities/validation/Validation";
import colors from "../styles/Colors";
import AreaValidation from "../utilities/validation/AreaValidation";
import {DateTime} from "luxon";
import DateUtilities from "../utilities/DateUtilities";


const AdminAreaCapability: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isDirty = useTypedSelector(state => state.app.isDirty);
    const areas = useTypedSelector(state => state.outages.areas);
    const areasLoaded = useTypedSelector(state => state.outages.areasLoaded);
    const isBusy = useTypedSelector(state => state.app.isBusy);
    const [capabilitiesToEdit, setCapabilitiesToEdit] = useState<ICapabilityForEdit[]>([]);
    const [areasToEdit, setAreasToEdit] = useState<IAreaForEdit[]>([]);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("none");
    const [resultSuccess, setResultSuccess] = useState<boolean>(false);

    useAreas();

    useEffect(() => {
        if (!areasLoaded) {
            return;
        }

        setCapabilitiesToEdit(AdminUtilities.PrepareCapabilitiesForEdit(areas));
        setAreasToEdit(areas.filter(a => a.capabilities.length > 0).sort(SortFunctions.DefaultAreaSortFunction()).map(a => AdminUtilities.PrepareAreaForEdit(a, null)));

    }, [areasLoaded, areas]);

    const columnWidths = {
        start: '120',
        end: '120',
        capability: '87',
        unit: '50',
        addRow: '20',
        removeRow: '20',
        notes: '300',
        capabilityAssumptions: '150',
    };

    const updateInputs = (selectedCapability: ICapabilityForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        selectedCapability.hasFieldDirty[fieldName] = true;

        if (validation) {
            selectedCapability.validationResults[fieldName] = validation.validate(newValue);
        }

        const updatedCapabilities = capabilitiesToEdit.map(capability =>
            capability.id === selectedCapability.id ? {
                ...capability,
                [fieldName]: newValue,
            } : capability
        );

        if (fieldName === "startDate" || fieldName === "endDate") {
            // requires re-validation of all capabilities in the area for overlap/gaps
            reValidateCapabilitiesForArea(updatedCapabilities.filter(x => x.areaId === selectedCapability.areaId));
        }

        setCapabilitiesToEdit(updatedCapabilities);
        dispatch(setIsDirty());
    };

    const reValidateCapabilitiesForArea = (capabilitiesForArea: ICapabilityForEdit[]) => {
        for (const capability of capabilitiesForArea) {
            const otherCapabilitiesForArea = capabilitiesForArea.filter(x => x.id !== capability.id);
            capability.validationResults["startDate"] =
                AreaCapabilityValidation.StartDateValidation(capability, otherCapabilitiesForArea).validate(capability.startDate);
            capability.validationResults["endDate"] =
                AreaCapabilityValidation.EndDateValidation(capability, otherCapabilitiesForArea).validate(capability.endDate);
        }
    };

    const updateAreaInputs = (selectedArea: IAreaForEdit, fieldName: string, newValue: string, validation: Validation | null = null) => {
        selectedArea.hasFieldDirty[fieldName] = true;

        if (validation) {
            selectedArea.validationResults[fieldName] = validation.validate(newValue);
        }

        const updatedAreas = areasToEdit.map(area =>
            area.id === selectedArea.id ? {
                ...area,
                [fieldName]: newValue,
            } : area
        );

        setAreasToEdit(updatedAreas);
        dispatch(setIsDirty());
    };

    function removeRow(capability: ICapabilityForEdit) {
        const capabilitiesToUpdate = capabilitiesToEdit.map(a => a);
        const index = capabilitiesToUpdate.indexOf(capability);
        capabilitiesToUpdate.splice(index, 1);

        // can create gaps, need to re-validate capabilities for the area in question
        reValidateCapabilitiesForArea(capabilitiesToUpdate.filter(x => x.areaId === capability.areaId));

        setCapabilitiesToEdit(capabilitiesToUpdate);
        dispatch(setIsDirty());
    }

    function addRow(area: IArea) {
        const update = [...capabilitiesToEdit];
        const latestCapabilityEndDate = DateTime.max(...update.filter(x => x.areaId === area.id).map(x => DateTime.fromFormat(x.endDate, DateUtilities.uiDateFormat)));
        update.push(AdminUtilities.PrepareNewCapabilityForEdit(latestCapabilityEndDate, area));
        setCapabilitiesToEdit(update);
        dispatch(setIsDirty());
    }

    function saveClick() {
        if(!isDirty){
            return;
        }

        const isValid: boolean = isEverythingValid();
        dispatch(showConfirm({
            headerText: locale('saveCapabilityHeader'),
            bodyText: locale(isValid ? 'saveCapabilityBody' : 'validation.areaCapability.save.validationError'),
            buttonCancelText: isValid ? locale('cancel') : locale('ok'),
            buttonConfirmText: isValid ? locale('save') : null,
            background: isValid ? undefined : colors.error_background,
            border: isValid ? undefined : S.ErrorBorderStyle,
            onConfirm: save
        }));
    }

    function isEverythingValid() {
        return capabilitiesToEdit.every(outage => Object.keys(outage.validationResults).every(field => outage.validationResults[field].isValid))
            && areasToEdit.every(area => Object.keys(area.validationResults).every(field => area.validationResults[field].isValid));
    }

    function cancelClick() {
        if (isDirty) {
            dispatch(showDiscardConfirm(() => {
                dispatch(clearIsDirty());
                history.push("/Admin");
            }));
        } else {
            history.push("/Admin");
        }
    }

    function save() {
        Fetch.Build("/areas", dispatch)
            .withPayload([
                ...areasToEdit,
                ...AdminUtilities.PrepareCapabilitiesForSave(capabilitiesToEdit)])
            .success(serviceSuccess())
            .error(serviceError())
            .finally(serviceFinally())
            .post();
    }

    function serviceSuccess() {
        return () => {
            setResultText(locale("genericSaveSuccess"));
            setResultSuccess(true);
            dispatch(clearIsDirty());
            dispatch(setAreasLoaded(false));
            dispatch(clearOutagesLoaded());
            dispatch(clearOutageChartDataLoaded());
        };
    }
    function hasError(item: IHasValidationResults, field: string): boolean {
        return item.validationResults[field] ? !item.validationResults[field].isValid : false;
    }

    function errorMessage(item: IHasValidationResults, field: string): string {
        return item.validationResults[field] ? item.validationResults[field].message : "";
    }

    function serviceError() {
        return () => {
            setResultText(locale("genericError"));
            setResultSuccess(false);
        };
    }

    function serviceFinally() {
        return () => {
            dispatch(clearBusy(true));
            setShowResult(true);
        };
    }

    return (
        <S.PageWithNavigationContainer>
            <BackNavigationHeader handleBackNavigation={() => history.push('/Admin')}
                                  leftContent={locale('admin.options.areaCapability')}/>

            {areasLoaded &&
            <S.InformationContainer>
                <S.FlexContainerRow>
                    <S.InformationItem>
                        <S.AdminPageHeader>{locale('admin.options.areaCapability')}</S.AdminPageHeader>
                    </S.InformationItem>
                </S.FlexContainerRow>

                <S.ActionButtonContainer>
                    <S.SecondaryAction>
                        <div onClick={cancelClick}>{locale('cancel')}</div>
                    </S.SecondaryAction>
                    <S.SaveButton disabled={!isDirty} onClick={saveClick}>{locale('save')}</S.SaveButton>
                </S.ActionButtonContainer>
            </S.InformationContainer>}

            {areasLoaded && areasToEdit.map(area => {
                return <S.AdminAreaCard key={area.acronym}>
                    <S.AdminAreaHeader color={area.color}>
                        <S.AdminAreaHeaderText>{area.typeName}: {area.displayName}</S.AdminAreaHeaderText>
                    </S.AdminAreaHeader>

                    {/*header*/}
                    <S.TableHeaderRow key={area.id + "-CapabilityHeaderRow"}>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width={columnWidths.start}>
                                <S.TableCellHeaderText>{locale('start')}</S.TableCellHeaderText>
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width={columnWidths.end}>
                                <S.TableCellHeaderText>{locale('end')}</S.TableCellHeaderText>
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width={columnWidths.capability}>
                                <S.TableCellHeaderText>{locale('capability')}</S.TableCellHeaderText>
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                    </S.TableHeaderRow>

                    {/*rows*/}
                    {capabilitiesToEdit.filter(c => c.areaId === area.id).map(capability => {
                        return <S.TableRow key={capability.id}>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumn width={columnWidths.start}  title={errorMessage(capability, "startDate")}>
                                    <DateInput
                                        isDirty={capability.hasFieldDirty ? capability.hasFieldDirty["startDate"] : false}
                                        hasError={hasError(capability, "startDate")}
                                        date={new Date(capability.startDate)}
                                        handleChange={(date: Date) => updateInputs(capability, "startDate", date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.uiDateFormat) : '')}/>
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumn width={columnWidths.end} title={errorMessage(capability, "endDate")}>
                                    <DateInput
                                        isDirty={capability.hasFieldDirty ? capability.hasFieldDirty["endDate"] : false}
                                        date={new Date(capability.endDate)}
                                        hasError={hasError(capability, "endDate")}
                                        handleChange={(date: Date) => updateInputs(capability, "endDate", date !== null ? DateTime.fromJSDate(date).toFormat(DateUtilities.uiDateFormat) : '')}/>
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumn width={columnWidths.capability}>
                                    <S.AdminTextInput
                                        isDirty={capability.hasFieldDirty ? capability.hasFieldDirty["capability"] : false}
                                        title={errorMessage(capability, "capability")}
                                        hasError={hasError(capability, "capability")}
                                        type="number"
                                        min={0}  // minimum is actually "1", but putting one in here messes up the steps (goes 1001, 2001, 3001, etc)
                                        step={1000}
                                        onChange={text => updateInputs(capability, "capability", text.target.value, AreaCapabilityValidation.CapabilityValidation)}
                                        onKeyDown={e => { if (e.key === "-") {e.preventDefault();}}} // don't allow minus sign,
                                        value={capability.capability}/>
                                </S.TableColumn>
                            </S.TableCellWrapperNoGrow>
                            <S.TableCellWrapperNoGrow>
                                <S.TableColumnNoGrow width={columnWidths.removeRow}>
                                    {capability.allowDelete && <S.TableCellIconRemove src={trashIcon} width={18} height={18}
                                                           onClick={() => removeRow(capability)}/>}
                                </S.TableColumnNoGrow>
                            </S.TableCellWrapperNoGrow>
                        </S.TableRow>
                    })}
                    {/*Add row*/}
                    <S.TableRow key={area.id + '-AddRow'}>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumnNoGrow width={columnWidths.addRow}>
                                <S.TableCellIcon src={addIcon} width={15} height={15} onClick={() => addRow(area)}/>
                            </S.TableColumnNoGrow>
                            <S.TableCellAddText
                                onClick={() => addRow(area)}>{locale('addNewEntry')}</S.TableCellAddText>
                        </S.TableCellWrapperNoGrow>
                    </S.TableRow>

                    <S.HeaderLine/>

                    <S.TableHeaderRow key={area.id + "-CapabilityAssumptionsHeaderRow"}>
                        <S.TableCellWrapperNoGrow>
                            <S.TableColumn width={columnWidths.capabilityAssumptions}>
                                <S.TableCellHeaderText>{locale('admin.baseCapability.capabilityAssumptions')}</S.TableCellHeaderText>
                            </S.TableColumn>
                        </S.TableCellWrapperNoGrow>
                    </S.TableHeaderRow>


                    <S.TableRow key={area.id + '-CapabilityAssumptions'}>
                        <S.TableCellWrapper>
                            <S.TableColumn width={columnWidths.notes}>
                                <S.AdminTextArea
                                    isDirty={area.hasFieldDirty ? area.hasFieldDirty["capabilityAssumptions"] : false}
                                    title={errorMessage(area, "capabilityAssumptions")}
                                    hasError={hasError(area, "capabilityAssumptions")}
                                    value={area.capabilityAssumptions ?? ""}
                                    onChange={text => updateAreaInputs(area, "capabilityAssumptions", text.target.value, AreaValidation.CapabilityAssumptionsValidation(area))}
                                    rows={8}/>
                            </S.TableColumn>
                        </S.TableCellWrapper>
                    </S.TableRow>

                </S.AdminAreaCard>
            })}

            {areasLoaded && !isBusy &&
            <S.InformationContainer>
                <S.ActionButtonContainer>
                    <S.SecondaryAction>
                        <div onClick={cancelClick}>{locale('cancel')}</div>
                    </S.SecondaryAction>
                    <S.SaveButton disabled={!isDirty} onClick={saveClick}>{locale('save')}</S.SaveButton>
                </S.ActionButtonContainer>
            </S.InformationContainer>}

            <div style={{padding: "10px"}}></div>

            {showResult && <ResultModal text={resultText} success={resultSuccess} onExit={() => {
                setShowResult(false);
                if (!areasLoaded)
                    dispatch(setBusy());
            }}
            />}
        </S.PageWithNavigationContainer>
    );
};

export default AdminAreaCapability;