import {IBulletin, ColumnSort} from "../types";

import {
    BulletinActionTypes,
    CLEAR_BULLETINS_LOADED,
    SET_BULLETINS,
    SET_BULLETINS_LOADED,
    SET_SENT_BULLETINS,
    SET_BULLETIN_LIST_SORT,
    SET_BULLETIN_ADMIN_SORT,
    SET_BULLETIN_ROWS_PER_PAGE,
    SET_BULLETIN_CURRENT_PAGE,
    SET_BULLETIN_PAGING_RESET,
} from "../types/BulletinActionTypes";
import { PagingInfo } from "../components/PageSelector";

export function setBulletins(bulletins: IBulletin[]): BulletinActionTypes {
    return {
        type: SET_BULLETINS,
        bulletins: bulletins
    }
}

export function setBulletinsLoaded(loaded: boolean): BulletinActionTypes {
    return {
        type: SET_BULLETINS_LOADED,
        loaded: loaded
    }
}

export function clearBulletinsLoaded(): BulletinActionTypes {
    return {type: CLEAR_BULLETINS_LOADED}
}

export function setSentBulletins(sentBulletins: IBulletin[]): BulletinActionTypes {
    return {
        type: SET_SENT_BULLETINS,
        sentBulletins: sentBulletins
    }
}

export function setBulletinListSort(bulletinListSort: ColumnSort): BulletinActionTypes {
    return {
        type: SET_BULLETIN_LIST_SORT,
        bulletinListSort: bulletinListSort
    }
}

export function setBulletinAdminSort(bulletinAdminSort: ColumnSort): BulletinActionTypes {
    return {
        type: SET_BULLETIN_ADMIN_SORT,
        bulletinAdminSort: bulletinAdminSort
    }
}

export function setBulletinRowsPerPage(rowsPerPage: number): BulletinActionTypes{
    return {
        type: SET_BULLETIN_ROWS_PER_PAGE,
        rowsPerPage: rowsPerPage
    }
}

export function setBulletinCurrentPage(currentPage: PagingInfo<IBulletin>): BulletinActionTypes{
    return {
        type: SET_BULLETIN_CURRENT_PAGE,
        currentPage: currentPage
    }
}

export function setBulletinPagingReset(resetPaging: boolean): BulletinActionTypes{
    return{
        type: SET_BULLETIN_PAGING_RESET,
        resetPaging: resetPaging
    }
}