import {
    IArea,
    IAreaForEdit, IAreaHistoryForSave, IChartData,
    IDailyCapability,
    IDailyChartData, IDailyContract, IDailyFlow, IMainlineDailyCapability,
    IOutage,
    IOutageForEdit,
    IOutageForSave
} from "../types";

export const BUILD_CHART_DATA = "BUILD_CHART_DATA";
export const BUILD_DOP_SAVE_DATA = "BUILD_DOP_SAVE_DATA";
export const PARSE_CHART_DATA = "PARSE_CHART_DATA";
export const PARSE_MAINLINE_MAINTENANCE_DATA = "PARSE_MAINLINE_MAINTENANCE_DATA";

interface IParseMainlineMaintenanceData {
    type: typeof PARSE_MAINLINE_MAINTENANCE_DATA;
    file: File;
    startDate: string;
    endDate: string;
}

export interface IParseMainlineMaintenanceDataResponse {
    capabilities: IMainlineDailyCapability[];
}

export interface IBuildChartDataResponse {
    [key: string]: IChartData[];
}

interface IBuildChartData {
    type: typeof BUILD_CHART_DATA;
    outages: IOutage[];
    historicalOutages: IOutage[];
    areas: IArea[];
    dailyData: IDailyChartData[];
}

interface IBuildDopSaveData {
    type: typeof BUILD_DOP_SAVE_DATA;
    outages: IOutageForEdit[];
    previousOutages: IOutage[];
    historicalOutages: IOutage[];
    areas: IAreaForEdit[];
    includeChartData: boolean;
    dailyData: IDailyChartData[];
}

export interface IBuildDopDataWorkerResponse {
    outages: IOutageForSave[];
    chartData: IDailyCapability[];
    areas: IAreaHistoryForSave[];
}

interface IParseChartData {
    type: typeof PARSE_CHART_DATA;
    file: File;
    areas: IArea[];
}

export interface IParseChartDataWorkerResponse {
    capabilities: IDailyCapability[];
    flows: IDailyFlow[];
    contracts: IDailyContract[];
}

export const WORKER_SUCCESS = "SUCCESS";
export const WORKER_FAILURE = "FAILURE";
export const WORKER_PROGRESS = "PROGRESS";

export interface IResponse<T> {
    responseType: typeof WORKER_SUCCESS | typeof WORKER_FAILURE | typeof WORKER_PROGRESS;
    data: T;
}

export interface IFailureResponse extends IResponse<any> {
    error: any;
}

export type WorkerActionTypes = IBuildChartData | IBuildDopSaveData | IParseChartData | IParseMainlineMaintenanceData;