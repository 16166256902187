import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const ScrollToTop =() => {
    window.scrollTo(0,0);
};

export default function RouterScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        ScrollToTop();
    }, [pathname]);

    return null;
}