import styled from "styled-components";
import {device} from "./DeviceSizes";

export const MoreOptions = styled.div`
  display: flex;
`;

export const MoreOptionsItem = styled.div`
    height: 32px;
    min-width: 32px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
    margin-left: 10px;
    border-radius: 4px;
    background: #FFFFFF;
    cursor: pointer;
    @media ${device.mobile}{
      img {
        margin: 8px !important;
      }
    }
    @media print {
        display: none !important;
    }
`;

export const MoreOptionsLabel = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #555759;   
    margin-right: 10px;
    @media ${device.mobile}{
      display: none !important;
    }
`;