import useTypedSelector from "../hooks/useTypedSelector";
import DateUtilities from "./DateUtilities";
import {IArea, ICapability} from "../types";
import {AdminUtilities} from "./AdminUtilities";

function GetSelectedPublishDateAreaCapability(area: IArea): ICapability|undefined {
    const selectedPublishDate = useTypedSelector(x => x.outageComparison.publishDates.selectedPublishDate);

    return AdminUtilities.GetAreaCapabilityAndRangeByDate(DateUtilities.ParseDate(selectedPublishDate), area.capabilities);
}

export default GetSelectedPublishDateAreaCapability;