export default class ArrayUtilities {
    static Unique<T>(array: T[]): T[] {
        return array.filter((n, i) => array.indexOf(n) === i);
    }

    static Flatten<T>(arrayOfArrays: T[][]): T[] {
        let result: T[] = [];
        for (const array of arrayOfArrays) {
            result = [...result, ...array];
        }
        return result;
    }
}