import React, {ReactNode, useEffect, useState} from "react";
import {isMobile} from 'react-device-detect';
import PublishDatesSelector from "./PublishDatesSelector";
import DisplayValuesSelector from "./DisplayValuesSelector";
import {OutageControlsContainer, OutageControlsInner, RightControlsContainer} from "../styles/OutageControlStyles";
import {setHasEndedInteracting, setHideRef, setIsOpen, setScrollEndRef} from "../actions/outagesControlsActions";
import useTypedSelector from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import { VolumeUnits } from '../types';
import { setVolumeUnit } from '../actions/appActions';

type Renderable = void | boolean | JSX.Element | ReactNode;

interface Props {
    noCompareTo?: boolean;
    showDisplayValues?: boolean;
    showIfTopScroll?: boolean;
    renderers?: {
        left?: () => Renderable;
        center?: () => Renderable;
        right?: () => Renderable;
    };
    hasBanner: boolean;
    noPublishedList?: boolean;
    selectPublishDate?: string;
}

const OutageControls: React.FC<Props> = ({noCompareTo = false, noPublishedList = false, selectPublishDate = '', showDisplayValues = true, showIfTopScroll = false, renderers = {}, hasBanner = false}: Props) => {
    const dispatch = useDispatch();
    const isOpen = useTypedSelector(state => state.outageControls.isOpen);
    const hideRef = useTypedSelector(state => state.outageControls.hideRef);
    const scrollEndRef = useTypedSelector(state => state.outageControls.scrollEndRef);
    const hasEndedInteracting = useTypedSelector(state => state.outageControls.hasEndedInteracting);
    const [selectedUnit, setSelectedUnit] = useState<string>(useTypedSelector(x => x.app.volumeUnit));

    const [scrollPosition, setScrollPosition] = useState(0);
    const onTimeoutEnd = () => {
        dispatch(setIsOpen(false));
    };

    const onInteraction = () => {
        dispatch(setIsOpen(true));
        dispatch(setHasEndedInteracting(false));
        setScrollPosition(window.scrollY);
        clearTimeout(scrollEndRef);

        setScrollEndRef(setTimeout(() => {
            dispatch(setHasEndedInteracting(true))
        }, 50))
    };

    useEffect(() => {
        dispatch(setVolumeUnit(selectedUnit as VolumeUnits));

        window.addEventListener('scroll', onInteraction);
        return () => {
            window.removeEventListener('scroll', onInteraction);
        }
    }, []);

    useEffect(() => {
        if (hasEndedInteracting) {
            dispatch(setHideRef(setTimeout(onTimeoutEnd, 3000)))
        } else {
            clearTimeout(hideRef)
        }
    }, [hasEndedInteracting]);

    const units=Object.values(VolumeUnits);

    return (
        <OutageControlsContainer hasBanner={hasBanner}>
            <OutageControlsInner isOpen={!isMobile || (isOpen || (showIfTopScroll && scrollPosition === 0))}>
                <div>
                    <PublishDatesSelector noCompareTo={noCompareTo} noPublishedList={noPublishedList} selectPublishDate={selectPublishDate} />
                    {renderers.left && renderers.left()}
                </div>
                {renderers.center && renderers.center()}
                <RightControlsContainer>
                    {renderers.right && renderers.right()}
                    {showDisplayValues && <DisplayValuesSelector 
                        units={units} 
                        defaultValue={selectedUnit}
                        onChange={(newValue) => {
                            setSelectedUnit(newValue);
                        }}
                    />}
                </RightControlsContainer>
            </OutageControlsInner>
        </OutageControlsContainer>
    )
};

export default OutageControls