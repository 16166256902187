import React from "react";
import {IArea, IOutageComparison, Status} from "../../types";
import {AdminUtilities} from "../../utilities/AdminUtilities";
import DateUtilities from "../../utilities/DateUtilities";
import {locale} from "../../locale";
import useTypedSelector from "../../hooks/useTypedSelector";
import { convertAndDisplayVolumeUnit } from "../../utilities/UnitConversions";

interface ImpactTooltipInfoProps {
    allAreas: IArea[];
    outage: IOutageComparison;
}

const ImpactTooltipInfo: React.FC<ImpactTooltipInfoProps> = (props) => {
    const unit = useTypedSelector(state => state.app.volumeUnit);

    let capability;
    const area = props.allAreas.find(area => area.id === props.outage.areaId);
    if(props.outage.changeStatus === Status.REMOVED){
        capability =  props.outage.previous && area ?
            AdminUtilities.GetAreaCapabilityObjectByOutage(props.outage.previous, area.capabilities) : undefined;
    } else {
        capability =  props.outage.current && area ?
            AdminUtilities.GetAreaCapabilityObjectByOutage(props.outage.current, area.capabilities) : undefined;
    }

    const areaAcronym = area?.acronym ? area?.acronym : '';
    const baseOperationalCapability = capability?.capability ? capability?.capability : 0;
    const startDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.startDate) : '';
    const endDate = capability?.startDate ? DateUtilities.UiDateUtility.Reformat(capability?.endDate) : '';
    return (
        <span>
                {locale("outageCard.tooltip.text1").replace("{area-name}", areaAcronym)}
            {" "}<b>{convertAndDisplayVolumeUnit(baseOperationalCapability, unit)}</b>{" "}
            {locale("outageCard.tooltip.text2")}
            {" "}
            <b>
                {locale("outageCard.tooltip.text3")
                .replace('{start-date}', startDate)
                .replace('{end-date}', endDate)}
            </b>
            </span>
    )
};

export default ImpactTooltipInfo;