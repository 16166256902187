import {IOutage, IOutageComparison} from "./index";
import OutageComparison from "./OutageComparison";

export class OutageComparisonBuilder {
    static Build(currentOutages: IOutage[], historicalOutages: IOutage[]): IOutageComparison[] {
        const current = currentOutages.map(x => {return {impactId: x.impactId, outageId: x.outageId}});
        const removed = historicalOutages.map(x => {return {impactId: x.impactId, outageId: x.outageId}})
            .filter(x => {return !current.find(y => {return y.outageId === x.outageId && y.impactId === x.impactId;})});
        const keys = [...current, ...removed];
        const compares = keys.map(x => new OutageComparison(
         currentOutages.find(y => y.impactId === x.impactId && y.outageId === x.outageId) ?? null,
         historicalOutages.find(y => y.impactId === x.impactId && y.outageId === x.outageId) ?? null)
        );
        return compares;
    }
}