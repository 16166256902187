import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import { setAppStatus, clearBusy, setBusy, setError } from "../actions/appActions";
import { AppStatus } from "../types";
import Log from "../utilities/Log";

function useAppStatus(): void {
    const dispatch = useDispatch();
    const status = useTypedSelector(x => x.app.status);
    const error = useTypedSelector(state => state.app.error);

    useEffect(() => {
        const headers = new Headers();
        headers.set('Content-Type', 'application/json');
        dispatch(setBusy());
        fetch(`${process.env.REACT_APP_API_URL}/status`, {
            method: 'get',
            headers: headers,
            body: undefined
        })
            .then(response => {
                return response.json()
                    .then(response => {
                        try {
                            dispatch(setAppStatus(response.message as AppStatus))
                        } catch (e) {
                            console.log(e);
                            throw e;
                        }
                    })
            })
            .catch((reason) => {
                Log.debug(`fetch /status failed.`);
                Log.debug(`reason - ${JSON.stringify(reason)}.`);
                dispatch(setError(reason.toString()));
            })
            .finally(() => {
                dispatch(clearBusy());
            });
    }, [status, error, dispatch]);

}

export default useAppStatus;