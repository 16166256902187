import fetchHelper from "../utilities/fetchHelper";
import {setAreas} from "../actions/outagesActions";
import useTypedSelector from "./useTypedSelector";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

function useAreas(): void {
    const dispatch = useDispatch();
    const areasLoaded = useTypedSelector(x => x.outages.areasLoaded);
    const error = useTypedSelector(state => state.outages.error);

    useEffect(() => {
        if (!areasLoaded && !error) {
            fetchHelper("areas", x => dispatch(setAreas(x)), dispatch);
        }
    }, [areasLoaded, error, dispatch]);

}

export default useAreas;