import React, {useEffect, useState} from "react";
import useApiService from "../../../hooks/useApiService";
import DateUtilities from "../../../utilities/DateUtilities";
import styled from "styled-components";
import {locale} from "../../../locale";
import {DateTime} from "luxon";
import {FontSize} from "../../../styles/FontStyles";
import * as S from "../../../styles"
import {IMainlineDailyCapability, IMainlineDailyCapabilityPublication} from "../../../types";
import {FileDownloadUtilities} from "../../../utilities/FileDownloadUtilities";
import {useDispatch} from "react-redux";

const LastUpdatedMessage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 5px;
    ${FontSize("12px")}
`;

const DisclaimerText = styled.div`
    ${FontSize("14px")};
    white-space: pre-line;
    margin: 15px 5px;
`;

const MinorDisclaimerText = styled.div`
    ${FontSize("12px")};
    white-space: pre-line;
    margin: 15px 5px;
`;

interface IProps {
    capabilities: IMainlineDailyCapability[];
    primaryDisclaimer: string;
    secondaryDisclaimer: string;
}

export const RawMainlineMaintenanceWidget: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState<string>(locale("unknown"));
    const [heatValue, setHeatValue] = useState<string>(locale("unknown"));

    const J_TO_TJ_CONVERSION_FACTOR = 1/1000000000000;
    const J_TO_MJ_CONVERSION_FACTOR = 1/1000000;

    const formatNumberBuilder = (factor: number) => (x: number) => {
        return (x * factor).toLocaleString();
    };

    // values are in Joules.  Convert to TJ for display
    const formatNumber = formatNumberBuilder(J_TO_TJ_CONVERSION_FACTOR);

    useEffect(() => {
        if (props.capabilities.length > 0) {
            const date = DateTime.fromFormat(props.capabilities[0].lastUpdatedDateTimeUtc, DateUtilities.serviceDateFormat, {zone: DateUtilities.universalTimeZone});
            setLastUpdatedDateTime(date.setZone(DateUtilities.mountainTimeZone).toFormat(DateUtilities.uiDateTimeWithTimezone));

            const heatValue = formatNumberBuilder(J_TO_MJ_CONVERSION_FACTOR)(props.capabilities[0].heatingValue);
            setHeatValue(heatValue);
        }
    }, [props.capabilities, J_TO_MJ_CONVERSION_FACTOR]);

    return <S.Widget>
        <S.WidgetTitle>Maintenance</S.WidgetTitle>
        <LastUpdatedMessage>
            <div>{locale("widgets.mainline.capability.lastUpdatedTime").replace("{last-updated-time}", lastUpdatedDateTime)}</div>
            <div>{locale("widgets.mainline.capability.unitMessage")}</div>
        </LastUpdatedMessage>
        <S.WidgetTable>
            <S.WidgetTableRow>
                <S.WidgetTableCell key="gasDayHeader"/>
                <S.WidgetTableCell key="prairiesLineHeader">{locale("widgets.mainline.capability.prairiesLine")}</S.WidgetTableCell>
                <S.WidgetTableCell key="emersonExtension">{locale("widgets.mainline.capability.emersonExtension")}</S.WidgetTableCell>
                <S.WidgetTableCell key="northernOntarioLine">{locale("widgets.mainline.capability.northernOntarioLine")}</S.WidgetTableCell>
                <S.WidgetTableCell key="easternDeliveryArea">{locale("widgets.mainline.capability.easternDeliveryArea")}</S.WidgetTableCell>
                <S.WidgetTableCell key="downstreamStation148">{locale("widgets.mainline.capability.downstreamStation148")}</S.WidgetTableCell>
            </S.WidgetTableRow>
            {props && props.capabilities && props.capabilities.map(x => <S.WidgetTableRow key={x.gasDay.toString()}>
                <S.WidgetTableCell key={`gasDay_${x.gasDay}`}>{DateUtilities.UiDateUtility.Reformat(x.gasDay)}</S.WidgetTableCell>
                <S.WidgetTableCell key={`prairiesLineCapability_${x.gasDay}`}>{formatNumber(x.prairiesLineCapability)}</S.WidgetTableCell>
                <S.WidgetTableCell key={`emersonExtensionCapability_${x.gasDay}`}>{formatNumber(x.emersonExtensionCapability)}</S.WidgetTableCell>
                <S.WidgetTableCell key={`northernOntarioLineCapability_${x.gasDay}`}>{formatNumber(x.northernOntarioLineCapability)}</S.WidgetTableCell>
                <S.WidgetTableCell key={`easternDeliveryAreaCapability_${x.gasDay}`}>{formatNumber(x.easternDeliveryAreaCapability)}</S.WidgetTableCell>
                <S.WidgetTableCell key={`downstreamStation148Capability_${x.gasDay}`}>{formatNumber(x.downstreamStation148Capability)}</S.WidgetTableCell>
            </S.WidgetTableRow>)}
        </S.WidgetTable>
        <DisclaimerText>
            {locale("widgets.mainline.capability.disclaimer").replace("{heat-value}", heatValue) + "\n"}
            {props.primaryDisclaimer}
        </DisclaimerText>
        <MinorDisclaimerText>{props.secondaryDisclaimer}</MinorDisclaimerText>
        <S.WidgetCsvLink>
            <span onClick={() => FileDownloadUtilities.initiateCsvFileDownload("mainline/capabilities/csv?unit=TJ", dispatch)}>{locale("widgets.mainline.capability.downloadLinkText")}</span>
        </S.WidgetCsvLink>
    </S.Widget>
};

const MainlineMaintenanceWidget: React.FC = () => {
    const capabilitiesResult = useApiService<IMainlineDailyCapability[]>([], "mainline/capabilities");
    const publishResult = useApiService<IMainlineDailyCapabilityPublication | null>(null, "mainline/capabilities/latest_publication");

    return <RawMainlineMaintenanceWidget capabilities={capabilitiesResult.data} primaryDisclaimer={publishResult.data?.primaryDisclaimer??""} secondaryDisclaimer={publishResult.data?.secondaryDisclaimer??""}/>

};

export default MainlineMaintenanceWidget;
