import React from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from "draft-js";
import styled from 'styled-components';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface ToggleProps {
    toggle: () => void;
    isEnabled: boolean;
}

interface EditorCustomButtonProps {
    isEnabled: boolean;
}

const EditorCustomButton = styled.div<EditorCustomButtonProps>`
  border: 1px solid #f1f1f1;
  ${props => props.isEnabled ? `
    border-top: 2px solid black;
    border-left: 2px solid black;
  ` : ''};
  padding: 5px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px #a8a8a8;
  }
`;

const HTMLToggle: React.FC<ToggleProps> = (props) => {
    return (
        <EditorCustomButton onClick={props.toggle} isEnabled={props.isEnabled}
                            title={'Switch between HTML and Rich Text'}>HTML</EditorCustomButton>
    )
};

interface Props {
    editorState: EditorState;
    action: any;
    pasteAction: any;
    hasError: boolean;
    placeholder: string;
    toggle: () => void;
    isEnabled: boolean;
}

const TextEditor: React.FC<Props> = (props) => {
    const wrapper = {
        height: '650px',
        border: props.hasError ? '1px solid red' : '1px solid #F5F5F5',
        background: props.hasError ? '#F9CCCA' : '#FFFFFF',
        marginRight: '10px',
    };

    const editor = {
        height: '600px',
        padding: '4px',
        background: props.hasError ? '#F9CCCA' : '#FFFFFF',
        fontSize: '14px',
    };

    const toolbar = {};

    return (
        <Editor
            wrapperStyle={wrapper}
            editorStyle={editor}
            toolbarStyle={toolbar}
            editorClassName={'editor-class'}
            editorState={props.editorState}
            onEditorStateChange={props.action}
            stripPastedStyles={props.isEnabled}
            spellCheck={true}
            placeholder={props.placeholder}
            handlePastedText={props.pasteAction}
            toolbarCustomButtons={[<HTMLToggle key={'toggle-switch'} toggle={props.toggle} isEnabled={props.isEnabled}/>]}
            toolbar={{
                options: ['inline', 'blockType', 'list', 'link', 'history'],
                inline: {inDropdown: false},
                blockType: {
                    options: ['H1', 'H2', 'H3', 'Normal'],
                },
                list: {
                    options: ['unordered', 'ordered'],
                },
            }}
        />
    )
};

export default TextEditor;