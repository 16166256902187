import {INVALID, IOutageComparison} from "../types";
import React from "react";
import * as S from "../styles";
import StatusBadge from "./StatusBadge";
import {locale} from "../locale";
import {FlexContainerColumnNoWrap} from "../styles";
import arrow from "../icons/chevron_right.svg";
import CompareDetailBox from "./CompareDetailBox";
import {OutageComparisonModel} from "./OutageCard";
import OutageCardChip from "./OutageCardChip";
import alertCircle from "../icons/alert-circle-red.svg";
import CompareDetailBoxTooltip from "./CompareDetailBoxTooltip";
import ImpactTooltipInfo from "./tooltip/ImpactTooltipInfo";
import useTypedSelector from "../hooks/useTypedSelector";
import useAreas from "../hooks/useAreas";
import LocalAreaImpactTooltipInfo from "./tooltip/LocalAreaImpactTooltipInfo";

interface OutageCardParameters {
    outage: IOutageComparison;
}

export const OutageCardWide: React.FC<OutageCardParameters> = (props) => {
    useAreas();
    
    const allAreas = useTypedSelector(x => x.outages.areas);
    const units = useTypedSelector(x => x.app.volumeUnit);
    const showFHBCBaseCapability = useTypedSelector(state => state.toggles.showFHBCBaseCapability)

    const model = new OutageComparisonModel(props.outage, units);
    const showPrevious = true;

    function handleClick() {
        // this is temporarily disabled, since we don't have anything more to see that isn't already on the card.
        // this will change down-the-road
        // dispatch(setSelectedOutage(outage));
    }

    function plantTurnAroundActivityCard() {
        return <S.OutageCardWide key={model.outage.id}>
            <StatusBadge status={model.outage.changeStatus}/>
            <S.OutageCardContent>
                <S.FlexContainerColumnNoWrap>
                    <S.FlexContainerRow>
                        <S.OutageCardWideItem width={"85px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasEndDateTimeChanged || model.outage.hasStartDateTimeChanged}
                                label={locale('duration')}
                                data={model.current.durationString()}
                                previous={model.previous?.durationString()}/>
                        </S.OutageCardWideItem>

                        <S.OutageCardWideItem width={"95px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasStartDateTimeChanged}
                                label={locale('start')}
                                data={model.current.startDateString()}
                                previous={model.previous?.startDateString()}/>
                        </S.OutageCardWideItem>

                        <S.OutageCardWideItem width={"95px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasEndDateTimeChanged} label={locale('end')}
                                data={model.current.endDateString()}
                                previous={model.previous?.endDateString()}/>
                        </S.OutageCardWideItem>
                        <S.OutageCardWideItem width={"150px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasTypicalFlowChanged}
                                label={locale('impact')}
                                data={model.current.typicalFlowString()}
                                previous={model.previous?.typicalFlowString()}
                                unit={units}/>
                        </S.OutageCardWideItem>
                    </S.FlexContainerRow>
                </S.FlexContainerColumnNoWrap>
                <FlexContainerColumnNoWrap style={{margin: "auto", marginRight: "0px"}}>
                    <S.RightIcon style={{display: "none"}} src={arrow}/>
                </FlexContainerColumnNoWrap>
            </S.OutageCardContent>
        </S.OutageCardWide>;
    }

    if (model.isPlantTurnAround) {
        return plantTurnAroundActivityCard();
    }

    return (
        <S.OutageCardWide onClick={() => handleClick()} key={model.outage.id}>
            <S.OutageCardWideHeader>
                <div>
                        <span>
                            <StatusBadge status={model.outage.changeStatus}/>
                        </span>
                </div>
                <div>
                    <span>{model.outage.outageId}</span>
                </div>
                {/*Keep this div to keep proper alignment of outage it*/}
                <div>
                    <span> </span>
                </div>
                {/*****************************************************/}
            </S.OutageCardWideHeader>
            <S.OutageCardContent>
                <S.FlexContainerColumnNoWrap>
                    <S.FlexContainerRow>
                        <S.OutageCardWideItem width={"85px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasEndDateTimeChanged || model.outage.hasStartDateTimeChanged}
                                label={locale('duration')}
                                data={model.current.durationString()}
                                previous={model.previous?.durationString()}/>
                        </S.OutageCardWideItem>

                        <S.OutageCardWideItem width={"95px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasStartDateTimeChanged}
                                label={locale('start')}
                                data={model.current.startDateString()}
                                previous={model.previous?.startDateString()}/>
                        </S.OutageCardWideItem>

                        <S.OutageCardWideItem width={"95px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasEndDateTimeChanged} label={locale('end')}
                                data={model.current.endDateString()}
                                previous={model.previous?.endDateString()}/>
                        </S.OutageCardWideItem>

                        {
                            model.current.isLocalArea() && model.current.capabilityPercentageString() !== INVALID
                            && <S.OutageCardWideItem width={"150px"}>
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasFlowCapabilityChanged}
                                    label={locale('serviceAllowable')}
                                    data={model.current.capabilityPercentageString()}
                                    previous={model.previous?.capabilityPercentageString()}/>
                            </S.OutageCardWideItem>
                        }
                        {
                            !model.current.isLocalArea() && model.current.capabilityReductionString() !== INVALID
                            && (model.outage.area.id !== 4 || showFHBCBaseCapability)
                            && <S.OutageCardWideItem width={"150px"}>
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasFlowCapabilityChanged || model.outage.hasAreaBaseCapabilityChanged}
                                    label={locale('impact')}
                                    data={model.current.capabilityReductionString()}
                                    previous={model.previous?.capabilityReductionString()}
                                    /*icon={alertCircle}*/
                                    unit={units}>
                                    <ImpactTooltipInfo allAreas={allAreas} outage={model.outage}/>
                                </CompareDetailBox>
                            </S.OutageCardWideItem>
                        }

                        <S.OutageCardWideItem width={"150px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasFlowCapabilityChanged}
                                label={locale('outageCap')}
                                data={model.current.outageCapabilityString()}
                                previous={model.previous?.outageCapabilityString()}
                                unit={units}/>
                        </S.OutageCardWideItem>

                        {
                            model.current.isLocalArea() &&
                            <S.OutageCardWideItem width={"250px"}>
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasTypicalFlowChanged}
                                    label={locale('typicalFlow')}
                                    data={model.current.typicalFlowString()}
                                    previous={model.previous?.typicalFlowString()}
                                    unit={units}/>
                            </S.OutageCardWideItem>
                        }
                        {
                            model.current.includedLocalAreaOutages() && model.current.localAreaOutageCapabilityReductionString() &&
                            <S.OutageCardWideItem width={"150px"}>
                                {!isNaN(model.current.localAreaOutageCapabilityNumber()) ? 
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasLocalAreaBaseCapabilityChanged}
                                    label={locale('localAreaImpact')}
                                    data={model.current.localAreaOutageCapabilityReductionString()}
                                    previous={model.previous?.localAreaOutageCapabilityReductionString()}
                                    /*icon={alertCircle}*/
                                    unit={units}>
                                    <LocalAreaImpactTooltipInfo outage={model.outage}/>
                                </CompareDetailBox> : 
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasLocalAreaBaseCapabilityChanged}
                                    label={locale('localAreaImpact')}
                                    data={model.current.localAreaOutageCapabilityReductionString()}
                                    previous={model.previous?.localAreaOutageCapabilityReductionString()}/>
                                }
                            </S.OutageCardWideItem>
                        }
                        {
                            model.current.includedLocalAreaOutages() && model.current.localAreaOutageCapabilityString() &&
                            <S.OutageCardWideItem width={"150px"}>
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasLocalAreaOutageCapabilityChanged}
                                    label={locale('localAreaCapability')}
                                    data={model.current.localAreaOutageCapabilityString()}
                                    previous={model.previous?.localAreaOutageCapabilityString()}
                                    unit={units}/>
                            </S.OutageCardWideItem>
                        }
                        {
                           model.current.includedLocalAreaOutages() &&
                            <S.OutageCardWideItem width={"250px"}>
                                <CompareDetailBox
                                    showPrevious={showPrevious}
                                    hasChanged={model.outage.hasTypicalFlowChanged}
                                    label={locale('typicalFlow')}
                                    data={model.current.typicalFlowString()}
                                    previous={model.previous?.typicalFlowString()}
                                    unit={units}/>
                            </S.OutageCardWideItem>
                        }
                        {/* Base Operational Capability? */}

                        {/* Outage Id? */}

                        <S.OutageCardWideItem width={"350px"}>
                            <CompareDetailBox
                                showPrevious={showPrevious}
                                hasChanged={model.outage.hasDescriptionChanged}
                                label={locale('outageDescription')}
                                data={model.current.description}
                                previous={model.previous?.description}/>
                        </S.OutageCardWideItem>


                        <S.OutageCardWideItem width={"auto"} style={{flex: '1 1 auto'}}>
                            <CompareDetailBox
                                showPrevious={showPrevious} hasChanged={model.outage.hasImpactChanged}
                                label={locale('serviceAllowableLocation')}
                                data={model.current.impact}
                                previous={model.previous?.impact}
                                breakLineOn={";"}/>
                        </S.OutageCardWideItem>
                    </S.FlexContainerRow>
                    <OutageCardChip data={`${model.current.impact} ${model.current.description}`}/>
                </S.FlexContainerColumnNoWrap>
                <FlexContainerColumnNoWrap style={{margin: "auto", marginRight: "0px"}}>
                    <S.RightIcon style={{display: "none"}} src={arrow}/>
                </FlexContainerColumnNoWrap>
            </S.OutageCardContent>
        </S.OutageCardWide>);
};
